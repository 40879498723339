import { RouteComponentProps } from 'react-router-dom';
import Crud from 'components/ui/Crud/Crud';
import { useAddLaboratory, useLaboratorys, useLazyLab, useUpdateLab } from 'requests/laboratorys';
import LabForm from 'components/common/forms/LabForm';

const Laboratories = (props: RouteComponentProps) => {
  return (
    <Crud
      confirmationSuccess="Un laboratoire a été ajouté avec succès"
      {...props}
      list={useLaboratorys}
      headers={[
        {
          title: 'Nom du laboratoire',
          dataIndex: 'name',
          key: 'name',
        },
      ]}
      Form={LabForm}
      create={useAddLaboratory}
      update={useUpdateLab}
      get={useLazyLab}
    />
  );
};

export default Laboratories;

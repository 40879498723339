import React, { FunctionComponent, ReactNode } from 'react';
import classNames from 'utils/classNames';
import TooltipComponenet from './TooltipComponent';

export type TextFieldProps = {
  className?: string;
  label?: string;
  error?: string;
  errorColor?: string;
  iconLeft?: ReactNode;
  iconRight?: ReactNode;
  children: React.ReactNode;
  handleError?: boolean;
  labelClassName?: string;
  item?: string;
  classes?: {
    iconsLeft?: string;
    iconRight?: string;
  };
  alwaysShowError?: boolean;
  heightToslice?: number;
  addEtoile?: boolean;
};

const InputWrapper: FunctionComponent<TextFieldProps> = ({
  iconLeft,
  error,
  errorColor = 'red-100',
  iconRight,
  label,
  handleError = true,
  children,
  labelClassName = 'text-white',
  classes = {},
  className,
  alwaysShowError = false,
  item,
  heightToslice,
  addEtoile,
}) => {
  return (
    <div className={classNames(className, handleError && 'pb-2', 'w-full')}>
      {label && (
        <div className="flex items-center gap-5 mb-2 flex-1 min-h-0 h-8">
          <div className={classNames('text-md font-regular whitespace-nowrap flex gap-2', labelClassName)}>
            {label} <div className="text-yellow">{addEtoile && '*'}</div>
          </div>
          {item && <TooltipComponenet item={item} heightToslice={heightToslice} />}
        </div>
      )}
      <div className="flex justify-center items-center relative">
        {iconLeft && (
          <div className={classNames('absolute inset-y-0 left-5 flex items-center justify-center', classes.iconsLeft)}>
            {iconLeft}
          </div>
        )}
        {children}
        {iconRight && (
          <div className={classNames('absolute inset-y-0 right-5 flex items-center justify-center', classes.iconRight)}>
            <button type="button" className="focus:outline-none ">
              {iconRight}
            </button>
          </div>
        )}
      </div>

      {(error || alwaysShowError) && (
        <div className={classNames('lg:text-xs mt-1.5 text-sm h-5 relative left-1', `text-${errorColor}`)}>{error}</div>
      )}
    </div>
  );
};

export default InputWrapper;

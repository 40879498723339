import { useCountedClasses } from 'requests/classes';
import MedecineMenu from '../MedecineMenu/MedecineMenu';

const Devices = () => {
  const { data } = useCountedClasses();

  return <MedecineMenu pathname="/classes" type="classe" data={data?.classesCount} title="Classes thérapeutiques" />;
};

export default Devices;

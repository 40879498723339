import React from 'react';
import { Link } from 'react-router-dom';

interface Props {
  medicineId: string;
  medicineName?: string;
  deviceType?: string;
}

const SuggestionCard = ({ medicineName, deviceType, medicineId }: Props) => {
  return (
    <div className="px-1 bg-blue-100 min-w-96 rounded-md sm: min-w-full">
      <div className="rounded-lg  flex items-center">
        <div className="flex-1 sm:flex-0">
          <Link
            className="text-sm font-semibold mb-1 text-blue-600 hover:text-yellow pb-1"
            to={`/medicament/${medicineId}?from=home`}
          >
            {medicineName}
          </Link>
          <p className=" text-sm font-medium  text-gray-600 mb-1">{deviceType}</p>
        </div>
      </div>
    </div>
  );
};

SuggestionCard.defaultProps = {
  medicineName: 'ACORSPRAY®',
  deviceType: 'AÉROSOL DOSEUR PRESSURISÉ',
};

export default SuggestionCard;

import { ApolloError, MutationHookOptions, MutationTuple } from '@apollo/client';
import { ComponentType, useState } from 'react';
import useSnackbarState from 'hooks/useSnackbarState';
import Snackbar from 'components/ui/Snackbar/Snackbar';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import ConfirmationModal from 'components/ux/ConfirmationModal';

export type MutationParams<T> = T extends (...args: any[]) => MutationTuple<any, infer R> ? R : never;

export interface FormProps<V, I> {
  error?: ApolloError;
  onSubmit: (values: V) => void;
  onClose: () => void;
  loading?: boolean;
  item?: I;
}

export type GenericMutation<V = unknown> = (options?: MutationHookOptions<any, V>) => MutationTuple<any, V>;

interface CreateProps<V, Props extends FormProps<V, null>> {
  api: GenericMutation<V>;
  Form: ComponentType<Props>;
  formProps: Omit<Props, keyof FormProps<V, null>>;
  onDone: () => void;
  onClose: () => void;
  confirmationSuccess?: string;
}

const Create = <V, Props extends FormProps<V, null>>({
  api: useApi,
  Form,
  formProps,
  onDone,
  onClose,
  confirmationSuccess = 'Votre document a été ajoutée avec succès',
}: CreateProps<V, Props>) => {
  const [msg, setMsg] = useState<string>('');

  const [call, { loading, error }] = useApi({
    onCompleted: () => {
      if (confirmationSuccess) setMsg(confirmationSuccess);
    },
  });

  const snackbarState = useSnackbarState({ loading, error });
  return (
    <>
      <ConfirmationModal
        onClose={() => {
          setMsg('');
          onDone();
        }}
        open={msg !== ''}
        msg={msg}
      >
        <TaskAltIcon fill="#323232" fontSize="large" />
      </ConfirmationModal>
      <Form
        {...(formProps as Props)}
        onClose={onClose}
        loading={loading}
        error={error}
        onSubmit={(values) => {
          call({ variables: values });
        }}
      />
      <Snackbar {...snackbarState} />
    </>
  );
};

export default Create;

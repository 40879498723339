import image from 'assets/png/gammeZephir.png';
import Header from '../DetailsMedication/Components/HeaderRouter';
import ZephirDescription from '../../components/common/ZephirDescription';

const GammeZephir = () => {
  return (
    <div className="bg-grey-300 ">
      <Header pageTitle="À propos" subTitle="Gamme Zéphir" />
      <ZephirDescription width="65px" className="justify-center h-14" />
      <div className="flex-1 flex flex-col lg:mx-0 px-28 mt-11 mb-20	2xl:mt-8	sm:px-5 lg:px-20 ">
        <div className="text-blue-600 font-bold text-37 2xl:text-26 lg:text-37 sm:text-22 "> Gamme Zéphir </div>
        <img src={image} alt="" className="mt-8	h-auto sm:h-60" />
      </div>
    </div>
  );
};

export default GammeZephir;

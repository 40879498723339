import Filter from 'components/filtres/Filter';

import { LabelProps } from 'components/filtres/Select/GuideSelect';

import { useHistory, useLocation } from 'react-router-dom';
import { useDCI } from 'requests/dcis';
import { useTypes } from 'requests/medecineTypes';
import { encodeUri, decodeUri } from 'utils/url';
import HomeGeneric from '../HomeGeneric/HomeGeneric';

const DCI = () => {
  const location = useLocation();
  const history = useHistory();
  const { data } = useTypes();
  const uri = decodeUri(location.search);
  const { data: dataDCI } = useDCI({ variables: { id: uri.dci }, fetchPolicy: 'no-cache' });

  function handleChoosed(value: LabelProps | null) {
    if (value) {
      if (value.value === 'Tous') {
        const { deviceType, ...Original } = uri;
        history.replace({
          pathname: location.pathname,
          search: encodeUri({ ...Original }),
        });
      } else {
        history.replace({
          pathname: location.pathname,
          search: encodeUri({ ...uri, deviceType: value.value }),
        });
      }
    }
  }
  const textSelected = dataDCI?.dci.name;
  return (
    <HomeGeneric
      from="dci"
      textSelected={textSelected}
      title="DCI"
      filterComponent={
        <>
          <Filter
            icon={<img alt="" src="trousse.svg" height="23" />}
            title="Types de dispositifs"
            type="dci"
            type2="deviceType"
            data={data?.types.data}
            value={uri.deviceType}
            onChange={handleChoosed}
          />
        </>
      }
    />
  );
};

export default DCI;

import React, { useMemo, ComponentType, useCallback } from 'react';
import { QueryResult, QueryHookOptions } from '@apollo/client';
import { matchPath, useLocation, useHistory, RouteComponentProps } from 'react-router-dom';

import { TableItem, Header } from 'components/common/Table/Table';
import { List as ListType, ListVariables } from 'utils/generic';
import { graphQLResult } from 'utils/graphql';
import { decodeUri, encodeUri } from 'utils/url';
import Modal from 'components/common/Modal/Modal';
import List from './List';

import Create, { GenericMutation, FormProps } from './Create';
import Update, { GenericLazyGet } from './Update';
import Delete from './Delete';

export const PER_PAGE = 10;

interface Props<
  K extends string, // List Api Key
  T extends TableItem, // List Item
  V extends ListVariables, // List Variables
  P extends FormProps<C, G>, // Form Props
  GK extends string = never, // Get api key
  C = never, // Create Api Parameter
  G = never, // One Item Info
> extends RouteComponentProps {
  list: (options?: QueryHookOptions<Record<K, ListType<{ id: string }>>, V>) => QueryResult<Record<K, ListType<T>>, V>;
  headers: Header<T>[];
  create?: GenericMutation<C>;
  get?: GenericLazyGet<Record<GK, G>>;
  update?: GenericMutation<{ id: string } & Partial<C>>;
  Form?: React.ComponentType<P>;
  formProps?: {
    create?: Omit<P, keyof FormProps<C, G>>;
    update?: Omit<P, keyof FormProps<C, G>>;
  };
  delete?: GenericMutation<{ id?: string; ids?: string[] }>;
  onRowClick?: (row: T, index: number) => void;
  confirmationSuccess?: string;
  numberPerPage?: number;
  headerActions?: React.ReactNode;
}

const Crud = <
  K extends string,
  T extends TableItem,
  V extends ListVariables,
  P extends FormProps<C, G>,
  GK extends string = never,
  C = never,
  G = never,
>({
  list: useList,
  headers,
  create,
  update,
  headerActions,
  get,
  Form,
  formProps: {
    create: createFormProps = {} as Omit<P, keyof FormProps<C, G>>,
    update: updateFormProps = {} as Omit<P, keyof FormProps<C, G>>,
  } = {},
  delete: remove,
  match,
  onRowClick,
  confirmationSuccess,
  numberPerPage = 10,
}: Props<K, T, V, P, GK, C, G>) => {
  const { path, url } = match;
  const location = useLocation();
  const history = useHistory();
  const search = decodeUri(location.search);
  const {
    data: listData,
    loading,
    refetch,
    called,
  } = useList({
    variables: {
      page: Number(search.page) || 1,
      perPage: Number(search.perPage) || numberPerPage,
      search: search.search,
    } as any,
    fetchPolicy: 'cache-and-network',
  });

  const createMatch = matchPath(location.pathname, { path: `${path}/add`, exact: true });
  const updateMatch = matchPath(location.pathname, { path: `${path}/update/:id`, exact: true });
  const removeMatch = matchPath(location.pathname, { path: `${path}/delete/:id`, exact: true });

  const { data, count, perPage } = useMemo(() => {
    if (!listData) return { page: 0, perPage: 0, totalPages: 0, data: [], count: 0 };
    return graphQLResult(listData);
  }, [listData]);
  function onPageChange(page: number) {
    history.replace({ search: encodeUri({ ...search, page }) });
  }
  function handleChangeRowsPerPage(nextPerPage: number) {
    history.replace({ search: encodeUri({ ...search, perPage: nextPerPage }) });
  }

  const onDone = useCallback(() => {
    refetch();
    history.push({ pathname: url });
  }, []);
  return (
    <>
      <List
        called={called}
        loading={loading}
        count={count}
        perPage={perPage}
        match={match}
        headerActions={headerActions}
        canUpdate={!!(update && get && Form)}
        canCreate={!!(create && Form)}
        canDelete={!!remove}
        handleChangePage={onPageChange}
        page={Number(search.page) || 1}
        data={data}
        headers={headers}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        onRowClick={onRowClick}
      />

      {create && Form && (
        <Modal open={!!createMatch} onClose={() => history.replace(url)}>
          <Create
            onClose={() => history.push('.')}
            onDone={onDone}
            api={create as GenericMutation<C>}
            Form={Form as ComponentType<FormProps<C, null>>}
            formProps={createFormProps}
            confirmationSuccess={confirmationSuccess}
          />
        </Modal>
      )}

      {update && get && Form && (
        <Modal open={!!updateMatch} onClose={() => history.replace(url)}>
          <Update
            match={updateMatch as any}
            onClose={() => history.push('.')}
            get={get as GenericLazyGet<Record<GK, G>>}
            api={update as GenericMutation<{ id: string } & Partial<C>>}
            onDone={onDone}
            Form={Form as ComponentType<FormProps<unknown, never>>}
            formProps={updateFormProps}
          />
        </Modal>
      )}

      {remove && (
        <Modal open={!!removeMatch} onClose={() => history.replace(url)}>
          <Delete match={removeMatch as any} delete={remove} onDone={onDone} onClose={() => history.replace(url)} />
        </Modal>
      )}
    </>
  );
};

export default Crud;

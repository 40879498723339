import { FunctionComponent, useEffect, useState, useMemo, Fragment } from 'react';
import { useFormik } from 'formik';
import { InputGeneric as Input } from 'components/ux/InputGeneric';
import Button from 'components/Button';
import Modal from 'components/common/Modal/Modal';
import { MedicineLaboratory } from 'requests/types';
import ConfirmationModal from 'components/ux/ConfirmationModal';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import Snackbar from 'components/ui/Snackbar/Snackbar';
import useSnackbarState from 'hooks/useSnackbarState';
import debounce from 'lodash/debounce';
import moment from 'moment';
import * as yup from 'yup';
import { useDCIS } from 'requests/dcis';
import { useClasses } from 'requests/classes';
import { useDevices } from 'requests/devices';
import { useTypes } from 'requests/medecineTypes';

import { LabelProps } from 'components/filtres/FilterByAZ';

import { useLaboratorys } from 'requests/laboratorys';
import { useUpdateMedicines } from 'requests/medicines';
import SelectComponent from './Select';
import PdfComponent from './PdfComponent';

export interface CreateMedicationVariables {
  name: string;
  small_title: string;
  dose: {
    name: string;
    note: string;
    copd: string;
    number: string;
    price: string;
    reproducibility: string;
  };
  components: {
    propellantGaz: string;
    excipient: string;
  };
  visualization: {
    doseRemaining: string;
    doseTaken: string;
  };
  expires: string;
  ready: string;
  readyFirstUse: string;
  price: string;
  action: {
    expire: string;
    expireBronchodilator: string;
    duration: string;
    durationBronchodilator: string;
  };
  coMarketing: string;
  handToMouthCoordination: string;
  inhalationChamber: string;
  modality: {
    inhalation: string;
    utilisation: string;
  };
  existence: string;
  repayment: string;
  opinion: string;
  rank: string;
  refillCartridges: string;
  percentageFineParticles: string;
  dci: string;
  classe: string;
  device: string;
  lab: string;
  deviceDiameter: string;
  refill: string;
  useControl: string;
  updatedDate: string;
  deviceType: string;
  MobileSensorExistence: string;
  deviceResistance: string;
  comment: string;
}

export type ModalProps = {
  open: boolean;
  onClose: () => void;
  onSubmit: (values: CreateMedicationVariables) => void;
  item: MedicineLaboratory;
  userFirstName?: string;
  userLastName?: string;
  userEmail?: string;
};
const ModalMedicine: FunctionComponent<ModalProps> = ({
  open,
  onClose,
  item,
  userFirstName,
  userLastName,
  userEmail,
}) => {
  const [msgConfirmationModal, setMsgConfirmationModal] = useState<string>('');
  const {
    name,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    small_title,
    dose,
    components,
    lab,
    visualization,
    coMarketing,
    handToMouthCoordination,
    modality,
    existence,
    expires,
    repayment,
    opinion,
    rank,
    refillCartridges,
    percentageFineParticles,
    dci,
    classe,
    device,
    action,
    deviceDiameter,
    inhalationChamber,
    refill,
    useControl,
    price,
    updatedDate,
    ready,
    readyFirstUse,
    deviceType,
    MobileSensorExistence,
    deviceResistance,
    comment,
  } = item || {};
  const [opinionFile, setOpinionFile] = useState(null as File | null);
  const [updateMedicine, { loading, error }] = useUpdateMedicines({
    onCompleted: () => {
      onClose();
      setMsgConfirmationModal('Votre demande de correction a bien été prise en compte');
    },
  });
  const snackbarState = useSnackbarState({ loading, error });
  const onSubmit = useMemo(() => {
    return debounce(async (v: any) => {
      const variables = {
        ...v,
        id: item.id,
        valuesEmail: {
          firstName: userFirstName,
          lastName: userLastName,
          nameLab: lab.name,
          createdAt: moment().format('DD/MM/YYYY-hh:mm:ss'),
          nameMededine: name,
          smallTitle: small_title,
          url: `${process.env.REACT_APP_URL_EMAIL}/medicament/${item.id}/admin?request=${lab.id}&page=1`,
          email: userEmail,
        },
        opinion: opinionFile,
      };
      updateMedicine({ variables });
    }, 100);
  }, [opinionFile]);

  const { values, handleChange, handleSubmit, resetForm, setFieldValue, touched, errors, handleBlur } = useFormik({
    initialValues: {
      name: name || '',
      small_title: small_title || '',
      dose: {
        name: dose?.name || '',
        note: dose?.note || '',
        copd: dose?.copd || '',
        number: dose?.number || '',
        price: dose?.price || '',
        reproducibility: dose?.reproducibility || '',
      },
      components: {
        propellantGaz: components?.propellantGaz || '',
        excipient: components?.excipient || '',
      },

      price: price || '',
      action: {
        expire: action?.expire || '',
        expireBronchodilator: action?.expireBronchodilator || '',
        duration: action?.duration || '',
        durationBronchodilator: action?.durationBronchodilator || '',
      },
      coMarketing: coMarketing || '',
      updatedDate: updatedDate || '',
      modality: {
        inhalation: modality?.inhalation || '',
        utilisation: modality?.utilisation || '',
      },
      existence: existence || '',
      repayment: repayment || '',
      opinion: opinion || '',
      rank: rank || '',
      expires: expires || '',
      refillCartridges: refillCartridges || '',
      percentageFineParticles: percentageFineParticles || '',
      dci: dci?.id || '',
      deviceType: deviceType?.id || '',
      classe: classe?.id || '',
      device: device?.id || '',
      deviceDiameter: deviceDiameter || '',
      lab: lab.id || '',
      refill: refill || '',
      useControl: useControl || '',
      handToMouthCoordination: handToMouthCoordination || '',
      inhalationChamber: inhalationChamber || '',
      visualization: {
        doseRemaining: visualization?.doseRemaining || '',
        doseTaken: visualization?.doseTaken || '',
      },
      ready: ready || '',
      readyFirstUse: readyFirstUse || '',
      MobileSensorExistence: MobileSensorExistence || '',
      deviceResistance: deviceResistance || '',
      comment: comment || '',
    } as CreateMedicationVariables,
    validationSchema: yup.object({
      name: yup.string().required('Ce champ est obligatoire'),
      small_title: yup.string().required('Ce champ est obligatoire'),
    }),
    onSubmit,
  });
  interface RenderBorderColorInterface {
    inputName: string;
    itemName: string;
    inputError: string | undefined;
    inputTouched: boolean | undefined;
  }
  const renderBorderColor = ({ inputName, itemName, inputError, inputTouched }: RenderBorderColorInterface) => {
    if (inputName && inputName !== itemName && !inputError) return '#22c55e';
    if (inputTouched && inputError) return '#E71A1AA6';
    return '#323232';
  };
  useEffect(() => {
    if (open) resetForm();
  }, [open]);
  return (
    <>
      <ConfirmationModal
        onClose={() => {
          if (onClose) {
            onClose();
            setMsgConfirmationModal('');
          }
        }}
        open={msgConfirmationModal !== ''}
        msg={msgConfirmationModal}
      >
        <TaskAltIcon fill="#323232" fontSize="large" />
      </ConfirmationModal>
      <Modal
        alignItems="flex-start"
        className="rounded-5 lg:rounded-none lg:h-full z-50 relative text-left py-12 px-12 bg-grey-300 w-9/12	h-9/10 overflow-auto"
        onClose={() => {
          if (onClose) {
            onClose();
            resetForm();
          }
        }}
        open={open}
      >
        <form className="flex flex-col w-full   mt-4 " onSubmit={handleSubmit}>
          <div className="flex gap-5 items-start">
            <Input
              onBlur={handleBlur}
              name="name"
              item={item.name}
              label="Nom du médicament"
              value={values.name}
              onChange={(e) => {
                if (e.target.value[0] !== ' ') {
                  handleChange(e);
                }
              }}
              error={touched.name && errors.name ? String(errors.name) : ''}
              borderColor={renderBorderColor({
                inputName: values.name,
                itemName: item.name,
                inputError: errors?.name,
                inputTouched: touched?.name,
              })}
            />
            <Input
              onBlur={handleBlur}
              name="small_title"
              item={item.small_title}
              label="Sous nom du médicament"
              value={values.small_title}
              onChange={(e) => {
                if (e.target.value[0] !== ' ') {
                  handleChange(e);
                }
              }}
              error={touched.small_title && errors.small_title ? String(errors.small_title) : ''}
              borderColor={renderBorderColor({
                inputName: values.small_title,
                itemName: item.small_title,
                inputError: errors?.small_title,
                inputTouched: touched?.small_title,
              })}
              heightToslice={100}
            />
          </div>
          <SelectComponent
            item={item.classe.name}
            onChange={(e: LabelProps) => setFieldValue('classe', e.value)}
            value={values.classe}
            useData={useClasses}
            label="Classe pharmacothérapeutique"
            labelClassName="text-black font-bold"
            borderColor={renderBorderColor({
              inputName: values.classe,
              itemName: item.classe.id,
              inputError: errors?.classe,
              inputTouched: touched?.classe,
            })}
            heightToslice={100}
          />
          <SelectComponent
            item={item.dci.name}
            onChange={(e: LabelProps) => setFieldValue('dci', e.value)}
            value={values.dci}
            useData={useDCIS}
            label="DCI"
            labelClassName="text-black font-bold"
            borderColor={renderBorderColor({
              inputName: values.dci,
              itemName: item.dci.id,
              inputError: errors?.dci,
              inputTouched: touched?.dci,
            })}
            heightToslice={100}
          />

          <div className="flex gap-5 items-center">
            <SelectComponent
              item={item.deviceType.name}
              onChange={(e: LabelProps) => setFieldValue('deviceType', e.value)}
              value={values.deviceType}
              useData={useTypes}
              label="Type de dispositif"
              labelClassName="text-black font-bold"
              borderColor={renderBorderColor({
                inputName: values.deviceType,
                itemName: item.deviceType.id,
                inputError: errors?.deviceType,
                inputTouched: touched?.deviceType,
              })}
              heightToslice={100}
            />

            <SelectComponent
              item={item.device.name}
              onChange={(e: LabelProps) => setFieldValue('device', e.value)}
              value={values.device}
              useData={useDevices}
              label="Nom de dispositif"
              labelClassName="text-black font-bold"
              borderColor={renderBorderColor({
                inputName: values.device,
                itemName: item.device.id,
                inputError: errors?.device,
                inputTouched: touched?.device,
              })}
              heightToslice={100}
            />
          </div>
          <div className="flex gap-5 items-center">
            <Input
              onBlur={handleBlur}
              item={item.dose.name}
              label="Dosage"
              value={values.dose.name}
              onChange={(e) => {
                if (e.target.value[0] !== ' ') {
                  handleChange(e);
                }
              }}
              name="dose.name"
              placeholder="Dosage"
              error={touched.dose?.name && errors.dose?.name ? String(errors.dose.name) : ''}
              borderColor={renderBorderColor({
                inputName: values.dose.name,
                itemName: item.dose.name,
                inputError: errors?.dose?.name,
                inputTouched: touched?.dose?.name,
              })}
              heightToslice={100}
            />
            <Input
              onBlur={handleBlur}
              item={item.dose.note}
              label="Note à propos du dosage"
              value={values.dose.note}
              onChange={(e) => {
                if (e.target.value[0] !== ' ') {
                  handleChange(e);
                }
              }}
              name="dose.note"
              placeholder="Note à propos du dosage"
              error={touched.dose?.note && errors.dose?.note ? String(errors.dose.note) : ''}
              borderColor={renderBorderColor({
                inputName: values.dose.note,
                itemName: item.dose.note,
                inputError: errors?.dose?.note,
                inputTouched: touched?.dose?.note,
              })}
              heightToslice={100}
            />
          </div>
          <Input
            onBlur={handleBlur}
            item={item.components.propellantGaz}
            label="Gaz propulseur "
            value={values.components.propellantGaz}
            onChange={(e) => {
              if (e.target.value[0] !== ' ') {
                handleChange(e);
              }
            }}
            error={
              touched.components?.propellantGaz && errors.components?.propellantGaz
                ? String(errors.components.propellantGaz)
                : ''
            }
            name="components.propellantGaz"
            borderColor={renderBorderColor({
              inputName: values.components.propellantGaz,
              itemName: item?.components?.propellantGaz,
              inputError: errors?.components?.propellantGaz,
              inputTouched: touched?.components?.propellantGaz,
            })}
            heightToslice={100}
          />
          <Input
            onBlur={handleBlur}
            item={item.components.excipient}
            label="Excipient(s)"
            value={values.components.excipient}
            onChange={(e) => {
              if (e.target.value[0] !== ' ') {
                handleChange(e);
              }
            }}
            error={
              touched.components?.excipient && errors.components?.excipient ? String(errors.components.excipient) : ''
            }
            name="components.excipient"
            borderColor={renderBorderColor({
              inputName: values.components.excipient,
              itemName: item?.components?.excipient,
              inputError: errors?.components?.excipient,
              inputTouched: touched?.components?.excipient,
            })}
            heightToslice={100}
          />
          <div className="flex gap-5 items-center">
            <Input
              onBlur={handleBlur}
              item={item.deviceDiameter}
              label="Diamètre Aérodynamique Massique Médian"
              value={values.deviceDiameter}
              onChange={(e) => {
                if (e.target.value[0] !== ' ') {
                  handleChange(e);
                }
              }}
              error={touched.deviceDiameter && errors.deviceDiameter ? String(errors.deviceDiameter) : ''}
              name="deviceDiameter"
              borderColor={renderBorderColor({
                inputName: values.deviceDiameter,
                itemName: item?.deviceDiameter,
                inputError: errors?.deviceDiameter,
                inputTouched: touched?.deviceDiameter,
              })}
            />
          </div>

          <div className="flex gap-5 items-center">
            <Input
              onBlur={handleBlur}
              item={item.dose.number}
              label="Nombre de doses"
              value={values.dose.number}
              onChange={(e) => {
                if (e.target.value[0] !== ' ') {
                  handleChange(e);
                }
              }}
              error={touched.dose?.number && errors.dose?.number ? String(errors.dose.number) : ''}
              name="dose.number"
              borderColor={renderBorderColor({
                inputName: values.dose.number,
                itemName: item?.dose?.number,
                inputError: errors?.dose?.number,
                inputTouched: touched?.dose?.number,
              })}
            />
            <Input
              onBlur={handleBlur}
              item={item.refill}
              label="Recharge(s)"
              value={values.refill}
              onChange={(e) => {
                if (e.target.value[0] !== ' ') {
                  handleChange(e);
                }
              }}
              error={touched.refill && errors.refill ? String(errors.refill) : ''}
              name="refill"
              borderColor={renderBorderColor({
                inputName: values.refill,
                itemName: item?.refill,
                inputError: errors?.refill,
                inputTouched: touched?.refill,
              })}
            />
          </div>
          <Input
            onBlur={handleBlur}
            item={item.modality.inhalation}
            label="Modalité(s) d'inhalation"
            value={values.modality.inhalation}
            onChange={(e) => {
              if (e.target.value[0] !== ' ') {
                handleChange(e);
              }
            }}
            name="modality.inhalation"
            error={
              touched.modality?.inhalation && errors.modality?.inhalation ? String(errors.modality.inhalation) : ''
            }
            borderColor={renderBorderColor({
              inputName: values.modality.inhalation,
              itemName: item?.modality.inhalation,
              inputError: errors?.modality?.inhalation,
              inputTouched: touched?.modality?.inhalation,
            })}
            heightToslice={100}
          />
          <div className="flex gap-5 items-center">
            <Input
              onBlur={handleBlur}
              item={item.dose.reproducibility}
              label="Reproductibilité de la dose"
              value={values.dose.reproducibility}
              onChange={(e) => {
                if (e.target.value[0] !== ' ') {
                  handleChange(e);
                }
              }}
              placeholder="Reproductibilité de la dose"
              name="dose.reproducibility"
              error={
                touched.dose?.reproducibility && errors.dose?.reproducibility
                  ? String(errors.dose?.reproducibility)
                  : ''
              }
              borderColor={renderBorderColor({
                inputName: values.dose.reproducibility,
                itemName: item?.dose.reproducibility,
                inputError: errors?.dose?.reproducibility,
                inputTouched: touched?.dose?.reproducibility,
              })}
              heightToslice={100}
            />
          </div>
          <div className="flex gap-5 items-center">
            <SelectComponent
              isDisabled
              item={item.lab.name}
              onChange={(e: LabelProps) => setFieldValue('lab', e.value)}
              value={values.lab}
              useData={useLaboratorys}
              label="Laboratoire"
              labelClassName="text-black font-bold"
              borderColor={renderBorderColor({
                inputName: values.lab,
                itemName: item?.lab.id,
                inputError: errors?.lab,
                inputTouched: touched?.lab,
              })}
            />
          </div>
          <Input
            onBlur={handleBlur}
            item={item.price}
            label="Prix unitaire / Prix par Dose "
            value={values.price}
            onChange={(e) => {
              if (e.target.value[0] !== ' ') {
                handleChange(e);
              }
            }}
            name="price"
            placeholder="Prix unitaire / Prix par Dose"
            error={touched.price && errors.price ? String(errors.price) : ''}
            borderColor={renderBorderColor({
              inputName: values.price,
              itemName: item?.price,
              inputError: errors?.price,
              inputTouched: touched?.price,
            })}
            heightToslice={100}
          />
          <div className="flex gap-5 items-center">
            <Input
              onBlur={handleBlur}
              item={item.repayment}
              label="Remboursement"
              value={values.repayment}
              onChange={(e) => {
                if (e.target.value[0] !== ' ') {
                  handleChange(e);
                }
              }}
              name="repayment"
              placeholder="Remboursement"
              error={touched.repayment && errors.repayment ? String(errors.repayment) : ''}
              borderColor={renderBorderColor({
                inputName: values.repayment,
                itemName: item?.repayment,
                inputError: errors?.repayment,
                inputTouched: touched?.repayment,
              })}
            />

            <Input
              onBlur={handleBlur}
              name="visualization.doseRemaining"
              item={item.visualization.doseRemaining}
              label="Visualisation du nombre de doses restantes"
              value={values.visualization.doseRemaining}
              onChange={(e) => {
                if (e.target.value[0] !== ' ') {
                  handleChange(e);
                }
              }}
              error={
                touched?.visualization?.doseRemaining && errors.visualization?.doseRemaining
                  ? String(errors.visualization?.doseRemaining)
                  : ''
              }
              borderColor={renderBorderColor({
                inputName: values.visualization.doseRemaining,
                itemName: item?.visualization.doseRemaining,
                inputError: errors?.visualization?.doseRemaining,
                inputTouched: touched?.visualization?.doseRemaining,
              })}
            />
          </div>
          <Input
            onBlur={handleBlur}
            name="readyFirstUse"
            item={item.readyFirstUse}
            label="Prêt à l’emploi dès la première utilisation"
            value={values.readyFirstUse}
            onChange={(e) => {
              if (e.target.value[0] !== ' ') {
                handleChange(e);
              }
            }}
            error={touched?.readyFirstUse && errors.readyFirstUse ? String(errors.readyFirstUse) : ''}
            borderColor={renderBorderColor({
              inputName: values.readyFirstUse,
              itemName: item?.readyFirstUse,
              inputError: errors?.readyFirstUse,
              inputTouched: touched?.readyFirstUse,
            })}
          />
          <div className="flex gap-5 items-center 2xl:flex-col 2xl:gap-0">
            <Input
              onBlur={handleBlur}
              name="handToMouthCoordination"
              item={item.handToMouthCoordination}
              label="Coordination main bouche"
              value={values.handToMouthCoordination}
              onChange={(e) => {
                if (e.target.value[0] !== ' ') {
                  handleChange(e);
                }
              }}
              error={
                touched?.handToMouthCoordination && errors.handToMouthCoordination
                  ? String(errors.handToMouthCoordination)
                  : ''
              }
              borderColor={renderBorderColor({
                inputName: values.handToMouthCoordination,
                itemName: item?.handToMouthCoordination,
                inputError: errors?.handToMouthCoordination,
                inputTouched: touched?.handToMouthCoordination,
              })}
            />
            <Input
              onBlur={handleBlur}
              name="inhalationChamber"
              item={item.inhalationChamber}
              label="Possibilité d'adaptation d'une chambre d'inhalation"
              value={values.inhalationChamber}
              onChange={(e) => {
                if (e.target.value[0] !== ' ') {
                  handleChange(e);
                }
              }}
              error={touched?.inhalationChamber && errors.inhalationChamber ? String(errors.inhalationChamber) : ''}
              borderColor={renderBorderColor({
                inputName: values.inhalationChamber,
                itemName: item?.inhalationChamber,
                inputError: errors?.inhalationChamber,
                inputTouched: touched?.inhalationChamber,
              })}
              heightToslice={5}
            />
          </div>
          <div className="flex gap-5 items-center 2xl:flex-col 2xl:gap-0">
            <Input
              onBlur={handleBlur}
              name="useControl"
              item={item.useControl}
              label="Contrôle de la prise"
              value={values.useControl}
              onChange={(e) => {
                if (e.target.value[0] !== ' ') {
                  handleChange(e);
                }
              }}
              error={touched?.useControl && errors.useControl ? String(errors.useControl) : ''}
              borderColor={renderBorderColor({
                inputName: values.useControl,
                itemName: item?.useControl,
                inputError: errors?.useControl,
                inputTouched: touched?.useControl,
              })}
            />
            <Input
              onBlur={handleBlur}
              name="existence"
              item={item.existence}
              label="Existence d’un générique"
              value={values.existence}
              onChange={(e) => {
                if (e.target.value[0] !== ' ') {
                  handleChange(e);
                }
              }}
              error={touched?.existence && errors.existence ? String(errors.existence) : ''}
              borderColor={renderBorderColor({
                inputName: values.existence,
                itemName: item?.existence,
                inputError: errors?.existence,
                inputTouched: touched?.existence,
              })}
            />
          </div>

          <div className="flex gap-5 items-center 2xl:flex-col 2xl:gap-0">
            <Input
              onBlur={handleBlur}
              name="MobileSensorExistence"
              item={item.MobileSensorExistence}
              label="Existence d'un capteur connecté à une application mobile"
              value={values.MobileSensorExistence}
              onChange={(e) => {
                if (e.target.value[0] !== ' ') {
                  handleChange(e);
                }
              }}
              error={touched?.MobileSensorExistence && errors.MobileSensorExistence ? String(errors.useControl) : ''}
              borderColor={renderBorderColor({
                inputName: values.MobileSensorExistence,
                itemName: item?.MobileSensorExistence,
                inputError: errors?.MobileSensorExistence,
                inputTouched: touched?.MobileSensorExistence,
              })}
            />
            <Input
              onBlur={handleBlur}
              name="coMarketing"
              item={item.coMarketing}
              label="Co-marketing"
              value={values.coMarketing}
              onChange={(e) => {
                if (e.target.value[0] !== ' ') {
                  handleChange(e);
                }
              }}
              error={touched?.coMarketing && errors.coMarketing ? String(errors.coMarketing) : ''}
              borderColor={renderBorderColor({
                inputName: values.coMarketing,
                itemName: item?.coMarketing,
                inputError: errors?.coMarketing,
                inputTouched: touched?.coMarketing,
              })}
            />
          </div>
          <div className="flex gap-5 items-center">
            <Input
              onBlur={handleBlur}
              name="ready"
              item={item.ready}
              label="Prêt à l’emploi"
              value={values.ready}
              onChange={(e) => {
                if (e.target.value[0] !== ' ') {
                  handleChange(e);
                }
              }}
              error={touched?.ready && errors.ready ? String(errors.ready) : ''}
              borderColor={renderBorderColor({
                inputName: values.ready,
                itemName: item?.ready,
                inputError: errors?.ready,
                inputTouched: touched?.ready,
              })}
            />
            <Input
              onBlur={handleBlur}
              name="refillCartridges"
              item={item.refillCartridges}
              label="Cartouches recharges"
              value={values.refillCartridges}
              onChange={(e) => {
                if (e.target.value[0] !== ' ') {
                  handleChange(e);
                }
              }}
              error={touched?.refillCartridges && errors.refillCartridges ? String(errors.refillCartridges) : ''}
              borderColor={renderBorderColor({
                inputName: values.refillCartridges,
                itemName: item?.refillCartridges,
                inputError: errors?.refillCartridges,
                inputTouched: touched?.refillCartridges,
              })}
            />
          </div>
          <div className="flex gap-5 items-center">
            <Input
              onBlur={handleBlur}
              name="percentageFineParticles"
              item={item.percentageFineParticles}
              label="Pourcentage de particules fines"
              value={values.percentageFineParticles}
              onChange={(e) => {
                if (e.target.value[0] !== ' ') {
                  handleChange(e);
                }
              }}
              error={
                touched?.percentageFineParticles && errors.percentageFineParticles
                  ? String(errors.percentageFineParticles)
                  : ''
              }
              borderColor={renderBorderColor({
                inputName: values.percentageFineParticles,
                itemName: item?.percentageFineParticles,
                inputError: errors?.percentageFineParticles,
                inputTouched: touched?.percentageFineParticles,
              })}
            />
            <Input
              onBlur={handleBlur}
              name="visualization.doseTaken"
              item={item.visualization.doseTaken}
              label="Visualisation du nombre de doses prises"
              value={values.visualization.doseTaken}
              onChange={(e) => {
                if (e.target.value[0] !== ' ') {
                  handleChange(e);
                }
              }}
              error={
                touched?.visualization?.doseTaken && errors.visualization?.doseTaken
                  ? String(errors.visualization?.doseTaken)
                  : ''
              }
              borderColor={renderBorderColor({
                inputName: values.visualization.doseTaken,
                itemName: item?.visualization.doseTaken,
                inputError: errors?.visualization?.doseTaken,
                inputTouched: touched?.visualization?.doseTaken,
              })}
            />
          </div>

          <div className="flex gap-5 items-center 2xl:flex-col 2xl:gap-0">
            <Input
              onBlur={handleBlur}
              name="expires"
              item={item.expires}
              label="Date de péremption"
              value={values.expires}
              onChange={(e) => {
                if (e.target.value[0] !== ' ') {
                  handleChange(e);
                }
              }}
              error={touched?.expires && errors.expires ? String(errors.expires) : ''}
              borderColor={renderBorderColor({
                inputName: values.expires,
                itemName: item?.expires,
                inputError: errors?.expires,
                inputTouched: touched?.expires,
              })}
            />
            <Input
              onBlur={handleBlur}
              name="deviceResistance"
              item={item.deviceResistance}
              label="Résistance du dispositif"
              value={values.deviceResistance}
              onChange={(e) => {
                if (e.target.value[0] !== ' ') {
                  handleChange(e);
                }
              }}
              error={touched?.deviceResistance && errors.deviceResistance ? String(errors.deviceResistance) : ''}
              borderColor={renderBorderColor({
                inputName: values.deviceResistance,
                itemName: item?.deviceResistance,
                inputError: errors?.deviceResistance,
                inputTouched: touched?.deviceResistance,
              })}
            />
          </div>

          <div className="flex gap-5 items-center">
            <Input
              onBlur={handleBlur}
              name="action.expireBronchodilator"
              item={item.action?.expireBronchodilator}
              label="Délai d’action du bronchodilatateur"
              value={values.action?.expireBronchodilator}
              onChange={(e) => {
                if (e.target.value[0] !== ' ') {
                  handleChange(e);
                }
              }}
              error={
                touched?.action?.expireBronchodilator && errors.action?.expireBronchodilator
                  ? String(errors.action?.expireBronchodilator)
                  : ''
              }
              borderColor={renderBorderColor({
                inputName: values.action?.expireBronchodilator,
                itemName: item?.action?.expireBronchodilator,
                inputError: errors?.action?.expireBronchodilator,
                inputTouched: touched?.action?.expireBronchodilator,
              })}
            />
            <Input
              onBlur={handleBlur}
              name="action.expire"
              item={item.action?.expire}
              label="Délai d’action"
              value={values.action?.expire}
              onChange={(e) => {
                if (e.target.value[0] !== ' ') {
                  handleChange(e);
                }
              }}
              error={touched?.action?.expire && errors.action?.expire ? String(errors.action?.expire) : ''}
              borderColor={renderBorderColor({
                inputName: values.action?.expire,
                itemName: item?.action?.expire,
                inputError: errors?.action?.expire,
                inputTouched: touched?.action?.expire,
              })}
            />
          </div>
          <div className="flex gap-5 items-center">
            <Input
              onBlur={handleBlur}
              name="action.duration"
              item={item.action?.duration}
              label="Durée d’action"
              value={values.action?.duration}
              onChange={(e) => {
                if (e.target.value[0] !== ' ') {
                  handleChange(e);
                }
              }}
              error={touched?.action?.duration && errors.action?.duration ? String(errors.action?.duration) : ''}
              borderColor={renderBorderColor({
                inputName: values.action?.duration,
                itemName: item?.action?.duration,
                inputError: errors?.action?.duration,
                inputTouched: touched?.action?.duration,
              })}
            />
            <Input
              onBlur={handleBlur}
              name="action.durationBronchodilator"
              item={item.action?.durationBronchodilator}
              label="Durée d'action du bronchodilatateur"
              value={values.action?.durationBronchodilator}
              onChange={(e) => {
                if (e.target.value[0] !== ' ') {
                  handleChange(e);
                }
              }}
              error={
                touched?.action?.durationBronchodilator && errors.action?.durationBronchodilator
                  ? String(errors.action?.durationBronchodilator)
                  : ''
              }
              borderColor={renderBorderColor({
                inputName: values.action?.durationBronchodilator,
                itemName: item?.action?.durationBronchodilator,
                inputError: errors?.action?.durationBronchodilator,
                inputTouched: touched?.action?.durationBronchodilator,
              })}
            />
          </div>

          <div className="flex gap-5 items-center">
            <Input
              onBlur={handleBlur}
              name="modality.utilisation"
              item={item.modality?.utilisation}
              label="Modalités d’utilisation"
              value={values.modality?.utilisation}
              onChange={(e) => {
                if (e.target.value[0] !== ' ') {
                  handleChange(e);
                }
              }}
              error={
                touched?.modality?.utilisation && errors.modality?.utilisation
                  ? String(errors.modality?.utilisation)
                  : ''
              }
              borderColor={renderBorderColor({
                inputName: values.modality?.utilisation,
                itemName: item?.modality?.utilisation,
                inputError: errors?.modality?.utilisation,
                inputTouched: touched?.modality?.utilisation,
              })}
            />
            <Input
              onBlur={handleBlur}
              item={item.updatedDate}
              label="Mise à jour"
              value={values.updatedDate}
              onChange={(e) => {
                if (e.target.value[0] !== ' ') {
                  handleChange(e);
                }
              }}
              placeholder="Mise à jour"
              error={touched.updatedDate && errors.updatedDate ? String(errors.updatedDate) : ''}
              name="updatedDate"
              borderColor={renderBorderColor({
                inputName: values.updatedDate,
                itemName: item?.updatedDate,
                inputError: errors?.updatedDate,
                inputTouched: touched?.updatedDate,
              })}
            />
          </div>
          <Input
            onBlur={handleBlur}
            item={item.comment}
            label="Commentaire"
            value={values.comment}
            onChange={(e) => {
              if (e.target.value[0] !== ' ' && e.target.value.length < 451) {
                handleChange(e);
              }
            }}
            placeholder="Commentaire"
            error={touched.comment && errors.comment ? String(errors.comment) : ''}
            name="comment"
            borderColor={renderBorderColor({
              inputName: values.comment,
              itemName: item?.comment,
              inputError: errors?.comment,
              inputTouched: touched?.comment,
            })}
          />
          <div className="mb-4 flex flex-col ">
            <div className="mb-4 flex items-center">
              <span className="text-md text-black font-bold mr-4">Avis de la commission de transparence :</span>
              {opinion?.includes('non disponible') ? (
                <p className="text-blue-900 lg:text-base sm:text-xs">{opinion}</p>
              ) : (
                <>
                  <PdfComponent url={opinion} />
                </>
              )}
            </div>
            <input
              name="opinion"
              type="file"
              onChange={(e) => {
                if (e.target.files) setOpinionFile(e.target.files[0]);
              }}
            />
          </div>

          <Button disabled={loading} variant="secondary" className=" w-full font-medium text-lg mt-4" type="submit">
            Envoyer
          </Button>
          <Button onClick={onClose} variant="primary" className="w-full font-medium text-lg mt-4 bg-red">
            Annuler
          </Button>
        </form>
      </Modal>
      <Snackbar {...snackbarState} />
    </>
  );
};

export default ModalMedicine;

import Modal from 'components/common/Modal/Modal';
import Video from 'components/icons/Video';
import { FC, Fragment, useState } from 'react';
import { useHistory } from 'react-router-dom';
import classNames from 'utils/classNames';
import EmptyProductImage from '../../../assets/png/EmptyProductImage.jpg';
import classes from './VideoCard.module.scss';
import VideoPlayer from './VideoPlayer';

interface ModalVideoProps {
  onClose: () => void;
  open: boolean;
  title?: string;
  deviceType?: string;
  smallTitle?: string;
  medicineId?: string;
  videoData: { url: string; qrCode: string };
  medidineDescription?: { classe?: string; dci?: string; device?: string; dosage?: string };
  detail?: boolean;
  from?: string;
  dosageNote?: string;
}

const ModalVideo: FC<ModalVideoProps> = ({
  onClose,
  open,
  title,
  deviceType,
  smallTitle,
  medicineId,
  videoData,
  from,
  medidineDescription,
  dosageNote,
  detail = true,
}) => {
  const history = useHistory();
  const [fullscreen, setfullscreen] = useState(false);
  return (
    <Modal
      responsivestate={fullscreen}
      className={classNames(
        'modalContainer bg-grey-300 w-10/12 lg:w-full lg:h-full lg:w-full  p-5 pb-14 md:p-0 md:pb-14  lg:rounded-none',
        classes.modalContainer,
      )}
      onClose={onClose}
      open={open}
    >
      <div
        className={classNames(
          'modalContainerChild h-full w-full flex  lg:flex-col-reverse lg:w-screen',
          classes.modalContainerChild,
        )}
      >
        <div
          className={classNames(
            'md:m-auto md:w-full lg:px-8 md:px-10 sm:pb-5 lg:pb-16  sm:pt-16 text-blue-600 justify-center font-semibold flex flex-col  2xl:px-5 pb-16 pt-32 bg-white lg:pb-0 pl-10 pr-5',
            classes.text,
            classes.DescriptionSection,
          )}
        >
          <div>
            <span className="font-bold text-26 2xl:text-lg"> {title} </span>
            <br />
            <span className="text-grey-600 font-normal text-lg 2xl:text-xs">{smallTitle}</span>
          </div>
          <div className="py-8">
            <p className="font-bold text-xl	2xl:text-sm pb-2">Description</p>
            <div className="flex flex-col justify-center items-start text-xs 	">
              <div className="text-sm 2xl:text-xs whitespace-nowrap	">
                <span className="font-medium text-blue-600 ">Classe pharmacothérapeutique : </span>{' '}
                <span className="font-sans text-grey-700 font-normal whitespace-normal	">
                  {medidineDescription?.classe}{' '}
                </span>
              </div>
              <div className="text-sm 2xl:text-xs whitespace-nowrap	">
                <span className="font-medium text-blue-600 ">DCI : </span>{' '}
                <span className="font-sans text-grey-700 font-normal whitespace-normal	">
                  {medidineDescription?.dci}{' '}
                </span>
              </div>
              <div className="text-sm 2xl:text-xs whitespace-nowrap	">
                <span className="font-medium text-blue-600 "> Type de Dispositif : </span>{' '}
                <span className="font-sans text-grey-700 font-normal whitespace-normal	"> {deviceType}</span>
              </div>
              <div className="text-sm 2xl:text-xs whitespace-nowrap	">
                <span className="font-medium text-blue-600 "> Nom de dispositif : </span>{' '}
                <span className="font-sans text-grey-700 font-normal whitespace-normal	">
                  {medidineDescription?.device}{' '}
                </span>
              </div>
              <div className="text-sm 2xl:text-xs whitespace-nowrap	">
                <span className="font-medium text-blue-600 ">Dosage :</span>{' '}
                <span className="font-sans text-grey-700 font-normal whitespace-normal	">
                  {medidineDescription?.dosage?.split('||').map((e: any, index: number, array: any) => (
                    <Fragment key={index}>
                      {e}
                      {array.length > 1 && index !== array.length - 1 && (
                        <span className="text-yellow font-bold">|</span>
                      )}
                    </Fragment>
                  ))}{' '}
                </span>
              </div>
              {true && <span className="font-sans text-10 text-grey-700 whitespace-normal"> {dosageNote} </span>}
            </div>
          </div>
          {detail && (
            <button
              onClick={() => history.push(`/medicament/${medicineId}?from=${from || 'video'}`)}
              className={classNames(
                'bg-blue-500 md:w-full w-1/2 text-white py-2 px-4 rounded-5 cursor-pointer 2xl:w-full w-4/6	hover:bg-blue-600',
                classes.detailButton,
              )}
            >
              <p className="text-base xl:text:sm">Détails </p>
            </button>
          )}
        </div>
        <div className={classNames('flex flex-col w-auto h-1/2 bg-grey-300', classes.videoSection)}>
          <div className={classNames('hideSection pb-5 pt-10  pl-8', classes.hideSection)}>
            <p className="flex w-212 items-center font-medium text-blue text-22 2xl:text-base">
              <span className="mr-2 ">
                {' '}
                <Video height="18px" fill="#014A8D" />
              </span>
              Vidéo éducative <br />
            </p>
            <p className="font-bold text-26 2xl:text-lg">
              {title} <span className="text-xl 2xl:text-md"> {smallTitle} </span>
            </p>
          </div>
          <div
            className={classNames(
              'player flex-1  border-none w-full h-full m-auto px-8 py-0',
              classes.Videoborder,
              classes.player,
            )}
          >
            {videoData?.url === '' ? (
              <img src={EmptyProductImage} alt="" />
            ) : (
              <VideoPlayer
                fullscreen={fullscreen}
                setfullscreen={setfullscreen}
                medicineName={title}
                smallTitle={smallTitle}
                videoUrl={videoData?.url}
                videoQrcode={videoData?.qrCode}
              />
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ModalVideo;

import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import classNames from 'utils/classNames';
import cardIdContext from 'contexts/CardIdContext';

import logo from 'assets/svg/logoZephirWhite.svg';
import MenuIcon from 'components/icons/MenuIcon';
import AboutIcon from 'components/icons/AboutIcon';
import Note from 'components/icons/Note';
import Favorite from 'components/icons/Favorite';

import { useLazyType } from 'requests/medecineTypes';
import { useLazyLaboratorys } from 'requests/laboratorys';
import { useLazyMedicines } from 'requests/medicaments';
import { useLazyIndication } from 'requests/indications';
import { useLazyDCIS } from 'requests/dcis';
import { useLazyClasses } from 'requests/classes';
import { useLazyDevices } from 'requests/devices';

import Delete from 'components/icons/Delete';
import Video from 'components/icons/Video';
import MenuContext, { MenuStatesValues } from 'contexts/MenuContext';

import PreviousMenuContext from 'contexts/PreviousMenuState';
import MobileMenuGeneric from './MedicineMenuSection/MobileMenuGeneric';

const ResponsiveMenu = () => {
  const { MenuStates, setMenuStates } = useContext(MenuContext);
  const { setPreviousStates, PreviousStates } = useContext(PreviousMenuContext);
  const [getTypes, { data: typpes }] = useLazyType();
  const [getDevices, { data: devices }] = useLazyDevices();
  const [getClasses, { data: classess }] = useLazyClasses();
  const [getDcis, { data: dcis }] = useLazyDCIS();
  const [getIndications, { data: indications }] = useLazyIndication();
  const [getGenerics, { data: generics }] = useLazyMedicines({
    variables: { generic: 'oui' },
  });

  const [getLabs, { data: labs }] = useLazyLaboratorys({ fetchPolicy: 'no-cache' });
  const history = useHistory();
  const { setCardId } = useContext(cardIdContext);

  useEffect(() => {
    switch (MenuStates) {
      case MenuStatesValues.deviceTypeMenu:
        getTypes();
        break;
      case MenuStatesValues.deviceMenu:
        getDevices();
        break;
      case MenuStatesValues.classeMenu:
        getClasses();
        break;
      case MenuStatesValues.dciMenu:
        getDcis();
        break;
      case MenuStatesValues.indicationMenu:
        getIndications();
        break;
      case MenuStatesValues.genericMenu:
        getGenerics();
        break;
      case MenuStatesValues.labMenu:
        getLabs();
        break;
      default:
        break;
    }
  }, [MenuStates]);

  useEffect(() => {
    const html = document?.querySelector('html');
    if (html) {
      if (MenuStates !== 'CLOSE') html.style.overflow = 'hidden';
      else html.style.overflow = 'unset';
    }
    return () => {
      if (html) html.style.overflow = 'unset';
    };
  }, [MenuStates]);

  const MenuGeneral = [
    {
      id: 0,
      image: Delete,
      name: 'Médicaments et liste de recherche',
      MenuTrigger: () => setMenuStates(MenuStatesValues.mainMedicineMenu),
      color: '#f8a609',
      pathname: ['/types', '/devices', '/classes', '/dci', '/indications', '/generic', '/chambreInhalation', '/lab'],
    },
    {
      id: 1,
      name: 'Vidéos éducatives',
      image: Video,
      pathname: ['/videos', ''],
    },
    {
      id: 2,
      name: 'À propos',
      image: AboutIcon,
      MenuTrigger: () => setMenuStates(MenuStatesValues.aboutMenu),
      pathname: ['/auteurs', '/gammeZephir', '/informations'],
    },
    {
      id: 4,
      name: 'Mes notes',
      image: Note,
      pathname: ['/notes', ''],
    },
    {
      id: 5,
      name: 'Mes Favoris',
      image: Favorite,
      pathname: ['/favoris', ''],
    },
  ];
  const MenuChambre = [
    { id: 1, name: 'Aerochamber Plus (Bleue)' },
    { id: 2, name: 'VORTEX avec embout buccal' },
    { id: 3, name: 'VORTEX avec masque pour adulte ' },
  ];
  const MenuAbout = [
    {
      id: 0,
      name: 'Informations',
      pathname: ['/informations'],
    },
    {
      id: 1,
      name: 'Auteurs',
      pathname: ['/auteurs'],
    },
    {
      id: 2,
      name: 'Gamme Zéphir',
      pathname: ['/gammeZephir'],
    },
  ];
  const MenuMedicine = [
    {
      id: -1,
      name: 'Liste des médicaments',
      pathname: ['/'],
      image: Delete,
    },
    {
      id: 0,
      name: 'Types de dispositifs',
      MenuTrigger: () => setMenuStates(MenuStatesValues.deviceTypeMenu),
      sectionName: 'deviceTypeMenu',
      pathname: ['/types'],
    },
    {
      id: 1,
      name: 'Noms de dispositifs',
      MenuTrigger: () => setMenuStates(MenuStatesValues.deviceMenu),
      sectionName: 'deviceMenu',
      pathname: ['/devices'],
    },
    {
      id: 2,
      name: 'Classes thérapeutiques',
      MenuTrigger: () => setMenuStates(MenuStatesValues.classeMenu),
      sectionName: 'classeMenu',
      pathname: ['/classes'],
    },
    {
      id: 3,
      name: 'DCI',
      MenuTrigger: () => setMenuStates(MenuStatesValues.dciMenu),
      sectionName: 'dciMenu',
      pathname: ['/dci'],
    },
    {
      id: 3,
      name: 'Indications',
      MenuTrigger: () => setMenuStates(MenuStatesValues.indicationMenu),
      sectionName: 'indicationMenu',
      pathname: [' /indications'],
    },
    {
      id: 3,
      name: 'Médicaments génériques',
      pathname: ['/generic'],
      sectionName: 'genericMenu',
    },
    {
      id: 3,
      name: `Chambres d’inhalation`,
      sectionName: ['chambreMenu'],
      pathname: ['/chambreInhalation'],
    },
    {
      id: 3,
      name: 'Laboratoires',
      MenuTrigger: () => setMenuStates(MenuStatesValues.labMenu),
      sectionName: ['labMenu'],
      pathname: ['/lab'],
    },
  ];

  return (
    <header
      className={classNames(
        '4xl:hidden 3xl:hidden 2xl:hidden xl:hidden lg:flex md:flex sm:flex h-28 w-full flex items-center justify-between px-28 py-12 sm:px-5 relative lg:px-20 bg-blue shadow-3 z-10',
      )}
    >
      <div>
        {/* eslint-disable-next-line */}
        <img
          src={logo}
          alt=""
          className="pr-32 lg:pr-0 sm:w-2/6 lg:w-3/12		"
          onClick={() => {
            history.push('/');

            setCardId('');
            setPreviousStates(MenuStatesValues.mainMenu);
          }}
        />
        <div className="text-white text-xs sm:text-10 sm:w-4/5	">
          Guide des thérapeutiques inhalées dans l'asthme et la BPCO chez l'adulte
        </div>
      </div>
      <MenuIcon
        fill="white"
        className="cursor-pointer"
        width="26"
        height="18"
        onClick={() =>
          setMenuStates(PreviousStates !== MenuStatesValues.CLOSE ? PreviousStates : MenuStatesValues.mainMenu)
        }
      />
      {/* Menu Modals */}
      <MobileMenuGeneric
        returnSection={MenuStatesValues.CLOSE}
        data={MenuGeneral}
        open={MenuStatesValues.mainMenu}
        pathName="/MenuGeneral"
        paramName="MenuGeneral"
      />
      <MobileMenuGeneric
        returnSection={MenuStatesValues.mainMenu}
        data={MenuMedicine}
        open={MenuStatesValues.mainMedicineMenu}
        pathName="/medicine"
        paramName="medicine"
      />
      <MobileMenuGeneric
        returnSection={MenuStatesValues.mainMenu}
        data={MenuAbout?.slice().sort((a, b) => a.name.localeCompare(b.name, 'es', { sensitivity: 'base' }))}
        open={MenuStatesValues.aboutMenu}
        pathName="/about"
        paramName="about"
      />
      <MobileMenuGeneric
        returnSection={MenuStatesValues.mainMedicineMenu}
        data={typpes?.types.data?.slice().sort((a, b) => a.name.localeCompare(b.name, 'es', { sensitivity: 'base' }))}
        open={MenuStatesValues.deviceTypeMenu}
        pathName="/types"
        paramName="deviceType"
        titleMenu="Types de dispositifs"
      />
      <MobileMenuGeneric
        returnSection={MenuStatesValues.mainMedicineMenu}
        data={devices?.devices.data
          ?.slice()
          .sort((a, b) => a.name.localeCompare(b.name, 'es', { sensitivity: 'base' }))}
        open={MenuStatesValues.deviceMenu}
        pathName="/devices"
        paramName="device"
        titleMenu="Noms de dispositifs"
      />
      <MobileMenuGeneric
        returnSection={MenuStatesValues.mainMedicineMenu}
        data={classess?.classes.data
          ?.slice()
          ?.sort((a, b) =>
            a.name.replace('β', 'b').localeCompare(b.name.replace('β', 'b'), 'es', { sensitivity: 'base' }),
          )}
        open={MenuStatesValues.classeMenu}
        pathName="/classes"
        paramName="classe"
        titleMenu="Classes thérapeutiques"
      />
      <MobileMenuGeneric
        returnSection={MenuStatesValues.mainMedicineMenu}
        data={dcis?.dcis.data?.slice().sort((a, b) => a.name.localeCompare(b.name, 'es', { sensitivity: 'base' }))}
        open={MenuStatesValues.dciMenu}
        pathName="/dci"
        paramName="dci"
        titleMenu="DCI"
      />
      <MobileMenuGeneric
        returnSection={MenuStatesValues.mainMedicineMenu}
        data={indications?.Indications.data
          ?.slice()
          .sort((a, b) => a.name.localeCompare(b.name, 'es', { sensitivity: 'base' }))}
        open={MenuStatesValues.indicationMenu}
        pathName="/indications"
        paramName="indication"
        titleMenu="Indications"
      />
      <MobileMenuGeneric
        returnSection={MenuStatesValues.mainMedicineMenu}
        data={generics?.medicines.data
          ?.slice()
          .sort((a, b) => a.name.localeCompare(b.name, 'es', { sensitivity: 'base' }))}
        open={MenuStatesValues.genericMenu}
        pathName="/generic"
        paramName="index"
      />
      <MobileMenuGeneric
        open={MenuStatesValues.chambreMenu}
        pathName="/chambreInhalation"
        paramName="chambre"
        returnSection={MenuStatesValues.mainMedicineMenu}
        data={MenuChambre?.slice().sort((a, b) => a.name.localeCompare(b.name, 'es', { sensitivity: 'base' }))}
        titleMenu="Chambres d'inhalation"
      />
      <MobileMenuGeneric
        returnSection={MenuStatesValues.mainMedicineMenu}
        data={labs?.laboratorys.data
          ?.slice()
          .sort((a, b) => a.name.localeCompare(b.name, 'es', { sensitivity: 'base' }))}
        open={MenuStatesValues.labMenu}
        pathName="/lab"
        paramName="lab"
        titleMenu="Laboratoires"
      />
    </header>
  );
};

export default ResponsiveMenu;

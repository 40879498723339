import { Paper } from '@mui/material';
import React from 'react';
import { matchPath, useHistory, useLocation } from 'react-router-dom';
import classNames from 'utils/classNames';
import { SideBarData } from './SideBarData';
import './SideBar.css';

const SideBar = () => {
  const history = useHistory();
  const location = useLocation();
  return (
    <Paper>
      <div style={{ minWidth: '280px' }} className="w-70 flex flex-col pt-10">
        {SideBarData.map(({ icon: Icon, ...value }) => {
          const isActive = !!matchPath(location.pathname, value.url);
          return (
            <div
              className={classNames(
                'class1 gap-5 h-20 w-full pl-8 flex items-center text-lg cursor-pointer hover:bg-blue-120 ',
                isActive ? 'font-bold bg-blue-120' : 'font-semibold',
              )}
              key={value.title}
              onClick={() => {
                if (!isActive) {
                  history.push({ pathname: value.url });
                }
              }}
            >
              <span className="class2">{Icon && <Icon fill="inherit" />}</span>
              <span className="class2"> {value.title}</span>
            </div>
          );
        })}
      </div>
    </Paper>
  );
};

export default SideBar;

import React from 'react';

interface Props {
  Data: { title: string; imageURL: string; data: { title: string; des: string }[] };
}

const SingleInhalater = ({ Data, ...rest }: Props) => {
  return (
    <div
      className="rounded-5 m-2 p-1 bg-blue flex flex-col ml-15 w-96 2xl:w-80 h-544 lg:mx-0 lg:w-full lg:flex-row sm:flex-col lg:h-1/5	sm:h-544"
      {...rest}
    >
      <div className="rounded-5 flex-1 bg-blue-200 justify-center items-center flex lg:w-1/2 sm:w-full">
        {' '}
        <img src={Data.imageURL} alt="" />{' '}
      </div>
      <div className="flex-col flex  2xl:ml-4	h-72  2xl:h-96	 px-5 py-4	lg:w-1/2 sm:w-full	sm:h-275">
        <div className="text-white font-bold text-sm  ">{Data.title}</div>
        <hr className="2xl:w-4/6 my-4	" />
        {Data?.data.map((i) => (
          <div className="text-white text-sm pb-2">
            <span className="font-semibold"> {i.title} </span>
            <span className="font-medium"> {i.des} </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SingleInhalater;

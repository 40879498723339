import { Link, useLocation } from 'react-router-dom';
import { encodeUri, decodeUri } from 'utils/url';
import classNames from 'utils/classNames';
import { useMedicamentsCount } from 'requests/medicaments';

interface Props {
  id: string;
  name: string;
  type: string;
  pathname?: string;
  array?: boolean;
  count?: boolean;
  search?: boolean;
  relatedName?: string;
  className?: string;
  color?: { defaultColor: string; selectedColor: string };
}

const MedecineMenu: React.FC<Props> = ({
  id,
  name,
  type,
  pathname,
  count = true,
  search = true,
  relatedName,
  className,
  array,
  color = { defaultColor: 'text-yellow', selectedColor: 'text-blue-350' },
}) => {
  const location = useLocation();
  const uri = decodeUri(location.search);
  const { data } = useMedicamentsCount({ variables: { [type]: array ? [id] : id }, fetchPolicy: 'no-cache' });

  return (
    <Link
      to={{ pathname, search: search ? encodeUri({ [type]: id }) : '' }}
      className={`flex py-1.5 hover:text-yellow ${uri[Object.keys(uri)[0]] === id ? color.selectedColor : ''}`}
    >
      <div className="truncate">
        {name} {type === 'generic' ? `(${relatedName})` : ''}
      </div>
      {count && (
        <div className={classNames('pl-1', className)}>
          [
          <span className={`${uri[Object.keys(uri)[0]] === id ? color.selectedColor : color.defaultColor}`}>
            {data?.medicines.count}
          </span>
          ]
        </div>
      )}
    </Link>
  );
};

export default MedecineMenu;

import { RouteComponentProps, useHistory } from 'react-router-dom';
import Crud from 'components/ui/Crud/Crud';
import { useFiltredMedicines } from 'requests/medicaments';
import moment from 'moment';

const Medications = (props: RouteComponentProps) => {
  const history = useHistory();

  return (
    <Crud
      {...props}
      list={useFiltredMedicines}
      numberPerPage={100}
      headers={[
        {
          title: 'Nom du médicament',
          render: (record) => {
            return (
              <div className="flex flex-col">
                {record.name} <span className="italic text-xs">{record.small_title}</span>
              </div>
            );
          },
          key: 'name',
        },
        {
          title: 'Laboratoire',
          render: (record) => {
            return record.lab?.name;
          },
          key: 'lab',
        },
        {
          title: "Date d'envoi de la dernière demande",
          render: (record) => {
            const dateLastModif = Number(record?.request[record?.request.length - 1]?.createdAt);
            return dateLastModif ? moment(dateLastModif).format('DD/MM/YYYY-hh:mm:ss') : '---';
          },
          key: 'request',
        },
        {
          title: 'Date de traitement',
          render: (record) => {
            const dateLastModif = Number(record?.request[record?.request.length - 1]?.updatedAt);
            return record?.request[record?.request?.length - 1]?.status !== 'pending' && dateLastModif
              ? moment(dateLastModif).format('DD/MM/YYYY-hh:mm:ss')
              : '---';
          },
          key: 'request',
        },
        {
          title: 'Statut de la demande',
          render: (record) => {
            return record?.request?.length > 0 ? (
              <div>
                {record?.request[record?.request?.length - 1]?.status === 'pending' ? (
                  <div className="bg-yellow font-bold text-white rounded-md py-1 px-2 text-sm flex justify-center w-24 ml-1 ">
                    En attente
                  </div>
                ) : (
                  <div className="bg-green-350 font-bold text-white rounded-md py-1 px-2 text-sm flex justify-center w-24 ml-1 ">
                    Traité
                  </div>
                )}
              </div>
            ) : (
              '   '
            );
          },
          key: 'status',
        },
      ]}
      onRowClick={(row) => history.push(`/medicament/${row.id}/admin`)}
    />
  );
};

export default Medications;

import { LazyQueryHookOptions, QueryTuple } from '@apollo/client';
import { graphQLResult } from 'utils/graphql';
import { ComponentType, useEffect, useMemo, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import TaskAltIcon from '@mui/icons-material/TaskAlt';
import ConfirmationModal from 'components/ux/ConfirmationModal';

import { FormProps, GenericMutation } from './Create';

export type GenericLazyGet<T> = (options?: LazyQueryHookOptions<T, { id: string }>) => QueryTuple<T, { id: string }>;

export type QueryTupleReturnType<T> = T extends QueryTuple<infer R, any> ? R : never;

interface UpdateProps<K extends string, V, Data, Props extends FormProps<V, Data>> {
  api: GenericMutation<Partial<V> & { id: string }>;
  get: GenericLazyGet<Record<K, Data>>;
  Form: ComponentType<Props>;
  formProps: Omit<Props, keyof FormProps<V, Data>>;
  onDone: () => void;
  onClose: () => void;
  match: RouteComponentProps<{ id: string }>['match'];
  updateSuccess?: string;
}

const Update = <K extends string, V, Data, Props extends FormProps<V, Data>>({
  api: useApi,
  get: useGet,
  Form,
  formProps,
  onDone,
  onClose,
  match,
  updateSuccess = 'Votre modification a bien été enregistrée',
}: UpdateProps<K, V, Data, Props>) => {
  const [msg, setMsg] = useState<string>('');

  const { params } = match;
  // const matchPath = useMatch(`${path.pathname}/update/:id`);
  const [get, getState] = useGet({ fetchPolicy: 'network-only' });
  const [call, updateState] = useApi({
    onCompleted: () => {
      if (updateSuccess) setMsg(updateSuccess);
    },
  });

  const { id } = params;

  useEffect(() => {
    if (id) {
      get({ variables: { id } });
    }
  }, [id, get]);

  const data = useMemo(() => {
    if (!getState.data) return undefined;
    return graphQLResult(getState.data);
  }, [getState.data]);

  return (
    <>
      <ConfirmationModal
        onClose={() => {
          setMsg('');
          onDone();
        }}
        open={msg !== ''}
        msg={msg}
      >
        <TaskAltIcon fill="#323232" fontSize="large" />
      </ConfirmationModal>
      <Form
        {...(formProps as Props)}
        onClose={onClose}
        item={data}
        loading={updateState.loading || getState.loading}
        error={updateState.error}
        onSubmit={(values) => {
          if (id) call({ variables: { id, ...values } });
        }}
      />
    </>
  );
};

export default Update;

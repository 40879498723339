import { useEffect, useRef, useState, useContext } from 'react';

import cardIdContext from 'contexts/CardIdContext';

import DropDown from 'components/common/DropDown/DropDown';
import useMouse from 'hooks/useMouse';
import classNames from 'utils/classNames';
import ArrowRight from 'components/icons/ArrowRight';
import Devices from 'components/common/Devices/Devices';
import Dci from 'components/common/Dci/Dci';
import Indication from 'components/common/Indication/Indication';
import Types from 'components/common/Types/Types';
// import Generic from 'components/common/Generic/Generic';
import ClassesTherp from 'components/common/Classes/Classes';
// import Inhalation from 'components/common/Inhalation/Inhalation';
import Lab from 'components/common/Lab/Lab';
import ArrowDown from 'components/icons/ArrowDown';
import { useHistory, matchPath, useLocation } from 'react-router-dom';
import Delete from 'components/icons/Delete';
import { decodeUri } from 'utils/url';
import MenuImage from 'assets/ImageMenu.png';
import UserContext from 'contexts/UserContext';

import HeaderLink from '../HeaderLink';
import classes from './medecines.module.scss';

const validPaths = [
  { path: '/', exact: true, id: -1 },
  { path: '/deviceType', exact: true, id: 0 },
  { path: '/device', exact: true, id: 1 },
  { path: '/classe', exact: true, id: 2 },
  { path: '/dci', exact: true, id: 3 },
  { path: '/indication', exact: true, id: 4 },
  { path: '/generic', exact: true, id: 5 },
  { path: '/chambreInhalation', exact: true, id: 6 },
  { path: '/lab', exact: true, id: 7 },
];

const Medecines = () => {
  const { setCardId } = useContext(cardIdContext);
  const [open, onMouseEnter, onMouseLeave] = useMouse();
  const divRef = useRef<HTMLButtonElement>(null);
  const [selectItem, setSelectItem] = useState(-1);
  const history = useHistory();
  const location = useLocation();
  const { user } = useContext(UserContext);

  const list = [
    {
      id: 0,
      title: 'Types de dispositifs',
    },
    {
      id: 1,
      title: 'Noms de dispositifs',
    },
    {
      id: 2,
      title: 'Classes thérapeutiques',
    },
    {
      id: 3,
      title: 'DCI',
    },
    {
      id: 4,
      title: 'Indications',
    },
    {
      id: 5,
      title: 'Médicaments génériques',
      pathName: '/generic',
    },
    {
      id: 6,
      title: "Chambres d'inhalation",
      pathName: '/chambreInhalation',
    },
    {
      id: 7,
      title: 'Laboratoires',
    },
  ];

  useEffect(() => {
    const unsubscribe = history.listen(onMouseLeave);
    return unsubscribe;
  }, []);

  function renderData() {
    switch (selectItem) {
      case -1:
        return (
          <div
            style={{
              backgroundImage: `url('${MenuImage}')`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}
            className="text-center h-full w-full items-center flex"
          >
            {false && <img alt="sdq" src={MenuImage} className="w-full m-auto hidden " />}
          </div>
        );
      case 0:
        return <Types />;
      case 1:
        return <Devices />;
      case 2:
        return <ClassesTherp />;
      case 3:
        return <Dci />;
      case 4:
        return <Indication />;
      case 5:
        return (
          <div
            style={{
              backgroundImage: `url('${MenuImage}')`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}
            className="text-center h-full w-full items-center flex"
          >
            {false && <img alt="sdq" src={MenuImage} className="w-full m-auto hidden " />}
          </div>
        );
      case 6:
        return (
          <div
            style={{
              backgroundImage: `url('${MenuImage}')`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}
            className="text-center h-full w-full items-center flex"
          >
            {false && <img alt="sdq" src={MenuImage} className="w-full m-auto hidden " />}
          </div>
        );
      case 7:
        return <Lab />;
      default:
        return null;
    }
  }

  const uri = decodeUri(location.search);
  const selected = validPaths.some(
    (path) => matchPath(`/${Object.keys(uri)[0]}`, path) || matchPath(`/${uri?.from}`, path),
  );
  const selectedMenu = validPaths.filter((path) =>
    matchPath(`${path.path}`, [`/${Object.keys(uri)[0]}`, `/${uri?.from}`, location?.pathname]),
  );
  useEffect(() => {
    if (!selected || location?.pathname === '/') setSelectItem(-1);
  }, [selected, location]);
  useEffect(() => {
    if (selectedMenu.length > 0) setSelectItem(selectedMenu[0]?.id);
  }, [open]);

  return (
    <>
      <HeaderLink
        open={open}
        selected={selected || open || uri.section === 'medicines'}
        onMouseEnter={() => {
          onMouseEnter();
        }}
        onMouseLeave={() => {
          onMouseLeave();
        }}
        ref={divRef}
        type="button"
        className={(classNames('flex flex-1 flex-row h-full w-full justify-center items-center'), classes.svg)}
      >
        <div className="flex items-baseline">
          <ArrowDown className="mr-3" width="11px" /> Médicaments et liste de recherche
        </div>
      </HeaderLink>

      <DropDown
        position={{ left: 0, top: user?.role === 'user' ? 118 : 170 }}
        onMouseEnter={() => {
          onMouseEnter();
        }}
        onMouseLeave={() => {
          onMouseLeave();
        }}
        className={classNames(`w-300 2xl:w-full`, classes.menu)}
        open={open}
        anchorEl={divRef}
      >
        <div
          onClick={(e) => {
            e.preventDefault();
          }}
          className={classNames('w-full flex grid', classes.dropDow)}
        >
          <div className={classNames('flex flex-col bg-blue-500 items-center ', classes.list)}>
            <div
              onClick={() => {
                setSelectItem(-1);
                history.push('/?section=medicines');

                setCardId('');
              }}
              className={classNames(
                `cursor-pointer hover:bg-yellow flex mb-8 p-3 mt-10 items-center text-white w-full  justify-center ${
                  selectItem === -1 && selected ? 'bg-yellow' : 'bg-blue-600'
                }`,
                classes.medecineRoot,
              )}
            >
              <div className={selectItem === -1 && selected ? classes.activeMedecine : classes.medecine}>
                <Delete height={18} />
              </div>
              <div className=" pl-4 text-current font-semibold text-lg">Liste des médicaments</div>
            </div>
            <div className="w-full  flex-col flex text-white pb-10 text-base">
              {list.map((e) => {
                return (
                  <div
                    key={e.id}
                    onClick={() => {
                      setSelectItem(e.id);
                      if (e.pathName) history.push(e.pathName);

                      setCardId('');
                    }}
                    className={classNames(
                      'w-full justify-end pr-12 items-center flex h-10 font-normal cursor-pointer',
                      'hover:bg-yellow hover:text-white hover:font-semibold',
                      e.id === selectItem && 'bg-white text-blue font-semibold',
                      classes.titlesMenu,
                    )}
                  >
                    <div className={`${e.pathName ? 'w-18' : 'w-72'} flex `}> {e.title}</div>
                    {!e.pathName && <ArrowRight height={14} />}
                  </div>
                );
              })}
            </div>
          </div>

          <div>{renderData()}</div>
        </div>
      </DropDown>
    </>
  );
};

export default Medecines;

import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useMedicamentsCount } from 'requests/medicaments';
import classNames from 'utils/classNames';
import { decodeUri } from 'utils/url';

import GuideSelect, { LabelProps } from './Select/GuideSelect';

import classes from './Select/select.module.scss';

interface Props<T extends { id: string; name: string }> {
  onChange?: (value: LabelProps | null) => void;
  value?: string;
  isDisabled?: boolean;
  title: string;
  type: string;
  type2: string;
  data?: T[];
  icon?: React.ReactNode;
  className?: string;
}
const Filter = <T extends { id: string; name: string }>({
  isDisabled,
  value,
  onChange,
  title,
  data,
  icon,
  type,
  type2,
  className,
}: Props<T>) => {
  const location = useLocation();
  const uri = decodeUri(location.search);
  const { data: countData } = useMedicamentsCount({ variables: { [type]: uri[type] }, fetchPolicy: 'no-cache' });
  const options = useMemo(() => {
    if (!data) return [];
    return data.map((d) => {
      const filtred = countData?.medicines.data.filter((e: any) => e[type2].id === d.id);
      return {
        value: d.id,
        label: `${d.name}[${filtred?.length}]`,
        isDisabled: filtred?.length === 0,
      };
    });
  }, [data, countData, type2]);

  const optionStyle = (old: any) => {
    const changes = {
      margin: 'auto',
      maxWidth: 'unset',
    };
    return Object.assign(old, changes);
  };

  const handleChange = (v: LabelProps | null) => {
    // eslint-disable-next-line
    if (onChange) onChange(v);
  };
  return (
    <div className={classNames('flex text-sm font-medium flex-col', className)}>
      <div className="block text-base sm:text-sm font-normal text-blue-600 pb-2 font-semibold">{title}</div>
      <GuideSelect
        isDisabled={isDisabled}
        icon={icon}
        placeholder={title}
        value={value}
        options={[
          { value: 'Tous', label: 'Tous' },
          ...options
            ?.slice()
            ?.sort((a: any, b: any) =>
              a.label?.replace('β', 'b').localeCompare(b.label.replace('β', 'b'), 'es', { sensitivity: 'base' }),
            ),
        ]}
        onChange={handleChange}
        design={{ singleValue: optionStyle }}
        classNameSelect={classes.filterSelect}
      />
    </div>
  );
};

Filter.defaultProps = {
  isDisabled: false,
  value: '',
  onChange: () => {},
  data: [],
  icon: null,
  className: '',
};
export default Filter;

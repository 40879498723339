import * as React from 'react';

function Note({ fill = 'currentColor', ...props }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 18.5" {...props}>
      <defs>
        <style>{`.prefix__b_note{stroke:${fill};fill:none;stroke-linecap:square;stroke-width:1.5px}`}</style>
      </defs>
      <path
        // eslint-disable-next-line
        d="M12.625 1.75h-1.944A1.123 1.123 0 009.625 1h-1.33a1.492 1.492 0 00-2.589 0H4.375a1.123 1.123 0 00-1.056.75H1.375A1.126 1.126 0 00.25 2.875v14.25a1.126 1.126 0 001.125 1.125h11.25a1.126 1.126 0 001.125-1.125V2.875a1.126 1.126 0 00-1.125-1.125zM4 2.125a.375.375 0 01.375-.375h1.569a.376.376 0 00.353-.25.744.744 0 011.406 0 .375.375 0 00.353.25h1.569a.375.375 0 01.375.375V2.5H4zm9 15a.375.375 0 01-.375.375H1.375A.375.375 0 011 17.125V2.875a.375.375 0 01.375-.375H3.25v.375a.375.375 0 00.375.375h6.75a.375.375 0 00.375-.375V2.5h1.875a.375.375 0 01.375.375z"
        stroke={fill}
        fill={fill}
        strokeWidth={0.5}
      />
      <path className="prefix__b_note" d="M9.6 7.785H4.401M9.598 10.416H4.399M9.598 13.047H4.399" />
    </svg>
  );
}

export default Note;

import { useCountedDCIS } from 'requests/dcis';
import MedecineMenu from '../MedecineMenu/MedecineMenu';

const Devices = () => {
  const { data } = useCountedDCIS();

  return <MedecineMenu pathname="/dci" type="dci" data={data?.dcisCount} title="DCI" />;
};

export default Devices;

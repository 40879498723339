import React from 'react';

interface Props {
  display: string;
}
const Editorial2023 = ({ display }: Props) => {
  const DisplayCondition = 'Editorial';

  return (
    <div
      id="edt-2023"
      style={{ display: display === DisplayCondition ? 'block' : 'none' }}
      className="text-sm font-regular"
    >
      {/* eslint-disable */}
      <div className="text-black font-bold text-xl mt-16">
        Guide ZEPHIR de la Société de pneumologie de langue française.
      </div>

      <p className="text-black leading-6 mt-8">
        "Guide Zéphir est une marque déposée de la SPLF", voici ce que l'on peut lire en exergue de la page internet qui
        donne accès, librement, aux vidéogrammes "d’aide à l’utilisation des thérapeutiques inhalées dans l’asthme et la
        BPCO chez l’adulte" (Qu'est cela ? C'est le titre). Cette page se trouve à l'adresse{' '}
        <a href="https://splf.fr/videos-zephir/" className="underline text-blue-500	">
          https://splf.fr/videos-zephir/
        </a>
        . Guide Zephir, devenu depuis plusieurs années non plus un simple guide, mais une marque désignant un ensemble
        varié d'outils d'aide à l'éducation thérapeutique. La première édition a été rendue publique en janvier 2012 à
        l'occasion du congrès de pneumologie de langue française. À l'époque, il s'agissait d'un guide papier et
        uniquement de cela, qui revêtait la forme d'un numéro spécial de la Revue des Maladies Respiratoires, l'un des
        journaux officiels de la Société de pneumologie de Langue française. Inutile de revenir sur les évolutions qui
        ont eu lieu depuis cette date (site internet, disques de poche, vidéos de formation, QR codes d'accès direct à
        ces vidéos d'abord à coller puis directement intégrées aux ordonnances par certains logiciels de prescription),
        elles ont été décrites en détail de nombreuses fois sur des supports divers. Inutile également de revenir sur le
        succès du Guide Zephir que ce soit dans le monde professionnel (de très nombreux prix dans le domaine de la
        communication médicale et dans celui de l'éducation thérapeutique ; la reprise par le dictionnaire Vidal®,
        d'autres choses encore) qu'auprès des patients (avec ici un succès attesté par les statistiques de connexion aux
        vidéos, qui montrent un intérêt non seulement soutenu, mais croissant d'année en année). Si l'on ne revient pas
        sur les évolutions et sur les succès, il importe de mettre en lumière deux éléments très importants. Le premier
        concerne les personnes qui rendent possible l'existence du Guide Zephir. Elles sont nombreuses, mais l'une
        d'entre elles mérite une mention particulière. Il s'agit du Dr Valery Trosini-Desert, qui chaque année porte une
        attention particulière et une rigueur exceptionnelle à la mise à jour des outils du Guide Zephir, y compris, et
        ce n'est pas la moindre tâche, la réalisation des vidéogrammes concernant les nouveaux produits et l'intégration
        de ces nouveaux produits à l'ensemble. Elle est aidée en cela par le Dr Marie Antignac, pharmacienne
        hospitalière, et le Dr Marie-Hélène Becquemin, dont l'activité passée dans le domaine des aérosols est connue de
        tous. Pour le coordonnateur, qui signe ces lignes, travailler avec une telle équipe rend les choses simples,
        très simples. Il faut aussi mentionner les efforts inlassables de Mme Frédérique Baudoin pour convaincre les
        partenaires du Guide Zephir de son importance, et de l'importance pour eux de continuer à contribuer à une
        opération d'intérêt général, une caractéristique reconnue par l'Agence nationale de sécurité du médicament et
        des produits de santé. Au-delà des remerciements personnels que l'auteur de ces lignes peut formuler, ce sont
        les remerciements de la communauté pneumologique, de tous les prescripteurs de traitements inhalés –quelle que
        soit leur spécialité– et, surtout, des patients concernés qu'il faut porter vers l'équipe du Guide. Le second
        élément qu'il faut mettre en lumière, c'est le soutien de la SPLF. Ce soutien a été acquis immédiatement, en
        2011, lorsque l'idée du Guide Zephir a été proposée au président en exercice à l'époque, le Professeur Dominique
        Valeyre, et au rédacteur en chef de la Revue des Maladies Respiratoires, le Professeur Nicolas Roche. Leur
        enthousiasme a été contagieux auprès de tous leurs successeurs au cours du temps, et cela a été un facteur
        déterminant des évolutions et des succès qui ont été mentionnés plus haut. Idée d'un petit nombre d'individus au
        service d'un grand nombre de personnes, une large part de la valeur du Guide Zephir réside dans la nature
        institutionnelle qu'il a acquise dès sa création et toujours conservée depuis. L'utilisation correcte des
        thérapeutiques inhalées représente un défi majeur pour les patients concernés, pour les prescripteurs, et pour
        le système de santé. Cette problématique est particulière à la pneumologie, venant encore compliquer les
        difficultés d'observance qui grèvent le succès du traitement des maladies chroniques en général, même lorsque ce
        traitement n'impose pas d'être un artiste de la coordination main-bouche-souffle. L'engagement de la SPLF dans
        le soutien au Guide Zephir est donc fondamental, et, encore une fois, on peut se féliciter qu'il ait toujours
        été sans faille. La Professeure Chantal Rahérisson-Semjen, présidente de la SPLF de 2020 à 2022,
        expliquait lors d'une réunion publique à quel point le Guide était devenu un outil clef de sa pratique
        professionnelle, et à quel point elle était fière que la SPLF l'ait aidé à se développer au cours du temps. Il
        faut aussi noter que le Guide Zephir contribue, au travers des revenus qu'il génère, à permettre à la SPLF de
        financer ses actions, y compris dans le domaine de la recherche. La SPLF et Zephir, "gagnant-gagnant" ? Non. Il
        faut ajouter un "gagnant" au début, et dire "gagnant-gagnant-gagnant", le premier "gagnant" désignant les
        patients atteints d'asthme et de BPCO qui doivent recourir à des traitements inhalés. Ces traitements sont
        contraignants. Ils sont aussi stigmatisants socialement (personne ne prête beaucoup d'attention à une personne
        qui prend un cachet en public, mais pour prendre un "petit coup de Ventoine", il faut s'isoler des regards).
        Autant, alors, qu'ils soient efficaces, et pour cela qu'ils soient pris correctement. Merci à Valery
        Trosini-Désert et son équipe et merci à la SPLF de contribuer à ce résultat avec autant de rigueur,
        d'imagination, et de constance.
      </p>
      <div className="flex flex-col text-base xl:text-sm">
        <span className=" mt-8">Pr Thomas Similowski</span>
        <span className="">Département R3S (Respiration, Réanimation, Réadaptation respiratoire, Sommeil)</span>
        <span className="">Hôpital Pitié-Salpêtrière, Paris</span>
        <span className="underline text-blue-500  mt-8 font-semibold">thomas.similowski@sorbonne-universite.fr</span>
        <span className="underline text-blue-500	 font-semibold">thomas.similowski@upmc.fr</span>
      </div>
    </div>
  );
};

export default Editorial2023;

import { useCountedTypes } from 'requests/medecineTypes';
import MedecineMenu from '../MedecineMenu/MedecineMenu';

const Types = () => {
  const { data: data2 } = useCountedTypes();

  return <MedecineMenu pathname="/types" type="deviceType" data={data2?.typesCount} title="Types de dispositifs" />;
};

export default Types;

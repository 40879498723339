import { useHistory, useLocation } from 'react-router-dom';
import { encodeUri, decodeUri } from 'utils/url';

import FilterByIndications from 'components/filtres/FilterByIndications';
import { useIndication } from 'requests/indications';

import HomeGeneric from '../HomeGeneric/HomeGeneric';

const Indication = () => {
  const { data } = useIndication();

  const location = useLocation();
  const history = useHistory();
  const uri = decodeUri(location.search);

  function handleChoosed(value: any) {
    if (value) {
      delete uri.start;
      history.replace({
        pathname: location.pathname,
        search: encodeUri({ ...uri, indication: value.join(',') }),
      });
    }
  }

  const indicationSelected = data?.Indications?.data?.find((e) => e.id === uri.indication);

  return (
    <HomeGeneric
      from="indication"
      textSelected={indicationSelected?.name}
      title="Indications"
      filterComponent={
        <>
          <FilterByIndications
            data={data?.Indications.data}
            indications={uri.indication}
            handleChoosed={handleChoosed}
          />
        </>
      }
    />
  );
};

export default Indication;

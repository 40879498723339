import React from 'react';
import Input from './Input';

interface InputGenericProps {
  item: string;
  label: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  name?: string;
  placeholder?: string;
  error: string;
  borderColor?: string;
  onBlur?: (e: any) => void;
  isTextarea?: boolean;
  heightToslice?: number;
}

export const InputGeneric = ({
  item,
  label,
  value,
  onChange,
  placeholder = label,
  error,
  name,
  borderColor,
  onBlur,
  isTextarea = true,
  heightToslice,
}: InputGenericProps) => {
  return (
    <Input
      onBlur={onBlur}
      item={item}
      className="flex-1 pb-8"
      label={label}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      inputClassName="bg-grey-300 border-grey-400"
      labelClassName="text-black font-bold"
      error={error}
      name={name}
      borderColor={borderColor}
      isTextarea={isTextarea}
      heightToslice={heightToslice}
    />
  );
};

import SelectArrow from 'components/icons/SelectArrow';
import React, { FC, useCallback } from 'react';
import Select, { ControlProps, Props as SelectProps, components, ValueContainerProps, MenuProps } from 'react-select';
import classNames from 'utils/classNames';

import classes from './select.module.scss';

const Menu = (p: MenuProps<LabelProps, false>) => {
  return <components.Menu {...p} className={classes.menu} />;
};

interface Props extends Omit<SelectProps<LabelProps, false>, 'value'> {
  value?: string;
  arrow?: boolean;
  className?: string;
  classNameSelect?: string;
  root?: string;
  errorColor?: string;
  error?: string;
  selectLabel?: string;
  labelClassName?: string;
  addEtoile?: boolean;
  cursor?: 'pointer' | 'auto';
}

export interface LabelProps {
  label: string;
  value: string;
}

const GuideSelect: FC<Props> = React.forwardRef(
  (
    {
      placeholder,
      icon,
      arrow = true,
      isDisabled,
      className,
      classNameSelect,
      root,
      error,
      cursor = 'pointer',
      errorColor = 'red-100',
      selectLabel,
      labelClassName,
      addEtoile,
      ...props
    },
    ref: React.Ref<Select<LabelProps, false>>,
  ) => {
    const Control = useCallback(
      ({ children, ...rest }: ControlProps<LabelProps, false>) => {
        return (
          <components.Control
            className={classNames(`w-full flex px-4  h-12 item-center `, classes.control, classNameSelect)}
            {...rest}
          >
            {icon}
            <div className="flex-1 text-left truncate ">{children}</div>
            {arrow && <SelectArrow className="cursor-pointer" fill={isDisabled ? '#B3B3B3' : '#014A8D'} />}
          </components.Control>
        );
      },
      [isDisabled],
    );

    const ValueContainer = (p: ValueContainerProps<LabelProps, false>) => {
      return (
        <components.ValueContainer
          className={classNames(
            'w-full truncate placeholderColor text-base 2xl:text-sm text-medium text-grey-500 ',
            className,
          )}
          {...p}
        />
      );
    };

    const formatOptionLabel = ({ label, value }: LabelProps) => (
      <div
        key={value}
        className={classNames('flex justify-around w-full text-sm font-semibold ', classes.SelectTextLabel)}
      >
        <div id="selectLabel" className="flex-1 ">
          {label}
        </div>
      </div>
    );
    return (
      <div className="flex flex-col flex-1">
        {selectLabel && (
          <div
            className={classNames(
              'text-md font-regular whitespace-nowrap  mb-4 flex-1 min-h-0 h-6 flex gap-2',
              labelClassName,
            )}
          >
            {selectLabel} <div className="text-yellow">{addEtoile && '*'}</div>
          </div>
        )}
        <Select
          isDisabled={isDisabled}
          placeholder={placeholder}
          ref={ref}
          className={classNames('w-full', root)}
          noOptionsMessage={() => 'Aucun résultat trouvé'}
          {...props}
          value={props.options.find((o: LabelProps) => o.value === props.value) || null}
          getOptionValue={(option) => option.value}
          formatOptionLabel={formatOptionLabel}
          styles={{
            menu: (old: any) => ({
              ...old,
              // minWidth: placeholder === 'De A-Z' || placeholder === 'Tous' ? 'auto' : '420px',
            }),
            control: (old) => ({
              ...old,
              cursor,
            }),
            option: (old: any, state: any) => ({
              ...old,
              ':active': !state.isSelected &&
                !state.isDisabled && { backgroundColor: '#014A8D', color: 'white !important' },
              backgroundColor: !state.isSelected ? 'white' : '#014A8D',
              '&:hover': { color: !state.isDisabled ? '#014A8D ' : '' },
              color: state.isDisabled ? ' #C3C3C3' : [state.isSelected ? 'white !important' : 'black !important'],
              cursor: state.isDisabled ? 'auto' : 'pointer',
            }),
          }}
          components={{
            Control,
            ClearIndicator: null,
            DropdownIndicator: null,
            DownChevron: () => null,
            ValueContainer,
            Menu,
          }}
        />
        {error && (
          <div className={classNames('lg:text-xs mt-1.5 text-sm h-5 relative left-1', `text-${errorColor}`)}>
            {error}
          </div>
        )}
      </div>
    );
  },
);

export default GuideSelect;

import ZephirDescription from 'components/common/ZephirDescription';
import Filter from 'components/icons/Filter';
import React from 'react';
import classNames from 'utils/classNames';
import classes from './HomeGeneric.module.scss';

interface Props {
  responsive: boolean;
  titleFilter: string | undefined;
  typeFilter: string | undefined;
  filterComponent: React.ReactNode;
}

const ResponsiveFilter = ({ responsive, titleFilter, typeFilter, filterComponent }: Props) => {
  return (
    <div className={classNames(classes.filter, responsive && 'w-full')}>
      <div className="flex rounded-5 childd bg-transparent">
        <div className="flex-auto lg:px-32 sm:px-10 py-7 sm:pb-2 pt-36">
          <div className="flex mb-4  items-center ">
            <Filter height={17} className="pr-6 2xl:pr-5" />

            <div className="font-bold text-2xl 2xl:text-xl">Filtrer par</div>
          </div>

          {(titleFilter === 'Laboratoires' || titleFilter === 'Indications') && (
            <>
              <div className="text-blue-600 font-semibold pb-4 text-base">{titleFilter} </div>
            </>
          )}
          <div
            className={classNames(
              'flex text-sm font-medium flex-col pb-14 h-420 sm:h-536  overflow-y-hidden',
              titleFilter === 'Laboratoires' || titleFilter === 'Indications'
                ? 'overflow-y-scroll'
                : 'overflow-y-hidden',
            )}
          >
            {typeFilter && (
              <div className="block text-base 2xl:text-sm font-medium pb-4 text-grey-500">{typeFilter}</div>
            )}

            {filterComponent}
          </div>
        </div>
        <div />
      </div>

      <ZephirDescription className="justify-center relative right-2" />
    </div>
  );
};

export default ResponsiveFilter;

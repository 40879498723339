/* eslint-disable max-len */
import auteur1 from 'assets/png/aut1.jpeg';
import auteur2 from 'assets/png/auteur2.png';
import auteur3 from 'assets/png/auteur3.png';
import auteur4 from 'assets/png/auteur4.png';
import classNames from 'utils/classNames';

interface Props {
  display: string;
}
const Interet = ({ display }: Props) => {
  const DisplayCondition = 'Interet';
  const array = [
    {
      img: auteur1,
      title: 'Valéry Trosini-Désert',
      desc: ' Au cours des 5 dernières années, le Dr Trosini-Désert a perçu des financements pour participer à des congrès de la part des laboratoires AstraZeneca, Boehringer Ingelheim, Chiesi, Glaxo Smithkline, Novartis et Teva. ',
    },
    {
      img: auteur2,
      title: ' Marie Antignac',
      desc: ' Au cours des 5 dernières années, le Dr Antignac a perçu des financements pour participation à des congrès de la part des laboratoires Novartis, Astra Zeneca, Carefusion.',
    },
    {
      img: auteur3,
      title: 'Marie-Hélène Becquemin',
      desc: ' Au cours des 5 dernières années le Dr Marie-Hélène Becquemin a perçu des financements pour participation à des actions de formations, à des congrés et à des communications de la part des laboratoires Boehringer Ingelheim, Chiesi et GSK.',
    },
    {
      img: auteur4,
      title: '  Thomas SIMILOWSKI  ',
      desc: (
        <div>
          <span>
            {' '}
            Dans le cadre de l’activité de recherche au sein du Groupe Hospitalier Pitié-Salpêtrière ou de l’Université
            Pierre et Marie Curie :{' '}
          </span>
          <div className="mt-5">
            - subventions de recherche « libres » attribuées à l’Association pour le Développement et l’Organisation de
            la Recherche en Pneumologie et sur le Sommeil (ADOREPS, association loi 1901 fiscalisée, Pr Thomas
            Similowski Trésorier), principalement par la société Maquet France (2009 - 40 000 euros sur 2 ans), les
            laboratoires Novartis (2011 -18000 euros), Pierre Fabre (2010-5000 euros), Pfizer France (2010-65000 euros
            sur 3 ans)
          </div>
          <div className="mt-5">
            - honoraires d’investigateur ou de formateur versés à l’ADOREPS au travers de l’activité de ses différents
            membres sur convention, pour des études de pneumologie ou de sommeil réalisées au sein du Service de
            Pneumologie et Réanimation Médicale ou de l’UF des Pathologies du Sommeil, par diverses entreprises du
            médicament ou sociétés commercialisant des dispositifs médicaux, dont principalement Novartis France,
            Biopharma, Chiesi France, Pfizer France, Resmed, Synapse Biomedical Europe. Dans le cadre privé : honoraires
            ou financements pour participation à des congrès, communications, actions de formation et de conseil, des
            groupes d’experts de la part des entreprises AstraZeneca France, AstraZeneca Corporate, Boerhinger Ingelheim
            France, GlaxoSmithKline France, Hamilton medical, Maquet France, Medapharma, MSD France, Novartis Pharma
            France, Nycomed France, Nycomed Corporate, Pierre Fabre, Pfizer France, Rox Biomedical Ltd, Synapse
            Biomedical Ltd.
          </div>
        </div>
      ),
    },
  ];

  return (
    <div className={classNames(display === DisplayCondition ? 'block' : 'hidden')}>
      <div className="flex flex-col lg:grid lg:grid-cols-2 sm:flex sm:flex-col">
        {array.map((m) => (
          <div className="flex flex-row lg:flex-col lg:items-center justify-center items-start  mb-5 lg:pr-4 lg:mb-8 sm:mb-5">
            <img src={m.img} alt="" className="rounded-5  lg:w-full lg:h-96" width="168px" height="300px" />{' '}
            {/* </div> */}
            <div className="flex flex-col flex-1 ml-4	lg:pt-4 lg:ml-0">
              <div className="2xl:text-base text-blue-600 text-26 font-semibold"> {m.title} </div>
              <div className="2xl:text-xs sm:text-sm text-lg text-blue-600 font-regular mt-5 text-justify	lg:text-xs">
                {' '}
                {m.desc}{' '}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Interet;

import Button from 'components/Button';

import { useContext, useEffect, useMemo, useRef } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useMedicamentsById } from 'requests/medicaments';
import { Spinner } from 'utils/loading';
import { decodeUri, encodeUri } from 'utils/url';
import classNames from 'utils/classNames';
import History from '@mui/icons-material/History';
import UserContext from 'contexts/UserContext';
import Table from 'components/common/Table/Table';
import 'components/common/Table/Table.css';

import { useMedicineRequest, useMedicineRequests } from 'requests/medicines';
import moment from 'moment';
import MedicineInfos from './MedicineInfos';

const DetailsMedicinesAdmin = () => {
  const params = useParams<{ id: string }>();
  const location = useLocation();
  const history = useHistory();
  const divRef = useRef<HTMLDivElement>(null);
  const uri = decodeUri(location.search);
  const { user } = useContext(UserContext);
  const requestId = uri.request;
  const { data, called, loading } = useMedicamentsById({
    variables: { id: params?.id },
    fetchPolicy: 'no-cache',
  });

  const { data: medicineRequestsData, refetch } = useMedicineRequests({
    variables: {
      medicine: params?.id,
      sort: 'createdAt',
      order: -1,
      page: Number(uri.page) || 1,
      perPage: Number(uri.perPage) || 10,
    },
    fetchPolicy: 'no-cache',
  });
  const {
    data: medicineRequestsLists,
    count,
    perPage,
  } = useMemo(() => {
    if (!medicineRequestsData) return { page: 0, perPage: 0, totalPages: 0, data: [], count: 0 };
    return medicineRequestsData.medicineRequests;
  }, [medicineRequestsData]);

  const [getMedicineRequest, { data: medicineRequestData, refetch: refetchMedicine }] = useMedicineRequest({
    fetchPolicy: 'no-cache',
  });
  useEffect(() => {
    window.scroll({ top: 0 });
  }, [location]);

  useEffect(() => {
    if (requestId) getMedicineRequest({ variables: { id: requestId } });
  }, [requestId]);
  useEffect(() => {
    if (medicineRequestsLists.length && !medicineRequestsLists.find((req: any) => req.id === requestId)) {
      history.replace({
        pathname: location.pathname,
        search: encodeUri({
          ...uri,
          request: medicineRequestsLists[0].id,
          page: Number(uri.page) || 1,
        }),
      });
    }
  }, [medicineRequestsLists]);

  const request = useMemo(() => {
    if (!medicineRequestData || !requestId) return null;
    return medicineRequestData.medicineRequest;
  }, [medicineRequestData]);

  if (loading)
    return (
      <div className="flex flex-1 items-center justify-center">
        <Spinner name="line-scale-pulse-out-rapid" color="#014A8D" />
      </div>
    );

  function onPageChange(page: number) {
    history.replace({ search: encodeUri({ ...uri, page }) });
  }
  function handleChangeRowsPerPage(perPages: number) {
    history.replace({ search: encodeUri({ ...uri, perPages }) });
  }
  return (
    <>
      {!data && called ? (
        <div className="flex flex-1 items-center justify-center">
          <Spinner name="line-scale-pulse-out-rapid" color="#1466ff" />
        </div>
      ) : (
        <div className="bg-grey-300 w-full h-screen flex-1  ">
          {user?.role === 'admin' && (
            <>
              <div className="flex items-end justify-end">
                <Button
                  onClick={() => {
                    if (divRef?.current) {
                      divRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
                    }
                  }}
                  className="m-5 2xl:m-2 mr-28 2xl:mr-12 self-end flex items-center justify-center gap-5"
                  variant="primary"
                  size="md"
                >
                  <History />

                  <div className="text-sm min-w-max md:hidden "> Afficher l'historique</div>
                </Button>
              </div>
              {request && (
                <div className="font-medium text-lg mx-28 2xl:mx-12  bg-grey-400 text-blue-950 border border-grey rounded-3 py-2 px-2">
                  Cette modification est proposée{' '}
                  <span>{request?.requestedBy?.firstName && request?.requestedBy?.lastName ? 'par' : ''}</span>
                  <span
                    className={classNames(
                      request?.requestedBy?.firstName && request?.requestedBy?.lastName
                        ? 'text-lg font-bold text-yellow'
                        : 'hidden',
                    )}
                  >
                    {` ${request?.requestedBy?.firstName} ${request?.requestedBy?.lastName} `}
                  </span>
                  <span>
                    {request?.requestedBy?.firstName &&
                    request?.requestedBy?.lastName &&
                    request?.requestedBy?.lab?.name
                      ? 'du laboratoire '
                      : ''}
                  </span>
                  {!request?.requestedBy?.firstName &&
                  !request?.requestedBy?.lastName &&
                  request?.requestedBy?.lab?.name
                    ? 'par le laboratoire '
                    : ''}
                  <span className="text-lg font-bold text-yellow">{request?.requestedBy?.lab?.name}</span> le{' '}
                  <span className="italic">{moment(Number(request?.createdAt)).format('DD/MM/YYYY')}</span>.
                </div>
              )}
              <MedicineInfos
                refetch={refetch}
                refetchMedicine={refetchMedicine}
                item={data?.medicine}
                request={request}
                commentAdmin={medicineRequestsLists?.filter((v) => v.id === requestId)[0]?.response?.commentAdmin}
                nameAdmin={`${user.firstName} ${user.lastName}`}
                roleRequestedBy={medicineRequestData?.medicineRequest?.requestedBy?.role}
                requestedByFirstName={request?.requestedBy?.firstName}
                requestedByLastName={request?.requestedBy?.lastName}
                requestedByEmail={request?.requestedBy?.email}
                requestedCreatedAt={moment(Number(medicineRequestData?.medicineRequest?.createdAt)).format(
                  'DD/MM/YYYY-hh:mm:ss',
                )}
              />
            </>
          )}
        </div>
      )}
      <div ref={divRef} className="m-16 mt-6 2xl:m-12">
        <div className="text-3xl	 font-bold mb-10">Historique</div>

        <Table
          getRowClassName={(row) => row.id === requestId && 'class4 bg-grey-800 hover:bg-grey-800'}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          handleChangePage={onPageChange}
          count={count}
          perPage={perPage}
          page={Number(uri.page) || 1}
          className="flex-1"
          data={medicineRequestsLists}
          emptyText="Aucune demande de correction n'a été envoyée pour ce médicament."
          headers={[
            {
              title: 'ID',
              render: (record) => {
                return record.id.slice(record.id.length - 5);
              },
              key: 'id',
            },
            {
              title: "Date d'envoi",
              render: (record) => {
                return moment(Number(record.createdAt)).format('DD/MM/YYYY');
              },
              key: 'createdAt',
            },
            {
              title: 'Demandée par',
              render: (record) => {
                return record.requestedBy?.firstName && record.requestedBy?.lastName
                  ? `${record.requestedBy?.firstName}  ${record.requestedBy?.lastName}`
                  : '---';
              },
              key: 'requestedBy',
            },
            {
              title: 'Date de traitement',
              render: (record) => {
                return record.status === 'pending' ? (
                  <div className="pl-8">--- </div>
                ) : (
                  moment(Number(record.updatedAt)).format('DD/MM/YYYY')
                );
              },
              key: 'updatedAt',
            },
            {
              title: 'Traitée par',
              render: (record) => {
                if ((record.status === 'declined' || record.status === 'accepted') && record.respondedBy) {
                  return `${record.respondedBy?.firstName} ${record.respondedBy?.lastName}`;
                }
                if ((record.status === 'declined' || record.status === 'accepted') && !record.respondedBy) {
                  return 'Admin';
                }
                return '';
              },
              key: 'respondedBy',
            },

            {
              title: 'Statut',
              render: (record) => {
                if (record.status === 'pending') {
                  return (
                    <div className="bg-yellow-200 w-24 h-7 text-white rounded-md text-center pt-1 font-semibold">
                      En attente
                    </div>
                  );
                }
                if (record.status === 'declined')
                  return (
                    <div className="bg-green-350 w-24 h-7 text-white rounded-md text-center pt-1 font-semibold">
                      Traité
                    </div>
                  );
                return (
                  <div className="bg-green-350 w-24 h-7 text-white rounded-md text-center pt-1 font-semibold">
                    Traité
                  </div>
                );
              },
              key: 'status',
            },
          ]}
          onRowClick={(row) => {
            history.replace({
              pathname: location.pathname,
              search: encodeUri({
                ...uri,
                request: row.id,
                page: Number(uri.page) || 1,
              }),
            });
          }}
        />
      </div>
    </>
  );
};

export default DetailsMedicinesAdmin;

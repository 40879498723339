import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useMedicaments } from 'requests/medicaments';
import Input from 'components/ux/Input';
import Button from 'components/Button';
import { useAddNote, useLazyNotes, useUpdateNote } from 'requests/note';
import Note from 'components/icons/Edit';
import Modal from 'components/common/Modal/Modal';
import { Note as NoteType } from 'requests/types';
import GuideSelect, { LabelProps } from 'components/filtres/Select/GuideSelect';
import ConfirmationModal from 'components/ux/ConfirmationModal';
import NoteIcon from 'components/icons/Note';
import { useHistory, useLocation, matchPath } from 'react-router-dom';
import InputWrapper from './InputWrapper';
import classes from './modalDetail.module.scss';

export type ModalProps = {
  open: boolean;
  onClose?: (updated: boolean) => void;
  note?: NoteType;
  medicine?: string;
  remove?: number;
};

const NoteModal: FunctionComponent<ModalProps> = ({ open, onClose, note, medicine, remove }) => {
  const location = useLocation();
  const history = useHistory();
  const [addNoteCall, addNoteState] = useAddNote();
  const [updateNoteCall, updateNoteState] = useUpdateNote();
  const [dataCall, { data: notesData }] = useLazyNotes({ fetchPolicy: 'no-cache' });
  const { data: medicinesData } = useMedicaments({ fetchPolicy: 'no-cache' });
  const [msgConfirmationModal, setMsgConfirmationModal] = useState<string>('');

  const formik = useFormik({
    initialValues: {
      medicine: medicine || '',
      title: '',
      content: '',
    },
    validationSchema: Yup.object({
      title: Yup.string().required('Ce champ est obligatoire'),
      content: Yup.string().required('Ce champ est obligatoire'),
      medicine: Yup.string().required('Ce champ est obligatoire'),
    }),
    onSubmit: async (values) => {
      if (note) {
        updateNoteCall({ variables: { ...values, id: note.id } });
      } else {
        addNoteCall({
          variables: values,
        });
      }
    },
  });

  const medicines = useMemo(() => {
    if (!medicinesData) return [];
    return medicinesData.medicines.data.map((med) => ({
      label: `${med.name} ${med.small_title}`,
      value: med.id,
      isDisabled: notesData?.notes.data.some((n) => n.medicine.id === med.id),
    }));
  }, [medicinesData, notesData]);

  useEffect(() => {
    dataCall();
    formik.setValues({
      medicine: note ? note.medicine.id : medicine || '',
      title: note?.title || '',
      content: note?.content || '',
    });
    formik.setTouched({ medicine: false, title: false, content: false });
  }, [note]);

  // call select data after delete card
  useEffect(() => {
    if (remove === -1) {
      dataCall();
    }
  }, [remove]);

  useEffect(() => {
    dataCall();
    if (onClose && (addNoteState.data || updateNoteState.data)) {
      onClose(true);

      if (matchPath(location.pathname, [`/notes/:id`, `/notes`])) formik.resetForm();
    }
  }, [addNoteState.data, updateNoteState.data]);
  useEffect(() => {
    if (addNoteState.data) setMsgConfirmationModal('Cette note a été ajoutée avec succès');
  }, [addNoteState.data]);
  useEffect(() => {
    if (updateNoteState.data) setMsgConfirmationModal('Cette note a été modifiée avec succès');
  }, [updateNoteState.data]);

  useEffect(() => {
    if (addNoteState.data && msgConfirmationModal === '' && matchPath(location.pathname, `/notes/:id`))
      history.push(`/notes`);
  }, [addNoteState.data, msgConfirmationModal]);
  return (
    <>
      <ConfirmationModal
        onClose={() => {
          if (onClose) {
            onClose(false);
            setMsgConfirmationModal('');
          }
        }}
        open={msgConfirmationModal !== ''}
        msg={msgConfirmationModal}
      >
        <NoteIcon fill="#323232" width="35px" />
      </ConfirmationModal>
      <Modal
        className="rounded-5 lg:rounded-none lg:h-full z-50 relative text-left py-24 px-20 bg-grey-300 w-1088 2xl:w-934	h-661 2xl:h-539	md:p-8"
        onClose={() => {
          if (onClose) {
            onClose(false);
            if (!note) formik.resetForm();
            else
              formik.setValues({
                medicine: note ? note.medicine.id : medicine || '',
                title: note?.title || '',
                content: note?.content || '',
              });
          }
        }}
        open={open}
      >
        <form className="w-full md:h-1/2" onSubmit={formik.handleSubmit}>
          <Input
            value={formik.values.title}
            onChange={formik.handleChange('title')}
            onBlur={formik.handleBlur('title')}
            placeholder="Titre de la note"
            error={formik.touched.title ? formik.errors.title : ''}
            iconRight={<Note width="15px" />}
          />
          <InputWrapper
            error={formik.touched.medicine ? formik.errors.medicine : ''}
            iconRight={note || medicine ? undefined : <Note width="15px" />}
          >
            <GuideSelect
              onBlur={formik.handleBlur('medicine')}
              isDisabled={!!(note || medicine)}
              value={formik.values.medicine}
              onChange={(e: LabelProps) => formik.setFieldValue('medicine', e.value)}
              arrow={false}
              placeholder="Nom du médicament"
              options={medicines}
              className={classes.placeholderColor}
            />
          </InputWrapper>
          <Input
            maxLength={300}
            isTextarea
            placeholder="Texte ici ..."
            value={formik.values.content}
            onChange={formik.handleChange('content')}
            onBlur={formik.handleBlur('content')}
            rows={5}
            iconRight={<Note width="15px" />}
            error={formik.touched.content ? formik.errors.content : ''}
          />
          {formik.values.content.length >= 300 && (
            <div className="lg:text-xs text-sm h-5 relative left-1 text-red-100">
              Le nombre maximum de caractères a été dépassé
            </div>
          )}

          <Button disabled={addNoteState?.loading} variant="primary" className=" w-full font-medium text-lg mt-4">
            Ajouter
          </Button>
        </form>
      </Modal>
    </>
  );
};

export default NoteModal;

interface SvgProps {
  fill: string;
}

export const SideBarData = [
  {
    title: 'Utilisateurs',
    url: '/admin',
    icon: ({ fill }: SvgProps) => (
      <svg width="40" height="40" viewBox="0 0 40 40" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M39.0412 26.7076C35.6894 23.9541 32.0754 22.1266 31.346 21.766C31.2641 21.725 31.2149 21.6431 31.2149 21.553V16.3327C31.8705 15.8984 32.3048 15.1526 32.3048 14.3003V8.89161C32.3048 6.20364 30.125 4.01556 27.4288 4.01556H26.8469H26.2651C23.5771 4.01556 21.389 6.19545 21.389 8.89161V14.3003C21.389 15.1444 21.8234 15.8902 22.479 16.3327V21.553C22.479 21.6431 22.4298 21.7332 22.3479 21.766C21.9709 21.9463 20.8482 22.5118 19.3976 23.3886V24.1016C20.9055 24.9047 23.8148 26.5601 26.5437 28.8056C27.5845 29.666 28.1827 30.9281 28.1827 32.2885V32.4442H40V28.74C39.9918 27.9451 39.6476 27.1993 39.0412 26.7076Z"
          fill={fill}
        />
        <path
          d="M25.3391 30.2561C21.9873 27.5026 18.3733 25.6751 17.6439 25.3145C17.562 25.2735 17.5128 25.1915 17.5128 25.1014V19.8812C18.1684 19.4468 18.6027 18.7011 18.6027 17.8488V12.4401C18.6027 9.75209 16.4229 7.56401 13.7267 7.56401H13.1448H12.563C9.87503 7.56401 7.68695 9.74389 7.68695 12.4401V17.8488C7.68695 18.6929 8.12129 19.4386 8.77689 19.8812V25.1014C8.77689 25.1915 8.72772 25.2817 8.64577 25.3145C7.90822 25.6751 4.2942 27.5026 0.950625 30.2561C0.344192 30.756 0 31.5017 0 32.2803V35.9844H13.153H26.3061V32.2803C26.2897 31.5017 25.9455 30.756 25.3391 30.2561Z"
          fill={fill}
        />
      </svg>
    ),
  },
  // {
  //   title: 'Laboratoires',
  //   url: '/laboratory',
  //   icon: ({ fill }: SvgProps) => (
  //     <svg width="34" height="39" viewBox="0 0 34 39" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
  //       <g opacity="0.8">
  //         <path
  //           d="M16.975 38.9979C12.2183 38.9979 7.46161 38.9979 2.70493 38.9979C1.34395 38.9979 0.36208 38.2543 0.0670945 37.0285C-0.075452 36.4426 0.00023784 35.825 0.28008 35.2907C2.18062 31.5636 4.08046 27.8359 5.97958 24.1074C6.26711 23.5402 6.72503 23.5168 7.2575 23.5763C8.7941 23.7315 10.3463 23.5919 11.8303 23.1652C12.76 22.9018 13.5938 22.4472 14.4 21.9267C15.8568 20.9887 17.3914 20.2175 19.0857 19.7926C21.4669 19.1946 23.749 19.4495 25.9183 20.5957C26.1707 20.7345 26.3777 20.9429 26.5146 21.1959C28.9093 25.8605 31.2937 30.5301 33.6677 35.2046C34.6134 37.0667 33.4217 38.9968 31.3249 39.0011C26.5419 39.006 21.7586 39.005 16.975 38.9979ZM23.7905 27.992C23.7914 27.659 23.6933 27.3332 23.5087 27.0558C23.3241 26.7783 23.0613 26.5616 22.7533 26.4329C22.4454 26.3043 22.1062 26.2695 21.7785 26.333C21.4507 26.3964 21.1492 26.5553 20.9118 26.7895C20.6745 27.0237 20.5121 27.3228 20.445 27.649C20.3778 27.9752 20.4091 28.3139 20.5348 28.6224C20.6604 28.9309 20.8749 29.1954 21.1511 29.3825C21.4273 29.5695 21.7528 29.6708 22.0866 29.6736C22.3097 29.6757 22.5309 29.6338 22.7376 29.5503C22.9444 29.4668 23.1325 29.3434 23.2913 29.1871C23.45 29.0308 23.5762 28.8448 23.6626 28.6397C23.749 28.4346 23.794 28.2145 23.7948 27.992H23.7905ZM16.139 31.3912C16.1411 31.0564 16.0432 30.7286 15.8578 30.4496C15.6725 30.1705 15.408 29.9528 15.0981 29.8242C14.7883 29.6957 14.447 29.662 14.1179 29.7276C13.7888 29.7932 13.4867 29.955 13.2502 30.1925C13.0136 30.43 12.8533 30.7323 12.7896 31.061C12.726 31.3897 12.7619 31.7298 12.8928 32.0381C13.0236 32.3463 13.2435 32.6088 13.5245 32.7919C13.8054 32.975 14.1347 33.0706 14.4703 33.0663C14.9127 33.0611 15.3355 32.8833 15.6482 32.571C15.9609 32.2587 16.1386 31.8368 16.1433 31.3954L16.139 31.3912Z"
  //           fill={fill}
  //         />
  //         <path
  //           d="M11.9017 5.03819H22.1047V5.4259C22.1047 7.63854 22.0984 9.85118 22.1133 12.0638C22.1167 12.3502 22.1832 12.6323 22.3081 12.8902C23.1537 14.5834 24.0216 16.266 24.8821 17.9518C24.9417 18.0686 24.9971 18.1865 25.0855 18.3703C24.6797 18.2641 24.34 18.1579 23.9854 18.0729C21.4892 17.4727 19.0708 17.7903 16.7439 18.792C15.6118 19.2792 14.5195 19.8535 13.4767 20.5097C11.5598 21.7217 9.49065 22.1094 7.26921 21.8672C7.24791 21.8672 7.22874 21.8417 7.1723 21.8035C7.24152 21.6579 7.31074 21.506 7.38529 21.3573C8.83501 18.5148 10.2826 15.6719 11.7281 12.8286C11.832 12.6183 11.8873 12.3875 11.8899 12.153C11.9017 9.91385 11.8974 7.67465 11.8974 5.43546L11.9017 5.03819Z"
  //           fill={fill}
  //         />
  //         <path
  //           d="M17.009 3.37154C15.5618 3.37154 14.1146 3.38747 12.6673 3.36517C11.809 3.35242 11.1604 2.70764 11.0582 1.84192C11.0217 1.4492 11.1241 1.05611 11.3478 0.730898C11.5716 0.405686 11.9024 0.168886 12.2829 0.0616099C12.4102 0.0245188 12.5421 0.00521554 12.6748 0.00424986C15.5501 0.00424986 18.4371 -0.00424803 21.3188 0.00424986C22.0078 0.00424986 22.5083 0.366472 22.7937 0.979382C23.0791 1.59229 23.0014 2.19777 22.5808 2.73526C22.2325 3.18352 21.7576 3.37897 21.19 3.3726C19.7949 3.36729 18.4009 3.3726 17.0069 3.3726L17.009 3.37154Z"
  //           fill={fill}
  //         />
  //       </g>
  //       <defs>
  //         <clipPath id="clip0_1_48">
  //           <rect width="34" height="39" fill={fill} />
  //         </clipPath>
  //       </defs>
  //     </svg>
  //   ),
  // },

  {
    title: 'Médicaments',
    url: '/medications',
    icon: ({ fill }: SvgProps) => (
      <svg width="30" height="40" viewBox="0 0 30 40" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.8">
          <path
            d="M28.6659 40H1.33904C0.333309 39.6517 -0.00291318 38.9239 1.8986e-05 37.8899C0.022499 30.9768 0.0117479 24.0646 0.0117479 17.1514V16.6936H29.9893V17.1905C29.9893 24.091 29.9785 30.9905 30.001 37.8899C30.0039 38.9269 29.6677 39.6517 28.6659 40ZM13.3404 33.3157H16.6635V29.9976H19.9759V26.6549H16.644V23.369H13.3306V26.6647H10.0261V30.0073H13.3365L13.3404 33.3157Z"
            fill={fill}
          />
          <path
            d="M0.0351562 14.9709C0.0364448 14.75 0.0817071 14.5315 0.1683 14.3283C0.254892 14.125 0.381079 13.9411 0.539489 13.7872C1.74266 12.5791 2.94289 11.37 4.16072 10.1766C4.278 10.0611 4.50378 10.0093 4.67971 10.0093C10.2991 10.0002 15.9184 9.99788 21.5377 10.0024C22.8132 10.0024 24.0877 9.99364 25.3623 10.0122C25.5608 10.0213 25.7499 10.0995 25.8969 10.2333C27.0698 11.3808 28.2201 12.5468 29.3823 13.7041C29.7283 14.0474 29.9511 14.4407 29.9687 14.9709H0.0351562Z"
            fill={fill}
          />
          <path d="M10.029 0.0166283H19.972V8.31304H10.029V0.0166283Z" fill={fill} />
          <path
            d="M8.31463 8.29641C7.09093 8.29641 5.89559 8.34141 4.7061 8.27978C3.97306 8.24163 3.38174 7.56567 3.37099 6.8271C3.34492 5.0578 3.34492 3.28817 3.37099 1.51822C3.38369 0.710197 4.06787 0.0440209 4.89181 0.0185868C6.02167 -0.0156514 7.15349 0.0107608 8.31267 0.0107608L8.31463 8.29641Z"
            fill={fill}
          />
          <path
            d="M21.6756 8.32282V0.0322812C21.7636 0.0179761 21.8524 0.00850633 21.9414 0.00391304C22.9296 0.00391304 23.9187 -0.00391283 24.9068 0.00391304C25.9399 0.0127172 26.6358 0.677917 26.6466 1.70408C26.6642 3.34361 26.6642 4.98345 26.6466 6.62362C26.6378 7.58523 26.0602 8.25923 25.1092 8.30814C23.9832 8.36781 22.8514 8.32282 21.6756 8.32282Z"
            fill={fill}
          />
        </g>
        <defs>
          <clipPath id="clip0_1_37">
            <rect width="30" height="40" fill={fill} />
          </clipPath>
        </defs>
      </svg>
    ),
  },
];

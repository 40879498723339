import Button from 'components/Button';
import FavoriteFilled from 'components/icons/FavoriteFilled';
import Plus from 'components/icons/Plus';
import DeleteModal from 'components/ux/DeleteModal';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDeleteFavorite } from 'requests/Favoris';
import classNames from 'utils/classNames';
import Trash from '../../../assets/svg/corbeille.svg';
import classes from './FavoriteCard.module.scss';

interface CardProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  title: string;
  footer: string;
  medicinePhoto: string;
  medicineId: string;
  FavoriteID: string;
  smallTitle: string;
  generic: string;
  refetch: any;
}

const Card = ({
  title,
  refetch,
  medicineId,
  FavoriteID,
  smallTitle,
  medicinePhoto,
  footer,
  className,
  generic,
  ...rest
}: CardProps) => {
  const [open, setOpen] = useState(false);
  const history = useHistory();

  return (
    <div
      id={`id_${FavoriteID}`}
      className={classNames(
        'flex flex-col text-blue-600 hover:text-white bg-white rounded-5 w-full',
        'hover:border-blue border border-grey hover:bg-blue min-h-250   ',
        classes.container,
        className,
      )}
    >
      <DeleteModal
        deleteCall={useDeleteFavorite}
        id={open ? FavoriteID : ''}
        msg="Êtes-vous sûr de vouloir supprimer ce médicament de la liste de vos favoris&nbsp;?"
        confirmationMsg="Ce médicament a été supprimé de la liste de vos favoris avec succès"
        onClose={(updated: boolean) => {
          setOpen(false);
          if (updated) {
            refetch();
          }
        }}
      />

      <div
        {...rest}
        className={classNames(
          'flex-1 relative bg-cover 3_5xl:bg-contain bg-no-repeat bg-center  ',
          classes.backgroundImage,
        )}
        style={{
          backgroundImage: `url(${medicinePhoto})`,
        }}
      >
        <div className={classNames('flex', classes.video)}>
          <div className={classNames('absolute top-0 right-0 flex items-center p-3', classes.video)}>
            {generic === 'oui' && (
              <div
                className={classNames(
                  'px-2  flex  py-2 bg-yellow text-white  text-xs font-medium	place-self-end rounded-3xl',
                  classes.footer,
                )}
              >
                <span className="px-1 "> Générique </span>
              </div>
            )}
            {/* eslint-disable-next-line */}
          </div>
        </div>

        <div
          className={classNames(
            'w-full h-full hover:bg-blue absolute  flex-col items-center justify-center hidden  ',
            classes.btns,
          )}
        >
          <Button
            onClick={() => {
              history.push(`/medicament/${medicineId}?from=favoris`);
            }}
            className="mt-20"
            variant="quinary"
            size="md"
          >
            <div className="flex items-center justify-center gap-5 w-full">
              <div className="w-6">
                <Plus height={24} />
              </div>
              <div className="w-28 xl:text-sm "> Voir détails</div>
            </div>
          </Button>
          <div onClick={() => setOpen(true)}>
            <p
              className={classNames(
                'text-xs rounded-md rounded-r-none rounded-br-none absolute p-1 text-white',
                'left-6 top-3 h-7 underline text-blue-200 cursor-pointer',
              )}
            >
              Supprimer
            </p>
            <img
              src={Trash}
              alt="trashh"
              className="rounded-md rounded-r-none rounded-br-none absolute p-1 text-white pr-3 left-1  top-3 h-7 cursor-pointer "
            />
          </div>
        </div>
      </div>
      <div className={classNames(' foot w-full ')}>
        <div className="text-base px-4 pb-5">
          <div className="font-bold">{title}</div>

          <div className="justify-between	flex">
            <div className="italic">{smallTitle}</div>
          </div>
        </div>
        <div className={classNames('flex px-6 pt-2 pb-3 text-blue', classes.footer)}>
          {footer}
          <div className={classNames('text-blue flex flex-1 justify-end items-center footer ', classes.footer)}>
            <FavoriteFilled height={13} />
            <div className="pl-2.5">Mes Favoris</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;

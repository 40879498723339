import React from 'react';

interface Props {
  display: string;
}
interface PropsTable {
  title: string;
  data: { desc: string; status: boolean }[];
}
const TablesDesc: React.FC<PropsTable> = ({ title, data }) => (
  <div className="w-3/4 sm:w-full sm:w-full lg:w-full border border-purple-200">
    <div className="bg-purple-250 border border-purple-200 colspan-4 w-full sm:text-sm">{title}</div>
    <table>
      <tr>
        <td className="bg-purple-300 border border-purple-200 w-4/12 text-center sm:text-sm">AVANTAGES</td>
        <td className="bg-purple-300 border border-purple-200 w-4/12 text-center  sm:text-sm">INCONVENIENTS</td>
      </tr>

      {data.map((item) =>
        item.status ? (
          <tr>
            <td className="bg-green-100  border border-purple-200 text-white sm:text-sm">{item.desc}</td>
            <td className="bg-white border border-purple-200"> </td>
          </tr>
        ) : (
          <tr>
            <td className="bg-white border border-purple-200 "> </td>
            <td className="bg-pink-100  border border-purple-200 text-white sm:text-sm">{item.desc}</td>
          </tr>
        ),
      )}
    </table>
  </div>
);
const Descriptions = ({ display }: Props) => {
  const DisplayCondition = 'Descriptions';
  const table1 = [
    { desc: 'Presque prêt à l’emploi', status: true },
    {
      desc: 'Coordination main bouche requise, technique d’inhalation à maîtriser (séquence à respecter)',
      status: false,
    },
    { desc: 'S’affranchit d’un débit inspiratoire minimum pour générer l’aérosol', status: true },
    { desc: 'Bonne reproductibilité de la dose émise (valve doseuse)', status: true },
    { desc: 'Dépôt oro-pharyngé important car vitesse de sortie des particules élevée', status: false },
    { desc: 'Branchement possible sur une chambre d’inhalation', status: true },
    { desc: 'Pas de compteur de doses (sauf seretide®)', status: false },
    { desc: 'Pas de contrôle de la prise (sauf seretide®)', status: false },
  ];
  const table2 = [
    { desc: 'Presque prêt à l’emploi', status: true },
    {
      desc: 'Ne nécessite pas de coordination main bouche',
      status: true,
    },
    { desc: 'Nécessité un débit inspiratoire pour générer l’aérosol (30 L /min)', status: false },
    { desc: 'Bonne reproductibilité de la dose émise (valve doseuse)', status: true },
    { desc: 'Dépôt oro-pharyngé important car vitesse de sortie des particules élevée', status: false },
    { desc: 'Branchement possible sur une chambre d’inhalation (en déclenchant manuellement l’aérosol)', status: true },
    { desc: 'Pas de compteur de doses', status: false },
    { desc: 'Pas de contrôle de la prise', status: false },
  ];
  const table3 = [
    { desc: 'Prêt à l’emploi', status: true },
    {
      desc: 'Ne nécessite pas de coordination main bouche',
      status: true,
    },
    { desc: 'S’affranchit du débit inspiratoire pour générer l’aérosol', status: true },
    { desc: 'Variabilité de la dose inhalée liée à la déperdition de médicament dans la chambre', status: false },
    { desc: 'Faible dépôt oro – pharyngé (meilleure déposition pulmonaire)', status: true },
    { desc: 'Chambre d’inhalation intégrée', status: true },
    { desc: 'Pas de compteur de doses', status: false },
    { desc: 'Pas de contrôle de la prise', status: false },
  ];
  const table4 = [
    { desc: 'Rarement prêts à l’emploi', status: false },
    {
      desc: 'Ne nécessite pas de coordination main bouche',
      status: true,
    },
    { desc: 'Nécessité d’un débit inspiratoire pour générer l’aérosol', status: false },
    {
      desc: 'Sensibilité à l’humidité (possible diminution de la proportion de fines particules inhalées)',
      status: false,
    },
    {
      desc: 'Bonne reproductibilité de la dose pour les DPI unidose, et multidose à doses préconditionnées (diskus®)',
      status: true,
    },
    { desc: 'Variabilité de la dose inhalée pour les dispositifs multidose à réservoir (turbuhaler®)', status: false },
    { desc: 'Faible dépôt oro – pharyngé (meilleure déposition pulmonaire)', status: true },
    { desc: 'Branchement sur une chambre d’inhalation impossible', status: false },
    { desc: 'Compteur de doses', status: true },
    {
      desc: 'Contrôle de la prise: - visuel (ex pour certains DPI< unidoses : la gélule est transparente, pour les autres il faut ouvrir la gélule pour s’assurer de sa vacuité)  - auditif (ex vrombissement ou déclic sonore) - gustatif (ex goût sucré)',
      status: true,
    },
  ];
  const table6 = [
    { desc: 'Nécessité d’un chargement de la cartouche', status: false },
    {
      desc: 'Coordination main bouche facilitée',
      status: true,
    },
    { desc: 'S’affranchit d’un débit inspiratoire minimum pour générer l’aérosol', status: true },
    { desc: 'Bonne reproductibilité de la dose émise', status: true },
    {
      desc: 'Dépôt oro – pharyngé faible (meilleure déposition pulmonaire) Vitesse de sortie des particules plus lente et durée de génération plus longue (« type nébulisation »)',
      status: true,
    },
    { desc: 'Branchement possible sur une chambre d’inhalation', status: true },
    { desc: 'Compteur de doses', status: true },
    { desc: 'Pas de contrôle de la prise', status: false },
  ];
  return (
    <div id="description" style={{ display: display === DisplayCondition ? 'block' : 'none' }} className="font-regular">
      <h2 className="h2-1  text-blue-900 text-right">Marie Antignac</h2>
      <h2 className="h2-1 font-bold text-blue-900">Préambule</h2>
      <p className=" text-sm py-3">
        Nous avons choisi d’utiliser le terme «{' '}
        <span className="bold-color text-blue-900">thérapeutiques inhalées </span>» pour regrouper l’ensemble des
        médicaments présentés dans des inhalateurs prêts à l’emploi et indiqués dans l’asthme ou la BPCO. Ces
        médicaments répondent aux exigences de la pharmacopée européenne définies dans la monographie « les préparations
        pour inhalation » ; lorsqu’elles sont conditionnées en récipients pressurisés elles satisfont aussi aux
        exigences de la monographie « préparations pharmaceutiques pressurisées » [<span className="navref">1</span>]
      </p>
      <p className=" text-sm py-3">
        Dans notre guide et de façon usuelle, nous désignerons par{' '}
        <span className="bold-color text-blue-900">médicament</span>
        le principe actif (la substance pharmacologiquement active désignée par la dénomination commune internationale
        (DCI)) et les excipients associés, et par <span className="bold-color text-blue-900">dispositif</span>{' '}
        l’inhalateur permettant l’aérosolisation du médicament.
        <br />
        ex : Nom de spécialité du médicament : beclospray® <br />
        <span className="pad-left-15 pad-15px">
          DCI (Dénomination Commune Internationale) : béclometasone <br />
          Dispositif : aérosol doseur pressurisé
        </span>
      </p>
      <h2 className="h2-1 font-bold text-blue-900">Les différents types de dispositifs</h2>
      <p className=" text-sm py-3">
        Trois types de générateurs d’aérosols prêts à l’emploi sont commercialisés.
        <br />
        Le médicament est conditionné dans un dispositif portable, qui permet la dispensation de bouffées calibrées de
        médicaments (doses).
      </p>
      <h2 className="h2-3 font-bold">Aérosol doseur pressurisé</h2>
      <h2 className="h2-flesh text-blue-900 font-bold"> ➤ Aérosol doseur pressurisé (MDI)</h2>
      <p className=" text-sm py-3">
        L’abréviation anglaise retrouvée dans la littérature scientifique est{' '}
        <span className="bold-color text-blue-900">MDI</span>
        (Metered Dose Inhaler) ou <span className="bold-color text-blue-900">pMDI</span> (pressurized Metered Dose
        Inhaler).
        <br />
        Dans un langage courant ces dispositifs sont -de manière erronée- souvent désignés par le terme « spray ».
      </p>
      <p className=" text-sm py-3">
        Le <span className="bold-color text-blue-900">médicament</span> en solution ou suspension est conditionné dans
        un récipient (en général métallique) maintenu sous pression avec du gaz propulseur liquéfié. Quand on appuie sur
        le <span className="bold-color text-blue-900">dispositif</span>, la préparation est libérée à l’aide d’une valve
        doseuse sous forme d’aérosol dans le gaz propulseur.
      </p>
      <p className=" text-sm py-3">
        <span className="bold-color text-blue-900">Les gaz propulseurs</span> CFC (chlorofluorocarbone) sont désormais
        remplacés par <span className="bold-color text-blue-900">les HFA</span> (hydrofluorocarbones) plus écologiques.
        <br /> La majorité des spécialités ont été reformulées et certains médicaments ont été arrêtés de
        commercialisation (beclone®). Les nouvelles formulations(avec HFA) présentent des tailles de particules
        d’aérosols souvent plus fines et donc de meilleures caractéristiques pharmaco-dynamiques [
        <span className="navref">2</span>].
      </p>
      <p className=" text-sm py-3">
        Le médicament sous forme d’aérosol sort du dispositif à grande vitesse, la dose délivrée est précise et
        reproductible mais l’impaction dans la sphère ORL est importante. Ces dispositifs nécessitent une bonne
        coordination main bouche de la part du patient pour synchroniser génération de l’aérosol et inspiration [
        <span className="navref">3</span>].
      </p>
      <p className=" text-sm py-3">
        La spécialité peut être utilisée seule ou avec une chambre d’inhalation en cas de mauvaise coordination main –
        bouche.
      </p>
      <p className=" text-sm py-3">
        Avec ces dispositifs, le patient ne peut pas s’assurer que la prise a été correcte ni objectiver l’aérosol
        généré sauf pour les aérosols munis d’un compteur de dose (sérétide®). Le fait que le patient ressente
        l’impaction de l’aérosol dans la sphère ORL ne garantit pas la prise effective du principe actif : il peut
        s’agir du gaz propulseur seulement. Il est donc indispensable que le patient soit formé à l’utilisation du
        dispositif et que ses compétences et sa technique soient évaluées régulièrement par le clinicien.
      </p>
      <p className=" text-sm py-3">
        Les avantages et inconvénients des MDI sont précisés dans le Tableau 1 : Avantages et inconvénients des aérosols
        doseurs pressurisés (MDI).
      </p>
      {/* Start Table */}
      <div className="  flex justify-center sm:justify-start   py-8">
        <TablesDesc
          title="Tableau 1 : Avantages et inconvénients des aérosols doseurs pressurisés (MDI)."
          data={table1}
        />
      </div>
      {/* End Table */}
      <h2 className="h2-flesh text-blue-900 font-bold">➤ Aérosol doseur pressurisé autodéclenché (MDI - BA)</h2>
      <p className=" text-sm py-3">
        L’abréviation anglaise retrouvée dans la littérature scientifique est{' '}
        <span className="bold-color text-blue-900">MDI – BA</span>
        (Metered Dose Inhaler Breath Actuated). Le terme déposé par l’industrie pharmaceutique est autohaler®.
      </p>
      <p className=" text-sm py-3">
        La génération de l’aérosol est déclenchée par l’inhalation à travers le dispositif. Le débit inspiratoire
        minimum pour déclencher la production d’aérosol est de 30 L/min [<span className="navref">4</span>].
      </p>
      <p className=" text-sm py-3">
        Ils ne nécessitent pas de coordination main bouche. Il est toutefois possible de brancher des chambres
        d’inhalation sur ces dispositifs et de les utiliser comme un aérosol doseur pressurisé classique, en déclenchant
        la génération de l’aérosol manuellement. Les avantages et inconvénients des MDI - BA sont précisés dans le
        Tableau 2 : Avantages et inconvénients des aérosols doseurs pressurisés autodéclenchés (MDI-BA).
      </p>
      {/* Start Table */}
      <div className="  flex justify-center sm:justify-start   py-8">
        <TablesDesc
          title="Tableau 2 : Avantages et inconvénients des aérosols doseurs pressurisés autodéclenchés (MDI-BA)."
          data={table2}
        />
      </div>
      {/* End Table */}
      <h2 className="h2-flesh text-blue-900 font-bold">
        ➤ Aérosol doseur pressurisé avec chambre intégrée (MDI - spacer)
      </h2>
      <p className=" text-sm py-3">
        L’abréviation anglaise retrouvée dans la littérature scientifique est
        <span className="bold-color text-blue-900">MDI – Spacer</span> (Metered Dose Inhaler Spacer). Spacer désigne la
        chambre d’inhalation. Le seul représentant actuellement commercialisé est le beclojet®.
      </p>
      <p className=" text-sm py-3">
        La pression sur le dispositif génère un aérosol dans la chambre d’inhalation. L’impaction du médicament dans la
        sphère ORL est moins importante que pour les MDI. De plus ces dispositifs ne nécessitent pas de coordination
        main bouche. Les avantages et inconvénients des MDISpacer sont précisés dans le Tableau 3 : Avantages et
        inconvénients des aérosols doseurs pressurisés avec chambre intégrée (MDI-spacer).
      </p>
      {/* Start Table */}
      <div className="  flex justify-center sm:justify-start   py-8">
        <TablesDesc
          title="Tableau 3 : Avantages et inconvénients des aérosols doseurs pressurisés avec chambre intégrée (MDI-spacer)."
          data={table3}
        />
      </div>
      {/* End Table */}
      <h2 className="h2-3">Inhalateur de poudre (DPI)</h2>
      <p className=" text-sm py-3">
        L’abréviation anglaise retrouvée dans la littérature scientifique est <span className="bold-color">DPI</span>
        (Dry Powder Inhaler).
      </p>
      <p className=" text-sm py-3">
        Le médicament est présenté sous forme de poudre. La génération de l’aérosol est déclenchée par l’inhalation dans
        le dispositif. Le débit inspiratoire minimum pour déclencher la production d’aérosol est d’environ 30 à 35 L/min
        [<span className="navref">5</span>] (sauf pour aerolizer® où le débit nécessaire serait probablement plus élevé
        &gt; 60 L/min [<span className="navref">6</span>]). <br />
        La qualité de l’aérosol (caractérisée par la taille des particules) peut varier en fonction des conditions
        atmosphériques (en particulier en fonction de l’humidité). Pour les dispositifs unidose ou multidose avec doses
        preconditionnées (ex diskus®), la reproductibilité de la dose sera meilleure que pour les dispositifs multidose
        où toutes les doses sont dans la même cartouche [<span className="navref">7</span>].
      </p>
      <p className=" text-left p-1-Bottom-0">
        Il existe deux types de DPI [<span className="navref">7</span>]:
      </p>
      <ul className="itemColor list-disc text-sm py-3 px-4 ">
        <li>
          <span className="text-sm py-3 text-blue-900"> Unidose</span> (single dose dry powder inhaler) ou inhalateurs à
          doses prémesurées : Le dispositif est chargé avec des unités de prise telles que des gélules ou autres formes
          pharmaceutiques appropriées. On place une gélule de médicament dans le dispositif avant chaque utilisation. La
          gélule est perforée lors de l’utilisation. Le médicament est aérosolisé lorsque l’on inspire dans le
          dispositif. Il est possible de s’assurer de la prise du médicament en vérifiant que la gélule est vide après
          la prise. Il est généralement recommandé d’inspirer à deux reprises dans le dispositif pour vider complètement
          la gélule avant cette vérification.
        </li>
        <li>
          <span className="text-sm py-3 text-blue-900">Multidose</span> (multidose powder inhaler) ou inhalateurs
          comportant un réservoir de poudre. La délivrance des doses unitaires s’effectue grâce à un mécanisme doseur
          intégré au dispositif. La spécialité contient plusieurs doses de médicaments. Parfois des cartouches pour
          recharger le dispositif sont commercialisées séparément. Parmi les DPI multidose, il existe deux types de
          dispositifs : <br />–{' '}
          <span className="pad-left-15 align-none">
            <span className="text-sm py-3 text-blue-900">Multi dose reservoir device</span> : Toutes les doses du
            médicament sont stockées dans la même loge (ex : turbuhaler®, clickhaler®, easyhaler®, novolizer®). La
            reproductibilité de la dose inhalée est moins bonne que pour les autres dispositifs.
          </span>{' '}
          <br />–{' '}
          <span className="pad-left-15 align-none">
            <span className="text-sm py-3 text-blue-900">Multi unit dose device</span> : Chaque dose de médicament est
            préconditionnée dans une loge individuelle (ex : diskus ){' '}
          </span>
        </li>
      </ul>
      <p className=" text-sm py-3">
        Les avantages et inconvénients des DPI sont précisés dans le Tableau 4 : Avantages et inconvénients des
        inhalateurs de poudre (DPI).
      </p>
      {/* Start Table */}
      <div className="  flex justify-center sm:justify-start   py-8">
        <TablesDesc title="Tableau 4 : Avantages et inconvénients des inhalateurs de poudre (DPI)." data={table4} />
      </div>
      {/* End Table */}
      <h2 className="h2-flesh text-blue-900 font-bold">➤ Notion de résistance</h2>
      <p className=" text-sm py-3">
        La notion de résistance{' '}
        <span className="bold-color text-blue-900">
          (R) ne concerne que les inhalateurs de poudre (DPI) [<span className="navref text-blue-900">8</span>]
        </span>
        . Elle caractérise la capacité du dispositif à s’opposer à la création de l’aérosol.
      </p>
      <div className="eight centered">
        <div className="table-1 flex flex-col  items-center px-4 py-2">
          <div className="border border-purple-250 flex flex-col  items-center w-2/4 sm:w-full ">
            <span className="bg-purple-250 text-left border border-purple-250 text-sm px-4 py-2 w-full">
              R = (P)1/2 / Q
            </span>
            <span className="twelve td-5 text-left px-4 py-2">
              R = Résistance du dispositif, constante pour un dispositif donné (la résistance varie de 0,055 à 0,5 cm
              H2O 1/2 /(L/min) selon le dispositif)
              <br />P = Pression nécessaire pour <span className="bold">désagréger</span> la poudre le mieux possible
              (particule fine)
              <br />Q = Débit inspiratoire du patient
            </span>
          </div>
        </div>
      </div>
      <p className=" text-sm py-3">
        Le chiffre optimal de pression (P) pour désagréger la poudre est environ 4 KPa. Pour des DPI présentant une
        forte résistance, le débit inspiratoire du patient devra être faible et inversement. Le débit inspiratoire
        optimum du patient devra donc varier selon le DPI utilisé pour obtenir l’aérosol présentant les meilleures
        caractéristiques (Tableau 5 : Impact de la résistance du DPI sur le débit inspiratoire).
      </p>
      {/* Start Table */}
      <div className="  flex justify-center sm:justify-start    py-8">
        <div className=" border border-purple-200">
          <div className="bg-purple-250 border border-purple-200 text-center  w-full">
            Tableau 5 : Impact de la résistance du DPI sur le débit inspiratoire optimal
          </div>
          <table>
            <tr>
              <td className="bg-purple-300 border border-purple-250   sm:text-xs">Dispositif</td>
              <td className="bg-purple-300 border border-purple-250   sm:text-xs">
                Résistance* (selon les recommandations) [6]
              </td>
              <td className="bg-purple-300 border border-purple-250 sm:text-xs">Résistance (cm H2O 1/2 /(L/min))</td>
              <td className="bg-purple-300 border border-purple-250 sm:text-xs">Débit inspiratoire optimal (L/min)</td>
            </tr>
            <tr>
              <td className="bg-purple-100 border-l-2	border-purple-300  text-sm px-2">Unidose</td>
              <td className="bg-purple-100   text-sm " />
              <td className="bg-purple-100   text-sm " />
              <td className="bg-purple-100 border-r-2 border-purple-300  text-sm " />
            </tr>
            <tr>
              <td className="bg-white  border border-purple-200 sm:text-xs  ">aerolizer®</td>
              <td className="bg-white  border border-purple-200 sm:text-xs ">Faible [6]</td>
              <td className="bg-white  border border-purple-200 sm:text-xs ">0,055 [10]- 0,066 [11]</td>
              <td className="bg-white  border border-purple-200 sm:text-xs "> {'> 90 [6]'}</td>
            </tr>
            <tr>
              <td className="bg-white  border border-purple-200 sm:text-xs ">breezhaler®</td>
              <td className="bg-white  border border-purple-200 sm:text-xs ">Forte</td>
              <td className="bg-white  border border-purple-200 sm:text-xs ">0,2 [12]</td>
              <td className="bg-white  border border-purple-200 sm:text-xs "> {'< 50 [6]'}</td>
            </tr>
            <tr>
              <td className="bg-white  border border-purple-200 sm:text-xs ">handihaler®</td>
              <td className="bg-white  border border-purple-200 sm:text-xs ">Forte [6]</td>
              <td className="bg-white  border border-purple-200 sm:text-xs ">0,5 [12]</td>
              <td className="bg-white  border border-purple-200 sm:text-xs "> {'< 50 [6]'}</td>
            </tr>
            <tr>
              <td className="bg-purple-100  border-l-2	border-purple-300 text-sm px-2 ">Multidose</td>
              <td className="bg-purple-100   text-sm " />
              <td className="bg-purple-100   text-sm " />
              <td className="bg-purple-100   border-r-2	border-purple-300 text-sm " />
            </tr>
            <tr>
              <td className="bg-white  border border-purple-200 sm:text-xs ">diskus®</td>
              <td className="bg-white  border border-purple-200 sm:text-xs ">Moyenne [6]</td>
              <td className="bg-white  border border-purple-200 sm:text-xs ">0,078 [11] – 0,086 [10]</td>
              <td className="bg-white  border border-purple-200 sm:text-xs "> 60 - 90 [6]</td>
            </tr>
            <tr>
              <td className="bg-white  border border-purple-200 sm:text-xs ">novolizer®</td>
              <td className="bg-white  border border-purple-200 sm:text-xs ">Moyenne [6]</td>
              <td className="bg-white  border border-purple-200 sm:text-xs ">0,055 – 0,110 [11]</td>
              <td className="bg-white  border border-purple-200 sm:text-xs "> 60 - 90</td>
            </tr>
            <tr>
              <td className="bg-white  border border-purple-200 sm:text-xs ">turbuhaler®</td>
              <td className="bg-white  border border-purple-200 sm:text-xs ">Moyenne / Forte [6]</td>
              <td className="bg-white  border border-purple-200 sm:text-xs ">00,110 [11]</td>
              <td className="bg-white  border border-purple-200 sm:text-xs "> 50 - 60 [6]</td>
            </tr>
            <tr>
              <td className="bg-white  border border-purple-200 sm:text-xs ">clickhaler®</td>
              <td className="bg-white  border border-purple-200 sm:text-xs ">Moyenne / Forte [6]</td>
              <td className="bg-white  border border-purple-200 sm:text-xs ">0,133 [11]</td>
              <td className="bg-white  border border-purple-200 sm:text-xs "> 50 - 60 [6]</td>
            </tr>
            <tr>
              <td className="bg-white  border border-purple-200 sm:text-xs ">easyhaler®</td>
              <td className="bg-white  border border-purple-200 sm:text-xs ">Forte [6]</td>
              <td className="bg-white  border border-purple-200 sm:text-xs ">0,155 [11]</td>
              <td className="bg-white  border border-purple-200 sm:text-xs "> {'< 50 [6]'}</td>
            </tr>
            <tr>
              <td className="bg-white  border border-purple-200 sm:text-xs ">Twisthaler®</td>
              <td className="bg-white  border border-purple-200 sm:text-xs ">Forte [6]</td>
              <td className="bg-white  border border-purple-200 sm:text-xs ">-</td>
              <td className="bg-white  border border-purple-200 sm:text-xs "> {'< 50 [6]'}</td>
            </tr>
          </table>
        </div>
      </div>
      <div className="text-sm text-center">
        * La résistance est dite faible si {'<'}0,07 (cm H2O 1/2 /(L/min)) ; moyenne de 0,07 à 0,12 et forte si {'>'}{' '}
        0,12 [9].
      </div>
      {/* End Table */}
      <p className=" text-sm py-3">
        Il est possible de mesurer le débit inspiratoire d’un patient, pour une résistance donnée avec des instruments
        tel par exemple In-Check Dial® (Clement Clarke International Ltd, UK)[<span className="navref">13</span>] .
      </p>
      <h2 className="h2-3 text-blue-900 font-bold">Inhalateur de brumisat (SMI)</h2>
      <p className=" text-sm py-3">
        L’abréviation anglaise retrouvée dans la littérature scientifique est{' '}
        <span className="color-bold">SMI (Soft Mist Inhaler)</span>. <br />
        Il s’agit de la dernière génération de médicaments inhalés. Aujourd’hui une seule spécialité est commercialisée
        le SPIRIVA respimat® (tiotropium bromure, anticholinergique longue durée) [<span className="navref">14</span>].
      </p>
      <p className=" text-sm py-3">
        Le médicament en solution est stocké dans une cartouche cylindrique. Le brumisat est émis de manière mécanique
        au moyen d’un ressort comprimé (interne au dispositif). La génération de l’aérosol est déclenchée par la
        pression simultanée par le pouce et l’index de part et d’autre du dispositif. La dose délivrée est indépendante
        du débit inspiratoire du patient mais la coordination main bouche est encore nécessaire. <br />
        L’émission de l’aérosol est lente (6-10 fois moins rapide qu’avec les aérosols doseurs) et prolongée.
        L’impaction dans la sphère ORL est faible.
      </p>
      <p className=" text-sm py-3">
        Les avantages et inconvénients du SMI sont récapitulés dans le Tableau 6 : Avantages et inconvénients de
        l’inhalateur de brumisat (SMI).
      </p>
      {/* Start Table */}
      <div className="  flex justify-center sm:justify-start   py-8">
        <TablesDesc title="Tableau 6 : Avantages et inconvénients inhalateur de brumisat (SMI)." data={table6} />
      </div>
      {/* End Table */}
      <h2 className="h2-1 text-blue-900 font-bold">Les excipients à effets notoires</h2>
      <p className=" text-sm py-3">
        Les excipients sont des substances sans activité thérapeutique qui entrent dans la composition d’un médicament
        ou qui sont utilisés pour sa fabrication. L’excipient a pour fonction d’améliorer l’aspect ou le goût, d’assurer
        la conservation, de faciliter la mise en forme et l’administration du médicament. Il sert aussi à acheminer le
        principe actif vers son site d’action et à contrôler son absorption par l’organisme. L’excipient devrait être
        bien toléré. Néanmoins, certains peuvent entraîner des intolérances individuelles : il s’agit des excipients à
        effets notoires. Ceux retrouvés fréquemment dans les thérapeutiques inhalées prêtes à l’emploi sont le lactose
        et l’éthanol. L’éthanol intervient dans la composition de plusieurs aérosols doseurs pressurisés, il s’évapore
        lors de la génération de l’aérosol [<span className="navref">15</span>]. Ces excipients n’exercent aucun effet
        notable sur les fonctions de la muqueuse du tractus respiratoire et de ses cils.
      </p>
      <h2 className="h2-1 text-blue-900 font-bold">Prix et remboursement</h2>
      <p className=" text-sm py-3">
        Les <span className="bold-color text-blue-900">prix</span> diffèrent beaucoup d’une spécialité à l’autre. Ces
        spécialités sont remboursables à 65 %, à l’exception de l’atrovent® (Ipratropium bromure, en aérosol doseur
        pressurisé) non remboursé. Après obtention d’une AMM (Autorisation de Mise sur le Marché), autorisation
        précisant les indications du médicament; le laboratoire fabricant peut demander le{' '}
        <span className="bold-color text-blue-900">remboursement</span> du médicament par l’Assurance Maladie. La
        commission de la transparence intégrée à la HAS (Haute Autorité de Santé) donne un avis sur la prise en charge
        des médicaments par la sécurité sociale en appréciant leur{' '}
        <span className="bold-color text-blue-900">« Service Médical Rendu » (SMR)</span> classé de « insuffisant » à «
        important ». Le service médical rendu est un critère qui prend en compte plusieurs aspects :
      </p>
      <ul className="itemColor list-disc px-4 py-2">
        <li>d’une part la gravité de la pathologie pour laquelle le médicament est indiqué ;</li>
        <li>
          d’autre part des données propres au médicament lui-même dans une indication donnée (efficacité et effets
          indésirables; place dans la stratégie thérapeutique et existence d’alternatives thérapeutiques; intérêt pour
          la santé publique).
        </li>
      </ul>
      <p className=" text-sm py-3">
        Les médicaments dont le SMR est insuffisant au regard des autres thérapies disponibles ne sont pas inscrits sur
        la liste des spécialités remboursables [<span className="navref">16</span>]. La décision finale du taux de
        remboursement est fixée par l’Union Nationale des Caisses d’Assurance Maladie (UNCAM).
      </p>
      <p className=" text-sm py-3">
        Le prix du médicament [<span className="navref">17</span>] est le fruit d’une négociation entre le laboratoire
        fabricant et le Comité Economique des Produits de Santé (CEPS). L’article L.162-16-4 du code de la sécurité
        sociale détermine les règles de la fixation du prix des médicaments remboursables par la sécurité sociale.{' '}
        <br />
        Quatre critères principaux sont pris en compte :
      </p>
      <ul className="itemColor list-disc text-sm py-3 px-8">
        <li>
          Le <span className="bold-color text-blue-900">niveau d’ASMR</span> (Amélioration du Service Médical Rendu) du
          médicament. Il s’agit d’une évaluation comparative prenant en compte l’efficacité et la tolérance du
          médicament par rapport aux médicaments jugés comparables, elle est côtée sur 5 niveaux (I : progrès
          thérapeutiques majeurs à V : absence d’amélioration).
        </li>
        <li>Le prix des médicaments à même visée thérapeutique déjà disponibles sur le marché,</li>
        <li>Le volume des ventes prévues ou constatées,</li>
        <li>Les conditions prévisibles et réelles d’utilisation du médicament.</li>
      </ul>
      <p className=" text-sm py-3">
        Le prix et le taux de remboursement font l’objet d’une publication au Journal Officiel (JO).
      </p>
      <p className=" text-sm py-3">
        Lorsqu’un médicament est <span className="bold-color text-blue-900">remboursé à 65 %</span>, cela signifie que
        le <span className="bold-color text-blue-900">ticket modérateur</span> est de 35 %. Le ticket modérateur est la
        partie des dépenses de santé qui reste à la charge du patient après le remboursement de l’Assurance Maladie. La
        complémentaire santé (« mutuelle ») peut prendre en charge la totalité ou une partie du montant du ticket
        modérateur. Les médicaments sont pris en charge par l’Assurance Maladie sur présentation de la prescription
        médicale. <br />
        En cas de <span className="bold-color text-blue-900">tiers-payant</span> appliqué à la pharmacie d’officine, le
        patient n’a pas à avancer l’argent pour la partie des dépenses remboursées par l’Assurance Maladie. Il ne doit
        payer que le ticket modérateur.
        <br />
        Lorsque le médecin traitant a établi une demande de prise en charge à 100 % (cas des{' '}
        <span className="bold-color text-blue-900">ALD</span> Affection de Longue Durée) des soins et des traitements
        liés à la maladie, l’Assurance Maladie rembourse la totalité des dépenses. Les médicaments pour être pris en
        charge à 100 % doivent être prescrits sur la partie supérieure de l’ordonnance bizone et doivent être inscrits
        sur la liste des produits remboursés par l’Assurance Maladie [<span className="navref">18</span>].
      </p>
      <h2 className="h2-1 text-blue-900 font-bold">Génériques et princeps</h2>
      <p className=" text-sm py-3">
        A ce jour, aucun médicament générique n’est commercialisé parmi ces thérapeutiques inhalées prêtes à l’emploi.
        Tous les médicaments ont fait l’objet d’une AMM (Autorisation de Mise sur le Marché) comme médicament princeps.
      </p>
      <h2 className="h2-1 text-blue-900 font-bold">Bibliographie</h2>
      <ul className="num text-sm py-3">
        <li className="px-2 py-1">
          <span className="itemStyleDeciaml">1.</span>
          <span>
            {' '}
            Pharmacopée Européenne 7.0. « monographies générales. Préparations pour inhalation ». In: (DEQM) DEdlQdM,
            ed.: Conseil de l’Europe 2011.
          </span>
        </li>
        <li className="px-2 py-1">
          <span className="itemStyleDeciaml">2.</span>
          <span>
            {' '}
            Anderson PJ. History of aerosol therapy: liquid nebulization to MDIs to DPIs. Respir Care. 2005
            Sep;50(9):1139-50.
          </span>
        </li>
        <li className="px-2 py-1">
          <span className="itemStyleDeciaml">3.</span>
          <span>
            {' '}
            Broeders ME, Sanchis J, Levy ML, Crompton GK, Dekhuijzen PN. The ADMIT series-issues in inhalation therapy.
            2. Improving technique and clinical effectiveness. Prim Care Respir J. 2009 Jun;18(2):76-82.
          </span>
        </li>
        <li className="px-2 py-1">
          <span className="itemStyleDeciaml">4.</span>
          <span>
            {' '}
            Geller DE. Comparing clinical features of the nebulizer, metered-dose inhaler, and dry powder inhaler.
            Respir Care. 2005 Oct;50(10):1313-21; discussion 21-2.
          </span>
        </li>
        <li className="px-2 py-1">
          <span className="itemStyleDeciaml">5.</span>
          <span>
            {' '}
            Chrystyn H, Price D. Not all asthma inhalers are the same: factors to consider when prescribing an inhaler.
            Prim Care Respir J. 2009 Dec;18(4):243-9.
          </span>
        </li>
        <li className="px-2 py-1">
          <span className="itemStyleDeciaml">6.</span>
          <span>
            {' '}
            Laube BL, Janssens HM, de Jongh FH, Devadason SG, Dhand R, Diot P, et al. What the pulmonary specialist
            should know about the new inhalation therapies. Eur Respir J. 2011 Jun;37(6):1308-31.
          </span>
        </li>
        <li className="px-2 py-1">
          <span className="itemStyleDeciaml">7.</span>
          <span>
            {' '}
            Chrystyn H. The Diskus: a review of its position among dry powder inhaler devices. Int J Clin Pract. 2007
            Jun;61(6):1022-36.
          </span>
        </li>
        <li className="px-2 py-1">
          <span className="itemStyleDeciaml">8.</span>
          <span>
            {' '}
            Dolovich MB, Dhand R. Aerosol drug delivery: developments in device design and clinical use. Lancet. 2011
            Mar 19;377(9770):1032-45.
          </span>
        </li>
        <li className="px-2 py-1">
          <span className="itemStyleDeciaml">9.</span>
          <span>
            {' '}
            Byron PR, Kelly, E.L., Kontny, M.J., Lovering, E.G., Poochikian, G.K., Sethi, S., Thiel, C.G. and Vadas,
            E.B. Recommendations of the USP advisory panel on aerosols on the USP general chapters on aerosols and
            uniformity of dosage units Pharm Forum. 1994;20:7477-503.
          </span>
        </li>
        <li className="px-2 py-1">
          <span className="itemStyleDeciaml">10.</span>
          <span>
            {' '}
            Janssens W, VandenBrande P, Hardeman E, De Langhe E, Philps T, Troosters T, et al. Inspiratory flow rates at
            different levels of resistance in elderly COPD patients. Eur Respir J. 2008 Jan;31(1):78-83.
          </span>
        </li>
        <li className="px-2 py-1">
          <span className="itemStyleDeciaml">11.</span>
          <span>
            {' '}
            Roche N, Dessanges JF, Dubus JC. [Dry powder inhalers in extreme clinical situations]. Rev Mal Respir. 2005
            Sep;22(4):605-13.
          </span>
        </li>
        <li className="px-2 py-1">
          <span className="itemStyleDeciaml">12.</span>
          <span>
            {' '}
            Chapman KR, Fogarty CM, Peckitt C, Lassen C, Jadayel D, Dederichs J, et al. Delivery characteristics and
            patients’ handling of two single-dose dry-powder inhalers used in COPD. Int J Chron Obstruct Pulmon Dis.
            2011;6:353-63.
          </span>
        </li>
        <li className="px-2 py-1">
          <span className="itemStyleDeciaml">13.</span>
          <span>
            {' '}
            Lavorini F, Levy ML, Corrigan C, Crompton G. The ADMIT series - issues in inhalation therapy. 6) Training
            tools for inhalation devices. Prim Care Respir J. 2010 Dec;19(4):335-41.
          </span>
        </li>
        <li className="px-2 py-1">
          <span className="itemStyleDeciaml">14.</span>
          <span>
            {' '}
            Henriet AC, Marchand-Adam S, Mankikian J, Diot P. [Respimat(R), first Soft Mist inhaler: new perspectives in
            the management of COPD]. Rev Mal Respir. 2010 Dec;27(10):1141-9.
          </span>
        </li>
        <li className="px-2 py-1">
          <span className="itemStyleDeciaml">15.</span>
          <span>
            {' '}
            Reychler G, Dessanges JF, Vecellio L. [Aerosol: present and future]. Rev Mal Respir. 2007 Oct;24(8):1013-23.
          </span>
        </li>
        <li className="px-2 py-1">
          <span className="itemStyleDeciaml">16.</span>
          <span>
            {' '}
            Décret no 99-915 du 27 octobre 1999 relatif aux médicaments remboursables et modifiant le code de la
            sécurité sociale (deuxième partie : Décrets en Conseil d’Etat). 27 octobre 1999.
          </span>
        </li>
        <li className="px-2 py-1">
          <span className="itemStyleDeciaml">17.</span>
          <span>
            {' '}
            Safon MSV. La politique du médicament en France. Rapport IRDES (Institut de recherche et documentation en
            économie de la santé). Pôle documentation de l’Irdes. www.irdes.fr/
            EspaceDoc/DossiersBiblios/HistoriquePolitiqueMedicament.pdf Mars 2011.
          </span>
        </li>
        <li className="px-2 py-1">
          <span className="itemStyleDeciaml">18.</span>
          <span>
            {' '}
            Ameli.fr AdpdlAM.{' '}
            <a
              href="http://www.ameli.fr/assures/soins-et-remboursements/ce-qui-est-avotre-charge/le-ticket-moderateur.php"
              className="text-blue-900 underline"
            >
              http://www.ameli.fr/assures/soins-et-remboursements/ce-qui-est-avotre-charge/le-ticket-moderateur.php
            </a>
            .
          </span>
        </li>
      </ul>
    </div>
  );
};

export default Descriptions;

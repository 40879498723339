import Search from 'components/common/Search/Search';

import { useEffect, useState } from 'react';
import ZephirDescription from 'components/common/ZephirDescription';
import { useLazyMedicines } from 'requests/medicaments';
import { useHistory, useLocation } from 'react-router-dom';
import { encodeUri, decodeUri } from 'utils/url';
import { Spinner } from 'utils/loading';

import FilterAlpha from 'components/ux/FilterAlphabetical';
import classNames from 'utils/classNames';
import VideoCard from 'components/common/VideoCard/VideoCard';
import SplfCard from 'components/Splf';
import { PER_PAGE } from 'containers/HomeGeneric/HomeGeneric';
import Up from 'assets/svg/goUp.svg';

const PageVideos = () => {
  const location = useLocation();
  const history = useHistory();
  const [readSectionActive, setreadSectionActive] = useState(false);

  const uri = decodeUri(location.search);
  const [dataCall, { data, loading: videoLoading }] = useLazyMedicines({
    fetchPolicy: 'no-cache',
  });

  const updatePage = (e: any): void => {
    if (e.target.scrollingElement.scrollTop > 1040) {
      setreadSectionActive(true);
    } else {
      setreadSectionActive(false);
    }
  };
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  useEffect(() => {
    window.addEventListener('scroll', updatePage);
    return () => {
      window.removeEventListener('scroll', updatePage);
    };
  }, []);

  useEffect(() => {
    dataCall({
      variables: {
        perPage: PER_PAGE,
        ...uri,
        page: uri.page ? Number(uri.page) : 1,
        sort: 'name',
        order: uri?.order === '-1' ? -1 : 1,
      },
    });
  }, [uri.page, uri.order, uri.start]);

  <div className="absolute top-1/3 right-1/2">
    <Spinner name="line-scale-pulse-out-rapid" color="#014A8D" />
  </div>;

  useEffect(() => {
    if (uri.videoId) {
      const el = document.getElementById(uri.videoId);
      if (el) {
        el.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location, uri]);

  return (
    <>
      <Search />
      <ZephirDescription width="65px" className="justify-center h-14" />

      <div className={classNames('bg-grey-300 p-16 px-28 lg:px-20 sm:px-5 sm:px-5 w-full')}>
        <div className="">
          <div className="flex w-full justify-between  pb-4 lg:flex-col ">
            <div className="text-grey-400 text-32 2xl:text-26 lg:text-22 font-bold">Vidéos éducatives</div>
            <div className={classNames('text-sm 2xl:text-xs flex items-center pt-3 lg:pt-0 pr-0.5')}>
              <div className="text-yellow font-semibold">{data?.medicines.count}</div>
              <div className="text-blue-600  font-normal pl-1 ">
                Résultat{data?.medicines?.count! > 1 ? 's' : ''} trouvé{data?.medicines?.count! > 1 ? 's' : ''}
              </div>
            </div>
          </div>
          <FilterAlpha
            uri={uri}
            filterAllDatas={() => {
              const { start, ...newUri } = uri;
              return history.replace({ pathname: location.pathname, search: encodeUri(newUri) });
            }}
            filterdDatas={(i) => {
              history.replace({ pathname: location.pathname, search: encodeUri({ ...uri, start: i }) });
            }}
          />
          <div className="grid grid-cols-4 2xl:grid-cols-3 lg:grid-cols-2 sm:grid-cols-1 gap-5 justify-between  pt-2.5 pb-10  relative min-h-40 ">
            {videoLoading ? (
              <div className="absolute top-1/3 right-1/2">
                <Spinner name="line-scale-pulse-out-rapid" color="#1466ff" />
              </div>
            ) : (
              <>
                <SplfCard />
                {data?.medicines.data.map((e) => (
                  <VideoCard
                    smallTitle={e.small_title}
                    deviceType={e.deviceType.name}
                    title={e.name}
                    footer={e.lab.name}
                    medicinePhoto={e?.image}
                    medicineId={e?.id}
                    videoData={e?.video}
                    generic={e.generic}
                    medidineDescription={{
                      classe: e?.classe?.name,
                      dci: e?.dci?.name,
                      device: e?.device?.name,
                      dosage: e?.dose?.name,
                    }}
                  />
                ))}
              </>
            )}
          </div>
        </div>
        <button
          className={`fixed bottom-5 ${readSectionActive ? 'right-1' : '-right-24'}`}
          onClick={() => scrollToTop()}
        >
          <img alt="up" src={Up} height={30} />
        </button>
      </div>
    </>
  );
};

export default PageVideos;

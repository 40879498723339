import React from 'react';

interface Props {
  display: string;
}
const Introduction = ({ display }: Props) => {
  const DisplayCondition = 'Introduction';

  return (
    <div id="intro" style={{ display: display === DisplayCondition ? 'block' : 'none' }}>
      <h2 className="text-blue-900 text-right">Valéry Trosini-Désert</h2>

      <p className="py-3 text-sm">
        La broncho-pneumopathie chronique obstructive (BPCO) et l’asthme concernent à elles deux environ 7 millions de
        personnes en France. Elles correspondent à un volume de prescriptions médicamenteuses considérable, dont
        l’essentiel est constitué de thérapeutiques administrées par inhalation. Prescrire un traitement d’asthme ou de
        BPCO revient à choisir pour chaque cas donné la classe thérapeutique ou la combinaison de classes appropriée, la
        molécule ou combinaison de molécules, une forme galénique, un type d’inhalateur, en modulant ce choix selon les
        connaissances du clinicien sur ces dispositifs, la capacité du patient à manipuler le dispositif
        d’administration, le taux de remboursement du médicament, l’existence ou non d’un générique etc... Exercice
        difficile…
      </p>
      <h2 className="h2-1 text-blue-900 font-semibold">BPCO</h2>
      <p className="p-1 text-sm">
        La BPCO est l’une des principales causes de morbidité et mortalité dans le monde. Au niveau mondial, elle est en
        augmentation constante depuis 20 ans avec plus de 44 millions de malades, soit 4 à 10 % de la population adulte
        [1]. En France, la BPCO touche 3,5 millions de personnes. La prévalence de la BPCO est estimée à 7,6 % [2]. La
        mortalité augmente régulièrement depuis 20 ans, avec environ 16 000 décès par an attribués à la BPCO [3]. On
        estime que d’ici à 2020, la BPCO deviendra la 5ème cause de morbidité et en 2030 la 3ème cause de mortalité dans
        le monde [4]. La prévalence de la BPCO augmente avec l’âge, avec un risque multiplié par 5 pour les plus de 65
        ans comparés aux sujets de moins de 40 ans et la consommation de tabac [5]. La prise en charge thérapeutique de
        la BPCO repose sur une approche éducative et physique d’une part (sevrage tabagique, réhabilitation respiratoire
        et réentraînement à l’effort), et d’autre part sur une approche médicamenteuse. Les bronchodilatateurs soulagent
        la dyspnée, augmentent la tolérance à l’exercice, préviennent les exacerbations, et améliorent la qualité de
        vie. Pour certaines catégories de patients, leurs effets peuvent être renforcés par leur association à des
        corticostéroïdes. Les indications et les modalités de prescription de ces traitements sont formalisées, en
        fonction de la sévérité de la maladie, par des recommandations internationales [1] et des recommandations
        françaises [6- 7]. Dans la quasi-totalité des cas l’administration se fait par voie inhalée.
        <br />
        Le poids médico-économique de la maladie est important [3] : le coût direct de la maladie est estimé à 3,5
        milliards d’euros par an dont 60 % sont liés aux exacerbations et 40 % au suivi au long cours de la pathologie.
        Les dépenses de santé sont fonction de la sévérité de la maladie. Le coût moyen de la prise en charge d’une BPCO
        est estimé à 4000 euros par malade et par an. Pour les malades les plus sévèrement atteints, admis en «
        affection de longue durée » (ALD) pour insuffisance respiratoire chronique, les dépenses moyennes annuelles de
        santé (dont 50 % sont constituées par les dépenses d’hospitalisation, 20 % par les médicaments) sont supérieures
        à 6 000 euros.
      </p>
      <h2 className="h2-1 text-blue-900 font-semibold">Asthme</h2>
      <p className="p-1 text-sm">
        L’asthme est un problème de santé public mondial, qui affecte environ 300 millions d’individus [8]. En France,
        l’asthme concerne près de 3,5 millions de personnes. Les dernières enquêtes nationales montrent une prévalence
        cumulée de l’asthme de plus de 10 % chez l’enfant âgé d’au moins dix ans et une prévalence de l’asthme actuel de
        6 à 7 % chez l’adulte. Il faut indiquer qu’en France environ 1 500 à 2 000 personnes meurent d’asthme chaque
        année [9].
        <br />
        Les recommandations internationales distinguent l’asthme intermittent qui ne nécessite qu’un traitement
        bronchodilatateur à la demande et les asthmes persistants légers, modérés et sévères qui nécessitent un
        traitement de fond qui repose essentiellement sur la corticothérapie inhalée. L’association avec un b2-agoniste
        de longue durée d’action est nécessaire dès lors que l’asthme n’est pas contrôlé par la seule corticothérapie
        inhalée [8]. La prise en charge thérapeutique de l’asthme repose aussi sur une approche éducative des patients
        selon les recommandations de l’HAS [10]. Le coût global de l’asthme représente environ près de 2 milliards
        d’euros par an représenté pour moitié par les remboursements de médicaments d’antiasthmatiques, en augmentation
        très forte depuis une dizaine d’années avec le développement rapide des associations fixes [11].
      </p>
      <div className="twelve">
        <h2 className="h2-1">Des recommandations thérapeutiques simples, une réalité quotidienne complexe</h2>
      </div>
      <p className="p-1 text-sm">
        Si les schémas de prescription préconisés par les recommandations de pratique clinique qui sont en vigueur pour
        l’asthme et la BPCO sont assez claires et simples à mettre en oeuvre, il n’en va pas forcément de même au
        quotidien. En effet, le prescripteur, généraliste, pneumologue, ou autre, dispose d’un « choix » très large, non
        seulement de molécules, mais aussi de formes galéniques, et de dispositifs d’administration. Il n’est pas
        toujours facile de trouver son chemin dans la jungle des dénominations des médicaments et des inhalateurs.
        <br />
        Par exemple, il existe une demi-douzaine de possibilité pour les bronchodilatateurs bêta-2 mimétiques de courte
        durée d’action, une quinzaine pour les corticostéroïdes seuls, etc.
      </p>
      <h2 className="h2-1 font-bold">Comment choisir ?</h2>
      <p className="p-1 text-sm py-3">
        Une fois le choix fait, quelles recommandations faire au patient ?
        <br />
        Comment lui apprendre à se servir du dispositif ?
        <br />
        Comment vérifier qu’il sait s’en servir ?
        <br />
        Comment évaluer son observance ?
        <br />
        Prescrire un traitement inhalé dans l’asthme ou la BPCO implique de savoir répondre à ces questions. La réalité
        de terrain montre que les prescripteurs se limitent en général à un très petit nombre de solutions, qu’ils
        connaissent le mieux, souvent faute du temps nécessaire à se documenter sur des alternatives nouvelles, ou
        existantes mais qui seraient plus appropriées pour leurs patients.
      </p>
      <h2 className="h2-1 font-bold">Pourquoi un guide des thérapeutiques inhalées prêtes à l’emploi ?</h2>
      <p className="p-1 text-sm">
        L’objectif de ce guide thérapeutique, fruit du travail conjoint de pneumologues et de pharmaciens hospitaliers,
        est double : Premièrement, il a pour but d’aider les prescripteurs à optimiser leurs choix en recensant la
        totalité des traitements inhalés prêt à l’emploi disponibles dans l’asthme et la BPCO chez l’adulte (aérosols
        doseurs pressurisés, inhalateurs de poudre et inhalateurs de brumisat), et en colligeant pour chacun les
        caractéristiques susceptibles d’être discriminantes dans la décision thérapeutique. à noter que ce guide ne
        traitera pas de la nébulisation pour lequel des recommandations ont déjà été publiées [12].
        <br />
        Deuxièmement, il a également pour but de fournir aux pneumologues, généralistes et pharmaciens un répertoire
        illustré, rapide et facile d’accès. Ce document pourra ainsi constituer un support d’éducation thérapeutique du
        patient et des personnels soignants et pharmaciens dans ces deux affections, pouvant être utilisé dans des
        programmes spécialisés. L’apprentissage des modalités d’utilisation de ces dispositifs est en effet une des
        premières compétences d’auto-soin cité dans les programmes d’éducation thérapeutique [13].
      </p>
      <h2 className="h2-1 text-blue-900 font-semibold">Bibliographie</h2>
      <ul className="num text-sm py-3">
        <li>
          <span className="itemStyleDeciaml">1.</span>
          <span>
            {' '}
            T.he Global Initiative for Chronic Obstructive Lung disease (GOLD) revised 2010; www.goldcopd.com.
          </span>
        </li>
        <li>
          <span className="itemStyleDeciaml">2.</span>
          <span>
            {' '}
            Raherison C. Epidémiologie de la bronchopathie chronique obstructive. Presse Med. 2009 Mar; 38(3):400-5.
            Epub 2009 Jan 29. French
          </span>
        </li>
        <li>
          <span className="itemStyleDeciaml">3.</span>
          <span>
            {' '}
            Ministère de la Santé et des Solidarités, Programme d’actions en faveur de la bronchopneumopathie chronique
            obstructive (BPCO): « connaître, prévenir et mieux prendre en charge la BPCO » 2005- 2010 / novembre 2005 -{' '}
            <a href="http://www.sante.gouv.fr/htm/" className="none-deco text-blue-900 underline">
              http://www.sante.gouv.fr/htm/dossiers/bpco /plan_bpco.pdf dossiers/bpco /plan_bpco.pdf
            </a>
          </span>
        </li>
        <li>
          <span className="itemStyleDeciaml">4.</span>
          <span>
            {' '}
            World health statistics 2008.ISBN 978 92 4 156359 8 (NLM classification: WA 900.1); ISBN 978 92 40682740
            (version électronique) ;{' '}
            <a className="none-deco text-blue-900 underline" href="http://www.who.int/whosis/whostat/EN_WHS08_Full.pdf">
              http://www.who.int/whosis/whostat/EN_WHS08_Full.pdf
            </a>
          </span>
        </li>
        <li>
          <span className="itemStyleDeciaml">5.</span>
          <span>
            {' '}
            DGS/ GTNDO, « Broncho-Pneumopathie Chronique Obstructive », Mise à jour 27/ 03/ 03{' '}
            <a
              className="none-deco  text-blue-900 underline"
              href="http://www.sante.gouv.fr/htm/dossiers/losp/50bpco.pdf"
            >
              http://www.sante.gouv.fr/htm/dossiers/losp/50bpco.pdf
            </a>
          </span>
        </li>
        <li>
          <span className="itemStyleDeciaml">6.</span>
          <span>
            {' '}
            SPLF - Recommandations pour la prise en charge de la BPCO – Actualisation 2003. Argumentaire, Rev. Mal.
            Respir. 2003 ; (20) : 4539-45.
          </span>
        </li>
        <li>
          <span className="itemStyleDeciaml">7.</span>
          <span>
            {' '}
            Allain YM, Giraud F, Huchon G, Roche N. Traitement médicamenteux de la bronchopneumopathie chronique
            obstructive en état stable. Presse Med. 2009 Mar; 38(3):432-44
          </span>
        </li>
        <li>
          <span className="itemStyleDeciaml">8.</span>
          <span>
            {' '}
            Global Strategy for Asthma Management and Prevention, Global Initiative for Asthma (GINA). Revised 2006
            Rapports disponibles sur le site internet{' '}
            <a href="https://ginasthma.org" target="blank" className="none-deco  text-blue-900 underline">
              www.ginasthma.org
            </a>
            .
          </span>
        </li>
        <li>
          <span className="itemStyleDeciaml">9.</span>
          <span>
            {' '}
            Delmas MC, Fuhrman C, pour le groupe épidémiologie et recherche clinique de la SPLF. L’asthme en France.
            Synthèse des données épidémiologiques descriptives. Rev Mal Respir 2010; 27 151-9
          </span>
        </li>
        <li>
          <span className="itemStyleDeciaml">10.</span>
          <span>
            {' '}
            Education thérapeutique du patient asthmatique adulte et adolescent, synthèse des recommandations, ANAES,
            juin 2001 -{' '}
            <a
              href="http://www.has-sante.fr/portail/upload/docs/application/pdf/education_asthmatique_adulte_-_synthese.pdf"
              className="none-deco text-blue-900 underline"
            >
              http://www.has-sante.fr/portail/upload/docs/application/pdf/education_asthmatique_adulte_-_synthese.pdf
            </a>
          </span>
        </li>
        <li>
          <span className="itemStyleDeciaml">11.</span>
          <span>
            {' '}
            Cuerq A, Pépin S, Ricordeau P. Caisse Nationale d’Assurance Maladie des Travailleurs Salariés (CNAMTS).
            Remboursement de médicaments antiasthmatiques : une approche de la prévalence et du contrôle de l’asthme.
            Point de repère n°24-décembre 2008
          </span>
        </li>
        <li>
          <span className="itemStyleDeciaml">12.</span>
          <span>
            {' '}
            Dautzenberg B, Becquemin MH, Chaumuzeau JP, Diot P. Good practice for aerosol therapy by nebulisation. Rev
            Mal Respir. 2007 Juin; 24(6):751-757.
          </span>
        </li>
        <li>
          <span className="itemStyleDeciaml">13.</span>{' '}
          <span>
            Magar Y, Vervloet D, Steenhouwer F, et al. Assessment of a therapeutic education programme for asthma
            patients: «un souffle nouveau». Patient Educ Couns 2005; 58(1):41-46
          </span>
        </li>
      </ul>
    </div>
  );
};

export default Introduction;

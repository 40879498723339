import React from 'react';
import { Link } from 'react-router-dom';

interface Props {
  display: string;
  setdisplaySection: (target: string) => void;
}
const Editorial21 = ({ display, setdisplaySection }: Props) => {
  const DisplayCondition = 'Éditorial 2021';

  return (
    <div
      id="edt-2021"
      style={{ display: display === DisplayCondition ? 'block' : 'none' }}
      className="text-sm text-blue-600 font-regular"
    >
      {/* eslint-disable */}
      <p className="p-1 ">
        Les éditoriaux d’ouverture des éditions annuelles du Guide ZÉPHIR1 des thérapeutiques inhalées de l’asthme et de
        la BPCO chez l’adulte ont souvent commencé par un discours sur le nombre de combinaisons de molécules et de
        dispositifs d’inhalation qui sont à la disposition des prescripteurs et des patients. Des molécules, des
        associations de molécules, des dosages différents, des dispositifs différents, réalisant une combinatoire
        infernale qui, comme l’univers, est en expansion constante. Toujours du nouveau, jamais de « ménage ». Des
        traitements qui résistent au temps alors que d’autres plus modernes, plus sophistiqués, à propos desquels le
        niveau de preuve est élevé, peinent à conquérir les parts de marché convoitées. C’est sur ce thème que débutait
        l’éditorial de « ZÉPHIR 2020 »{' '}
        <Link
          to={`/informations?section=Éditorial`}
          className="text-blue-90 underline"
          onClick={() => {
            setdisplaySection('Éditorial 2020');
          }}
        >
          (cf. éditorial 2020)
        </Link>
        . Ce même éditorial abordait ensuite l’essence même de la « gamme ZÉPHIR », qui est d’être un outil d’appui aux
        prescripteurs ne disposant ni d’une connaissance suffisante des multiples dispositifs ni du temps qui sont
        nécessaires à une éducation thérapeutique de qualité. Il n’est pas inutile de rappeler que la « gamme ZÉPHIR »,
        déclinaison en multiples outils du Guide ZÉPHIR, comprend une application pour téléphones mobiles intelligents,
        un site internet, des étiquettes dynamiques à coller sur les ordonnances (Classeurs, Pocket), et des disques
        mémentos.{' '}
      </p>
      <p className="p-1 text-sm">
        Tous ces outils ont pour objectif d’ aider les prescripteurs à connaître l’intimité des traitements qu’ils
        recommandent à leurs patients, et, surtout, de contribuer à l’éducation thérapeutique en favorisant une
        utilisation optimale. Ainsi, la principale particularité du Guide ZÉPHIR est d’être autant orienté « médecin»
        que « patient ». Ce sont les vidéos ZÉPHIR qui définissent le mieux cette orientation « patient ». Chaque fiche
        ZÉPHIR comporte un petit film réalisé dans des conditions standardisées et décrivant scrupuleusement détail
        après détail, la technique de prise telle qu’elle est recommandée par le fabricant. Ces vidéos sont librement
        accessibles pour les patients sur le site de la Société de Pneumologie de Langue Française, à l’adresse{' '}
        <a href="http://splf.fr/videos-zephir" target="_blank" className="text-blue-90 underline">
          http://splf.fr/videos-zephir.{' '}
        </a>{' '}
      </p>
      <p className="p-1 text-sm">
        Chaque prescripteur peut donner à son patient un accès direct aux vidéos qui le concernent, grâce aux «
        étiquettes dynamiques ZÉPHIR ». Ces étiquettes autocollantes sont revêtues de « flashcodes » dont la lecture par
        un smartphone renvoie directement à la vidéo du produit concerné, que le patient peut alors visualiser à
        loisir2. Il y a autant d’étiquettes que de produits commercialisés. Ces étiquettes sont destinées à être collées
        sur les ordonnances, par le prescripteur ou le pharmacien d’officine, à côté du nom du produit prescrit. Elles
        sont réunies sous forme de planches, dans un classeur mis à la disposition des pneumologues (aujourd’hui à des
        médecins généralistes et demain à des pharmaciens)3. En 2020, nous vous avions annoncé que le « VIDAL »
        s’emparait du sujet, en intégrant les vidéos ZÉPHIR au sein de toutes ses fiches médicament (dans le VIDAL
        professionnel comme dans le « VIDAL de la famille », c’est-à-dire les fiches « Eurêka Santé »), et en offrant la
        possibilité, à partir de l’espace « campus » du dictionnaire VIDAL (
        <a href="https://campus.vidal.fr/" target="_blank" className="text-blue-90 underline">
          https://campus.vidal.fr/
        </a>{' '}
        ), la possibilité aux prescripteurs qui ne disposent pas d’étiquettes ZÉPHIR d’en imprimer en un clic afin de
        les remettre au patient pour qu’il puisse les visualiser après la consultation. Nous vous avions également
        annoncé la première intégration des étiquettes ZÉPHIR dans un logiciel de prescription, le logiciel ORBIS de
        l’Assistance Publique-Hôpitaux de Paris. Les prescripteurs qui, à la rubrique « ordonnance » d’ORBIS, tapent le
        nom d’un traitement inhalé de l’asthme ou de la BPCO, voient automatiquement apparaître le QR code ZÉPHIR
        correspondant sur leur prescription telle qu’elle sort de l’imprimante. L’année 2021 va voir ce concept
        s’élargir : ainsi, différents partenariats sont en cours de négociation entre la SPLF et des éditeurs de
        logiciel de prescription, et la présence des « flashcodes » ZÉPHIR intégrés aux ordonnances devrait s’étendre
        considérablement au cours de cette année.
      </p>
      <p className="p-1 text-sm">
        Dernier point, la télémédecine. L’épidémie « COVID-19 » (et oui, il fallait bien la mentionner) a fait exploser
        la télémédecine dans toutes ses dimensions et applications, et a été à l’origine de progrès technologiques qui
        devraient durablement installer cette approche dans le paysage de la pratique médicale. Cette dynamique est
        porteuse d’espoirs de progrès dans le domaine des thérapeutiques inhalées de l’asthme et de la BPCO. Une étude
        pilote publiée en 20204 a fait la démonstration qu’il était possible d’améliorer la qualité de l’utilisation des
        inhalateurs au travers d’une interaction à distance entre un patient et un professionnel de santé. Dans cette
        étude, financée par l’ANTADIR [
        <a href="https://pubmed.ncbi.nlm.nih.gov/32302518/" target="_blank" className="text-blue-90 underline">
          https://pubmed.ncbi.nlm.nih.gov/32302518/{' '}
        </a>
        ], la confrontation pas à pas des gestes effectués par les patients avec la gestuelle « idéale » décrite par les
        vidéos ZÉPHIR permettait d’identifier les erreurs, de les expliquer aux patients, et de réduire rapidement et
        considérablement le nombre d’erreurs commises. Vers quoi ce type de résultat nous emmène-t-il ? Clairement, la
        nécessité d’une interaction humaine, même à distance, limite les possibilités d’expansion de ce type particulier
        de télémédecine que serait la « télé-éducation thérapeutique ». Peut-on imaginer de se passer de cette
        interaction, et d’automatiser le processus ? Peut-on imaginer d’utiliser l’intelligence artificielle à cette fin
        ? Il « suffirait » pour cela de pouvoir reconnaître automatiquement les gestes effectués par le patient et de
        les comparer à la « gestuelle ZÉPHIR » pour identifier les anomalies et suggérer des corrections. Impossible ?
        Bien sûr que non : c’est le concept sur lequel repose le projet HEPHAÏ [
        <a href="https://hephai.fr/" target="_blank" className="text-blue-90 underline">
          https://hephai.fr/{' '}
        </a>
        ], dont les premières concrétisations sont attendues en 2021. Si un tel progrès se concrétise, et il y a toutes
        les raisons de penser que ce sera rapidement le cas, le guide ZÉPHIR aura été à l’origine d’une véritable
        révolution dans l’éducation thérapeutique à la prise des traitements inhalés.{' '}
      </p>
      <p className="p-1" />
      <h2 className="h2-1 font-bold text-blue">Le comité scientifique ZÉPHIR </h2>
      <p className="p-1">
        Valéry TROSINI-DÉSERT
        <br />
        Pneumologue, Praticien Hospitalier, Service de Pneumologie, Médecine Intensive et Réanimation (département R3S),
        Groupe Hospitalier APHP. Sorbonne Université, site Pitié-Salpêtrière, Paris{' '}
      </p>
      <p className="p-1" />
      <p className="p-1">
        Marie ANTIGNAC <br />
        Pharmacien – Cheffe Service de pharmacie Pitié-Salpêtrière Charles Foix. <br /> Coordinatrice du diplôme
        universitaire d'éducation thérapeutique du patient, de l'université Paris 11
      </p>
      <p className="p-1">
        Marie-Hélène BECQUEMIN <br />
        Pneumologue, ancien Maître de Conférence des Universités (Paris 7) - ancien Praticien Hospitalier, Service
        d’Explorations Fonctionnelles de la Respiration, de l’Exercice et de la Dyspnée, Groupe Hospitalier
        Pitié-Salpêtrière Charles Foix, Paris. Membre du GAT (Groupe Aérosol Thérapie de la Société de Pneumologie de
        Langue Française){' '}
      </p>
      <p className="p-1">
        Thomas SIMILOWSKI <br />
        Pneumologue, Professeur des Universités-Praticien Hospitalier, Chef du Service de Pneumologie, Médecine
        Intensive et Réanimation (département R3S), Groupe Hospitalier APHP. Sorbonne Université, site
        Pitié-Salpêtrière, Paris{' '}
      </p>
      <p className="p-1" />
      <p className=" text-sm p-1  font-regular">
        <p className="pb-2 sm:pb-4">
          <span className="text-blue-90"> 1. </span> « Guide ZÉPHIR » est une marque déposée qui protège les diverses
          déclinaisons de la gamme ZÉPHIR ; à compter de 2019, la Société de Pneumologie de Langue Française est seule
          propriétaire de la marque « Guide ZÉPHIR » et seul « opérateur » de la gamme ZÉPHIR.{' '}
        </p>
        <p className="pb-2 sm:pb-4">
          <span className="text-blue-90 "> 2. </span>Les patients qui n’ont pas de « smartphone » peuvent aussi saisir
          une adresse Internet simplifiée dans leur navigateur, adresse qui figure sur chaque étiquette.{' '}
        </p>
        <p>
          <span className="text-blue-90 "> 3.</span> On ne peut pas obtenir uniquement la planche d’un médicament donné
          ; en effet, les outils ZÉPHIR en général et les étiquettes dynamiques ZÉPHIR en particulier sont considérés
          comme « institutionnels » par l’ANSM, et non promotionnels ; cette labellisation implique que les 61 planches
          correspondant à 61 traitements soient indissociables, pour éviter tout usage publicitaire.
          <p>
            <span className="text-blue-90 "> 4. </span> TROSINI-DESERT V, LAFOESTE H, REGARD L, MALRIN R,
            GALARZA-JIMENEZ A, ESTEBAN-AMARILLA C, DELRIEU J, FORET D, MELLONI B, EL-KHOUARI F, SIMILOWSKI T. A
            telemedicine intervention to ensure the correct usage of inhaler devices. Telemedicine &amp; E-Health, 2020,
            DOI10.1089/tmj.2019.0246.
          </p>
        </p>
      </p>
    </div>
  );
};

export default Editorial21;

import React from 'react';

interface Props {
  display: string;
}
const Facteurs = ({ display }: Props) => {
  const DisplayCondition = 'Facteurs';

  return (
    <div id="factureurs" style={{ display: display === DisplayCondition ? 'block' : 'none' }}>
      <h2 className=" text-blue-900 text-right">Marie-Hélène Becquemin</h2>
      <p className="p-2" />
      <p className="text-sm py-3">
        Un aérosol se définit comme la suspension dans l’air (ou dans un autre gaz) de particules liquides ou solides
        dont la très petite taille (&lt; 100 μm) leur assure une vitesse de chute très faible.
        <br />
        L’aérosolthérapie se définit comme la délivrance par voie inhalée d’un principe actif médicamenteux sous forme
        d’aérosol. Elle utilise la large surface de contact entre le milieu aérien et la muqueuse bronchique et/ou la
        surface alvéolaire et présente de nombreux avantages : atteinte directe de l’organe cible, rapidité d’action,
        voie naturelle et indolore, faible posologie, peu d’effets indésirables...
        <br />
        L’efficacité d’un aérosol est en rapport directement avec la masse médicamenteuse déposée au niveau des voies
        aériennes. Elle correspond à une certaine quantité de médicament déposée dans un territoire donné [1], ce qui
        est différent de la masse inhalée, qui correspond à la quantité de médicament pénétrant dans les voies
        aériennes. La masse déposée est très variable et dépend de différents facteurs.
      </p>
      <h2 className="h2-1-bottom font-bold text-blue-900">Facteurs liés au générateur</h2>
      <p className="p-1-Bottom-0 text-sm">
        Ces facteurs déterminent les caractéristiques chimiques et physiques de l’aérosol.
      </p>
      <h2 className="h2-3 font-bold">La taille des particules :</h2>
      <p className="text-sm py-3">
        Pour définir la taille des particules sous forme aérosol [2], on parle de Diamètre Aérodynamique Equivalent ou
        diamètre d’une sphère ayant la même vitesse de chute qu’une particule de masse spécifique égale à 1 g/cm3. Le
        Diamètre Aérodynamique Massique Médian (DAMM) ou MMAD (Mass Median Aerodynamic Diameter) divise la masse de
        l’aérosol en 2 parties égales. Ce diamètre tient compte non seulement du diamètre géométrique de la particule
        mais aussi de sa forme et de sa densité. Ainsi est il inférieur au diamètre réel géométrique si sa densité est
        inférieure à 1 g/cm3 ; si la densité devient supérieure à 1 g/cm3, le MMAD devient supérieur au diamètre réel
        géométrique : par exemple, pour une particule de diamètre géométrique de 2 μm, le MMAD est de 4,5 μm si la
        densité est de 5. En fait ce diamètre rend compte du comportement aérodynamique des particules tout le long des
        voies aériennes et détermine les mécanismes de dépôt.
        <br />
        La majorité des dispositifs d’inhalation génère des particules (liquides ou de poudre sèche) dites fines, de
        MMAD compris entre 1 et 5 μm pour un dépôt optimal dans les voies aériennes inférieures. L’écart-type
        géométrique exprime la dispersion des tailles particulaires de l’aérosol, et pour la quasi totalité des aérosols
        médicamenteux, cet écart-type est supérieur à 1,2, signifiant que tous ces aérosols ont une distribution
        polydispersée.
        <br />
        Les particules de MMAD &gt; 5 μm se déposent principalement dans l’oropharynx, celles de 2 à 5 μm se déposent
        principalement dans la trachée et les bronches, et celles de 0,5 à 2 μm dans les bronchioles et les sacs
        alvéolaires [3].
        <br />
        Les particules ultrafines (MMAD &lt; 0,1 μm) sont exhalées ou déposées, et dans ce dernier cas, elles sont alors
        très pénétrantes et du domaine des nanoparticules.
      </p>
      <h2 className="h2-3 font-bold">La vitesse initiale des particules sortant du générateur</h2>
      <p className="text-sm py-3">
        Plus cette vitesse est élevée, plus les particules se déposent par impaction dans la sphère ORL. Cependant, pour
        les aérosol-doseurs pressurisés, le remplacement du gaz propulseur CFC par de l’hydrofluoroalkane-134a [4] ou la
        modification de l’excipient ont permis en partie de diminuer cette vitesse de sortie tout en diminuant également
        la taille des particules [5]. L’utilisation des chambres d’inhalation a permis également de diminuer le dépôt au
        niveau de l’oropharynx. Un nouveau dispositif a vu récemment le jour, basé sur un système mécanique (compression
        d’un ressort) qui tout en fournissant de fines particules, produit un aérosol sous forme de brumisat dont la
        vitesse de sortie est ralentie [6].
      </p>
      <h2 className="h2-3 font-bold">Autres facteurs</h2>
      <p className="text-sm py-3">
        Taille des particules et vitesse initiale de sortie ne sont pas les seuls éléments à prendre en compte pour
        juger de l’efficacité d’un couple médicament inhalé-dispositif [7] en terme de masse médicamenteuse déposée.
        <br />
        Pour les aérosols doseurs pressurisés, outre le gaz propulseur et la valve doseuse [8], il faut aussi tenir
        compte de la pression d’évaporation, des agents tensioactifs, de l’hygroscopie et des forces d’attraction
        électrostatiques. En ce qui concerne l’hygroscopie, les substances solubles dans l’eau forment des aérosols dont
        les particules sont hygroscopiques à des degrés divers ; en effet, lorsque ces substances arrivent dans les
        voies aériennes riches en vapeur d’eau, la taille des particules augmente et ce d’autant que les particules
        initiales sont petites. Quand aux forces d’attraction électrostatiques, elles entrainent des phénomènes de
        coalescence des particules entre elles.
        <br />
        Pour les inhalateurs de poudre sèche, les facteurs tels que la formulation, l’utilisation de particules poreuses
        de faible densité, le processus de production de la poudre, l’utilisation d’excipients anti-adhérents, la
        diminution de la sensibilité à l’humidité ou aux changements de température, tous cherchent à diminuer les
        forces de cohésion inter particulaire pour une meilleure désagrégation de la poudre lors de l’inhalation.
      </p>
      <h2 className="h2-1-bottom font-bold text-blue-900">Facteurs liés au sujet</h2>
      <h2 className="h2-3-top-5 font-semibold">Le mode ventilatoire durant l’inhalation</h2>
      <p className="text-sm py-3">
        Le lieu de dépôt du médicament dépend de la vitesse de déplacement de celui-ci dans les voies aériennes et donc
        du débit inspiratoire [9] : le débit,rapide au niveau des voies aériennes hautes décroit progressivement à
        mesure que l’on atteint les voies aériennes distales et les sacs alvéolaires.
      </p>
      <p className="p-2 font-semibold">Le dépôt par impaction concerne :</p>
      <div className="pad-left-15 text-sm">
        <ul className="itemColor list-disc">
          <li>les voies aériennes supérieures et proximales en raison du débit rapide</li>
          <li>les particules de MMAD &gt; 5 μm en raison de leur énergie cinétique.</li>
        </ul>
      </div>
      <p className="p-2 font-semibold">Le dépôt par sédimentation concerne :</p>
      <div className="pad-left-15 text-sm">
        <ul className="itemColor list-disc">
          <li>la trachée et les bronches en raison du débit plus lent</li>
          <li>les particules de MMAD entre 2 à 5 μm par effet de pesanteur.</li>
        </ul>
      </div>
      <p className="p-2 font-semibold">Le dépôt par diffusion concerne :</p>
      <div className="pad-left-15 text-sm">
        <ul className="itemColor list-disc">
          <li>les bronchioles et les sacs alvéolaires</li>
          <li>
            et les plus fines particules de MMAD entre 0,5 et 2 μm qui vont, du fait de mouvements browniens être
            plaquées contre les parois.
          </li>
        </ul>
      </div>
      <p className="p-2" />

      <p className="text-sm py-3">
        La voie buccale est dans tous les cas à privilégier. L’influence des modalités d’inhalation sur le dépôt (volume
        courant, débit inspiratoire, pauses entre inspiration et expiration) a été soulignée dès les années 1980 [10] :
        lorsque le volume courant augmente de 0,25 à 1litre et que la fréquence respiratoire diminue de 30 à 7,5 cycles
        par min, le dépôt des particules de 3 μm au niveau des voies aériennes les plus distales augmente d’environ 10 à
        60 % tandis que le dépôt au niveau des voies plus proximales des plus grosses particules diminue d’environ de 25
        à 15 %. <br />
        Ainsi une respiration rapide et superficielle augmente le dépôt des plus grosses particules dans les voies
        aériennes supérieures. Une inspiration lente et profonde favorise le dépôt des plus petites par sédimentation au
        niveau des voies aériennes distales [11]. <br /> Une expiration jusqu’à la CRF avant l’inhalation est
        recommandée mais une expiration forçée jusqu’au VR peut entrainer un collapsus des petites voies aériennes et
        donc une diminution de la masse médicamenteuse déposée. Une apnée de quelques secondes après l’inhalation par
        contre favoriserait ce dépôt. <br />
        Pour les inhalateurs de poudre sèche, le problème est différent dans la mesure où un débit inspiratoire minimal
        est nécessaire. C’est en effet ce débit (inversement proportionnel à la résistance intrinsèque du dispositif)
        qui, créant un flux turbulent entraine la désagrégation de la poudre. <br />
        Enfin, l’inhalation des aérosols se fait le plus souvent en position assise ou debout, favorisant un dépôt vers
        les bases au détriment des sommets. <br />
        L’éducation du patient pour contrôler les paramètres ventilatoires durant l’inhalation est essentielle pour
        optimiser la masse médicamenteuse déposée.
      </p>
      <h2 className="h2-3 font-bold">L’anatomie des voies aériennes</h2>
      <p className="text-sm py-3">
        Diamètre et courbures des voies aériennes déterminent en partie les mécanismes de dépôt. <br /> Chez l’enfant
        chez qui les voies aériennes sont géométrique exprime la dispersion des tailles particulaires de l’aérosol, et
        pour la quasi totalité des aérosols médicamenteux, cet écart-type est supérieur à 1,2, signifiant que tous ces
        aérosols ont une distribution polydispersée. Les particules de MMAD &gt; 5 μm se déposent principalement dans
        l’oropharynx, celles de 2 à 5 μm se déposent principalement dans la trachée et les bronches, et celles de 0,5 à
        2 μm dans les bronchioles et les sacs alvéolaires [3]. <br />
        Les particules ultrafines (MMAD &lt; 0,1 μm) sont exhalées ou déposées, et dans ce dernier cas, elles sont alors
        très pénétrantes et du domaine des nanoparticules.
      </p>
      <h2 className="h2-3 font-bold">La vitesse initiale des particules sortant du générateur</h2>
      <p className="text-sm py-3">
        Plus cette vitesse est élevée, plus les particules se déposent par impaction dans la sphère ORL. Cependant, pour
        les aérosol-doseurs pressurisés, le remplacement du gaz propulseur CFC par de l’hydrofluoroalkane-134a [4] ou la
        modification de l’excipient ont permis en partie de diminuer cette vitesse de sortie tout en diminuant également
        la taille des particules [5]. L’utilisation des chambres d’inhalation a permis également de diminuer le dépôt au
        niveau de l’oropharynx. Un nouveau dispositif a vu récemment le jour, basé sur un système mécanique (compression
        d’un ressort) qui tout en fournissant de fines particules, produit un aérosol sous forme de brumisat dont la
        vitesse de sortie est ralentie [6].
      </p>
      <h2 className="h2-3 font-bold">Autres facteurs</h2>
      <p className="text-sm py-3">
        Taille des particules et vitesse initiale de sortie ne sont pas les seuls éléments à prendre en compte pour
        juger de l’efficacité d’un couple médicament inhalé-dispositif [7] en terme de masse médicamenteuse déposée.
        Pour les aérosols doseurs pressurisés, outre le gaz propulseur et la valve doseuse [8], il faut aussi tenir
        compte de la pression d’évaporation, des agents tensioactifs, de l’hygroscopie et des forces d’attraction
        électrostatiques. En ce qui concerne l’hygroscopie, les substances solubles dans l’eau forment des aérosols dont
        les particules sont hygroscopiques à des degrés divers ; en effet, lorsque ces substances arrivent dans les
        voies aériennes riches en vapeur d’eau, la taille des particules augmente et ce d’autant que les particules
        initiales sont petites. Quand aux forces d’attraction électrostatiques, elles entrainent des phénomènes de
        coalescence des particules entre elles.
        <br />
        Pour les inhalateurs de poudre sèche, les facteurs tels que la formulation, l’utilisation de particules poreuses
        de faible densité, le processus de production de la poudre, l’utilisation d’excipients anti-adhérents, la
        diminution de la sensibilité à l’humidité ou aux changements de température, tous cherchent à diminuer les
        forces de cohésion inter particulaire pour une meilleure désagrégation de la poudre lors de l’inhalation.
      </p>
      <h2 className="h2-1 text-blue-900 font-bold">Facteurs liés au sujet</h2>
      <h2 className="h2-3-top-5">Le mode ventilatoire durant l’inhalation</h2>
      <p className="text-sm py-3">
        Le lieu de dépôt du médicament dépend de la vitesse de déplacement de celui-ci dans les voies aériennes et donc
        du débit inspiratoire [9] : le débit,
      </p>
    </div>
  );
};

export default Facteurs;

import Button from 'components/Button';
import ArrowVideo from 'components/icons/ArrowVideo';
import Video from 'components/icons/Video';
import { useState } from 'react';
import classNames from 'utils/classNames';

import ModalVideo from '../../ux/ModalVideo/ModalVideo';
import classes from '../../ux/ModalVideo/VideoCard.module.scss';

interface CardProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  title: string;
  footer?: string;
  medicineId?: string;
  deviceType?: string;
  smallTitle?: string;
  medicinePhoto?: string;
  generic?: string;
  videoData: { url: string; qrCode: string };
  medidineDescription: { classe: string; dci: string; device: string; dosage: string };
}

const VideoCard: React.FC<CardProps> = ({
  title,
  medicineId,
  footer,
  className,
  medicinePhoto,
  deviceType,
  smallTitle,
  generic,
  videoData = { url: 'http://splf.fr/ventoline/&flux=3', qrCode: 'qrTest' },
  medidineDescription,
}) => {
  const [videoModal, setvideoModal] = useState(false);
  return (
    <div
      id={medicineId}
      className={classNames(
        'flex flex-col text-blue-600 hover:text-white bg-white rounded-md ',
        'hover:border-blue border border-grey hover:bg-blue',
        classes.container,
        className,
      )}
    >
      <div className="flex-1 p-2">
        <div className={classNames('h-60 rounded-md overflow-hidden', classes.video)}>
          <div className="relative">
            {generic === 'oui' && (
              <div
                className={classNames(
                  'absolute px-2 ml-auto flex  py-2 bg-yellow text-white  text-xs font-medium	place-self-end rounded-3xl right-3 top-3',
                  classes.footer,
                )}
              >
                <span className="px-1 "> Générique </span>
              </div>
            )}
            {/* eslint-disable-next-line */}
            <img alt="fdsf" src={medicinePhoto} />
          </div>
        </div>

        <div className={classNames('gap-3 flex-col items-center justify-center hidden h-60 rounded-md ', classes.btns)}>
          <Button className="relative bg-yellow" variant="quaternary" size="md" onClick={() => setvideoModal(true)}>
            <ArrowVideo height={17} className="absolute  top-1/3 left-7  " />
            Voir la vidéo
          </Button>
        </div>

        <div className="text-base px-4 py-2 pb-5">
          <div className="font-bold">{title}</div>

          {/* <div className="justify-between	flex"> */}
          <div className="italic">{smallTitle}</div>
          {/* </div> */}
        </div>
      </div>
      <div className="border-t border-grey-300 w-full">
        <div className="flex font-medium px-6 pt-2 pb-3">
          <div className={classNames('flex-1 mr-2', footer?.trim().split(' ').length === 1 && 'truncate')}>
            {footer}
          </div>
          <div className={classNames('text-blue flex flex-1 justify-end items-center', classes.footer)}>
            <Video height={13} />
            <div className="pl-2.5 truncate">Vidéo Éducative</div>
          </div>
        </div>
      </div>
      <ModalVideo
        onClose={() => setvideoModal(false)}
        medidineDescription={medidineDescription}
        title={title}
        deviceType={deviceType}
        medicineId={medicineId}
        videoData={videoData}
        open={videoModal}
        smallTitle={smallTitle}
      />
    </div>
  );
};

export default VideoCard;

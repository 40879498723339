import { useState, useEffect } from 'react';
import { Redirect, Switch } from 'react-router-dom';

import UserContext from 'contexts/UserContext';
import Route from 'components/ui/Route/Route';
import { User } from 'requests/types';
import startup from 'utils/startup';
import ProtectedRoute from 'components/ui/Route/ProtectedRoute';
import PreviousMenuContext from 'contexts/PreviousMenuState';
import { MenuStatesValues } from 'contexts/MenuContext';
import CardIdContext from 'contexts/CardIdContext';
import Indication from './Indication/Indication';

import AuteurContainer from './AuteurContainer/AuteurContainer';
import HomeContainer from './Home/HomeContainer';
import LoginContainer from './Login/LoginContainer';
import GammeZephir from './GammeZephir/GammeZephir';

import DetailsContainer from './DetailsMedication/DeatilsMedication';
import NotFoundContainer from './NotFoundConatiner/NotFoundConatiner';

import PageStatique from './PageStatique';
import PageFavoris from './PageFavoris';
import Note from './Note/Note';

import InhalationChamber from './InhalationChamber/InhalationChamber';
import PageVideos from './PageVideoEducatives';
import DetailsNoteContainer from './DetailsNotes/DeatilsNotes';
import TypeDevices from './TypeDevices/TypeDevices';
import Classes from './ClassesTherapeutic/ClassesTherapeutic';
import DevicesName from './DevicesName/DevicesName';
import DCI from './DCI/DCI';
import Lab from './Lab/Lab';
import Generic from './GenericMedecine/GenericMedecine';
import DashboardAdmin from './DashboardAdmin/DashboardAdmin';
import Laboratories from './Laboratories/Laboratories';
import Instalation from './Instalation/Instalation';
import Medications from './Medications/Medications';
import DetailsMedicineAdmin from './DetailsMedicinesAdmin/DetailsMedicinesAdmin';

const RootContainer = () => {
  const [user, setUser] = useState(null as User | null);
  const [PreviousStates, setPreviousStates] = useState<MenuStatesValues | any>(MenuStatesValues.mainMenu);
  const [startupEnded, setStartupEnded] = useState(false);
  const [cardId, setCardId] = useState<string | any>('');
  useEffect(() => {
    startup().then((u) => {
      setUser(u);
      setStartupEnded(true);
    });
  }, []);

  if (!startupEnded) return <div />;

  return (
    <UserContext.Provider value={{ user, setUser }}>
      <PreviousMenuContext.Provider value={{ PreviousStates, setPreviousStates }}>
        <CardIdContext.Provider value={{ cardId, setCardId }}>
          <Switch>
            <ProtectedRoute footer={false} header path="/admin" protected component={DashboardAdmin} />
            <ProtectedRoute footer={false} header path="/laboratory" protected component={Laboratories} />
            <ProtectedRoute footer={false} header path="/medications" protected component={Medications} />

            <Route path="/" exact protected component={HomeContainer} />
            <Route path="/types" exact protected component={TypeDevices} />
            <Route path="/classes" exact protected component={Classes} />
            <Route path="/devices" exact protected component={DevicesName} />
            <Route path="/dci" exact protected component={DCI} />
            <Route path="/indications" exact protected component={Indication} />

            <Route path="/favoris" exact protected component={PageFavoris} />
            <Route path="/notes" exact protected component={Note} />
            <Route path="/informations" exact protected component={PageStatique} />
            <Route path="/videos" exact protected component={PageVideos} />
            <Route path="/auteurs" exact protected component={AuteurContainer} />
            <Route path="/gammeZephir" exact protected component={GammeZephir} />
            <Route path="/installation.html" component={Instalation} />
            <Route path="/medicament/:id" exact protected component={DetailsContainer} />
            <Route path="/medicament/:id/admin" exact protected component={DetailsMedicineAdmin} />

            <Route path="/notes/:id" exact protected component={DetailsNoteContainer} />

            <Route path="/chambreInhalation" exact protected component={InhalationChamber} />
            <Route path="/lab" exact protected component={Lab} />
            <Route path="/generic" exact protected component={Generic} />
            {/* <Route path="/installation.html" render={() => <Redirect to="/installation.html" />} /> */}

            <Route footer={false} header={false} path="/login" exact component={LoginContainer} />
            <Route path="/404" component={NotFoundContainer} />
            <Redirect from="*" to="/404" />
          </Switch>
        </CardIdContext.Provider>
      </PreviousMenuContext.Provider>
    </UserContext.Provider>
  );
};

export default RootContainer;

import { ButtonHTMLAttributes, FunctionComponent } from 'react';
import classNames from 'utils/classNames';

export type ButtonProps = {
  size?: 'sm' | 'xm' | 'md' | 'lg' | 'xl';
  variant?: 'primary' | 'secondary' | 'tertiary' | 'quaternary' | 'tertiary2' | 'quinary';
  icon?: string;
} & ButtonHTMLAttributes<HTMLButtonElement>;

const variants = {
  primary: 'hover:bg-blue-600 bg-yellow text-grey-100 hover:outline-none font-medium ',

  secondary: [
    'bg-blue hover:bg-blue-600 border border-transparent',
    'text-white active:bg-blue-700 focus:ring-blue-800 active:bg-blue-600',
  ],

  tertiary: [
    'bg-blue hover:bg-white border border-grey-100 text-grey-100 font-medium',
    'hover:outline-none hover:text-blue-900 hover:bg-grey-100 hover:border-none ',
  ],
  tertiary2: [
    'bg-blue hover:bg-yellow border border-grey-100 text-grey-100',
    'hover:outline-none hover:text-white hover:bg-grey-100 ',
  ],
  quaternary: 'font-medium bg-yellow hover:bg-white hover:outline-none hover:text-blue  ',
  quinary: 'bg-white hover:bg-yellow text-yellow hover:outline-none font-medium hover:text-white',
};

const sizes = {
  sm: 'w-8 h-12',
  xm: 'h-12 w-60	text-lg',
  md: 'h-12 w-64	text-lg',
  lg: 'h-14 w-64 text-xl',
  xl: 'h-16 w-64 text-2xl',
};

const Button: FunctionComponent<ButtonProps> = ({
  variant,
  size = 'md',
  icon,
  disabled,
  children,
  className,
  ...rest
}) => {
  const classes = classNames(
    'focus:outline-none focus:ring-0 rounded-5 md:rounded-3 disabled:bg-grey disabled:hover:bg-grey-600 disabled:cursor-default',
    'flex flex-row items-center justify-center space-x-2 ',
    'transition-all ease-linear duration-200 ',
    variant && variants[variant],
    sizes[size],
    className,
  );
  return (
    <button
      disabled={disabled}
      style={{
        backgroundColor: disabled ? '#B3B3B3' : '',
      }}
      className={classes}
      {...rest}
    >
      {icon && <img src={icon} alt="" className="mr-2" />}
      {children}
    </button>
  );
};

export default Button;

import { FC } from 'react';
import { useDeleteNote } from 'requests/note';
import DeleteModal from 'components/ux/DeleteModal';

interface DeleteNoteModalProps {
  onDelete: (updated: boolean) => void;
  id?: string;
}

const DeleteNoteModal: FC<DeleteNoteModalProps> = ({ onDelete, id }) => {
  return (
    <DeleteModal
      confirmationMsg="Cette note a été supprimée avec succès"
      onClose={onDelete}
      deleteCall={useDeleteNote}
      id={id}
      msg="Êtes-vous sûr de vouloir supprimer cette note ?"
    />
  );
};

export default DeleteNoteModal;

import gql from 'graphql-tag';

import { MutationHookOptions, QueryHookOptions } from '@apollo/client';
import { useLocalLazyQuery, useLocalMutation, useLocalQuery } from 'hooks/apollo';

import { GetVariables, List } from 'utils/generic';
import { User, Token } from './types';

export const loginMutation = gql`
  mutation Login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      user {
        id
        email
        role
        firstName
        lastName
        lab {
          id
          name
        }
      }
      token {
        tokenType
        accessToken
        refreshToken
        expiresIn
      }
    }
  }
`;

export const usersQuery = gql`
  query ($perPage: Int, $page: Int, $search: String) {
    users(perPage: $perPage, page: $page, search: $search, sort: "_id", order: -1) {
      count
      totalPages
      perPage
      page
      data {
        lab {
          name
          id
        }
        id
        role
        email
        firstName
        lastName
        is_active
        last_login
        createdAt
      }
    }
  }
`;
export const userQuery = gql`
  query user($id: ID!) {
    user(id: $id) {
      id
      role
      email
      firstName
      lastName
      is_active
      last_login
      createdAt
      lab {
        id
        name
      }
    }
  }
`;

export const registerMutation = gql`
  mutation Register(
    $email: String!
    $password: String
    $firstName: String!
    $lastName: String!
    $role: String!
    $lab: ID
    $useDefaultPassword: Boolean
  ) {
    register(
      email: $email
      password: $password
      firstName: $firstName
      lastName: $lastName
      role: $role
      lab: $lab
      useDefaultPassword: $useDefaultPassword
    ) {
      user {
        id
        email
        role
        firstName
        lastName
        lab {
          id
          name
        }
      }
      token {
        tokenType
        accessToken
        refreshToken
        expiresIn
      }
    }
  }
`;

export const updateUserMutation = gql`
  mutation updateUserData(
    $id: ID!
    $email: String
    $password: String
    $firstName: String
    $lastName: String
    $useDefaultPassword: Boolean
    $lab: String
    $role: String
  ) {
    updateUserData(
      id: $id
      email: $email
      password: $password
      firstName: $firstName
      lastName: $lastName
      useDefaultPassword: $useDefaultPassword
      lab: $lab
      role: $role
    ) {
      id
      email
      firstName
      lastName
      lab {
        id
        name
      }
    }
  }
`;

export const deleteUserMutation = gql`
  mutation RemoveUser($id: ID, $ids: [ID]) {
    removeUser(id: $id, ids: $ids)
  }
`;

export const updateStatusMutation = gql`
  mutation UpdateUser($id: ID!, $is_active: String!) {
    updateUserStaus(id: $id, is_active: $is_active)
  }
`;

export interface LoginArguments {
  email: string;
  password: string;
}
export interface RegisterArguments {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
}

export interface UpdateArguments {
  id: string;
  email: string;
  password?: string;
  firstName: string;
  lastName: string;
}

export interface UpdateUserArguments {
  id: string;
  email?: string;
  password?: string;
  firstName?: string;
  lastName?: string;
  useDefaultPassword?: boolean;
  lab?: string;
  role?: string;
}
export interface LoginData {
  user: User;
  token: Token;
}

export interface UsersData {
  data: User[];
  page: number;
  totalPages: number;
  count: number;
}
export interface UserData {
  id: string;
  name: string;
}

export const useLogin = (options: MutationHookOptions<{ login: LoginData }, LoginArguments> = {}) =>
  useLocalMutation(loginMutation, options);

export const useRegister = (options: MutationHookOptions<{ register: LoginData }, RegisterArguments> = {}) =>
  useLocalMutation(registerMutation, options);

export const useUpdateUser = (options: MutationHookOptions<{ updateUserData: User }, UpdateArguments> = {}) =>
  useLocalMutation(updateUserMutation, options);

export const useUpdateUserAdmin = (
  options: MutationHookOptions<{ updateUserData: { id: string } }, UpdateUserArguments> = {},
) => useLocalMutation(updateUserMutation, options);

export const useDeleteUser = (
  options: MutationHookOptions<{ removeUser: string }, { id?: string; ids?: string[] }> = {},
) => useLocalMutation(deleteUserMutation, options);

export const useStatusUser = (
  options: MutationHookOptions<{ updateUserStaus: boolean }, { id: string; is_active: string }> = {},
) => useLocalMutation(updateStatusMutation, options);

export const useUsers = (options: QueryHookOptions<{ users: List<User> }, {}> = {}) =>
  useLocalQuery(usersQuery, options);

export const useLazyUser = (options?: QueryHookOptions<{ user: User }, GetVariables>) =>
  useLocalLazyQuery(userQuery, options);

export const refreshMutation = gql`
  mutation Refresh($refreshToken: String!) {
    refresh(refreshToken: $refreshToken) {
      user {
        id
        email
        role
        firstName
        lastName
        lab {
          id
          name
        }
      }
      token {
        tokenType
        accessToken
        refreshToken
        expiresIn
      }
    }
  }
`;

export interface RefreshArguments {
  refreshToken: string;
}

export const useRefresh = (options: MutationHookOptions<LoginData, RefreshArguments> = {}) =>
  useLocalMutation<LoginData, RefreshArguments>(refreshMutation, options);

export const resetMutation = gql`
  mutation Reset($password: String!, $token: String!) {
    reset(password: $password, token: $token) {
      user {
        id
        email
        role
        firstName
        lastName
        lab {
          id
          name
        }
      }
      token {
        tokenType
        accessToken
        refreshToken
        expiresIn
      }
    }
  }
`;

export interface ResetArguments {
  password: string;
  token: string;
}
export interface ResetData {
  user: User;
  token: Token;
}

export const useReset = (options: MutationHookOptions<{ reset: ResetData }, ResetArguments> = {}) =>
  useLocalMutation(resetMutation, options);
export const logoutMutation = gql`
  mutation logout {
    logout
  }
`;
export interface LogoutData {
  logout: string;
}
export const useLogout = (options: MutationHookOptions<{ logout: LogoutData }, ResetArguments> = {}) =>
  useLocalMutation(logoutMutation, options);

import { useState } from 'react';
import Splf from 'components/Splf';
import inhalationvideo from 'assets/svg/inhalationvideo.svg';
import inhalationinfo from 'assets/svg/inhalationinfo.svg';
import SingleInhalater from './SingleInhalater';
import ChamnreVideoModal from '../ChamnreVideoModal';
import ChambreInfoModal from '../ChambreInfoModal';

const Card = () => {
  const [openVideo, setopenVideo] = useState(false);
  const [openInfo, setopenInfo] = useState(false);
  // eslint-disable-next-line
  const CardA = {
    title: 'Aerochamber Plus (Bleue)',
    imageURL: 'https://api-guidezephir.wereact.tech/uploads/Archive%202/chambres/zephir/images/aerochamber.png',
    data: [
      {
        title: 'Laboratoire:',

        des: ' GlaxoSmithKline',
      },
      {
        title: 'Distributeur :',
        des: 'Trudell Medical Int',
      },
      {
        title: 'Prix libre',
        des: '',
      },
      {
        title: ' Forfait de remboursement (tous les 6 mois):',
        des: '8,84€',
      },
    ],
  };

  const CardB = {
    title: 'VORTEX avec embout buccal',
    imageURL: 'https://api-guidezephir.wereact.tech/uploads/Archive%202/chambres/zephir/images/vortex_embout.png',
    data: [
      {
        title: 'Laboratoire:',

        des: '   PARI ',
      },
      {
        title: 'Distributeur :',
        des: ' Pulmomed  ',
      },
      {
        title: 'Chambre + embout buccal: ',
        des: '20,86€',
      },
      {
        title: 'Lot de 10 embouts (+ valves) de remplacement:',
        des: '20,9€ ',
      },
      {
        title: "Forfait de remboursement (tous les 6 mois) , chambre d'inhalation : ",
        des: ' 8,84€,',
      },
      {
        title: 'lot  de valves :',
        des: '5,03€',
      },
    ],
  };
  const CardC = {
    title: 'VORTEX avec masque pour adulte',
    imageURL: 'https://api-guidezephir.wereact.tech/uploads/Archive%202/chambres/zephir/images/vortex_masque.png',
    data: [
      {
        title: 'Laboratoire:',

        des: 'PARI',
      },
      {
        title: 'Distributeur :',
        des: 'Pulmomed',
      },
      {
        title: 'Chambre + masque:',
        des: ' 33,40€',
      },
      {
        title: ' Masque de remplacement (avec valves) :',
        des: '8,6€ ',
      },
      {
        title: "Forfait de remboursement (tous les 6 mois) , chambre d'inhalation : ",
        des: ' 8,84€,',
      },
      {
        title: ' masque de remplacement: ',
        des: '6,86€',
      },
    ],
  };

  return (
    <>
      <div className="w-full flex-1 flex xl:flex-col  justify-between items-center lg:items-start lg:pt-0 ">
        <div className=" flex flex-col w-full">
          <div className="flex justify-between items-center p-2 sm:hidden sm:hidden lg::hidden xl:hidden 2xl:flex 3xl:flex 4xl:flex">
            <img // eslint-disable-line
              alt="sdqs"
              src={inhalationvideo}
              className=" cursor-pointer bg-black-100 p-3 rounded-md "
              onClick={() => setopenVideo(true)}
            />
            <img // eslint-disable-line
              alt="dsdsd"
              src={inhalationinfo}
              className=" cursor-pointer bg-black-100 py-2 px-5 rounded-md"
              onClick={() => setopenInfo(true)}
            />
          </div>
          <div className="w-full flex-1 flex xl:flex-col justify-between items-center lg:items-start lg:pt-2">
            <div className="flex justify-between w-full items-center p-2 sm:flex sm:flex lg::flex xl:flex 2xl:hidden 3xl:hidden 4xl:hidden lg:px-0">
              <div
                onClick={() => setopenVideo(true)}
                className=" cursor-pointer bg-black-100 h-10 w-10 rounded-md flex items-center "
              >
                <img // eslint-disable-line
                  alt="sdqs"
                  src={inhalationvideo}
                  className="m-auto"
                />
              </div>
              <div
                onClick={() => setopenInfo(true)}
                className=" cursor-pointer bg-black-100  rounded-md  h-10 w-10 flex items-center "
              >
                <img // eslint-disable-line
                  alt="dsdsd"
                  src={inhalationinfo}
                  className="m-auto"
                />
              </div>
            </div>
            {[CardA, CardB, CardC].map((data) => (
              <SingleInhalater Data={data} />
            ))}
            <div className=" rounded-5 m-2  p-1 flex flex-col  ml-15 w-96 lg:w-80 h-544 lg:w-full lg:mx-0 lg:px-0 lg:h-1/5">
              <div className="flex-col flex h-full lg:h-auto sm:h-full">
                <Splf />
              </div>
            </div>
          </div>
        </div>
        <ChamnreVideoModal openVideo={openVideo} setopenVideo={setopenVideo} />
        <ChambreInfoModal openInfo={openInfo} setopenInfo={setopenInfo} />
      </div>
    </>
  );
};

export default Card;

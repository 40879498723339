import Filter from 'components/filtres/Filter';
import { LabelProps } from 'components/filtres/Select/GuideSelect';
import therapeutique from 'assets/svg/therapeutique.svg';

import { useDCIS } from 'requests/dcis';
import { useClasses } from 'requests/classes';
import { useDevice } from 'requests/devices';

import { useHistory, useLocation } from 'react-router-dom';
import { encodeUri, decodeUri } from 'utils/url';
import HomeGeneric from '../HomeGeneric/HomeGeneric';

const Classes = () => {
  const location = useLocation();
  const history = useHistory();
  const { data } = useClasses();
  const { data: dataDci } = useDCIS();
  const uri = decodeUri(location.search);

  const { data: dataDevice } = useDevice({ variables: { id: uri.device }, fetchPolicy: 'no-cache' });

  function handleChoosed(value: LabelProps | null) {
    if (value) {
      if (value.value === 'Tous') {
        const { classe, ...Original } = uri;
        history.replace({
          pathname: location.pathname,
          search: encodeUri({ ...Original }),
        });
      } else {
        history.replace({
          pathname: location.pathname,
          search: encodeUri({ ...uri, classe: value.value }),
        });
      }
    }
  }

  function setDcisChoosed(value: LabelProps | null) {
    if (value) {
      if (value.value === 'Tous') {
        const { dci, ...Original } = uri;
        history.replace({
          pathname: location.pathname,
          search: encodeUri({ ...Original }),
        });
      } else {
        history.replace({
          pathname: location.pathname,
          search: encodeUri({ ...uri, dci: value.value }),
        });
      }
    }
  }
  const textSelected = dataDevice?.device.name;
  return (
    <HomeGeneric
      from="device"
      title="Noms de dispositifs"
      textSelected={textSelected}
      filterComponent={
        <>
          <Filter
            isDisabled={Boolean(uri.dci)}
            icon={<img alt="" src={therapeutique} height="23" />}
            title="Classes thérapeutiques"
            type="device"
            type2="classe"
            data={data?.classes.data}
            value={uri.classe}
            onChange={handleChoosed}
          />
          <Filter
            isDisabled={Boolean(uri.classe)}
            className="pt-5"
            icon={<img alt="" src="dcisLogo.svg" height="23" />}
            type="device"
            type2="dci"
            title="DCI"
            data={dataDci?.dcis.data}
            value={uri.dci}
            onChange={setDcisChoosed}
          />
        </>
      }
    />
  );
};

export default Classes;

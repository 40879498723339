import { useHistory, useLocation } from 'react-router-dom';

import { encodeUri, decodeUri } from 'utils/url';

import Filter from 'components/filtres/FilterByIndications';
import { useCountedLaboratorys } from 'requests/laboratorys';

import HomeGeneric from '../HomeGeneric/HomeGeneric';

const Indication = () => {
  const { data } = useCountedLaboratorys();

  const location = useLocation();
  const history = useHistory();
  const uri = decodeUri(location.search);
  function handleChoosed(value: any) {
    if (value) {
      delete uri.start;
      history.replace({
        pathname: location.pathname,
        search: encodeUri({ ...uri, lab: value.join(',') }),
      });
    }
  }

  const laboratorySelected = data?.laboratorysCount.find((e) => e.id === uri.lab);

  return (
    <>
      <HomeGeneric
        from="lab"
        textSelected={laboratorySelected?.name}
        title="Laboratoires"
        filterComponent={
          <>
            <Filter data={data?.laboratorysCount} indications={uri.lab} handleChoosed={handleChoosed} />
          </>
        }
      />
    </>
  );
};

export default Indication;

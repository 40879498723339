import React from 'react';
import classNames from 'utils/classNames';

interface HeaderLinkProps
  extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  selected?: boolean;
  open?: boolean;
}

const HeaderLink = React.forwardRef(
  ({ children, className, selected, open, ...rest }: HeaderLinkProps, ref: React.Ref<HTMLButtonElement>) => (
    <>
      <button
        ref={ref}
        {...rest}
        className={classNames(
          'font-medium text-base 2xl:text-sm flex h-full hover:bg-yellow px-6 ',
          'cursor-pointer hover:text-white items-center flex-1 justify-center	',
          open ? 'bg-yellow text-white' : 'text-white', // eslint-disable-line
          className,
        )}
      >
        <div className={selected ? ' text-white border-b-2 border-yellow h-8' : 'text-white h-8'}>{children}</div>
      </button>
    </>
  ),
);

HeaderLink.defaultProps = {
  selected: false,
  open: false,
};

export default HeaderLink;

import * as React from 'react';

function QRCode({ fill = 'currentColor', ...props }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" {...props}>
      <defs>
        <style>{`.prefix__qrCode{fill:${fill}}`}</style>
      </defs>
      <path
        className="prefix__qrCode"
        d="M17.291 6.378a.708.708 0 01-.709-.708V2.038a.621.621 0 00-.62-.62H12.33a.709.709 0 010-1.417h3.632a2.04 2.04 0 012.037 2.037V5.67a.709.709 0 01-.709.708zm0 0M.708 6.378A.708.708 0 010 5.67V2.038A2.04 2.04 0 012.037 0h3.632a.709.709 0 010 1.417H2.037a.621.621 0 00-.62.62v3.632a.709.709 0 01-.709.708zm0 0M5.669 18H2.037A2.04 2.04 0 010 15.962V12.33a.709.709 0 011.417 0v3.632a.621.621 0 00.62.62h3.632a.709.709 0 010 1.417zm0 0M15.962 18H12.33a.709.709 0 010-1.417h3.632a.621.621 0 00.62-.62v-3.632a.709.709 0 011.417 0v3.632A2.04 2.04 0 0115.962 18zm0 0"
      />
      <path
        className="prefix__qrCode"
        d="M6.872 8.904H4.144A1.5 1.5 0 012.65 7.411V4.683a1.5 1.5 0 011.494-1.494h2.728a1.5 1.5 0 011.494 1.494v2.728a1.5 1.5 0 01-1.494 1.494zM4.144 4.228a.455.455 0 00-.455.455v2.728a.455.455 0 00.455.454h2.728a.455.455 0 00.455-.454V4.683a.455.455 0 00-.455-.455zm0 0M6.872 15.055H4.144a1.5 1.5 0 01-1.494-1.493v-2.728A1.5 1.5 0 014.144 9.34h2.728a1.5 1.5 0 011.494 1.494v2.728a1.5 1.5 0 01-1.494 1.494zm-2.728-4.676a.455.455 0 00-.455.455v2.728a.455.455 0 00.455.454h2.728a.455.455 0 00.455-.454v-2.728a.455.455 0 00-.455-.455zm0 0M13.317 8.904h-2.728a1.5 1.5 0 01-1.493-1.493V4.683a1.5 1.5 0 011.494-1.494h2.728a1.5 1.5 0 011.494 1.494v2.728a1.5 1.5 0 01-1.494 1.494zm-2.728-4.676a.455.455 0 00-.455.455v2.728a.455.455 0 00.455.454h2.728a.455.455 0 00.455-.454V4.683a.455.455 0 00-.455-.455zm0 0M13.967 11.939h-1.234v-1.754a.845.845 0 00-.844-.844H9.941a.845.845 0 00-.844.844v1.948a.845.845 0 00.844.844h1.755v1.234a.845.845 0 00.844.844h1.429a.845.845 0 00.844-.844v-1.429a.845.845 0 00-.844-.844zm-3.832-1.559h1.561v1.559h-1.559zm3.637 3.637h-1.039v-1.039h1.039zm0 0"
      />
    </svg>
  );
}

export default QRCode;

import Editorial20 from './Editorial20';
import Editorial21 from './Editorial21';
import Editorial22 from './Editorial22';

interface Props {
  display: string;
  displaySection: string;
  setdisplaySection: (target: string) => void;
}
const Editorial = ({ display, displaySection, setdisplaySection }: Props) => {
  const DisplayCondition = 'Éditorial';

  return (
    <div id="editorial" style={{ display: display === DisplayCondition ? 'block' : 'none' }} className="font-regular">
      <Editorial20 display={displaySection} />
      <Editorial21 display={displaySection} setdisplaySection={setdisplaySection} />
      <Editorial22 display={displaySection} />
    </div>
  );
};

export default Editorial;

import { FC } from 'react';
import Modal from 'components/common/Modal/Modal';
import classNames from 'utils/classNames';

interface ConfirmationModalProps {
  onClose: (updated: boolean) => void;
  open: boolean;
  msg?: string;
  className?: string;
}

const ConfirmationModal: FC<ConfirmationModalProps> = ({ onClose, open, msg, children, className }) => {
  return (
    <Modal onClose={() => onClose(false)} open={open} className="lg:w-4/5 lg:h-1/2">
      <div className={classNames('flex flex-col items-center pb-11 pt-24 px-4', className)}>
        {children}
        <div className="text-2xl py-9 text-blue-600 font-semibold text-center text-22">{msg}</div>
      </div>
    </Modal>
  );
};

export default ConfirmationModal;

import { useState, useEffect } from 'react';

import { Header } from 'components/common/Table/Table';
import { Checkbox } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

function useTableControls<T extends { id: string }>(
  headers: Header<T>[],
  data: T[],
  {
    onEdit,
    onDelete,
    onMultipleDelete,
  }: {
    onEdit?: (row: T, index: number) => void;
    onDelete?: (row: T, index: number) => void;
    onMultipleDelete?: (values: string[]) => void;
  } = {},
) {
  const [values, setValues] = useState([] as string[]);
  const isAllChecked = data.every((d) => values.some((v) => v === d.id));
  useEffect(() => {
    setValues((prevValues) => prevValues.filter((v) => data.some((d) => d.id === v)));
  }, [data]);

  function checkAll(e: React.ChangeEvent<HTMLInputElement>) {
    return setValues(e.target.checked ? data.map((d) => d.id) : values.filter((v) => !data.some((d) => d.id === v)));
  }

  function onRowCheck(e: React.ChangeEvent<HTMLInputElement>, id: string) {
    setValues(e.target.checked ? [...values, id] : values.filter((v) => v !== id));
  }

  return [
    (onMultipleDelete || onDelete) && {
      title: (
        <div className="pr-8">
          <Checkbox color="primary" checked={data.length ? isAllChecked : false} onChange={checkAll} />
        </div>
      ),
      key: '__check__',
      className: 'w-1/12',
      render(value: any) {
        const checked = values.some((v) => v === value.id);

        return <Checkbox color="primary" checked={checked} onChange={(e) => onRowCheck(e, value.id)} />;
      },
    },
    ...headers,
    (onMultipleDelete || onEdit || onDelete) && {
      title:
        onMultipleDelete && values.length ? (
          <div className="flex items-center justify-end pr-4">
            <DeleteIcon onClick={() => onMultipleDelete(values)} className="text-red-250 cursor-pointer flex" />
            <span className="flex items-center justify-end pl-4">Action</span>
          </div>
        ) : (
          <span className="flex items-center justify-end pr-4">Action</span>
        ),
      key: '__controls__',
      render: (row: T, index: number) => {
        return (
          <div className="flex gap-3 items-center justify-end pr-2">
            {onEdit && <EditIcon onClick={() => onEdit(row, index)} className="text-blue cursor-pointer" />}
            {onDelete && <DeleteIcon onClick={() => onDelete(row, index)} className="text-red-250 cursor-pointer" />}
          </div>
        );
      },
    },
  ].filter(Boolean) as Header<T>[];
}

export default useTableControls;

function AboutIcon({ fill = 'currentColor', ...props }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" {...props}>
      <defs>
        {/* eslint-disable-next-line */}
        <style>{`.about_a,.about_c{fill:none;}.about_a{stroke:${
          fill + ' !important' // eslint-disable-line
        };stroke-width:1.5px;}.about_b{stroke:none;}`}</style>
      </defs>
      <g transform="translate(-1336 -65.297)">
        <g className="about_a" transform="translate(1336 65.297)">
          <rect className="about_b" width="16" height="16" rx="3" />
          <rect className="about_c" x="0.75" y="0.75" width="14.5" height="14.5" rx="2.25" />
        </g>
        <line className="about_a" x2="10" transform="translate(1338.909 72.272)" />
        <line className="about_a" x2="10" transform="translate(1338.909 75.69)" />
      </g>
    </svg>
  );
}

export default AboutIcon;

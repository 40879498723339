import { FunctionComponent } from 'react';
import User from 'assets/svg/user.svg';
import Comm from 'assets/svg/comm.svg';

interface CommentProps {
  firstName?: string;
  lastName?: string;
  text?: string;
}
const Splf: FunctionComponent<CommentProps> = ({ firstName, lastName, text }) => {
  return (
    <div className="relative flex items-center mt-8 w-full">
      <img src={User} alt="user" width={30} height={40} className="" />
      <img src={Comm} alt="comm" width={32} className="absolute ml-9 -pt-6 z-10" />
      <div className="bg-white py-2 px-4 text-sm font-regular text-justify  text-black  flex flex-col min-h-20 max-h-auto rounded	 border border-gray-300 w-full ml-9  ">
        <div className="flex gap-2 mb-2">
          <span className="font-semibold">{firstName}</span>
          <span className="font-semibold">{lastName}</span>
        </div>
        <span>{text}</span>
      </div>
    </div>
  );
};

export default Splf;

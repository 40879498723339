import React from 'react';

interface Props {
  display: string;
}
const table = [
  {
    title: 'Béclométasone',
    coln1: (
      <div>
        <div className="flex flex-col pb-3">
          {' '}
          <b className="flex sm:flex-col sm:flex-col xl:flex-col lg:flex-col 2xl:gap-1 3xl:gap-1 4xl:gap-1">
            <span>Aérosol doseur </span> pressurisé :
          </b>
          <span>BECLOJET® 250 μg</span>
          <span>BECLOMETASONE TEVA® 50, 250 μg</span>
          <span>BECLOSPRAY® 50, 250 μg </span>
          <span> BECOTIDE® 250 μg </span>
          <span>QVARSPRAY® 100 μg</span>
        </div>
        <div className="flex flex-col pb-3">
          {' '}
          <b className="flex sm:flex-col sm:flex-col xl:flex-col lg:flex-col 2xl:gap-1 3xl:gap-1 4xl:gap-1">
            <span>Aérosol doseur </span> pressurisé autodéclenché :
          </b>
          <span>ECOBEC® 250 μg</span>
          <span>QVAR AUTOHALER® 100 μg</span>
        </div>
        <div className="flex flex-col pb-3">
          {' '}
          <b className="flex sm:flex-col sm:flex-col xl:flex-col lg:flex-col 2xl:gap-1 3xl:gap-1 4xl:gap-1">
            <span>Inhalateur de </span>poudre :
          </b>
          <span> ASMABEC CLICKHALER® 100, 250 μg </span>
          <span>BEMEDREX EASYHALER® 200 μg </span>
          <span>MIFLASONE AEROLIZER® 100, 200, 400 μg </span>
          <div className="flex sm:flex-col sm:flex-col xl:flex-col lg:flex-col 2xl:gap-1 3xl:gap-1 4xl:gap-1">
            <span className="flex whitespace-nowrap">Association : </span>
            <span className="flex whitespace-nowrap">Béclométasone + </span>
            <span className="flex whitespace-nowrap">Formotérol</span>
          </div>
        </div>
        <div className="flex flex-col pb-3">
          {' '}
          <div className="flex sm:flex-col sm:flex-col xl:flex-col lg:flex-col 2xl:gap-1 3xl:gap-1 4xl:gap-1">
            <span className="text-purple-200">Aérosol doseur </span>{' '}
            <span className="text-purple-200"> pressurisé :</span>
          </div>
          <span>FORMODUAL® 100 μg de corticoïde </span>
          <span>INNOVAIR® 100 μg de corticoïde</span>
        </div>
      </div>
    ),
    coln2: '200 - 500',
    coln3: '> 500 - 1000',
    coln4: '>1000- 2000',
  },
  {
    title: 'Budésonide',
    coln1: (
      <div>
        <div className="flex flex-col pb-3">
          {' '}
          <b className="flex sm:flex-col sm:flex-col xl:flex-col lg:flex-col 2xl:gap-1 3xl:gap-1 4xl:gap-1">
            <span>Inhalateur de </span>poudre :
          </b>
          <span>MIFLONIL AEROLIZER® 200, 400 μg </span>
          <span>NOVOPULMON NOVOLIZER® 200, 400 μg</span>
          <span>PULMICORT TURBUHALER® 100, 200, 400 μg</span>
        </div>
        <div className="flex flex-col pb-3">
          {' '}
          <div className="flex sm:flex-col sm:flex-col xl:flex-col lg:flex-col 2xl:gap-1 3xl:gap-1 4xl:gap-1">
            <span className="text-purple-200 font-semibold"> Association: </span>{' '}
            <span className="text-purple-200 font-semibold">Budésonide + </span>
            <span className="text-purple-200 font-semibold">Formotérol </span>
          </div>
          <b className="flex sm:flex-col sm:flex-col xl:flex-col lg:flex-col 2xl:gap-1 3xl:gap-1 4xl:gap-1">
            <span>Inhalateur de </span>poudre :
          </b>
          <span>SYMBICORT TURBUHALER® 100, 200, 400 μg de corticoïde </span>
        </div>
      </div>
    ),
    coln2: '200 - 400',
    coln3: '> 400 - 800',
    coln4: '> 800 - 1600',
  },
  {
    title: 'Fluticasone',
    coln1: (
      <div>
        <div className="flex flex-col pb-3">
          {' '}
          <b className="flex sm:flex-col sm:flex-col xl:flex-col lg:flex-col 2xl:gap-1 3xl:gap-1 4xl:gap-1">
            <span>Aérosol doseur </span> pressurisé :
          </b>
          <span>FLIXOTIDE® 50, 125, 250 μg</span>
          <b className="flex sm:flex-col sm:flex-col xl:flex-col lg:flex-col 2xl:gap-1 3xl:gap-1 4xl:gap-1">
            <span>Inhalateur de </span>poudre :
          </b>
          <span>FLIXOTIDE DISKUS® 100, 250, 500 μg</span>
        </div>
        <div className="flex flex-col pb-3">
          {' '}
          <div className="flex sm:flex-col sm:flex-col xl:flex-col lg:flex-col 2xl:gap-1 3xl:gap-1 4xl:gap-1">
            <span className="text-purple-200 font-semibold"> Association: </span>{' '}
            <span className="text-purple-200 font-semibold">Fluticasone + </span>
            <span className="text-purple-200 font-semibold">Salmétérol </span>
          </div>
          <b className="flex sm:flex-col sm:flex-col xl:flex-col lg:flex-col 2xl:gap-1 3xl:gap-1 4xl:gap-1">
            <span>Aérosol doseur </span> pressurisé :
          </b>
          <span>SERETIDE® 50, 125, 250 μg de corticoïde </span>
          <b className="flex sm:flex-col sm:flex-col xl:flex-col lg:flex-col 2xl:gap-1 3xl:gap-1 4xl:gap-1">
            <span>Inhalateur de </span>poudre :
          </b>
          <span>SERETIDE DISKUS® 100, 250, 500 μg de corticoïde </span>
        </div>
      </div>
    ),
    coln2: '100 - 250',
    coln3: '> 250 - 500',
    coln4: '> 500 - 1000',
  },
  {
    title: 'Ciclésonide',
    coln1: <div className=" py-3">ALVESCO® 80, 160 μg</div>,
    coln2: '80 - 160',
    coln3: '> 160 - 320',
    coln4: '> 320 - 1280',
  },
  {
    title: 'Mométasone',
    coln1: <div className=" py-3">ASMANEX Twisthaler® 200, 400 μg </div>,
    coln2: '200',
    coln3: '≥ 400	',
    coln4: '≥ 800	',
  },
];
const Annex2 = ({ display }: Props) => {
  const DisplayCondition = 'Annex2';
  return (
    <div style={{ display: display === DisplayCondition ? 'block' : 'none' }}>
      <div className="bg-yellow-200	 h-12 sm:h-20 font-semibold  flex items-center justify-center sm:text-10 ">
        Doses quotidiennes équivalentes des différents corticoïdes inhalés chez l’adulte dans le traitement de
        l’asthme*.
      </div>
      <table>
        <tr>
          <td className="bg-gray-400 border-2 border-white   font-semibold sm:text-10 px-4 sm:px-0">Corticoïdes</td>
          <td className="bg-gray-400 border-2 border-white  text-center font-semibold sm:text-10 px-4 sm:px-0">
            Dose quotidienne faible (μg/j)
          </td>
          <td className="bg-gray-400 border-2 border-white  text-center font-semibold sm:text-10 px-4 sm:px-0">
            Dose quotidienne intermédiaire (μg/j){' '}
          </td>
          <td className="bg-gray-400 border-2 border-white text-center font-semibold sm:text-10 px-4 sm:px-0">
            Dose quotidienne forte (μg/j)
          </td>
        </tr>
        {table.map((item) => {
          return (
            <>
              <tr>
                <td className="bg-purple-200 w-full text-white h-10 flex items-center px-4 sm:text-10 ">
                  {item.title}
                </td>
                <td className="bg-purple-200  h-10 " />
                <td className="bg-purple-200  h-10 " />
                <td className="bg-purple-200  h-10 " />
              </tr>
              <tr>
                <td className="bg-yellow-150  border-2 border-white   sm:text-10 px-4 sm:px-0 w-5/6">{item.coln1}</td>
                <td className="bg-yellow-150  border-2 border-white   sm:text-10 px-4  align-top sm:px-0">
                  {item.coln2}
                </td>
                <td className="bg-yellow-150  border-2 border-white   sm:text-10 px-4 align-top sm:px-0">
                  {item.coln3}
                </td>
                <td className="bg-yellow-150  border-2 border-white   sm:text-10 px-4 align-top sm:px-0">
                  {item.coln4}
                </td>
              </tr>
            </>
          );
        })}
      </table>
      <div className="mt-10">* selon http://www.ginasthma.org</div>
    </div>
  );
};

export default Annex2;

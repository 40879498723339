import React, { useState } from 'react';

import classNames from 'utils/classNames';

import RightArrow from '../../assets/svg/rightArrowGray.svg';
import OpenedArrow from '../../assets/svg/openedArrow.svg';
import RightArrowWhite from '../../assets/svg/arrowRightWhite.svg';

interface Props {
  // eslint-disable-next-line react/require-default-props
  className?: string;
  medicamentName: string;
  target: string;
  currentSection?: string | null;
  setdisplaySection: (target: string) => void;
}

const MenuItem = ({ medicamentName, currentSection, className, target, setdisplaySection }: Props) => {
  const [hovered, sethovered] = useState(false);
  const [setresponsive, setSetresponsive] = useState<boolean>(false);
  const checkResponsive = () => {
    if (window.innerWidth < 1000) {
      setSetresponsive(true);
    } else {
      setSetresponsive(false);
    }
  };
  React.useEffect(() => {
    window.addEventListener('resize', checkResponsive);
    return () => {
      window.removeEventListener('resize', checkResponsive);
    };
  }, []);
  return (
    <div
      className={classNames('sm:w-full lg:w-full   w-420 sm:w-96 2xl:w-96 mb-2 bg-blue-100 cursor-pointer', className)}
      onClick={() => setdisplaySection(target)}
      onMouseLeave={() => sethovered(false)}
      onMouseOver={() => sethovered(true)}
    >
      <div
        style={currentSection === target ? { backgroundColor: '#014A8D' } : {}}
        className={classNames(
          'bg-white rounded-5 menu-itemm p-4  shadow-lg flex items-center  ',
          ' h-20  lg:w-full xl:w-96 2xl:h-16',
          className,
        )}
      >
        <div className="flex-1">
          <p
            className={classNames(
              'font-medium  mb-1 hover:text-white sm:text-xs lg:text-sm xl:text-sm 2xl:text-sm',
              currentSection === target || hovered ? 'text-white' : 'text-blue-600',
            )}
          >
            {medicamentName}
          </p>
        </div>
        <button className={`  text-white py-2 px-4 rounded `}>
          {!hovered && !(currentSection === target) ? (
            <img height="17" width="12" alt="" src={RightArrow} />
          ) : (
            <img
              width={setresponsive ? '17' : '12'}
              height={setresponsive ? '12' : '8'}
              alt=""
              src={setresponsive ? OpenedArrow : RightArrowWhite}
            />
          )}
        </button>
      </div>
    </div>
  );
};

MenuItem.defaultProps = {
  currentSection: 'Editorial2021',
};

export default MenuItem;

import React, { FunctionComponent, ReactNode, useState } from 'react';
import classNames from 'utils/classNames';
import PasswordIcon from '../icons/Password';
import { ReactComponent as WrongPassword } from '../../assets/svg/Passwordyellow.svg';
import InputWrapper from './InputWrapper';

export type TextFieldProps = {
  className?: string;
  type?: string;
  error?: string;
  iconLeft?: ReactNode;
  iconRight?: ReactNode;
  addnote?: boolean;
  label?: string;
  handleError?: boolean;
  isTextarea?: boolean;
  errorColor?: string;
  inputClassName?: string;
  labelClassName?: string;
  disabled?: boolean;
  item?: string;
  borderColor?: string;
  alwaysShowError?: boolean;
  heightToslice?: number;
  addEtoile?: boolean;
} & (
  | (React.DetailedHTMLProps<React.TextareaHTMLAttributes<HTMLInputElement>, HTMLInputElement> & { textarea?: false })
  | (React.DetailedHTMLProps<React.TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement> & {
      textarea: true;
    })
);

const Input: FunctionComponent<TextFieldProps> = ({
  className,
  type,
  iconLeft,
  error,
  addnote,
  iconRight,
  label,
  handleError = true,
  isTextarea = false,
  errorColor = 'red-100',
  inputClassName,
  labelClassName,
  disabled,
  alwaysShowError,
  item,
  borderColor,
  heightToslice,
  addEtoile,
  ...rest
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const inputHeight = 'h-12';

  const inputClasses = classNames(
    'w-full	border border-yellow rounded-5 focus:ring-0 focus:outline-none p-4 py-4 md:rounded-3',
    'text-base font-regular border-blue-100',
    isTextarea ? 'resize-none' : inputHeight,
    error && `border-${errorColor} border-2 focus:border-${errorColor}`,
    iconLeft && 'pl-16',
    addnote && 'pl-2.5 !important',
    disabled ? 'border-grey bg-grey-500' : 'bg-white ',
  );

  return (
    <InputWrapper
      item={item}
      className={className}
      error={error}
      iconRight={iconRight}
      iconLeft={iconLeft}
      errorColor={errorColor}
      label={label}
      handleError={handleError}
      labelClassName={labelClassName}
      alwaysShowError={alwaysShowError}
      classes={{ iconRight: isTextarea ? `top-0 ${inputHeight}` : undefined }}
      heightToslice={heightToslice}
      addEtoile={addEtoile}
    >
      {isTextarea ? (
        <textarea
          style={{
            borderColor,
          }}
          className={classNames(inputClasses, inputClassName)}
          {...(rest as any)}
        />
      ) : (
        <input
          style={{
            borderColor,
          }}
          disabled={disabled}
          className={classNames(inputClasses, inputClassName)}
          type={type === 'password' && showPassword ? 'text' : type}
          {...(rest as any)}
        />
      )}
      {type === 'password' && (
        <div className="absolute inset-y-0 right-5 flex items-center justify-center">
          <button type="button" className="focus:outline-none " onClick={() => setShowPassword(!showPassword)}>
            {!error ? (
              <PasswordIcon fill={disabled ? '#11113633' : '#111136'} width="25px" />
            ) : (
              <WrongPassword className="w-6" />
            )}
          </button>
        </div>
      )}
    </InputWrapper>
  );
};

export default Input;

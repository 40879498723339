import splf from 'assets/png/splfCard.png';
import ZephirDescription from 'components/common/ZephirDescription';
import Header from 'containers/DetailsMedication/Components/HeaderRouter';
import MenuItem from 'containers/PageStatique/MenuItem';
import { FC, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import SplfCard from '../../components/Splf';
import Auteur from './Components/Auteur';
import Interets from './Components/Interet';

interface PagesData {
  name: string;
}

interface StaticPagesInfo {
  [key: string]: PagesData;
}

const PageStatique: FC = () => {
  const INFOS: StaticPagesInfo = {
    Auteurs: { name: 'Auteurs' },
    Interet: { name: "Déclaration D'intérêts" },
  };
  const history = useHistory();
  const { search } = useLocation();

  const name = new URLSearchParams(search).get('section');
  const [displaySection, setdisplaySection] = useState('Auteurs');
  const [currentSection, setcurrentSection] = useState<string | null>('Auteurs');
  useEffect(() => {
    history.push(`/auteurs?section=${displaySection}`);
    setcurrentSection(name);
  }, [displaySection, search, currentSection]);
  return (
    <div className="bg-grey-300 container">
      <Header pageTitle="À propos" subTitle="Auteurs" />
      <ZephirDescription width="65px" className="justify-center h-14" />
      <div className="px-28 lg:px-20 sm:px-4 flex lg:flex-col xs:flex row pt-38 m-auto justify-center ">
        <div className="flex-0.3">
          <div className=" menu-info py-7 flex-0.3">
            {Object.keys(INFOS).map((key) => (
              <MenuItem
                medicamentName={INFOS[key]?.name}
                target={key}
                setdisplaySection={setdisplaySection}
                // className="w-536 h-90 2xl:w-351"
                currentSection={currentSection}
              />
            ))}
            <div className="w-11/12	lg:hidden">
              <SplfCard colorType />
            </div>
          </div>
        </div>
        <div className=" p-2 flex-0.7  ">
          {' '}
          <div className="mt-6 ml-4 lg:ml-0 grid grid-cols-1 text-justify">
            <Auteur display={displaySection} />
            <Interets display={displaySection} />
          </div>{' '}
        </div>
      </div>
      <div className="items-center flex-1 py-4 bg-white flex-col text-center lg:text-justify sm:px-5 lg:px-20 hidden lg:flex">
        <div className="lg:w-full flex justify-center mb-6">
          <img src={splf} alt="splf" className="m-auto lg:m-0" />
        </div>
        <div>
          <div className="font-regular text-center text-sm text-blue-600 flex flex-col lg:w-full leading-7 lg:text-xs lg:px-5 sm:px-0 2xl:w-4/5 lg:leading-4 m-auto">
            <div className="mb-4">
              En accord avec l’ANSM, cet outil éducationnel ne s’inscrit pas dans une démarche promotionnelle des
              laboratoires pharmaceutiques concernés.
            </div>
            <div>
              {' '}
              Pour protéger l’environnement , il est important de rapporter les inhalateurs en pharmacie vides ou non.
              Collecte agréée Cyclamed.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageStatique;

import * as React from 'react';

function Delete({ fill = 'currentColor', ...props }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16.029 22.723" {...props}>
      <path
        id="Tracé_84122"
        data-name="Tracé 84122"
        d="M95.966,85.895a2.453,2.453,0,0,0,2.408,1.929h9.894V79.641h-5.277l-2.773-12.229-.855.194L98.8,65.1l-6.447,1.462.568,2.505-.676.153Zm9.984-4.968h1.033v5.242h-1.033Zm-3.986,0h2.7v5.612H98.374a1.176,1.176,0,0,1-1.154-.924L93.774,70.192l4.77-1.082.705-.16ZM97.826,66.639l.284,1.251-3.94.894-.284-1.252Z"
        transform="translate(-92.24 -65.101)"
        fill={fill}
      />
    </svg>
  );
}

export default Delete;

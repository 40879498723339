import classNames from 'utils/classNames';
import Edit from 'components/icons/Edit';
import Remove from 'components/icons/Remove';
import Note from 'components/icons/Note';
import { Link } from 'react-router-dom';
import moment from 'moment';
import classes from './Note.module.scss';

interface CardProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  title?: string;
  deviceType?: string;
  smallTitle?: string;
  onDelete?: () => void;
  onEdit?: () => void;
  imgSrc?: string;
  name?: string;
  content?: string;
  dateC?: string;
  noteId?: string;
  medId?: string;
  fullContent?: boolean;
}

const NoteCard: React.FC<CardProps> = ({
  title,
  className,
  deviceType,
  smallTitle,
  datatype,
  imgSrc,
  name,
  dateC,
  noteId,
  medId,
  content,
  fullContent,
  onDelete = undefined,
  onEdit = undefined,
  ...rest
}) => {
  function renderIcons(Icon: React.ComponentType<React.SVGProps<SVGSVGElement>>, handleClick?: () => void) {
    return (
      <div className="bg-blue-200 rounded-md py-1.5 px-2 cursor-pointer">
        <Icon height={16} onClick={handleClick} />
      </div>
    );
  }

  return (
    <div
      className={classNames(
        'flex flex-col relative text-blue-600 pb-3.5 rounded-5 pt-6 px-6 bg-white rounded-md w-full',
        classes.container,
        className,
      )}
    >
      <div {...rest} className="flex-1">
        <div className="absolute flex top-2.5 right-2 items-end gap-2.5">
          {renderIcons(Remove, onDelete)}
          {renderIcons(Edit, onEdit)}
        </div>

        <div className="flex gap-5 text-base  sm:flex-col">
          <div className="flex items-center justify-center h-56 w-52  rounded-5">
            {imgSrc ? (
              <div
                className="w-full  border border-grey h-full"
                style={{
                  backgroundImage: `url("${imgSrc?.replaceAll('\\', '/')}")`,
                  backgroundPosition: 'center',
                  backgroundSize: 'cover',
                }}
              />
            ) : (
              <Note height={70} width={52} />
            )}
          </div>
          <div className="pt-8 flex-1 min-w-0">
            <Link to={`/notes/${noteId}`}>
              <div className="text-blue-600 text-xl font-semibold break-all"> {title}</div>
            </Link>
            <Link to={`/medicament/${medId}?from=note`}>
              <div className="text-blue text-xs font-semibold pt-2.5 hover:cursor-pointer	">
                {name} <span> {smallTitle} </span>{' '}
              </div>
            </Link>
            {content && (
              <div className="text-grey-700 text-sm font-medium pt-4  break-words">
                {content?.length > 50 && !fullContent ? `${content.slice(0, 50)}...` : content}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="flex font-normal pt-7 w-full text-sm 2xl:text-xs">
        <div className="text-grey-700 flex flex-1 justify-end items-center">
          Créer le <div className="text-blue pl-1"> {moment(dateC).format(' DD MMMM YYYY')} </div>
        </div>
      </div>
    </div>
  );
};

export default NoteCard;

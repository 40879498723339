import React, { useEffect } from 'react';
import Input from 'components/ux/Input';
import Button from 'components/Button';
import { useFormik } from 'formik';
import * as yup from 'yup';

import { Lab } from 'requests/types';
import { whiteSpaceRegex } from './AdminForm';

export interface CreateAdminVariables {
  name: string;
}

export interface LabFormProps {
  onSubmit: (values: CreateAdminVariables) => void;
  item?: Lab;
  onClose: () => void;
}

const LabForm = ({ onSubmit, item, onClose }: LabFormProps) => {
  const { values, handleChange, handleSubmit, setValues, resetForm, handleBlur, touched, errors } = useFormik({
    initialValues: {
      name: item?.name || '',
    },
    validationSchema: yup.object({
      name: yup.string().required('Ce champ est obligatoire').matches(whiteSpaceRegex, 'Ce champ est obligatoire'),
    }),
    onSubmit: async (data) => {
      onSubmit({
        ...data,
        name: values.name.trim(),
      });
    },
  });

  useEffect(() => {
    if (item) {
      setValues({
        ...item,
      });
    } else {
      resetForm();
    }
  }, [item]);

  return (
    <form className="flex flex-col gap-5 item-center pt-10 m-8 w-full" onSubmit={handleSubmit}>
      <Input
        label="Nom du laboratoire"
        value={values.name}
        onChange={handleChange}
        onBlur={handleBlur}
        id="name"
        name="name"
        placeholder="Nom du laboratoire"
        inputClassName="border-grey-400"
        labelClassName="!text-blue-600"
        error={touched.name && errors.name ? String(errors.name) : ''}
      />

      <div className="text-center">
        <Button
          type="submit"
          variant="primary"
          className="w-full  2xl:mb-2 lg:mb-5 h-12 py-1.5 rounded-5 text-26 font-semibold "
        >
          {item ? 'Modifier' : 'Ajouter'}
        </Button>
        <div className="text-blue text-lg   w-full flex justify-center mt-4">
          <div onClick={onClose} className="cursor-pointer w-auto font-semibold">
            Annuler
          </div>
        </div>
      </div>
    </form>
  );
};

export default LabForm;

import React from 'react';

import Modal from 'components/common/Modal/Modal';
import classes from 'components/ux/ModalVideo/VideoCard.module.scss';
import classNames from 'utils/classNames';

interface Props {
  openInfo: boolean;
  setopenInfo: (status: boolean) => void;
}

export default function ChambreInfoModal({ openInfo, setopenInfo }: Props) {
  return (
    <Modal className=" w-11/12 md:w-full md:h-5/6 " open={openInfo} onClose={() => setopenInfo(false)}>
      <div
        className={classNames(
          'modalContainerChild h-full w-full  lg:flex  lg:flex-col-reverse  md:justify-center contents  ',
          classes.modalContainerChild,
        )}
      >
        <div
          className={classNames(
            'flex flex-col items-center justify-center w-auto h-1/2 bg-grey-300 md:rounded-md  ',
            classes.videoSection,
          )}
        >
          <img
            className=" sm:w-2211 sm:h-2212 transform sm:rotate-90"
            src={`${process.env.REACT_APP_PHOTO_API}/uploads/rooms.png`}
            alt="Chambres d’inhalation"
            title="Chambres d’inhalation"
          />
        </div>
      </div>
    </Modal>
  );
}

import React from 'react';
import Img from '../../assets/png/algorithm.png';

interface Props {
  display: string;
}

const TherapeuticManagementAlgorithm = ({ display }: Props) => {
  const DisplayCondition = 'TherapeuticManagementAlgorithm';
  return (
    <div style={{ display: display === DisplayCondition ? 'block' : 'none' }}>
      <div className="flex  justify-center mt-8">
        <img src={Img} alt="TherapeuticManagementAlgorithm" title="TherapeuticManagementAlgorithm" />
      </div>
    </div>
  );
};

export default TherapeuticManagementAlgorithm;

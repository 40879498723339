import MenuContext, { MenuStatesValues } from 'contexts/MenuContext';
import { useState, useContext } from 'react';
import UserContext from 'contexts/UserContext';

import ResponsiveMenu from './ResponsiveMenu';
import WebMenu from './WebMenu';
import HeaderLab from './HeaderLab';

const Header = () => {
  const [MenuStates, setMenuStates] = useState<MenuStatesValues | any>(MenuStatesValues.CLOSE);
  const { user } = useContext(UserContext);
  return (
    <>
      {user?.role === 'lab' || user?.role === 'admin' ? (
        <>
          <HeaderLab user={user} />
          <div className=" h-12 md:hidden" />
        </>
      ) : null}
      <MenuContext.Provider value={{ MenuStates, setMenuStates }}>
        <WebMenu />
        <ResponsiveMenu />
      </MenuContext.Provider>
    </>
  );
};

export default Header;

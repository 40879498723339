import { useContext, useRef } from 'react';
import { useHistory, useLocation, matchPath } from 'react-router-dom';
import Row from 'components/common/DropDown/DropDownRow';
import DropDown from 'components/common/DropDown/DropDown';
import useMouse from 'hooks/useMouse';
import ArrowDown from 'components/icons/ArrowDown';
import UserContext from 'contexts/UserContext';

import classNames from 'utils/classNames';
import HeaderLink from './HeaderLink';
import classes from './about.module.scss';

const About = () => {
  const [open, onMouseEnter, onMouseLeave] = useMouse();
  const history = useHistory();
  const location = useLocation();
  const { user } = useContext(UserContext);

  const divRef = useRef<HTMLButtonElement>(null);

  const list = [
    {
      id: 0,
      title: 'Informations',

      pathname: '/informations',
    },
    {
      id: 1,
      title: 'Auteurs',

      pathname: '/auteurs',
    },
    {
      id: 2,
      title: 'Gamme Zéphir',

      pathname: '/gammeZephir',
    },
  ];
  const selected = list.some((e) => matchPath(location.pathname, e.pathname));
  return (
    <>
      <HeaderLink
        open={open}
        onMouseEnter={() => {
          onMouseEnter();
        }}
        onMouseLeave={() => {
          onMouseLeave();
        }}
        ref={divRef}
        type="button"
        selected={open || selected}
        className={classNames(
          'flex flex-1 flex-row h-full w-full justify-center items-center ',
          classes.aboutRoot,
          ` ${open && 'bg-yellow text-white'}`,
        )}
      >
        <div className="flex items-baseline text-white">
          <ArrowDown className="mr-3 text-white" width="11px" /> À propos
        </div>
      </HeaderLink>

      <DropDown
        position={{ top: user?.role === 'user' ? 118 : 170 }}
        onMouseEnter={() => {
          onMouseEnter();
        }}
        onMouseLeave={() => {
          onMouseLeave();
        }}
        open={open}
        anchorEl={divRef}
        full
      >
        {list
          ?.sort((a, b) => a.title.localeCompare(b.title, 'es', { sensitivity: 'base' }))
          .map((item) => (
            <Row
              pathname={item.pathname}
              selected={!!matchPath(location.pathname, item.pathname)}
              title={item.title}
              key={item.id}
              onClick={() => {
                history.push(`${item.pathname}`);
                onMouseLeave();
              }}
            />
          ))}
      </DropDown>
    </>
  );
};

export default About;

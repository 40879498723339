import { FunctionComponent } from 'react';
import { useLocation } from 'react-router-dom';
import splf from 'assets/png/SPLF.svg';

interface CardProps {
  colorType?: boolean;
}
const Splf: FunctionComponent<CardProps> = ({ colorType }) => {
  const location = useLocation();
  return (
    <div
      className={`w-full flex flex-col  items-center flex-1 ${
        !colorType ? 'bg-white border border-grey' : ''
      } rounded border-gray-900 py-4`}
    >
      {!location.pathname.includes('/informations') && <img src={splf} alt="splf" width="25%" className="mb-8" />}
      <div className="font-regular text-justify text-xs text-blue-900 flex flex-col w-10/12">
        <span className="mb-25 text-xs">
          En accord avec l’ANSM, cet outil éducationnel ne s’inscrit pas dans une démarche promotionnelle des
          laboratoires pharmaceutiques concernés.{' '}
        </span>
        <span className="text-xs flex flex-col">
          Pour protéger l’environnement , il est important de rapporter les inhalateurs en pharmacie vides ou non.
          Collecte agréée Cyclamed.
        </span>
      </div>
    </div>
  );
};

export default Splf;

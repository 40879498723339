import { useContext } from 'react';
import { Redirect } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';
import logo from 'assets/svg/SPLFWhiteNew.svg';
import UserContext from 'contexts/UserContext';
import cardIdContext from 'contexts/CardIdContext';

import Input from 'components/ux/Input';
import Button from 'components/Button';
import TitleLogo from 'components/ux/TitleLogo';
import { Sponsor } from 'components/ux/sponsor';
import useAuth from 'hooks/useAuth';
import { useLogin } from 'requests/auth';
import LoginIcon from 'components/icons/LoginIcon';

import logoZephir from 'assets/svg/logoZephirWhite.svg';
import useSnackbarState from 'hooks/useSnackbarState';
import Snackbar from 'components/ui/Snackbar/Snackbar';

const LoginContainer = () => {
  const [loginCall, loginState] = useAuth(useLogin);
  const snackbarState = useSnackbarState(loginState);
  const { setCardId } = useContext(cardIdContext);
  const { user } = useContext(UserContext);
  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: yup.object({
      email: yup
        .string()
        .email('Votre adresse e-mail doit être valide (exemple@domain.com)')
        .required('Veuillez renseigner votre adresse e-mail'),
      password: yup.string().required('Veuillez renseigner votre mot de passe'),
    }),
    onSubmit: (values) => {
      loginCall({
        variables: values,
      });
      setCardId('');
    },
  });

  if (user) {
    return user.role === 'admin' ? <Redirect to="/admin" /> : <Redirect to="/" />;
  }

  return (
    <div className="flex flex-col flex-1 items-center justify-center h-screen w-full bg-blue-500">
      <div className="flex flex-col flex-1 justify-center sm:mt-1 w-536 2xl:w-420 xl:w-5/6 md:w-342 lg:w-3/5 mt-2 2xl:mt-20 3xl:mt-20	">
        <TitleLogo logo={logoZephir} subtitle="Ce site est réservé aux professionnels de santé" />
        <form onSubmit={formik.handleSubmit}>
          <Input
            label="Identifiant"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            id="email"
            name="email"
            error={formik.touched.email ? formik.errors.email : ''}
            placeholder="Adresse email"
            iconRight={<LoginIcon fill="#111136" width="25px" className="h-8 lg:h-4" />}
            errorColor="yellow"
          />

          <Input
            label="Mot de passe"
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            id="password"
            name="password"
            type="password"
            placeholder="Mot de passe"
            error={formik.touched.password ? formik.errors.password : ''}
            errorColor="yellow"
          />

          <div className="text-center 2xl:mt-4 mt-6">
            <Button
              variant="primary"
              className="w-full cursor-pointer mb-30 2xl:mb-2 lg:mb-5 h-12  rounded-5	3xl:text-xl sm:text-base 2xl:text-lg  md:hover:bg-blue-600"
            >
              Se connecter
            </Button>
            <a
              className="text-grey-300 text-base underline 2xl:text-xs	 cursor-pointer"
              href="mailto:webmaster@splf.org?subject=Demande de code d'accès - Guide Zephir Web&body=Bonjour,%0D%0A%0D%0A
              Le Guide Zéphir est réservé aux professionnels de santé.%0D%0A
              Je vous remercie de joindre un justificatif professionnel (numéro RPPS, carte professionnelle de formation, feuille de soins, carte d'étudiant, certificat de scolarité ou tout autre document attestant votre statut) afin de vous donner un accès.%0D%0A
              Cordialement,%0D%0A%0D%0A
              Ève Gillier
              Imothep-MS
              Webmaster pour le site de la Société de Pneumologie de Langue Française (SPLF)%0D%0A
              https://splf.fr – @ : webmaster@splf.org"
            >
              Obtenir un code d'accès
            </a>
          </div>
        </form>
      </div>

      <circle cx="60" cy="60" r="50" />
      <div className="flex-1 flex flex-row justify-center items-center w-full ">
        <img src={logo} alt="" className="sm:h-20 sm:w-20 w-40 h-40 2xl:h-32 2xl:w-32" />
      </div>
      <div className="flex flex-col justify-center w-full items-center sm:mt-2 lg:mt-12 2xl:mt-8 2xl:mb-4 mb-10">
        <Sponsor />
      </div>
      <Snackbar {...snackbarState} />
    </div>
  );
};

export default LoginContainer;

import React, { useState } from 'react';
import Video from 'components/icons/Video';
import Modal from 'components/common/Modal/Modal';
import classes from 'components/ux/ModalVideo/VideoCard.module.scss';
import classNames from 'utils/classNames';
import VideoPlayer from 'components/ux/ModalVideo/VideoPlayer';

interface Props {
  openVideo: boolean;
  setopenVideo: (status: boolean) => void;
}

export default function ChamnreVideoModal({ openVideo, setopenVideo }: Props) {
  const [fullscreen, setfullscreen] = useState(false);
  return (
    <Modal
      className=" w-9/12 lg:w-full lg:h-full lg:rounded-none"
      open={openVideo}
      onClose={() => setopenVideo(false)}
      responsivestate={fullscreen}
    >
      <div
        className={classNames(
          'modalContainerChild h-full w-full  lg:flex  lg:flex-col-reverse  sm:justify-center contents  ',
          classes.modalContainerChild,
        )}
      >
        <div
          className={classNames(
            'flex flex-col w-auto h-1/2 sm:rounded-md lg:absolute lg:top-0 lg:h-5/6',
            classes.videoSection,
          )}
        >
          <div className={classNames(' pt-8 px-8 lg:pt-16')}>
            <p className="text-blue flex  items-center text-lg sm:text-base font-semibold">
              <span className="mr-2">
                {' '}
                <Video height={20} />
              </span>
              Vidéo éducative <br />
            </p>
            <p className="font-bold text-26 2xl:text-lg">Chambres d'inhalation</p>
          </div>
          <div
            className={classNames(
              'player flex-1  border-none w-full h-full m-auto px-8 py-8 sm:px-0',
              classes.Videoborder,
              classes.player,
            )}
          >
            <VideoPlayer
              fullscreen={fullscreen}
              setfullscreen={setfullscreen}
              medicineName="Chambres d'inhalation"
              videoUrl="http://splf.fr/chambreinhalation/&flux=3"
              videoQrcode="splf.fr/chambreinhalation"
              smallTitle=""
            />
          </div>
        </div>
      </div>
    </Modal>
  );
}

import React from 'react';

interface Props {
  display: string;
}

const Chambres = ({ display }: Props) => {
  const DisplayCondition = 'Chambres';
  return (
    <div id="rooms" style={{ display: display === DisplayCondition ? 'block' : 'none' }}>
      <p>
        <img
          src={`${process.env.REACT_APP_PHOTO_API}/uploads/rooms.png`}
          alt="Chambres d’inhalation"
          title="Chambres d’inhalation"
        />
      </p>
    </div>
  );
};

export default Chambres;

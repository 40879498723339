import Button from 'components/Button';
import { useEffect } from 'react';
import { RouteComponentProps, useLocation } from 'react-router-dom';
import IconDelete from 'components/icons/IconDelete';
import { GenericMutation } from './Create';

interface DeleteProps {
  delete: GenericMutation<{ id?: string; ids?: string[] }>;
  onDone: () => void;
  onClose: () => void;
  match: RouteComponentProps<{ id: string }>['match'];
}

const Delete = ({ delete: useRemove, onClose, onDone, match }: DeleteProps) => {
  const [remove, { data }] = useRemove();
  const location = useLocation();
  useEffect(() => {
    if (data) onDone();
  }, [data, onDone]);

  const renderTitle = () => {
    if (location.pathname.split('/')[1] === 'laboratory') {
      return 'ce laboratoire';
    }

    return 'cet utilisateur';
  };
  return (
    <>
      <div className=" bg-white px-4 sm:p-6 sm:pb-4 flex flex-col items-center pb-11 pt-24 w-5/6">
        <div className="flex flex-col items-center">
          <IconDelete height={29} />

          <div className="mt-5 text-center ">
            <p className="text-sm text-gray-500">Voulez-vous vraiment supprimer {renderTitle()} ?</p>
          </div>
        </div>

        <div className=" flex gap-4 w-5/6 mt-10  items-center">
          <Button
            onClick={() => {
              if (match?.params.id) remove({ variables: { ids: match.params.id.split(',') } });
            }}
            type="button"
            variant="secondary"
            className="cursor-pointer flex-1 w-auto"
          >
            Oui
          </Button>
          <Button variant="primary" className="cursor-pointer flex-1 w-auto" onClick={onClose} type="button">
            Non
          </Button>
        </div>
      </div>
    </>
  );
};

export default Delete;

import React from 'react';

import classNames from 'utils/classNames';

import MedicineInfosRow from './MedicineInfosRow';

interface MedicineInfoComponentProps {
  value?: React.ReactNode | string;
  medicineStatus: string | undefined;
  onCancel: () => void;
  onClick: () => void;
  title: string;
  description?: string | React.ReactNode;
  isChecked: any;
  isFlex?: boolean;
  isPdf?: boolean;
  isNull?: boolean;
  isAdmin?: boolean;
  keyInfo?: string;
}

const MedicineInfoComponent = ({
  value,
  medicineStatus,
  onCancel,
  onClick,
  title,
  description,
  isChecked,
  isFlex,
  isPdf,
  isNull,
  isAdmin,
  keyInfo,
}: MedicineInfoComponentProps) => {
  return (
    <div
      key={title}
      className={classNames('text-sm 2xl:text-xs  leading-6	 2xl:leading-6	lg:text-base sm:text-xs', isFlex && 'flex')}
    >
      <span className="font-bold text-blue-600">{title} </span>
      <span
        className={classNames(
          'font-sans  ml-1',
          (isChecked || (value && isAdmin)) && !isPdf && 'line-through',
          isNull ? 'text-yellow-200' : 'text-blue-900',
          keyInfo === 'small_title',
        )}
      >
        {description}
      </span>
      {value && (
        <MedicineInfosRow
          value={value}
          onClick={onClick}
          onCancel={onCancel}
          medicineStatus={medicineStatus}
          isChecked={!isAdmin && isChecked === '' && !isPdf}
        />
      )}
    </div>
  );
};

export default MedicineInfoComponent;

import gql from 'graphql-tag';

import { MutationHookOptions, QueryHookOptions } from '@apollo/client';
import { useLocalLazyQuery, useLocalMutation, useLocalQuery } from 'hooks/apollo';
import { GetVariables, List } from 'utils/generic';
import { Lab } from './types';

export const labQuery = gql`
  query laboratorys($name: String, $perPage: Int, $page: Int) {
    laboratorys(name: $name, perPage: $perPage, page: $page) {
      count
      totalPages
      perPage
      page
      data {
        id
        name
      }
    }
  }
`;

export const labQuery2 = gql`
  query {
    laboratorysCount {
      id
      name
      count
    }
  }
`;

export interface LaboratorysData {
  count: number;
  page: number;
  perPage: number;
  data: {
    id: string;
    name: string;
  }[];
}
export interface LaboratorysCountedData {
  id: string;
  name: string;
}

export const useLaboratorys = (options: QueryHookOptions<{ laboratorys: List<Lab> }, {}> = {}) =>
  useLocalQuery(labQuery, options);

export const useCountedLaboratorys = (
  options: QueryHookOptions<{ laboratorysCount: LaboratorysCountedData[] }, {}> = {},
) => useLocalQuery(labQuery2, options);

export const useLazyLaboratorys = (options: QueryHookOptions<{ laboratorys: LaboratorysData }, {}> = {}) =>
  useLocalLazyQuery(labQuery, options);

export const addLaboratoryQuery = gql`
  mutation createLaboratory($name: String!) {
    createLaboratory(name: $name) {
      id
      name
    }
  }
`;
export interface AddLaboratoryResponseData {
  id: string;
  name: string;
}

export const useAddLaboratory = (
  options: MutationHookOptions<{ createLaboratory: AddLaboratoryResponseData }, {}> = {},
) => useLocalMutation(addLaboratoryQuery, options);

export const updateLabMutation = gql`
  mutation updateLaboratory($id: ID!, $name: String) {
    updateLaboratory(id: $id, name: $name) {
      id
      name
    }
  }
`;
export interface UpdateLabArguments {
  id: string;
  name?: string;
}

export const useUpdateLab = (
  options: MutationHookOptions<{ updateLaboratory: { id: string } }, UpdateLabArguments> = {},
) => useLocalMutation(updateLabMutation, options);

export const laboratoryQuery = gql`
  query laboratory($id: ID!) {
    laboratory(id: $id) {
      id
      name
    }
  }
`;

export const useLazyLab = (options?: QueryHookOptions<{ laboratory: Lab }, GetVariables>) =>
  useLocalLazyQuery(laboratoryQuery, options);

export const deleteLabMutation = gql`
  mutation removeLaboratory($id: ID, $ids: [ID]) {
    removeLaboratory(id: $id, ids: $ids)
  }
`;

export const useDeleteLab = (
  options: MutationHookOptions<{ removeLaboratory: string }, { id?: string; ids?: string[] }> = {},
) => useLocalMutation(deleteLabMutation, options);

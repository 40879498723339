import { useCountedIndication } from 'requests/indications';
import MedecineMenu from '../MedecineMenu/MedecineMenu';

const Indication = () => {
  const { data } = useCountedIndication();

  return (
    <MedecineMenu array pathname="/indications" type="indication" data={data?.IndicationsCount} title="Indications" />
  );
};

export default Indication;

import gql from 'graphql-tag';

import { QueryHookOptions } from '@apollo/client';
import { useLocalLazyQuery, useLocalQuery } from 'hooks/apollo';

export const indicationQuery = gql`
  query Indications($name: String) {
    Indications(name: $name) {
      count
      page
      data {
        id
        name
      }
    }
  }
`;

export const indicationQuery2 = gql`
  query {
    IndicationsCount {
      id
      name
      count
    }
  }
`;

export interface IndicationData {
  count: number;
  page: number;
  data: {
    id: string;
    name: string;
    count: number;
  }[];
}

export const useIndication = (options: QueryHookOptions<{ Indications: IndicationData }, {}> = {}) =>
  useLocalQuery(indicationQuery, options);

export const useCountedIndication = (options: QueryHookOptions<{ IndicationsCount: IndicationData }, {}> = {}) =>
  useLocalQuery(indicationQuery2, options);
export const useLazyIndication = (options: QueryHookOptions<{ Indications: IndicationData }, {}> = {}) =>
  useLocalLazyQuery(indicationQuery, options);

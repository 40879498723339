import Filter from 'components/filtres/Filter';
import { LabelProps } from 'components/filtres/Select/GuideSelect';
import { useDCIS } from 'requests/dcis';
import { useTypes } from 'requests/medecineTypes';
import { useClasse } from 'requests/classes';

import { useHistory, useLocation } from 'react-router-dom';
import { encodeUri, decodeUri } from 'utils/url';
import HomeGeneric from '../HomeGeneric/HomeGeneric';

const Classes = () => {
  const location = useLocation();
  const history = useHistory();
  const { data: dataDci } = useDCIS();
  const uri = decodeUri(location.search);

  const { data: dataClasse } = useClasse({ variables: { id: uri.classe }, fetchPolicy: 'no-cache' });

  const { data } = useTypes();
  function handleChoosed(value: LabelProps | null) {
    if (value) {
      if (value.value === 'Tous') {
        const { deviceType, ...Original } = uri;
        history.replace({
          pathname: location.pathname,
          search: encodeUri({ ...Original }),
        });
      } else {
        history.replace({
          pathname: location.pathname,
          search: encodeUri({ ...uri, deviceType: value.value }),
        });
      }
    }
  }

  function setDcisChoosed(value: LabelProps | null) {
    if (value) {
      if (value.value === 'Tous') {
        const { dci, ...Original } = uri;
        history.replace({
          pathname: location.pathname,
          search: encodeUri({ ...Original }),
        });
      } else {
        history.replace({
          pathname: location.pathname,
          search: encodeUri({ ...uri, dci: value.value }),
        });
      }
    }
  }
  const textSelected = dataClasse?.classe.name;
  return (
    <HomeGeneric
      from="classe"
      title="Classes thérapeutiques"
      textSelected={textSelected}
      filterComponent={
        <>
          <Filter
            isDisabled={Boolean(uri.dci)}
            icon={<img alt="" src="trousse.svg" height="23" />}
            title="Types de dispositifs"
            type="classe"
            type2="deviceType"
            data={data?.types.data}
            value={uri.deviceType}
            onChange={handleChoosed}
          />

          <Filter
            isDisabled={Boolean(uri.deviceType)}
            className="pt-5"
            icon={<img alt="" src="dcisLogo.svg" height="23" />}
            title="DCI"
            type="classe"
            type2="dci"
            data={dataDci?.dcis.data}
            value={uri.dci}
            onChange={setDcisChoosed}
          />
        </>
      }
    />
  );
};

export default Classes;

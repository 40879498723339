import { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Plus from 'components/icons/Plus';
import Button from 'components/Button';
import { useNotesById } from 'requests/note';
import { Spinner } from 'utils/loading';

import Note from 'components/common/NoteCard/NoteCard';
import NoteModal from 'components/ux/ModalDetailNote';
import DeleteNoteModal from 'containers/Note/DeleteNote';

import HeaderRouter from '../DetailsMedication/Components/HeaderRouter';

const DetailsNoteContainer = () => {
  const params = useParams<{ id: string }>();
  const history = useHistory();
  const { data: noteid, called, refetch } = useNotesById({ variables: { id: params?.id } });
  const [remove, setRemove] = useState(false);
  const [modalOpen, setModalOpen] = useState(-1);
  return (
    <>
      {!noteid && called ? (
        <div className="flex flex-1 items-center justify-center">
          <Spinner name="line-scale-pulse-out-rapid" color="#014A8D" />
        </div>
      ) : (
        <div>
          <HeaderRouter
            onSubTitleClick={() => {
              history.push('/notes');
            }}
            pageTitle="Mon espace"
            subTitle={noteid?.note?.medicine?.name}
            smallTitle={noteid?.note?.medicine?.small_title}
          />
          <div className="bg-grey-300 flex-1 flex w-full h-screen flex-col px-139 pt-61 pb-40 lg:px-5">
            <div className="flex pb-10 items-center justify-end">
              <Button onClick={() => setModalOpen(0)} className="cursor-pointer w-32 " size="sm" variant="secondary">
                <Plus fill="#ffff" height={14} className="pr-1.5" />
                <div className="w-w8">Ajouter</div>
              </Button>
            </div>

            <Note
              onEdit={() => setModalOpen(1)}
              onDelete={() => setRemove(true)}
              title={noteid?.note.title}
              content={noteid?.note.content}
              noteId={noteid?.note.id}
              name={noteid?.note.medicine.name}
              imgSrc={noteid?.note.medicine.image}
              medId={noteid?.note.medicine.id}
              dateC={noteid?.note.createdAt}
              smallTitle={noteid?.note.medicine?.small_title}
              fullContent
            />
          </div>
          <NoteModal
            note={modalOpen === 1 ? noteid?.note : undefined}
            open={modalOpen > -1}
            onClose={(updated) => {
              setModalOpen(-1);
              if (updated) {
                refetch();
              }
            }}
          />
          <DeleteNoteModal
            id={remove ? noteid?.note.id : ''}
            onDelete={(updated) => {
              if (updated) {
                history.push('/notes');
              } else {
                setRemove(false);
              }
            }}
          />
        </div>
      )}
    </>
  );
};

export default DetailsNoteContainer;

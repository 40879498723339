import gql from 'graphql-tag';

import { QueryHookOptions } from '@apollo/client';
import { useLocalLazyQuery, useLocalQuery } from 'hooks/apollo';

export const devicesQuery = gql`
  query devices($name: String, $sort: String) {
    devices(name: $name, sort: $sort) {
      count
      data {
        id
        name
      }
    }
  }
`;

export const devicesQuery2 = gql`
  query {
    devicesCount {
      id
      name
      count
    }
  }
`;

export interface DevicesData {
  count: number;
  data: {
    id: string;
    name: string;
    count: string;
  }[];
}

export const useDevices = (options: QueryHookOptions<{ devices: DevicesData }, {}> = {}) =>
  useLocalQuery(devicesQuery, options);

export const useCountedDevices = (options: QueryHookOptions<{ devicesCount: DevicesData }, {}> = {}) =>
  useLocalQuery(devicesQuery2, options);

export const useLazyDevices = (options: QueryHookOptions<{ devices: DevicesData }, {}> = {}) =>
  useLocalLazyQuery(devicesQuery, options);

export const deviceQuery = gql`
  query device($id: ID!) {
    device(id: $id) {
      id
      name
    }
  }
`;

export interface DeviceData {
  id: string;
  name: string;
}

export const useDevice = (options: QueryHookOptions<{ device: DeviceData }, {}> = {}) =>
  useLocalQuery(deviceQuery, options);

import { useContext, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import localforage from 'localforage';
import { useLogout } from 'requests/auth';
import UserContext from 'contexts/UserContext';

import classNames from 'utils/classNames';
import Button from 'components/Button';
import LogoutIcon from 'components/icons/LogoutIcon';
import ReturnIcon from 'components/icons/ReturnIcon';
import CancelIcon from 'components/icons/CancelIcon';

import MenuContext, { MenuStatesValues } from 'contexts/MenuContext';

import MedecineMenu from 'components/common/MedecineCount/MedecineCount';
import { decodeUri } from 'utils/url';
import PreviousMenuContext from 'contexts/PreviousMenuState';
import cardIdContext from 'contexts/CardIdContext';
import MobileMenu from '../MobileMenu';
import classes from '../Responsive.module.scss';
import classes2 from './menuSection.module.scss';
import { returnText } from '../HeaderLab';

interface Props {
  open: keyof typeof MenuStatesValues;
  paramName: string;
  pathName: string;
  titleMenu?: string;
  returnSection: keyof typeof MenuStatesValues;
  data?: { id: string | number; name: string; pathname?: string[]; image?: any; MenuTrigger?: () => void }[];
}

const MobileMenuGeneric = ({ open, data, pathName, paramName, titleMenu, returnSection }: Props) => {
  const { setUser } = useContext(UserContext);
  const [logoutCall, { data: logoutData }] = useLogout();
  const { setMenuStates, MenuStates } = useContext(MenuContext);
  const history = useHistory();
  const location = useLocation();
  const uri = decodeUri(location.search);
  const { setCardId } = useContext(cardIdContext);
  const { user } = useContext(UserContext);

  const { setPreviousStates } = useContext(PreviousMenuContext);

  const isAdmin = user?.role === 'admin';
  const isLab = user?.role === 'lab';

  useEffect(() => {
    if (logoutData) {
      setUser(null);
      localforage.clear();
    }
  }, [logoutData]);
  const colorCount = { defaultColor: 'text-white', selectedColor: 'text-blue-600' };
  return (
    <MobileMenu responsiveMenuState={Boolean(MenuStates === open)}>
      <CancelIcon
        className=" absolute right-12 top-12 cursor-pointer z-50"
        height="26"
        width="20"
        fill="white"
        onClick={() => {
          setMenuStates(MenuStatesValues.CLOSE);
        }}
      />
      <div className="absolute top-12 translate-x-1/2 r-1/2 text-white text-lg sm:text-base font-semibold">
        {' '}
        {titleMenu}{' '}
      </div>

      {MenuStates !== 'mainMenu' && (
        <ReturnIcon
          className=" absolute left-12 top-12 cursor-pointer"
          height="26"
          fill="white"
          width="20"
          onClick={() => setMenuStates(returnSection)}
        />
      )}

      <ul className={classNames('sm:w-9/12 lg:h-1/2 sm:h-4/6	menuList items-start', classes2.menuList)}>
        {(isLab || isAdmin) && (
          <div className="flex flex-col items-center text-white ">
            Espace {user && returnText(user)} -{' '}
            <span className="font-bold text-yellow-200">
              {user?.firstName} {user?.lastName} <span className="text-white font-normal">du laboratoire </span>
              <span className="text-lg  ">{user?.lab?.name}</span>{' '}
            </span>
          </div>
        )}
        <div className=" grid flex-col  items-center flex-1 content-center z-30  gap-3">
          {data?.map(({ id, name, pathname, image: Image, MenuTrigger }) => (
            <li // eslint-disable-line
              onClick={
                MenuTrigger // eslint-disable-line
                  ? MenuTrigger
                  : () => {
                      history.push((pathname && pathname[0]) || `${pathName}?${paramName}=${id}`);
                      setMenuStates(MenuStatesValues.CLOSE);
                      setPreviousStates(open);
                      setCardId('');
                    }
              }
              key={id}
              className={classNames(
                `{menuItem font-medium lg:w-536 sm:w-342 m-auto relative p-3  text-white flex items-center  justify-between cursor-pointer ${
                  id === -1 ? 'hover:bg-yellow  text-white bg-blue-600' : ''
                } 
   
                ${
                  id !== -1 && (uri[Object.keys(uri)[0]] === id || pathname?.some((e) => e === location.pathname))
                    ? 'bg-white text-blue-600'
                    : ''
                }`,
                classes.menuItem,
              )}
            >
              <div className={classNames(`hoverState  absolute w-0 h-full`, classes.hoverState)} />

              {Image && (
                <div>
                  <Image
                    className={classNames(
                      `${id > 0 ? `w-6 h-6 sm:w-5 sm:h-5 ` : `w-7 h-7 sm:w-6 sm:h-6 ${classes.icon}`}`,
                    )}
                    color={name === 'Liste des médicaments' ? '#F8A609' : ''}
                  />
                </div>
              )}
              <p
                className={`text-left 
                ${
                  pathname
                    ? [pathname?.length > 1 ? 'text-2xl sm:text-base font-medium' : 'text-xl sm:text-sm font-semibold']
                    : 'text-lg sm:text-xs font-semibold'
                }
                ${!Image ? 'w-full' : 'w-11/12	sm:pl-1 lg:px-2  '}  
              }`}
              >
                {' '}
                {name}
              </p>
              {titleMenu && titleMenu !== `Chambres d'inhalation` && (
                <MedecineMenu
                  count
                  id={typeof id === 'string' ? id : ''}
                  name=""
                  type={paramName}
                  color={colorCount}
                  className="text-lg sm:text-xs"
                />
              )}
            </li>
          ))}
        </div>
      </ul>
      <Button
        onClick={() => logoutCall()}
        className={classNames(
          ' lg:w-112 sm:w-342 absolute bottom-12 mb-4  logoutButton flex  bg-yellow   cursor-pointer z-30 hover:text-blue-600 text-white hover:bg-white',
          classes.logoutButton,
        )}
      >
        <LogoutIcon className={classNames('logoutIcon h-5 sm:h-4', classes.logoutIcon)} />
        <p className="logoutText text-white text-22 hover:text-gray-900 sm:text-sm">Déconnexion</p>
      </Button>
    </MobileMenu>
  );
};

MobileMenuGeneric.defaultProps = {
  data: [],
  titleMenu: '',
};

export default MobileMenuGeneric;

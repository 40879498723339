import { Search } from '@mui/icons-material';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import { useHistory, useLocation } from 'react-router-dom';
import { decodeUri, encodeUri } from 'utils/url';

export type SearchProps = {
  label?: string;
};
const SearchComponent = ({ label = 'Rechercher par nom du médicament' }: SearchProps) => {
  const history = useHistory();
  const location = useLocation();

  const { search } = decodeUri(location.search);

  return (
    <TextField
      label={label}
      variant="outlined"
      value={search || ''}
      sx={{ marginBottom: '1.25rem', marginTop: '1rem' }}
      onChange={(event) => {
        history.replace({
          pathname: location.pathname,
          search: encodeUri({ search: event.target.value }),
        });
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <IconButton>
              <Search />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};
export default SearchComponent;

import { useEffect, useState, useContext } from 'react';
import cardIdContext from 'contexts/CardIdContext';
import { useMedicine } from 'requests/medicaments';
import { groupBy } from 'lodash';
import { useLocation, useHistory } from 'react-router-dom';
import { decodeUri } from 'utils/url';
import Up from 'assets/svg/goUp.svg';
import Counter from 'components/ux/CounterData';
import ArrowNext from 'components/icons/ArrowRight';
import HeaderRouterHeader from 'containers/DetailsMedication/Components/HeaderRouter';
import Sections from './Components/Sections';
import ZephirDescription from '../../components/common/ZephirDescription';
import CardInhalation from './Components/CardInhalation';

const InhalationChamber = () => {
  const { cardId, setCardId } = useContext(cardIdContext);
  const { data } = useMedicine({ variables: { inhalationChamber: 'oui' }, fetchPolicy: 'no-cache' });
  const groupedCmp = groupBy(data?.medicines.data, 'deviceType.name');
  const location = useLocation();
  const uri = decodeUri(location.search);
  const history = useHistory();
  const [readSectionActive, setreadSectionActive] = useState(false);

  useEffect(() => {
    const el = document.getElementById(cardId || '');
    if (el && uri.section) {
      el.scrollIntoView({ behavior: 'smooth' });
    }
  }, [location, uri]);

  const updatePage = (e: any): void => {
    if (e.target.scrollingElement.scrollTop > 1040) {
      setreadSectionActive(true);
    } else {
      setreadSectionActive(false);
    }
  };
  const scrollToTop = () => {
    setCardId('');
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  useEffect(() => {
    window.addEventListener('scroll', updatePage);
    return () => {
      window.removeEventListener('scroll', updatePage);
    };
  }, []);

  return (
    <div className="flex-1 h-screen w-full bg-grey-300">
      <HeaderRouterHeader
        onClick={() => {
          history.push('/');
        }}
        className=" py-6"
        pageTitle="Médicaments et liste de recherche"
        subTitle="Chambres d'inhalation"
        previousPage="/"
      />

      <ZephirDescription width="65px" className="justify-center h-14" />
      <div className="px-28 py-20 lg:px-20 sm:px-5 sm:px-5	2xl:py-12">
        {/* eslint-disable-next-line */}

        <div className="text-grey-400 text-32 2xl:text-26 lg:text-22  font-bold "> Chambres d'inhalation </div>

        <CardInhalation />

        <div>
          {Object.keys(groupedCmp)
            .sort((a, b) => a.localeCompare(b, 'es', { sensitivity: 'base' }))
            .map((key) => (
              <>
                <div>
                  <div
                    id={key.split(' ').join('-')}
                    className=" flex text-grey-600 font-normal text-lg  lg:flex-col xl:flex-col 2xl:flex-col  3xl:flex-col 4xl:flex-col pt-16 lg:font-medium lg:text-blue-600 sm:text-xl"
                  >
                    Types de dispositifs compatibles
                    <ArrowNext
                      fill="#F8A809"
                      className="lg:hidden xl:hidden 2xl:hidden 3xl:hidden 4xl:hidden mx-2 w-2.5 lg:w-6 sm:w-2.5"
                    />
                    <span className="text-blue-900 font-semibold 2xl:text-lg lg:text-base"> {key} </span>
                  </div>

                  <Counter data={groupedCmp[key]} />
                </div>
                <Sections description={groupedCmp[key]} title={key} />
              </>
            ))}
        </div>
      </div>
      <button className={`fixed bottom-5 ${readSectionActive ? 'right-1' : '-right-24'}`} onClick={() => scrollToTop()}>
        <img alt="up" src={Up} height={30} />
      </button>
    </div>
  );
};

export default InhalationChamber;

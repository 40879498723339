import React from 'react';
import classNames from 'utils/classNames';
import Check from '@mui/icons-material/CheckCircle';
import Cancel from '@mui/icons-material/CancelRounded';

interface MedicineInfosRowProps {
  value: React.ReactNode | string;
  isChecked: boolean;
  medicineStatus: string | undefined;
  onCancel: () => void;
  onClick: () => void;
}
const MedicineInfosRow = ({ value, isChecked, medicineStatus, onCancel, onClick }: MedicineInfosRowProps) => {
  return (
    <>
      <span className={classNames('font-sans text-green-250 mx-4', isChecked && 'line-through')}>{value}</span>
      {medicineStatus === 'pending' && (
        <>
          <span>
            <Cancel color="inherit" onClick={onCancel} className="cursor-pointer text-red-250 mr-3" />
          </span>
          <span>
            <Check className="cursor-pointer text-green-250" onClick={onClick} color="inherit" />
          </span>
        </>
      )}
    </>
  );
};

export default MedicineInfosRow;

import { Link } from 'react-router-dom';
import classNames from 'utils/classNames';
import MedecineCount from '../MedecineCount/MedecineCount';

interface Props {
  data: any;
  title: string;
  type?: string;
  pathname?: string;
  count?: boolean;
  array?: boolean;
  className?: string;
  search?: boolean;
}

const MedecineMenu: React.FC<Props> = ({ data = [], title, type, pathname = '', count, className, search }) => {
  return (
    <div className="pl-12 py-14 flex flex-col bg-white">
      <div className="text-blue-600 font-semibold text-base pb-1.5 self-start">
        {title}
        <div className="border-t-2 border-yellow w-full" />
      </div>
      <div
        className={classNames(
          'grid grid-flow-col grid-rows-12 2xl:grid-rows-16 gap-x-9 font-medium font-sans text-sm ',
          className,
        )}
      >
        {data
          ?.slice()
          ?.sort((a: any, b: any) =>
            a.name.replace('β', 'b').localeCompare(b.name.replace('β', 'b'), 'es', { sensitivity: 'base' }),
          )
          ?.map((e: any) =>
            type ? (
              <MedecineCount
                search={search}
                count={count}
                key={e.id}
                pathname={pathname}
                type={type}
                name={e.name}
                id={e.id}
                relatedName={e?.lab?.name}
              />
            ) : (
              <Link key={e?.id} to={`/medicament/${e?.id}`} className="flex py-1.5">
                <div className="truncate">{e?.name}</div>
              </Link>
            ),
          )}
      </div>
    </div>
  );
};

export default MedecineMenu;

import { useEffect, useState } from 'react';

import FavoriteCard from 'components/common/FavoriteCard/FavoriteCard';
import HeaderRouterHeader from 'containers/DetailsMedication/Components/HeaderRouter';
import { useLazyFavorites } from 'requests/Favoris';
import { Link, useLocation } from 'react-router-dom';
import { decodeUri } from 'utils/url';
import { Spinner } from 'utils/loading';
import Up from 'assets/svg/goUp.svg';

import EmptyFavoris from 'assets/svg/EmptyFavoris.svg';
import ZephirDescription from 'components/common/ZephirDescription';

const PER_PAGE = 100;

const PageFavoris = () => {
  const [dataCall, { data, refetch, loading: favoriteLoading, called: favorisCalled }] = useLazyFavorites({
    fetchPolicy: 'no-cache',
  });

  const location = useLocation();
  const uri = decodeUri(location.search);
  const [readSectionActive, setreadSectionActive] = useState(false);

  const updatePage = (e: any): void => {
    if (e.target.scrollingElement.scrollTop > 1040) {
      setreadSectionActive(true);
    } else {
      setreadSectionActive(false);
    }
  };
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  useEffect(() => {
    window.addEventListener('scroll', updatePage);
    return () => {
      window.removeEventListener('scroll', updatePage);
    };
  }, []);

  useEffect(() => {
    dataCall({ variables: { perPage: PER_PAGE, page: uri.page ? Number(uri.page) : 1 } });
  }, [uri.page]);

  if (!data?.favorites?.data.length && !favoriteLoading && favorisCalled) {
    return (
      <div>
        <HeaderRouterHeader pageTitle="Mon espace" subTitle="Mes Favoris" />
        <ZephirDescription width="65px" className="justify-center h-14" />
        <div className="bg-grey-300 px-28 py-12 lg:px-20 sm:px-5 sm:px-5 2xl:py-2">
          <div className="flex flex-col items-center p-2 bg-grey-300 min-h-100">
            <div className="image w-2/5	lg:w-10/12">
              <img alt="emptyFav" src={EmptyFavoris} className="m-auto" />
            </div>
            <div className="textSection">
              <div className="text-22  font-bold sm:text-base 2xl:text-22 text-center	">Aucun favoris</div>
              <div className="text-md text-grey-600 font-semibold text-20 2xl:text-lg sm:text-sm">
                Consulter la page{' '}
                <Link to="/" className="font-semibold underline cursor-pointer text-blue-900">
                  médicaments
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <HeaderRouterHeader pageTitle="Mon espace" subTitle="Mes Favoris" />
      <ZephirDescription width="65px" className="justify-center h-14" />
      <div className="bg-grey-300 px-28 py-12 lg:px-20 sm:px-5">
        <div className="lg:text-22 font-semibold text-32 2xl:text-26"> Mes Favoris</div>

        <div className="grid grid-cols-4 2xl:grid-cols-3 lg:grid-cols-2 sm:grid-cols-1 gap-4 justify-between gap-y-5 pt-14 pb-10 relative min-h-40">
          {favoriteLoading ? (
            <div className="absolute top-1/3 right-1/2">
              <Spinner name="line-scale-pulse-out-rapid" color="#1466ff" />
            </div>
          ) : (
            data?.favorites.data.map((e) => (
              <FavoriteCard
                refetch={refetch}
                medicinePhoto={e.medicine.image}
                smallTitle={e.medicine.small_title}
                className="w-full"
                title={e.medicine.name}
                footer={e.medicine.lab.name}
                FavoriteID={e.id}
                medicineId={e.medicine.id}
                generic={e.medicine.generic}
              />
            ))
          )}
        </div>
      </div>
      <button className={`fixed bottom-5 ${readSectionActive ? 'right-1' : '-right-24'}`} onClick={() => scrollToTop()}>
        <img alt="up" src={Up} height={30} />
      </button>
    </div>
  );
};

export default PageFavoris;

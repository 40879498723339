import { useCountedLaboratorys } from 'requests/laboratorys';
import MedecineMenu from '../MedecineMenu/MedecineMenu';

const Lab = () => {
  const { data } = useCountedLaboratorys();

  return <MedecineMenu array type="lab" pathname="/lab" data={data?.laboratorysCount} title="Laboratoires" />;
};

export default Lab;

import { groupBy } from 'lodash';
import React, { FC, useState, useEffect, Fragment } from 'react';
import SuggestionItem from './SuggestionItem';
import classes from './suggestionCard.module.scss';

interface Medicine {
  [k: string]: any;
}

interface Props {
  titre: String;
  soustitre: String[];
  colorTitle?: any;
  data?: Medicine[];
  secondData?: Medicine[];
  lab?: boolean;
}

const colors: { [key: string]: string } = {
  blue: '#111136',
  yellow: '#F8A809',
  gray: '#707070',
  white: '#ffff',
};

const MainCard: FC<Props> = ({ titre, soustitre, data, secondData, colorTitle = 'blue' }) => {
  const [defaultData, setDefaultData] = useState(data);
  useEffect(() => {
    setDefaultData(data);
  }, [data]);

  const groupedTitle =
    titre === 'Autres classes' ? groupBy(defaultData, 'classe.name') : groupBy(defaultData, 'dci.name');

  return (
    <div
      className="flex flex-col  rounded-md childd  h-auto
      pb-6  m-2 w-1/3 lg:w-full"
    >
      <div className="flex flex-col justify-evenly w-62 ">
        <h4 className="px-4 font-bold  text-base font-PopinsMedium py-2.5 bg-blue-500 text-white rounded">{titre}</h4>
        <div className="flex relative">
          {/* <div className={`absolute  h-full w-1/2 border-b-2 border-yellow ${secondData ? '  w-1/2' : 'w-full'} `}>
            {}
          </div> */}
          <div
            className={`absolute  border-b-2 border-yellow h-full w-1/2 ${soustitre.length > 1 ? 'w-1/2' : 'w-full'} ${
              defaultData === data ? classes.firstData : classes.secondData
            }`}
          >
            {}
          </div>

          <div
            className={`whitespace-nowrap p-2 text-sm font-medium font-PopinsMedium z-10 whitespace-pre-line ${
              secondData ? 'cursor-pointer  w-1/2' : 'w-full'
            } `}
            style={{ color: defaultData === data ? colors[colorTitle] : '#111136' }}
            onClick={() => {
              setDefaultData(data);
            }}
          >
            {soustitre[0]} ( {data?.length} )
          </div>
          {secondData && (
            <div
              className={`whitespace-nowrap p-2 text-sm font-medium  font-PopinsMedium cursor-pointer flex-1 w-1/2 z-10"} `}
              style={{ color: defaultData === secondData ? colors[colorTitle] : '#111136' }}
              onClick={() => setDefaultData(secondData)}
            >
              {soustitre[1]} ( {secondData?.length} )
            </div>
          )}
        </div>
      </div>

      {/* Sugestion */}
      <div className="flex flex-col  justify-center w-full ">
        {defaultData?.length ? (
          <div className="px-4 suggestion-list pt-2">
            <div className="text-xl color-blue-600 font-bold lg:text-lg sm:text-base">
              {' '}
              {defaultData === data ? soustitre[0] : soustitre[1]}
            </div>
            {Object.keys(groupedTitle)

              ?.sort((a, b) => a.replace('β', 'b').localeCompare(b.replace('β', 'b'), 'es', { sensitivity: 'base' }))
              .map((key) => (
                <Fragment key={key}>
                  <h3 className="text-blue-900 font-bold text-sm mx-1 relative z-10 py-2">{key}</h3>
                  {groupedTitle[key]
                    ?.sort((a, b) =>
                      a.name.replace('β', 'b').localeCompare(b.name?.replace('β', 'b'), 'es', { sensitivity: 'base' }),
                    )
                    .map((e) => (
                      <SuggestionItem
                        key={e.name}
                        medicineName={e?.name}
                        deviceType={e?.small_title}
                        medicineId={e?.id}
                      />
                    ))}
                </Fragment>
              ))}
          </div>
        ) : (
          <div className=" m-auto w-full h-full p-2 text-gray-600 font-semibold">
            {' '}
            Aucun résultat pour votre recherche{' '}
          </div>
        )}
      </div>
    </div>
  );
};

MainCard.defaultProps = {
  lab: false,
};

export default MainCard;

import React, { FunctionComponent } from 'react';

interface Props {
  className?: string;
  type?: string;
  responsiveMenuState: boolean;
}

const MobileMenu: FunctionComponent<Props> = ({ responsiveMenuState, type, className, children, ...props }) => {
  React.useEffect(() => {
    document.body.style.overflow = responsiveMenuState ? 'hidden' : 'auto';
  }, [responsiveMenuState]);
  return (
    <>
      <div
        {...props}
        className={`fixed overflow-hidden h-screen w-screen top-0 left-0 z-50 transition-transform duration-700 flex flex-col justify-center items-center ${className}`}
        style={{
          background: type === 'filter' ? '#F5F5F7' : '#014A8D',
          transform: `translateX(${responsiveMenuState ? '0%' : '200%'})`,
          zIndex: 100,
        }}
      >
        {children}
      </div>
    </>
  );
};

export default MobileMenu;

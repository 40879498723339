import * as React from 'react';

function Favorite({ fill = 'currentColor', ...props }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.371 18" {...props}>
      <path
        // eslint-disable-next-line
        d="M13.91 0a5.313 5.313 0 00-3.881 1.691l-.342.361-.342-.361a5.3 5.3 0 00-7.486-.275q-.143.132-.275.275a5.941 5.941 0 000 8.078l7.6 8.015a.692.692 0 00.979.027l.026-.027 7.6-8.015a5.94 5.94 0 000-8.077A5.307 5.307 0 0013.91 0zm2.874 8.816l-7.1 7.486-7.1-7.486a4.539 4.539 0 010-6.172 3.913 3.913 0 015.529-.222q.116.107.222.222l.844.89a.713.713 0 001.005 0l.844-.89a3.913 3.913 0 015.529-.222q.116.107.222.222a4.5 4.5 0 010 6.171z"
        fill={fill}
      />
    </svg>
  );
}

export default Favorite;

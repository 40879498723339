import { createContext } from 'react';

export enum MenuStatesValues {
  CLOSE = 'CLOSE',
  mainMenu = 'mainMenu',
  mainMedicineMenu = 'mainMedicineMenu',
  aboutMenu = 'aboutMenu',
  mySpaceMenu = 'mySpaceMenu',
  deviceTypeMenu = 'deviceTypeMenu',
  deviceMenu = 'deviceMenu',
  indicationMenu = 'indicationMenu',
  classeMenu = 'classeMenu',
  dciMenu = 'dciMenu',
  genericMenu = 'genericMenu',
  chambreMenu = 'chambreMenu',
  labMenu = 'labMenu',
}

export default createContext<{
  MenuStates: MenuStatesValues | null;
  setMenuStates: (user: keyof typeof MenuStatesValues | null) => void;
}>({
  MenuStates: null,
  setMenuStates: () => {},
});

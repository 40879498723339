import Button from 'components/Button';
import Table, { TableProps, TableItem } from 'components/common/Table/Table';
import Add from '@mui/icons-material/Add';
import SideBar from 'containers/DashboardAdmin/SideBar';
import useTableControls from 'hooks/useTableControls';
import { Link, RouteComponentProps, useHistory } from 'react-router-dom';
import Search from 'components/ux/Search';
import React from 'react';

interface ListProps<T extends TableItem> extends TableProps<T> {
  canDelete?: boolean;
  canUpdate?: boolean;
  canCreate?: boolean;
  match: RouteComponentProps['match'];
  onRowClick?: (row: T, index: number) => void;
  loading?: boolean;
  headerActions?: React.ReactNode;
  called?: boolean;
}

const List = <T extends TableItem>({
  data,
  headers: headersProp,
  canDelete,
  canCreate,
  canUpdate,
  match,
  page,
  count,
  headerActions,
  perPage,
  loading,
  handleChangePage,
  handleChangeRowsPerPage,
  onRowClick,
  called,
}: ListProps<T>) => {
  const { path } = match;
  const history = useHistory();
  const headers = useTableControls(headersProp, data, {
    onEdit: canUpdate ? ({ id }) => history.replace({ pathname: `${path}/update/${id}` }) : undefined,
    onDelete: canDelete ? ({ id }) => history.replace({ pathname: `${path}/delete/${id}` }) : undefined,
    onMultipleDelete: canDelete ? (ids) => history.replace({ pathname: `${path}/delete/${ids.join(',')}` }) : undefined,
  });
  const renderTitle = () => {
    if (path === '/laboratory') {
      return 'laboratoire';
    }
    if (path === '/medications') {
      return 'medicament';
    }
    return 'utilisateur';
  };
  return (
    <div className="bg-grey-300  h-full w-full flex-1 flex  overflow-hidden	">
      <SideBar />
      <div className="flex mx-8 mt-4 flex-1 flex-col overflow-hidden">
        {path === '/medications' && <Search />}

        <div className="flex items-center justify-end ">
          {headerActions && headerActions}
          {canCreate && (
            <Link to={`${path}/add`}>
              <Button className="flex self-end" variant="primary" size="md">
                <div className="flex items-center justify-center gap-5 w-full">
                  <div className="w-6">
                    <Add />
                  </div>
                  <div className="w-28 text-sm min-w-max md:hidden "> Ajouter un {renderTitle()}</div>
                </div>
              </Button>
            </Link>
          )}
        </div>
        {path === '/admin' && <Search label="Rechercher par nom ou prénom ou email" />}
        <Table
          called={called}
          loading={loading}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          handleChangePage={handleChangePage}
          count={count}
          perPage={perPage}
          page={page}
          className="flex-1 mt-5"
          data={data}
          headers={headers}
          onRowClick={onRowClick}
        />
      </div>
    </div>
  );
};

export default List;

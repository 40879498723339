import classNames from 'utils/classNames';
import { Link } from 'react-router-dom';

interface RowProps {
  Image?: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  title: string;
  onClick: () => void;
  className?: string;
  width?: number;
  selected?: boolean;
  pathname: string;
}

const Row: React.FC<RowProps> = ({ Image, title, onClick, className, width = 18, selected, pathname }) => {
  return (
    <Link
      to={pathname}
      onClick={(e) => {
        e.preventDefault();
        onClick();
      }}
      className={classNames(
        'w-full flex flex-row p-4 items-center',
        'hover:bg-yellow cursor-pointer hover:text-white',
        selected ? 'bg-blue text-white' : 'text-blue-600',
        className,
      )}
    >
      {Image ? <Image width={width} /> : <div />}

      <div className={`flex-1 text-current font-medium text-sm	 whitespace-nowrap ${Image ? 'pl-4' : 'pl-4 2xl:pl-0'}`}>
        {title}
      </div>
    </Link>
  );
};

export default Row;

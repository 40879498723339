import { QueryHookOptions, QueryResult } from '@apollo/client';
import React, { useMemo } from 'react';
import { graphQLResult } from 'utils/graphql';
import Select, { Props } from 'react-select';
import classNames from 'utils/classNames';
import TooltipComponenet from './TooltipComponent';

interface SelectProps<
  T extends string,
  D extends {
    data: { id: string; name: string }[];
  },
> extends Omit<Props<{ value: string; label: string }, false>, 'value'> {
  useData: (
    options?: QueryHookOptions<
      {
        [k in T]: D;
      },
      {}
    >,
  ) => QueryResult<
    {
      [k in T]: D;
    },
    {}
  >;
  value: string;
  label?: string;
  labelClassName?: string;
  options?: any;
  item?: string;
  error?: string;
  errorColor?: string;
  borderColor?: string;
  isDisabled?: boolean;
  heightToslice?: number;
}

const SelectComponent = <
  T extends string,
  D extends {
    data: { id: string; name: string }[];
  },
>({
  value,
  useData,
  label,
  labelClassName,
  options: selectOptions,
  item,
  error,
  borderColor = '#323232',
  errorColor = 'red-100',
  isDisabled,
  heightToslice,
  ...props
}: SelectProps<T, D>) => {
  const colourStyles = {
    control: (base: any) => ({
      ...base,
      border: `1px solid ${borderColor}`,
      backgroundColor: 'transparent',
      height: 48,
      boxShadow: 'none',
      '&:hover': {
        border: `1px solid ${borderColor}`,
        cursor: 'pointer',
      },
    }),
    option: (base: any) => ({
      ...base,
      '&:hover': {
        cursor: 'pointer',
      },
    }),
  };
  const { data } = useData({ variables: { sort: 'name' }, fetchPolicy: 'no-cache' });

  const options = useMemo(() => {
    if (!data) return [];
    return graphQLResult(data)
      .data?.sort((a: any, b: any) =>
        a.name.replace('β', 'b').localeCompare(b.name.replace('β', 'b'), 'es', { sensitivity: 'base' }),
      )
      .map((values: any) => ({
        label: values.name,
        value: values.id,
      }));
  }, [data]);
  return (
    <div className={classNames('flex flex-col flex-1 pb-3')}>
      <div className="flex items-center gap-5 flex-1 mb-4 min-h-0 h-6">
        {label && <div className={classNames('text-md font-regular whitespace-nowrap', labelClassName)}>{label}</div>}
        {item && <TooltipComponenet item={item} heightToslice={heightToslice} />}
      </div>
      <Select
        isDisabled={isDisabled}
        styles={colourStyles}
        noOptionsMessage={() => 'Aucun résultat trouvé'}
        {...props}
        getOptionValue={(option) => option.value}
        value={options.find((o: { value: string; label: string }) => o.value === value) || null}
        options={selectOptions || options}
      />
      <div className={classNames('lg:text-xs mt-1.5 text-sm h-5 relative left-1', `text-${errorColor}`)}>{error}</div>
    </div>
  );
};

export default SelectComponent;

import { useContext, useEffect, useRef } from 'react';
import localforage from 'localforage';

import UserContext from 'contexts/UserContext';
import { useLogout } from 'requests/auth';

import Row from 'components/common/DropDown/DropDownRow';
import Logout from 'components/icons/Logout';
import Note from 'components/icons/Note';
import Favorite from 'components/icons/Favorite';
import Avatar from 'components/icons/Avatar';
import cardIdContext from 'contexts/CardIdContext';

import DropDown from 'components/common/DropDown/DropDown';
import useMouse from 'hooks/useMouse';
import { useHistory, useLocation, matchPath } from 'react-router-dom';
import classNames from 'utils/classNames';

const Header = () => {
  const [logoutCall, { data: logoutData }] = useLogout();
  const { user, setUser } = useContext(UserContext);
  const history = useHistory();
  const location = useLocation();

  const divRef = useRef<HTMLButtonElement>(null);
  const [open, onMouseEnter, onMouseLeave] = useMouse();
  const { setCardId } = useContext(cardIdContext);
  useEffect(() => {
    if (logoutData) {
      setUser(null);
      localforage.clear();
    }
  }, [logoutData]);

  const list = [
    {
      id: 0,
      title: 'Mes notes',
      image: Note,
      pathname: '/notes',
    },
    {
      id: 1,
      title: 'Mes favoris',
      image: Favorite,
      pathname: '/favoris',
    },
    {
      id: 2,
      title: 'Déconnexion',
      image: Logout,
      pathname: '/logout',
      click: logoutCall,
    },
  ];

  return (
    <>
      <div className={classNames('flex flex-row  items-end pl-1 m-auto w-full pr-28')}>
        <Avatar
          width={18}
          className="ml-auto text-white cursor-pointer"
          onMouseEnter={() => {
            onMouseEnter();
          }}
          onMouseLeave={() => {
            onMouseLeave();
          }}
        />
      </div>

      <DropDown
        position={{ top: user?.role === 'user' ? 112 : 150 }}
        onMouseEnter={() => {
          onMouseEnter();
        }}
        onMouseLeave={() => {
          onMouseLeave();
        }}
        className="w-52 right-0 mr-28"
        open={open}
        anchorEl={divRef}
        left="auto"
      >
        {list
          .filter((e) => e)
          .map((item) => (
            <Row
              selected={!!matchPath(location.pathname, item?.pathname)}
              pathname={item?.pathname}
              title={item?.title}
              Image={item?.image}
              key={item?.id}
              onClick={() => {
                setCardId('');
                onMouseLeave();
                if (item?.pathname !== '/logout') history.push(item?.pathname);
                if (item?.click) item?.click();
              }}
            />
          ))}
      </DropDown>
    </>
  );
};

export default Header;

import React, { useContext } from 'react';

import cardIdContext from 'contexts/CardIdContext';

import { useHistory, useLocation, matchPath } from 'react-router-dom';
import classNames from 'utils/classNames';

import logo from 'assets/svg/logoZephirWhite.svg';
import Menu from './Menu';
import About from './About';

import HeaderLink from './HeaderLink';
import Medecines from './Medecines/Medecines';

const list = [{ title: 'Vidéos éducatives', path: '/videos' }];

const WebMenu = () => {
  const { setCardId } = useContext(cardIdContext);
  const history = useHistory();
  const location = useLocation();
  return (
    <header
      className={classNames(
        'shadow-3	z-10	h-32 self-center	grid grid-flow-col bg-blue items-center  grid-cols-469 w-full 3xl:grid 2xl:grid xl:grid lg:hidden md:hidden sm:hidden  2xl:grid-cols-500 ',
      )}
    >
      <div className="bg-blue pl-28  2xl:px-12 h-full grid items-center w-12/12 py-2">
        {/* eslint-disable-next-line */}
        <img
          className="cursor-pointer w-5/12 2xl:w-7/12"
          src={logo}
          alt=""
          onClick={() => {
            history.push('/');

            setCardId('');
          }}
        />
        <div className="text-white text-xs ">
          Guide des thérapeutiques inhalées dans l'asthme et la BPCO chez l'adulte
        </div>
      </div>
      <div className="grid grid-flow-col h-full  items-stretch ">
        <Medecines />
        {list.map((l) => {
          return (
            <HeaderLink
              selected={!!matchPath(location.pathname, l.path)}
              onClick={() => history.push(l?.path)}
              key={l.title}
            >
              {l.title}
            </HeaderLink>
          );
        })}
        <About />
      </div>

      <Menu />
    </header>
  );
};

export default WebMenu;

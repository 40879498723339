import React, { FC, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Up from 'assets/svg/goUp.svg';
import ZephirDescription from 'components/common/ZephirDescription';
import HeaderRouterHeader from 'containers/DetailsMedication/Components/HeaderRouter';
import GuideSelect, { LabelProps } from 'components/filtres/Select/GuideSelect';
import Chambres from './Chambres';
import Criteres from './Criteres';
import Descriptions from './Descriptions';
import Editorial from './Editorial';
import MenuItem from './MenuItem';
import Editorial2023 from './Editorial2023';
import Facteurs from './Facteurs';
import Introduction from './Introduction';
import TherapeuticManagementAlgorithm from './TherapeuticManagementAlgorithm';
import Annex from './Annex';
import Annex2 from './Annex2';
import DefinitionsGlossaryAbbreviations from './DefinitionsGlossaryAbbreviations';
import SplfCard from '../../components/Splf';

interface PagesData {
  name: string;
}

interface StaticPagesInfo {
  [key: string]: PagesData;
}

const PageStatique: FC = () => {
  const INFOS: StaticPagesInfo = {
    Editorial: { name: 'Éditorial' },
    /* Éditorial: { name: 'Archives éditoriaux' }, */
    Introduction: { name: 'Introduction' },
    Facteurs: {
      name: 'Facteurs influençant le dépôt des aérosols au niveau des Voies Aériennes',
    },
    Descriptions: {
      name: 'Description des différents dispositifs d’inhalation prêts à l’emploi',
    },
    Criteres: {
      name: 'Critères de choix d’un dispositif d’inhalation prêt à l’emploi dans l’asthme et la BPCO',
    },
    Chambres: { name: 'Chambres d’inhalation' },
    TherapeuticManagementAlgorithm: { name: 'Alghorithme de prise en charge thérapeutique' },
    Annex: { name: 'Annexe 1' },
    Annex2: { name: 'Annexe 2' },
    DefinitionsGlossaryAbbreviations: { name: 'Définitions-Glossaire-Abréviations' },
  };
  const [displaySection, setdisplaySection] = useState('Editorial');
  const [currentSection, setcurrentSection] = useState<string | null>('Editorial');
  const [displaysubSection, setdisplaysubSection] = useState('Éditorial 2022');

  const { search } = useLocation();
  const history = useHistory();
  const ToRead = useRef<HTMLDivElement>(null);
  const ToMenu = useRef<HTMLDivElement>(null);
  const name = new URLSearchParams(search).get('section');
  const [readSectionActive, setreadSectionActive] = useState(false);
  useEffect(() => {
    history.push(`/informations?section=${displaySection}`);

    if (ToRead.current) {
      window.scrollTo({
        top: ToRead.current.offsetTop - 20,
        behavior: 'smooth',
      });
    }

    setcurrentSection(name);
  }, [displaySection, search, currentSection]);

  const scrollToMenu = () => {
    if (ToMenu.current) {
      ToMenu.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  const updatePage = (e: any): void => {
    if (e.target.scrollingElement.scrollTop > 1040) {
      setreadSectionActive(true);
    } else {
      setreadSectionActive(false);
    }
  };
  useEffect(() => {
    window.addEventListener('scroll', updatePage);
    return () => {
      window.removeEventListener('scroll', updatePage);
    };
  }, []);
  const options: LabelProps[] = [
    {
      value: 'Éditorial 2022',
      label: 'Année 2022',
    },
    {
      value: 'Éditorial 2021',
      label: 'Année 2021',
    },
    {
      value: 'Éditorial 2020',
      label: 'Année 2020',
    },
  ];

  const handleChange = (value: LabelProps) => {
    setdisplaysubSection(value.value);
  };
  return (
    <div className="container bg-grey-300 min-h-80">
      <HeaderRouterHeader pageTitle="À propos" subTitle="Informations" />
      <ZephirDescription width="65px" className="justify-center h-14" />
      <div className="lg:px-20 sm:px-5  px-28 m-auto  flex lg:flex-col xs:flex " ref={ToMenu}>
        <div className="menu-info py-7 flex-0.3">
          {Object.keys(INFOS).map((key) => (
            <MenuItem
              medicamentName={INFOS[key]?.name}
              currentSection={currentSection}
              target={key}
              setdisplaySection={setdisplaySection}
            />
          ))}
          <div className="w-11/12	">
            <SplfCard colorType />
          </div>
        </div>
        <div ref={ToRead} className="contenu-info p-2 flex-0.7 flex-col-reverse	">
          <div className="py-6 flex justify-between 	">
            <div>
              <h4 className="text-2xl font-bold">GUIDE ZEPHIR</h4>
              <h2 className="text-3xl font-bold text-blue sm:text-xl">
                {displaySection === 'Éditorial' ? displaysubSection : INFOS[displaySection]?.name}
              </h2>
            </div>
            {displaySection === 'Éditorial' && (
              <div className="w-44 ml-auto">
                <GuideSelect value={displaysubSection} options={options} onChange={handleChange} />
              </div>
            )}
          </div>
          <div className="grid grid-cols-1 text-justify">
            <Editorial
              display={displaySection}
              displaySection={displaysubSection}
              setdisplaySection={setdisplaysubSection}
            />
            <Chambres display={displaySection} />
            <Criteres display={displaySection} />
            <Descriptions display={displaySection} />
            <Editorial2023 display={displaySection} />
            <Facteurs display={displaySection} />
            <Introduction display={displaySection} />
            <TherapeuticManagementAlgorithm display={displaySection} />
            <Annex display={displaySection} />
            <Annex2 display={displaySection} />
            <DefinitionsGlossaryAbbreviations display={displaySection} />
          </div>
        </div>
      </div>
      <button
        className={` outline-none fixed bottom-5 ${readSectionActive ? 'right-1' : '-right-24'}`}
        onClick={() => scrollToMenu()}
      >
        <img alt="up" src={Up} height={30} />
      </button>
      <p className="p-8" />
    </div>
  );
};

export default PageStatique;

import * as React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';

import { Paper } from '@mui/material';
import classNames from 'utils/classNames';
import './Table.css';
import { decodeUri } from 'utils/url';
import { useLocation } from 'react-router-dom';

export interface Header<T extends TableItem> {
  title: string | JSX.Element;
  key: string;
  dataIndex?: keyof T;
  render?: (record: T, index: number) => React.ReactNode;
  className?: string;
}

export interface TableProps<T extends TableItem> {
  headers: Header<T>[];
  data: T[];
  onRowClick?: (row: T, index: number) => void;
  className?: string;
  page: number;
  perPage: number;
  count: number;
  handleChangePage: (page: number) => void;
  handleChangeRowsPerPage: (value: number) => void;
  getRowClassName?: (row: T) => void;
  emptyText?: JSX.Element | string;
  loading?: boolean;
  called?: boolean;
}

export interface TableItem {
  id: string;
}

const TableData = <T extends TableItem>({
  data,
  headers,
  perPage,
  page,
  loading,
  count,
  called,
  handleChangePage,
  handleChangeRowsPerPage,
  onRowClick,
  getRowClassName,
  emptyText = 'Aucun résultat trouvé',
}: TableProps<T>) => {
  const location = useLocation();
  const search = decodeUri(location.search);
  function EnhancedTableHead() {
    return (
      <TableHead className="bg-grey-250 ">
        <TableRow>
          {headers.map((header, i) => {
            const paddingLeft = i === 0 ? 30 : 4;

            return (
              <TableCell
                className={header.className}
                style={{
                  fontWeight: 'bold',
                  color: '#00000',
                  whiteSpace: 'nowrap',
                  paddingLeft,
                  height: 75,
                }}
                key={i}
              >
                {header.title}
              </TableCell>
            );
          })}
        </TableRow>
      </TableHead>
    );
  }
  /*  const renderValue = (value: string) => {
    if (!value) {
      return '----';
    }
    if (value?.length > 30 && typeof value === 'string') {
      return `${value.slice(0, 50)}...`;
    }
    return value;
  }; */
  const emptyComponent = <div className="text-lg text-red-250 font-semibold">{emptyText}</div>;
  if (data.length === 0) {
    if ((search.search && called) || !loading) return emptyComponent;
  }
  return (
    <Box className="overflow-auto">
      <Paper sx={{ width: '100%', mb: 5 }} className="border-blue-150 border bg-white">
        <TableContainer className="w-4/5">
          <Table className="w-4/5 max-w-full" sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
            <EnhancedTableHead />
            <TableBody>
              {data.map((row, i) => {
                return (
                  <TableRow
                    onClick={() => {
                      if (onRowClick) onRowClick(row, i);
                    }}
                    style={{
                      height: 50,
                      padding: 30,
                    }}
                    key={i}
                    className={classNames(
                      'class3 hover:bg-grey-50 ',
                      onRowClick && 'cursor-pointer',
                      getRowClassName && getRowClassName(row),
                    )}
                  >
                    {headers.map((header, index) => {
                      let value: any = header.dataIndex ? row[header.dataIndex] : null;
                      if (header.render) {
                        value = header.render(row, i);
                      }
                      const paddingLeft = index === 0 ? 30 : 0;
                      return (
                        <TableCell
                          style={{
                            paddingRight: 20,
                            paddingLeft,
                            color: 'rgba(0, 0, 0, 0.5)',
                          }}
                          padding="checkbox"
                          key={header.key}
                          className={classNames('class4 px-6 py-4 whitespace-nowrap', header.className)}
                        >
                          {value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[10, 50, 100]}
          component="div"
          count={count}
          rowsPerPage={perPage}
          page={page - 1}
          onPageChange={(e, pages) => handleChangePage(pages + 1)}
          onRowsPerPageChange={(e) => handleChangeRowsPerPage(Number(e.target.value))}
        />
      </Paper>
    </Box>
  );
};
export default TableData;

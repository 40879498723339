import { RouteComponentProps, useLocation } from 'react-router-dom';
import { useUsers, useRegister, useUpdateUserAdmin, useLazyUser, useStatusUser } from 'requests/auth';
import moment from 'moment';
import Crud from 'components/ui/Crud/Crud';
import AdminForm from 'components/common/forms/AdminForm';
import { Switch } from '@mui/material';
import { decodeUri } from 'utils/url';
import Button from 'components/Button';
import DownloadIcon from '@mui/icons-material/Download';

const renderTitle = (role: string) => {
  if (role === 'lab') {
    return 'Laboratoire';
  }
  if (role === 'admin') {
    return 'Admin';
  }

  return 'Utilisateur';
};

const DashboardAdmin = (props: RouteComponentProps) => {
  const location = useLocation();
  const search = decodeUri(location.search);

  const { refetch } = useUsers({
    variables: {
      page: Number(search.page) || 1,
      perPage: Number(search.perPage) || 50,
      search: search.search,
    } as any,
    fetchPolicy: 'network-only',
  });
  const [updateStatus] = useStatusUser({
    onCompleted: () => {
      refetch();
    },
  });
  const updateStatusFn = (status: boolean, id: string) => {
    updateStatus({ variables: { id, is_active: status ? '1' : '0' } });
  };

  const handelDownloadUserCsv = (searchWord: string) => {
    const link = document.createElement('a');
    if (searchWord) {
      link.href = `${process.env.REACT_APP_BACKEND_URL?.replace('graphql', 'user-csv')}?search=${searchWord}`;
    } else {
      link.href = process.env.REACT_APP_BACKEND_URL?.replace('graphql', 'user-csv') || '';
    }
    link.target = '_self';
    link.click();
  };

  return (
    <div className="w-full flex-1 flex flex-col relative">
      <Crud
        confirmationSuccess="Un utilisateur a été ajouté avec succès"
        {...props}
        list={useUsers}
        headerActions={
          <div className="w-full flex justify-end pr-2">
            <Button
              className="hover:bg-blue-600  text-blue-600 border-2 border-blue-600 border-dashed hover:outline-none hover:text-white font-medium "
              onClick={() => handelDownloadUserCsv(search.search)}
            >
              <div className="flex items-center justify-center gap-5 w-full">
                <div className="w-6">
                  <DownloadIcon />
                </div>
                <div className="w-28 text-sm min-w-max md:hidden "> Exportation CSV</div>
              </div>
            </Button>
          </div>
        }
        headers={[
          {
            title: 'Nom et prénom',
            render: (record) => {
              const name = record.firstName || record.lastName ? `${record.firstName} ${record.lastName}` : '';
              return name;
            },
            key: 'name',
          },
          {
            key: 'createdAt',
            title: "Date d'ajout",
            render: (record) => {
              const date = moment(Number(record.createdAt)).format('DD/MM/YYYY');
              return date;
            },
          },
          {
            key: 'email',
            title: 'Email',
            dataIndex: 'email',
          },
          {
            key: 'last_login',
            title: 'Dernière connexion',
            render: (record) => {
              const dateLastLogin = new Date(record.last_login);
              return record.last_login ? moment(Number(dateLastLogin)).format('DD/MM/YYYY') : '(pas encore connecté)';
            },
          },
          {
            key: 'role',
            title: 'Rôle',
            render: (record) => {
              return renderTitle(record?.role);
            },
          },

          {
            key: 'lab',
            title: 'Laboratoire',
            render: (record) => {
              return record.role === 'lab' ? record.lab?.name : '';
            },
          },
          {
            key: 'status',
            title: 'Statut',
            render: (record) => {
              return (
                <Switch
                  defaultChecked
                  checked={record.is_active === '1'}
                  onChange={(e) => updateStatusFn(e.target.checked, record.id)}
                />
              );
            },
          },
        ]}
        Form={AdminForm}
        create={useRegister}
        update={useUpdateUserAdmin}
        get={useLazyUser}
        numberPerPage={50}
      />
    </div>
  );
};

export default DashboardAdmin;

import React from 'react';

interface Props {
  display: string;
}
const Editorial22 = ({ display }: Props) => {
  const DisplayCondition = 'Éditorial 2022';

  return (
    <div
      id="edt-2022"
      style={{ display: display === DisplayCondition ? 'block' : 'none' }}
      className="text-sm text-blue-600 font-regular"
    >
      {/* eslint-disable */}
      <p className="p-1 text-black leading-6	">
        <p className="text-center	text-lg pb-4 leading-8">
          {' '}
          <span className="text-yellow font-semibold">Flashez</span> (le code sur l’étiquette) ! <br />
          <span className="text-yellow font-semibold">Inhalez</span> (le médicament) ! <br />
          <span className="text-yellow font-semibold">Respirez</span> (la vie à pleins poumons) !
        </p>
        <span className="ml-1 "> La respiration </span>
        (dans le sens impropre que le langage courant donne à ce terme, c’est-à-dire « l’activité ventilatoire » qui
        anime la cage thoracique de mouvements cycliques, par opposition à la « vraie » respiration, celle de la cellule
        ; pardon aux physiologistes de céder à une certaine facilité de langage), la respiration donc, est une drôle de
        fonction végétative. Comme les autres fonctions de ce type, elle est automatique, permanente, autorégulée, et
        inconsciente. Mais à la différence des autres fonctions vitales, elle peut être détournée de sa fonction
        homéostatique pour exécuter des manœuvres respiratoires volontaires, ou des actions qui utilisent le système
        respiratoire, mais n’ont rien à voir avec la respiration. Ainsi, il n’est pas possible d’arrêter ou d’accélérer
        son cœur ou son transit intestinal volontairement, mais il est possible d’arrêter de respirer, d’accélérer sa
        respiration, de prendre sur commande une grande inspiration, etc. Cette possibilité, incroyable quand on y
        réfléchit, tient à l’organisation anatomique et physiologique de la commande ventilatoire (ah, voici notre
        physiologiste qui refait surface ; commande respiratoire). En effet, celle-ci est située à l’extérieur de
        l’organe qui en est la cible, au lieu d’y être intégrée. Les poumons sont ainsi passifs, leurs variations de
        volume dépendant de muscles striés qui « prennent leurs ordres » d’oscillateurs neuronaux situés dans le système
        nerveux central, au niveau du tronc cérébral, ordres ensuite relayés par des motoneurones spinaux et des « nerfs
        respiratoires ». C’est très différent de l’organisation cardiaque ou intestinale, caractérisée par un système
        nerveux « autonome », incorporé à l’organe lui-même. Un cœur peut battre hors de l’organisme. Hors de
        l’organisme, les poumons resteront mous. Au grand bonheur du proverbial chat. Le circuit bulbospinal qui régit
        l’automatisme ventilatoire peut être court-circuité (temporairement) par un circuit corticospinal, qui permet la
        réalisation de toutes les actions qui ont été énumérées ci-dessus. C’est grâce à ce circuit que l’appareil
        respiratoire a pu, au fil de l’évolution, devenir un outil indispensable à la communication interhumaine. En
        effet, sans contrôle cortical de la respiration, impossible de parler, de chanter, de siffler, de jouer de la
        cornemuse, toutes actions qui requièrent de repousser en arrière-plan l’automatisme respiratoire. C’est aussi
        grâce à ce circuit qu’il est possible de nager le crawl, de peindre à la paille, de souffler le verre ou les
        bougies du gâteau d’anniversaire ou des bulles de savon ou les aigrettes d’un pissenlit, de tirer à la
        sarbacane, de pulvériser certaines maisons de petits cochons… Le contrôle corticospinal de la respiration est
        extrêmement fin, au point de pouvoir être utilisé pour interagir de façon sophistiquée avec des dispositifs
        électromécaniques ou des jeux vidéos (« commande au souffle »). À ce stade de la lecture, vous êtes sans doute
        un certain nombre à vous demander s’il n’y a pas une erreur éditoriale et si c’est bien du guide Zéphir qu’il
        est question. Rassurez-vous la réponse est oui. Car le contrôle corticospinal de la respiration est crucial pour
        les pneumologues et leurs patients. C’est l’ennemi quand il permet de tirer sur sa clope et de faire des ronds
        de fumée. C’est l’allié quand il permet la réalisation des explorations fonctionnelles respiratoires, ou la
        prise de traitements inhalés de l’asthme ou de la BPCO. Dans ces deux derniers cas, il est nécessaire de
        réaliser des manœuvres assez complexes du point de vue neuro-respiratoire. Dans le cas particulier des
        traitements inhalés, il faut coupler ces manœuvres respiratoires avec des actions locomotrices, ce qui induit un
        niveau de complexité encore supérieur. <br /> <br />
        L’une des caractéristiques de la commande corticale des muscles respiratoires est la facilité avec laquelle des
        personnes qui n’ont jamais fait certaines manœuvres sont capables de les maîtriser. Il faut très peu d’essais
        pour arriver à les réaliser de façon adéquate et reproductible, surtout si elles sont bien expliquées et
        qu’elles font l’objet d’un « feedback » de la part d’un tiers. Apprendre à quelqu’un à faire correctement une
        manœuvre expiratoire forcée est facile. Apprendre à quelqu’un à prendre correctement une bouffée de Ventoline
        est facile. Facile ? Facile, si cette personne dispose de sa pleine capacité à interférer avec les automatismes
        respiratoires, qui doivent, comme on l’a vu plus haut, temporairement être mis à l’écart. Chez un individu sain,
        il faut en effet plusieurs dizaines de secondes avant que l’automatisme respiratoire ne vienne « réclamer son dû
        » et rappeler à l’organisme qu’il y a du dioxyde de carbone à éliminer (lorsque l’on réalise une apnée
        volontaire, les 25 ou 30 premières secondes sont « confortables », on ne ressent rien). Mais lorsque l’appareil
        respiratoire est malade et n’effectue plus sa fonction normalement, ce délai se raccourcit. L’importance de
        respirer, et la difficulté que cela engendre, l’insupportable dyspnée qui caractérise la totalité des affections
        respiratoires, privent les patients de la souplesse et de la fluidité respiratoires qui sont l’apanage des «
        normaux ». La parole devient difficile, plus monotone et « essoufflante ». Il reste beaucoup de bougies allumées
        sur le gâteau. La maison du petit cochon n’a pas besoin d’être en briques pour rester debout. Les « EFR »
        deviennent une épreuve épuisante. La prise correcte du traitement inhalé n’est plus un jeu d’enfant, mais un
        casse-tête d’autant plus délicat que les enjeux en deviennent cruciaux, enjeu de l’efficacité symptomatique,
        enjeu des exacerbations. Et on ne parle pas de la nécessité, parfois, de réaliser ce casse-tête avec des doigts
        déformés par l’arhtrose. Prendre correctement un traitement inhalé, c’est crucial pour les patients souffrant de
        maladies respiratoires, mais cela peut être très difficile pour eux. La facilité d’apprentissage évoquée plus
        haut manque à l’appel. Il faut donc passer du temps à apprendre (pour le patient), à enseigner et vérifier (pour
        le prescripteur). Comme chacun le sait, ce temps manque au prescripteur. Et comme chacun a pu le constater au
        fil des années, le sujet s’est complexifié. En effet, même si faciliter une prise correcte du traitement fait
        partie des objectifs de l’industrie pharmaceutique qui développe les traitements inhalés, la multiplication des
        produits, des dispositifs, des modes d’emploi est un obstacle de plus en plus difficile à surmonter sur la voie
        d’une prise correcte de ces traitements. Or, on sait qu’une prise incorrecte est un facteur d’inobservance
        (puisqu’elle prive le patient des bénéfices symptomatiques du traitement – pourquoi je continuerais à prendre un
        « machin » qui « ne fait rien » (sic) –) et un facteur de risque de la survenue d’exacerbations (jusqu’à 50 %
        d’exacerbations excédentaires chez les patients qui commettent des « erreurs critiques » lors de la prise de
        leur traitement). Comment résoudre cette équation ? En remplaçant les traitements inhalés par des traitements
        oraux, évidemment (ou administrés par n’importe quelle autre voie, par exemple sous-cutanée, qui n’expose pas à
        une « perte de charge » de la substance active pouvant atteindre 80 % de la dose délivrée). Ce n’est pas pour
        tout de suite, mais cela doit rester présent à l’esprit. En attendant, en améliorant l’éducation thérapeutique,
        et en en déchargeant le prescripteur de première ligne qui n’a pas le temps de la faire correctement, et qui
        n’en a pas non plus les moyens, perdu qu’il est au milieu de 65 combinaisons différentes de produits et de
        dispositifs (oui, 65 ; 39 en 2012 ; incroyable évolution, avec des ajouts constants et jamais aucun retrait), ne
        disposant pas des démonstrateurs placebo pour cette kyrielle de combinaisons, etc. Depuis plus de 10 ans
        maintenant, c’est l’objectif de la gamme d’outils Zéphir que d’aider ainsi prescripteurs et patients à tirer le
        meilleur parti des traitements inhalés de l’asthme et de la BPCO. Le succès de Zéphir a largement démontré son
        adéquation à la problématique visée. L’élément le plus emblématique de ce succès est représenté par les vidéos
        Zéphir, en libre accès sur le site de la Société de Pneumologie de Langue Française qui porte cette action, et
        reprises par de très nombreux partenaires, dont, non des moindres, le dictionnaire Vidal® et ses déclinaisons.
        Pour autant, Zéphir aide à comprendre, éduque, mais ne vérifie pas, ne donne pas de retour d’information. Il a
        été démontré que vérification et retour d’information sont des outils puissants d’amélioration des résultats. La
        télémédecine aujourd’hui, l’intelligence artificielle et l’analyse du mouvement demain, viendront compléter
        Zéphir et optimiser encore la prise des traitements inhalés de l’asthme et de la BPCO, soyons-en convaincus.{' '}
        <br /> <br />
        Pour cette nouvelle édition 2022, un immense merci pour leur travail patient, organisé, exhaustif à Valery
        Trosini-Desert, Marie Antignac, et Marie-Hélène Becquemin, qui portent Zéphir sur leurs épaules depuis le début.
        Et à la Société de Pneumologie de Langue Française pour avoir accepté de labelliser Zéphir dès 2012 et continuer
        d’en faire bénéficier la communauté médicale et les patients. Longue vie à Zéphir. <br /> PS : il est impossible
        de parler de traitements inhalés de l’asthme et de la BPCO à la fin de l’année 2021 sans évoquer l’émotion que
        suscite l’acquisition par une firme cigarettière majeure – Philip Morris – d’une société qui conçoit et
        commercialise des dispositifs d’inhalation destinés au traitement de l’asthme et de la BPCO – Vectura – dont
        certains sont utilisés dans des spécialités pharmaceutiques décrites dans Zéphir. Il est évidemment paradoxal de
        voir « l’ennemi » prendre possession d’un outil de « l’allié » (voir ci-dessus), et il revient à chaque
        prescripteur de juger, avec son patient, de l’influence que cet évènement doit avoir sur les schémas
        thérapeutiques choisis. Mais au-delà de ce paradoxe, cette acquisition laisse présager que demain, de nouvelles
        modalités de consommation du tabac pourraient ressembler aux modalités d’administration des médicaments qui
        soignent les maladies liées à ce même tabac. Un comble ! Et une question épineuse, qu’il ne faut certainement
        pas esquiver, et dont le traitement doit sans doute faire appel à des compétences sortant du cadre de la lutte
        antitabac traditionnelle, y compris sociologiques, anthropologiques et ethnologiques.
      </p>
      <p className="pt-4 pb-2 font-bold text-lg text-blue"> Le comité scientifique ZÉPHIR </p>

      <p className="text-black leading-6 ">
        {' '}
        Valéry TROSINI-DÉSERT <br />
        Pneumologue, Praticien Hospitalier, Service de Pneumologie (département R3S), Groupe Hospitalier APHP. Sorbonne
        Université, hôpital Pitié-Salpêtrière, Paris <p />
        <p className="pt-2">
          Marie ANTIGNAC <br />
          Pharmacien – Cheffe Service de pharmacie Pitié-Salpêtrière Charles Foix. <br />
          Coordinatrice du diplôme universitaire d'éducation thérapeutique du patient, de l'université Paris 11
        </p>
        <p className="pt-2">
          {' '}
          Marie-Hélène BECQUEMIN <br /> Pneumologue, ancien Maître de Conférence des Universités (Paris 7) - ancien
          Praticien Hospitalier, Service d’Explorations Fonctionnelles de la Respiration, de l’Exercice et de la
          Dyspnée, Groupe Hospitalier Pitié-Salpêtrière Charles Foix, Paris. <br /> Membre du GAT (Groupe Aérosol
          Thérapie de la Société de Pneumologie de Langue Française)
        </p>
        <p className="pt-2">
          Thomas SIMILOWSKI <br /> Pneumologue, Professeur des Universités-Praticien Hospitalier, Département R3S
          (Respiration, Réanimation, Réhabilitation, Sommeil), Groupe Hospitalier APHP. Sorbonne Université, hôpital
          Pitié-Salpêtrière, Paris
        </p>
      </p>
    </div>
  );
};

export default Editorial22;

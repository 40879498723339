import logo from 'assets/svg/user-logo.svg';
import Button from 'components/Button';
import { useHistory } from 'react-router-dom';
import { User } from 'requests/types';
import classNames from 'utils/classNames';

interface HeaderLabInterface {
  user: User | null;
}

export const returnText = (user: User) => {
  if (user?.role === 'lab') return 'Laboratoire';
  return 'Admin';
};

const HeaderLab = ({ user }: HeaderLabInterface) => {
  const isAdmin = user?.role === 'admin';
  const isLab = user?.role === 'lab';
  const history = useHistory();

  return (
    <>
      <div
        className={classNames(
          'md:hidden fixed flex flex-1 items-center justify-between ',
          'h-12 overflow-hidden w-screen top-0 z-50 transition-transform duration-700 ',
          'bg-blue-800',
          'pl-28 2xl:pl-0  pr-8 2xl:pr-0 py-4',
        )}
      >
        <div className="flex items-center gap-2 text-white 2xl:ml-12">
          Espace {user && returnText(user)} -{' '}
          <span className="font-bold text-yellow-200">
            {user?.email}{' '}
            {isLab && (
              <>
                <span className="font-normal text-white">du laboratoire </span>
                {user?.lab?.name}
              </>
            )}
          </span>
        </div>
        {isAdmin && (
          <Button
            style={{
              width: 200,
              fontSize: 14,
              height: 35,
              fontWeight: 'bold',
            }}
            onClick={() => history.replace('/admin')}
            className="text-sm gap-4 bg-yellow-200 text-white  hover:bg-yellow mr-24 2xl:mr-12"
          >
            <img width={20} src={logo} alt="" />
            Dashboard Admin
          </Button>
        )}
      </div>
    </>
  );
};

export default HeaderLab;

import React from 'react';
import Img2 from '../../assets/png/image2.png';
import Img3 from '../../assets/png/image3.png';

interface Props {
  display: string;
}
const Criteres = ({ display }: Props) => {
  const DisplayCondition = 'Criteres';
  // eslint-disable
  return (
    <div id="critere" style={{ display: display === DisplayCondition ? 'block' : 'none' }}>
      {/* eslint-disable */}
      <h2 className="h2-1 right-align text-right text-blue-900">Valéry Trosini-Désert</h2>
      <p className="text-sm py-3">
        De nombreux médicaments pour le traitement de l’asthme et des BPCO sont administrés par inhalation. Les
        avantages de ce modèle d’administration locale sont clairs : d’une part, cela permet d’utiliser de petites
        quantités de principes actifs grâce à une délivrance ciblée au niveau de l’arbre trachéo-bronchique et d’autre
        part, cela permet d’éviter en grande partie le passage systémique du principe actif et les effets indésirables
        qui y sont liés. Enfin cette voie d’administration permet une rapidité d’action des molécules
        pharmacothérapeutiques. C’est pourquoi, les directives internationales pour le traitement de l’asthme et des
        BPCO optent résolument pour l’inhalation comme voie d’administration [<span className="navref">1</span>,
        <span className="navref">2</span>]. Cette voie a toutefois pour inconvénient d’exiger du patient une plus grande
        dextérité par rapport à la prise orale d’un médicament. L’efficacité d’un traitement par inhalation dépendra
        donc de l’apprentissage et de la mise en oeuvre des techniques d’inhalation par le patient.
      </p>
      <p className="text-sm py-3">
        Trois éléments sont à prendre en considération pour prescrire une thérapeutique inhalée prête à l’emploi :
      </p>
      <ul className="list-disc  text-sm py-3 px-4">
        <li>
          <span className="bold-color text-blue-900">La classe pharmacothérapeutique :</span> Le prescripteur a le choix
          parmi trois classes pharmacothérapeutiques seules ou en associations (anticholinergiques, b2 mimétiques de
          courte durée d’action et longue durée d’action, et les corticoïdes). Le choix de la prescription d’une ou
          plusieurs molécules repose sur le type de la maladie et son degré de sévérité. Ce choix se réfère au consensus
          international de GOLD [<span className="navref">2</span>], pour la BPCO et les recommandations internationales
          de GINA [<span className="navref">1</span>] pour l’asthme.
        </li>
        <li>
          <span className="bold-color text-blue-900">Le patient et ses caractéristiques cliniques :</span> Le
          prescripteur doit prendre en considération les caractéristiques cliniques du patient telles que son âge, sa
          capacité à réaliser une coordination main bouche, sa capacité à générer un débit inspiratoire satisfaisant. A
          cela s’ajoutent sa compréhension des techniques d’inhalation et ses préférences devant le choix des formes
          présentées.
        </li>
        <li>
          <span className="bold-color text-blue-900">Le dispositif d’inhalation :</span> Le dispositif d’inhalation
          permet au patient de faire pénétrer le médicament au niveau de l’arbre trachéobronchique sous la forme de
          fines particules. Or, il existe à l’heure actuelle sur le marché une multitude de dispositifs de
          thérapeutiques inhalées prêtes à l’emploi pour une même classe pharmacologique. Par exemple il existe une
          demi-douzaine de dispositifs en ce qui concerne les b2 mimétiques de courte durée d'action. Si le choix du
          dispositif d'inhalation devrait en théorie être rationnel, en pratique courante il se fait davantage de
          manière empirique.
        </li>
      </ul>
      <p className="text-sm py-3">
        La panoplie des médicaments inhalés destinés aux patients asthmatiques ou BPCO se complique chaque année.
        <br />
        Ainsi en 2011, nous avons recensé sur le marché:
      </p>
      <ul className="itemColor list-disc text-sm py-3 px-6">
        <li>
          3 classes pharmacothérapeutiques (anticholinergiques, b2 mimétiques de courte durée d’action et longue durée
          d’action, et les corticoïdes) avec 11 DCI{' '}
          <span className="bold-color text-blue-900">(Dénomination Commune Internationale)</span> et 41 spécialités.
        </li>
        <li>3 classes de dispositifs avec 15 dispositifs différents</li>
        <li>72 présentations différentes.</li>
      </ul>
      <p className="text-sm py-3">
        Par ailleurs, les prix diffèrent beaucoup d’une spécialité à l’autre, même si elles sont toutes remboursables à
        65 %. Aucun générique de ces médicaments n’existe à l’heure actuelle sur le marché.
      </p>
      <h2 className="h2-1 text-blue-900 font-bold">Quel serait le dispositif idéal ?</h2>
      <p className="text-sm py-3">
        Les recommandations internationales sur l’asthme indiquent qu’un inhalateur idéal doit être portable, simple à
        manipuler sans recours à un système de propulsion externe (l’inspiration elle-même devrait déclencher
        l’inhalation). La coopération et la coordination requises doivent être minimales, ainsi que l’entretien. Les
        recommandations anglo-saxonnes mentionnent en outre l’adaptation du dispositif aux préférences du patient [
        <span className="navref">1</span>,<span className="navref">3</span>].
        <br />
        En ce qui concerne la BPCO, les recommandations françaises et internationales [<span className="navref">4</span>
        ,<span className="navref">2</span>] soulignent une attention particulière à la technique d’inhalation en
        fonction du patient, lequel éprouve potentiellement davantage de difficultés que les patients plus jeunes
        (asthmatiques, par exemple) à utiliser correctement certains inhalateurs.
      </p>
      <p className="text-sm py-3">
        Ainsi idéalement, le dispositif d’inhalation devrait [<span className="navref">5</span>] :
      </p>
      <ul className="itemColor list-disc text-sm py-3 px-6">
        <li>être de faible dimension ;</li>
        <li>être prêt à l’emploi et simple d’utilisation ;</li>
        <li>être déclenché par l’inspiration quel que soit le débit inspiratoire ;</li>
        <li>être conçu avec des doses reproductibles d’une inhalation à l’autre ;</li>
        <li>ne pas nécessiter de coordination main–bouche pour son utilisation ;</li>
        <li>
          disposer de mécanismes multiples et automatiques de contrôle de l’inhalation correcte par le patient lui-même
          (ressenti de la prise);
        </li>
        <li>posséder un compteur de dose ;</li>
        <li>
          être réutilisable et valable pour tous les médicaments surtout chez les patients qui ont plusieurs classes
          pharmacothérapeutiques associées dans leur traitement.
        </li>
      </ul>
      <p className="text-sm py-3">
        Cependant même avec des dispositifs de plus en plus performants, il faut garder à l’esprit en matière de
        traitement par voie inhalée que le succès revient pour 10 % au traitement et pour 90 % à l’éducation
        thérapeutique qui dans ce domaine améliore de façon significative la bonne prise des thérapeutiques inhalées [
        <span className="navref">6</span>,<span className="navref">7</span>,<span className="navref">8</span>,
        <span className="navref">9</span>] Environ 40 % des patients font au moins une erreur dans l’utilisation de leur
        dispositif. Les patients n’ayant jamais reçu d’instruction sur l’utilisation de leur dispositif d’inhalation et
        ceux ayant plus de deux types de dispositifs dans leur traitement font significativement plus d’erreur [
        <span className="navref">10</span>]. <br />
        Cela montre qu’il est nécessaire d’initier non seulement les médecins prescripteurs (généralistes, pneumologues)
        au maniement des dispositifs de thérapeutiques inhalées prêts à l’emploi mais aussi de former les infirmières et
        les pharmaciens.
      </p>
      <h2 className="h2-1 text-blue-900 font-bold">Comment choisir le dispositif idéal ?</h2>
      <p className="text-sm py-3">
        Divers auteurs ont proposé des « checks list » de critères prioritaires ou listes de questions à se poser pour
        guider le choix du dispositif d’inhalation prêt à l’emploi « idéal » mais tout en laissant le soin aux
        praticiens de les adapter à leur pratique et surtout à leurs patients au cas par cas [
        <span className="navref">11</span>,<span className="navref">12</span>].
      </p>
      <ul className="sous-num text-sm py-3">
        <li>
          <span className="itemStyleDeciaml ">1.</span>
          <span> Dans quel dispositif le médicament choisi est-il disponible ?</span>
        </li>
        <li>
          <span className="itemStyleDeciaml">2.</span>
          <span>
            Quel dispositif le patient est-il le plus susceptible d’utiliser de façon adéquate compte tenu de ses
            caractéristiques cliniques (âge, coordination main bouche, manoeuvre d’inhalation...) ?
          </span>
        </li>
        <li>
          <span className="itemStyleDeciaml">3.</span>
          <span> Pour quelle combinaison dispositif-médicament peut-on obtenir un remboursement ?</span>
        </li>
        <li>
          <span className="itemStyleDeciaml">4.</span>
          <span> Quels sont les dispositifs les moins coûteux ?</span>
        </li>
        <li>
          <span className="itemStyleDeciaml">5.</span>
          <span>
            {' '}
            Est-ce que tous les types de médicaments inhalés pour l’asthme et la BPCO prescrits au patient sont offerts
            avec le même genre de dispositif ?
          </span>
        </li>
        <li>
          <span className="itemStyleDeciaml">6.</span>
          <span>
            {' '}
            Quels dispositifs sont les plus pratiques pour le patient, la famille le personnel médical qui les utilise
            compte tenu du temps nécessaire pour administrer des médicaments et nettoyer le dispositif, et compte tenu
            de la portabilité du dispositif ?
          </span>
        </li>
        <li>
          <span className="itemStyleDeciaml">7.</span>
          <span> Quelle est la durée de vie du dispositif ?</span>
        </li>
        <li>
          <span className="itemStyleDeciaml">8.</span>
          <span>Est-ce que le patient de clinicien à une préférence pour un dispositif en particulier ?</span>
        </li>
      </ul>
      <p className="text-sm py-3">
        D’autres auteurs à défaut de proposer un algorithme décisionnel au sens strict, proposent, avant de choisir une
        thérapeutique inhalée prête à l’emploi d’évaluer deux critères principaux qui sont la capacité d’une
        <span className="bold-color">coordination main bouche</span> et le{' '}
        <span className="bold-color">débit inspiratoire</span>
        (inférieur ou supérieur à 30 L/min) du patient [<span className="navref">13</span>,
        <span className="navref">14</span>].
      </p>
      <ul className="itemColor list-disc text-sm py-3 px-6">
        <li>
          <span className="bold-color text-blue-900">La coordination main bouche</span> est un élément essentiel de la
          bonne prise des aérosols doseurs pressurisés car ceux-ci ont l’inconvénient d’une propulsion extrêmement
          rapide du médicament [<span className="navref">15</span>], et rend difficile la synchronisation du
          déclenchement de l’aérosol et de l’inhalation. Cette coordination « main bouche » peut être particulièrement
          difficile chez les personnes âgées, car en plus des tremblements des mains, il peut exister une diminution de
          la force musculaire ce qui est gênant pour appuyer sur le flacon doseur. à noter que l’emploi des chambres
          d’inhalation peut pallier à une mauvaise coordination main bouche éventuelle mais le système s’avère plus
          encombrant, d’utilisation parfois complexe avec un risque de moindre observance et expose l’émission de la
          solution active à une rétention par des phénomènes électrostatiques au sein de la chambre [
          <span className="navref">5</span>,<span className="navref">16</span>,<span className="navref">17</span>].
          <br />
          <br />
          La coordination main bouche est [<span className="navref">18</span>] : <br />–{' '}
          <span className="bold-color text-blue-900">Nécessaire</span> pour les aérosols doseurs pressurisés, <br />–{' '}
          <span className="bold-color text-blue-900">Nécessaire mais moins importante</span> pour l’inhalateur de
          brumisat ,<br />– <span className="bold-color text-blue-900">Non nécessaire</span> pour les aérosols doseurs
          pressurisés avec <br />
          chambre d’inhalation, les aérosols doseurs pressurisés autodéclenchés ainsi que pour les inhalateurs de poudre
          sèche.
        </li>
      </ul>
      <p className="text-sm py-3">
        En ce qui concerne <span className="bold-color text-blue-900">le débit inspiratoire</span> : les inhalateurs de
        poudre sèche, contrairement aux aérosols doseurs pressurisés, nécessitent un effort inspiratoire pour dissocier
        la poudre et former l’aérosol. Le débit inspiratoire nécessaire pour déclencher l’aérosol et générer un dépôt
        optimal des particules au sein de l’arbre trachéo-bronchique dépend de la résistance interne du dispositif. Les
        inhalateurs de poudre sèche commercialisés actuellement présentent des caractéristiques techniques différentes
        et donc des résistances différentes. Habituellement on classe les résistances des dispositifs en trois
        catégories [<span className="navref">14</span>] : <br />
        <span className="pad-left-15  align-none">
          – résistance interne faible nécessitant un débit inspiratoire optimal &gt; 90 L/min ;
        </span>{' '}
        <br />
        <span className="pad-left-15 align-none text-sm py-3">
          – résistance interne moyenne nécessitant un débit inspiratoire optimal entre 50 et 90 L/min ;
        </span>{' '}
        <br />
        <span className="pad-left-15 text-sm py-3">
          – résistance interne forte nécessitant un débit inspiratoire optimal &lt; 50 L/min ;
        </span>
      </p>
      <p className="text-sm py-3">
        Une revue de la littérature s’est intéressée à savoir si certaines situations cliniques (sujets âgés, syndrome
        obstructif aigu ou chronique de l’asthme et des BPCO) pouvait constituer une limite à l’utilisation de ce type
        de thérapeutiques inhalées [<span className="navref">19</span>]. Les études cliniques analysées dans cette revue
        indiquent que les inhalateurs de poudre sèche peuvent être utilisés dans la grande majorité des situations
        cliniques chez l’adulte sous réserve d’une éducation attentive des patients. <br />
        D’autres auteurs mettent en évidence une corrélation négative significative entre l’âge et le débit inspiratoire
        de pointe atteint avec les appareils ayant la plus forte résistance (p &lt; 0,0001). En analyse multivariée
        l’effet de l’âge sur le débit inspiratoire est indépendant de la gravité de la BPCO [
        <span className="navref">20</span>].
        <br />
        Une autre étude souligne que le débit inspiratoire nécessaire pour désagréger la poudre d’un inhalateur de
        poudre sèche et générer l’aérosol, n’est pas corrélé avec le rapport de Tiffeneau ou la présence d’un syndrome
        obstructif mais avec l’âge (probablement à cause d’une diminution des forces des muscles respiratoires,
        difficulté d’effectuer une manoeuvre ventilatoire en terme de coopération et coordination) [
        <span className="navref">21</span>].
      </p>
      <h2 className="h2-1 text-blue-900 font-bold">Comment mesurer le débit inspiratoire en pratique courante ?</h2>
      <p className="text-sm py-3">
        Plusieurs outils ou dispositifs portatifs existent sur le marché [<span className="navref">22</span>] : Il
        existe plusieurs outils d’entraînement pour savoir si le patient peut générer un débit inspiratoire suffisant
        pour la bonne prise d’un inhalateur de poudre. Ces outils peuvent être spécifiques d’un dispositif en
        particulier (par exemple le turbuhaler® « siffleur » pour le turbuhaler® [cf. photo 1] ou le dispositif testeur
        diskus® [cf. photo 2] pour les diskus®. Ces dispositifs émettent un sifflement pour signaler la bonne
        utilisation du dispositif et il signale ainsi que l’inspiration du patient est suffisante pour utiliser
        correctement le dispositif. Ces outils sont à usage unique. <br />
        D’autre part il existe des outils d’entraînement qui concernent plusieurs types de dispositifs : on peut
        retrouver commercialisé en France un débitmètre inspiratoire portatif (check in dial®) [cf. photo 3] qui permet
        d’identifier l’inhalateur de poudre le plus approprié aux patients en fonction de leur débit inspiratoire. Ce
        dispositif simule les résistances internes des différents dispositifs inhalateurs de poudre existants et mesure
        le débit inspiratoire du patient. Il possède un embout jetable ou stérilisable ce qui permet de l’utiliser en
        pratique courante en consultation.
      </p>
      <div className="clear" />
      <div className="eight centered flex justify-center">
        <br />
        <img src={Img2} alt="Comment mesurer le débit inspiratoire en pratique courante" />
      </div>
      <p className="text-sm py-3">
        Ainsi en fonction des deux données que sont la coordination main bouche et le débit inspiratoire on peut
        proposer le logigramme suivant (adapté de Laube et al., 2011) [<span className="navref">14</span>].
      </p>
      <div className="clear" />
      <div className="eight centered flex justify-center">
        <br />
        <img src={Img3} alt="Coordination main bouche facilitée" title="Coordination main bouche facilitée" />
      </div>
      <h2 className="h2-1 text-blue-900 font-bold">Bibliographie</h2>
      <ul className="num text-sm py-3 gap-1">
        <li className="px-2 py-1">
          <span className="itemStyleDeciaml">1.</span>
          <span>
            Global Initiative for Asthma (GINA). Global strategy for asthma management and prevention. NHLBI/WHO
            workshop report. National Institutes of Health, National Heart, Lung and Blood Institute. Janvier 1995, NIH
            publication number 95-3659.
          </span>
        </li>
        <li className="px-2 py-1">
          <span className="itemStyleDeciaml">2.</span>
          <span>
            GOLD. Globale initiative for chronique obstructive lung disease. Mise à jour de 2009. Disponible sur http :
            //www.goldcopd.com/.
          </span>
        </li>
        <li className="px-2 py-1">
          <span className="itemStyleDeciaml">3.</span>
          <span>BTS, Scottish Intercollegiale Guidelines on the management of asthma. Thorax 2003;58:11-94</span>
        </li>
        <li className="px-2 py-1">
          <span className="itemStyleDeciaml">4.</span>
          <span>
            Recommendation for the clinical practice management of COPD. Rev Mal Respir. 2010 Mai; 27(5):522-548.
          </span>
        </li>
        <li className="px-2 py-1">
          <span className="itemStyleDeciaml">5.</span>
          <span>
            Virshow JC, Crompton GK, Dal Negro R, Pedersen S, Magnan A, Seidenberg J et al. Importance of inhaler
            devices in the management of airway disease. Respire Med 2008;102:10-9
          </span>
        </li>
        <li className="px-2 py-1">
          <span className="itemStyleDeciaml">6.</span>
          <span>
            Fink JB. Inhalers in asthma management: is demonstration the key to compliance? Respir Care. 2005 Mai;
            50(5):598-600.
          </span>
        </li>
        <li className="px-2 py-1">
          <span className="itemStyleDeciaml">7.</span>
          <span>
            Dubus JC, Bosdurea E, Andrieu V. Les systèmes d’inhalation en 2006. Revue française d’allergologie et
            d’immunologie clinique 2006 (46) :556–559
          </span>
        </li>
        <li className="px-2 py-1">
          <span className="itemStyleDeciaml">8.</span>
          <span>
            Van der Palen J, Klein JJ, Kerkhoff AH, van Herwaarden CL, Seydel ER. Evaluation of the long-term
            effectiveness of three instruction modes for inhaling medicines. Patient Educ Couns. 1997 Déc; 32(1
            Suppl):S87-95.
          </span>
        </li>
        <li className="px-2 py-1">
          <span className="itemStyleDeciaml">9.</span>
          <span>
            Lavorini F, Magnan A, Dubus JC, Voshaar T, Corbetta L, Broeders M, et al. Effect of incorrect use of dry
            powder inhalers on management of patients with asthma and COPD. Respir Med. 2008 Avr; 102(4):593-604.
          </span>
        </li>
        <li className="px-2 py-1">
          <span className="itemStyleDeciaml">10.</span>
          <span>
            Rootmensen GN, van Keimpema ARJ, Jansen HM, de Haan RJ. Predictors of incorrect inhalation technique in
            patients with asthma or COPD: a study using a validated videotaped scoring method. J Aerosol Med Pulm Drug
            Deliv. 2010 Oct; 23(5):323-328.
          </span>
        </li>
        <li className="px-2 py-1">
          <span className="itemStyleDeciaml">11.</span>
          <span>
            Dolovich MB, Ahrens RC, Hess DR, Anderson P, Dhand R, Rau JL, et al. Device selection and outcomes of
            aerosol therapy: Evidence-based guidelines: American College of Chest Physicians/American College of Asthma,
            Allergy, and Immunology. Chest 2005 Jan; 127(1):335-371.
          </span>
        </li>
        <li className="px-2 py-1">
          <span className="itemStyleDeciaml">12.</span>
          <span>
            Vincken W, Dekhuijzen PR, Barnes P. The ADMIT series - Issues in inhalation therapy. 4) How to choose
            inhaler devices for the treatment of COPD. Prim Care Respir J. 2010 Mar; 19(1):10-20.
          </span>
        </li>
        <li className="px-2 py-1">
          <span className="itemStyleDeciaml">13.</span>
          <span>
            Chapman KR, Voshaar TH, Virchow JC. Inhaler choice in primary practice. European Respiratory Review 2005 Déc
            1; 14(96):117 -122.
          </span>
        </li>
        <li className="px-2 py-1">
          <span className="itemStyleDeciaml">14.</span>
          <span>
            Laube B, Janssens H, de Jongh F, Devadason S, Dhand R, Diot P, et al. ERS/ISAM task force consensus
            statement. What the pulmonary specialist should know about the new inhalation therapies.Eur Respir J. 2011
            Jun; 37(6):1308-31
          </span>
        </li>
        <li className="px-2 py-1">
          <span className="itemStyleDeciaml">15.</span>
          <span>
            Giraud V, Roche N. Misuse of corticosteroid metered-dose inhaler is associated with decreased asthma
            stability. Eur Respir J. 2002 ; 19 :246-51
          </span>
        </li>
        <li className="px-2 py-1">
          <span className="itemStyleDeciaml">16.</span>
          <span>Newman SP. Principles of metered-dose inhaler design. Respir Care. 2005 Sep; 50 (9):1177-1190.</span>
        </li>
        <li className="px-2 py-1">
          <span className="itemStyleDeciaml">17.</span>
          <span>Newman SP. Inhaler treatment options in COPD. Eur Respir Rev. 2005; 14(96):102–8</span>
        </li>
        <li className="px-2 py-1">
          <span className="itemStyleDeciaml">18.</span>
          <span>
            Henriet A, Marchand-Adam S, Mankikian J, Diot P. Respimat®, premier inhalateur « Soft Mist » : intérêt dans
            la prise en charge de la BPCO. Rev Mal Respir. 2010 Déc; 27(10):1141-1149.
          </span>
        </li>
        <li className="px-2 py-1">
          <span className="itemStyleDeciaml">19.</span>
          <span>
            Roche N, Dessanges J, Dubus J. Les poudres sèches dans les situations cliniques extrêmes. Rev Mal Respir.
            2005 Sep; 22(4):605-613
          </span>
        </li>
        <li className="px-2 py-1">
          <span className="itemStyleDeciaml">20.</span>
          <span>
            Jarvis S, Ind PW, Shiner RJ. Inhaled therapy in elderly COPD patients; time for reevaluation? Age Ageing.
            2007 Mar; 36(2):213-8. Epub 2007 Jan 31.
          </span>
        </li>
        <li className="px-2 py-1">
          <span className="itemStyleDeciaml">21.</span>
          <span>
            Janssens W, VandenBrande P, Hardeman E, De Langhe E, Philps T, Troosters T, et al. Inspiratory flow rates at
            different levels of resistance in elderly COPD patients. Eur. Respir. J. 2008 Jan; 31(1):78-83.
          </span>
        </li>
        <li className="px-2 py-1">
          <span className="itemStyleDeciaml">22.</span>
          <span>
            Lavorini F, Levy ML, Corrigan C, Crompton G. The ADMIT series - issues in inhalation therapy. 6) Training
            tools for inhalation devices. Prim Care Respir J. 2010 Déc;19(4):335341
          </span>
        </li>
      </ul>
    </div>
  );
};

export default Criteres;

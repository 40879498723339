import cardIdContext from 'contexts/CardIdContext';
import { Fragment, FunctionComponent, useContext } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'utils/classNames';
import { encodeUri } from 'utils/url';
import pdfIcon from '../../../assets/svg/pdf.svg';

export interface DataType {
  name: string;
  id: string;
}
export type MedicationProps = {
  dci?: DataType | undefined;
  classPharma?: DataType | undefined;
  deviceName?: DataType | undefined;
  deviceType?: DataType | undefined;
  useControl?: string | undefined;
  deviceDiameter?: string | undefined;
  doseName?: { name: string } | any;
  dosageNote?: string | undefined;
  propellantGaz?: string | undefined;
  excipient?: string | undefined;
  doseNumber?: string | undefined;
  refill?: string | undefined;
  doseRemaining?: string | undefined;
  doseTaken?: string | undefined;
  readyFirstUse?: string | undefined;
  ready?: string | undefined;
  handToMouthCoordination?: string | undefined;
  inhalationChamber?: string | undefined;
  modalitInhalation?: string | undefined;
  modalitutilisation?: string | undefined;
  actionExpire?: string | undefined;
  actionDuration?: string | undefined;
  doseReproducibility?: string | undefined;
  refillCartridges?: string | undefined;
  coMarketing?: string | undefined;
  labName?: string | undefined;
  price?: string | undefined;
  repayment?: string | undefined;
  existence?: string | undefined;
  pdf?: string | undefined;
  opinion?: string | undefined;
  resistance?: string | undefined;
  durationBronchodilator?: string | undefined;
  percentageFineParticles?: string | undefined;
  expireBronchodilator?: string | undefined;
  expires?: string | undefined;
  col?: boolean;
  MobileSensorExistence?: string | undefined;
  updatedDate?: string | undefined;
};

const MedicationTable: FunctionComponent<MedicationProps> = ({
  propellantGaz,
  classPharma,
  dci,
  deviceName,
  deviceType,
  useControl,
  deviceDiameter,
  doseName,
  dosageNote,
  excipient,
  doseNumber,
  refill,
  doseRemaining,
  doseTaken,
  readyFirstUse,
  ready,
  handToMouthCoordination,
  inhalationChamber,
  modalitInhalation,
  refillCartridges,
  coMarketing,
  modalitutilisation,
  actionExpire,
  actionDuration,
  doseReproducibility,
  price,
  labName,
  repayment,
  pdf,
  opinion,
  existence,
  resistance,
  durationBronchodilator,
  percentageFineParticles,
  expireBronchodilator,
  expires,
  col,
  MobileSensorExistence,
  updatedDate,
  ...rest
}) => {
  const columnA = [
    {
      title: 'Classe pharmacothérapeutique :',
      des: classPharma,
      pathname: '/classes',
      uri: 'classe',
    },
    {
      title: 'DCI :',
      des: dci,
      pathname: '/dci',
      uri: 'dci',
    },
    {
      title: ' Type de Dispositif :',
      des: deviceType,
      pathname: '/types',
      uri: 'deviceType',
    },
    {
      title: ' Nom de dispositif :',
      des: deviceName,
      pathname: '/devices',
      uri: 'device',
    },
    {
      title: 'Dosage : ',
      des: doseName,
      note: dosageNote,
      uri: '',
    },
    {
      title: 'Mise à jour   : ',
      des: { name: updatedDate },
      uri: '',
    },
  ].filter((e) => e?.des?.name?.length);

  const columnB = [
    {
      title: 'Gaz propulseur :',
      des: propellantGaz,
    },
    {
      title: 'Excipient(s):',
      des: excipient,
    },
    {
      title: ' Diamètre Aérodynamique Massique Médian :',
      des: deviceDiameter,
    },
    {
      title: ' Pourcentage de particules fines :',
      des: percentageFineParticles,
    },
    {
      title: 'Nombre de doses : ',
      des: doseNumber,
    },
    {
      title: 'Recharge(s) : ',
      des: refill,
    },
    {
      title: 'Visualisation du nombre de doses restantes :  ',
      des: doseRemaining,
    },
    {
      title: 'Visualisation du nombre de doses prises :  ',
      des: doseTaken,
    },
    {
      title: 'Date de péremption :  ',
      des: expires,
    },
    {
      title: 'Prêt à l’emploi : ',
      des: ready,
    },
    {
      title: 'Prêt à l’emploi dès la première utilisation :   ',
      des: readyFirstUse,
    },
    {
      title: '   Coordination main bouche : ',
      des: handToMouthCoordination,
    },
    {
      title: "Possibilité d'adaptation d'une chambre d'inhalation :  ",
      des: inhalationChamber,
    },
    {
      title: 'Résistance du dispositif :  ',
      des: resistance,
    },
    {
      title: " Modalité(s) d'inhalation :  ",
      des: modalitInhalation,
    },
    {
      title: ' Modalités d’utilisation :  ',
      des: modalitutilisation,
    },
    {
      title: '  Délai d’action :  ',
      des: actionExpire,
    },
    {
      title: ' Délai d’action du bronchodilatateur :  ',
      des: expireBronchodilator,
    },

    {
      title: "  Durée d'action du bronchodilatateur :  ",
      des: durationBronchodilator,
    },
    {
      title: '  Durée d’action :   ',
      des: actionDuration,
    },
    {
      title: '   Contrôle de la prise :  ',
      des: useControl,
    },
    {
      title: '  Reproductibilité de la dose :  ',
      des: doseReproducibility,
    },
    {
      title: "Existence d'un capteur connecté à une application mobile :",
      des: MobileSensorExistence,
    },
    {
      title: '  Laboratoire : ',
      des: labName,
    },
    {
      title: '  Co-marketing : ',
      des: coMarketing,
    },
    {
      title: ' Existence d’un générique : ',
      des: existence,
    },
    {
      title: ' Prix unitaire / Prix par Dose  :  ',
      des: price,
    },
    {
      title: ' Cartouches recharges :',
      des: refillCartridges,
    },
    {
      title: ' Remboursement :',
      des: repayment,
    },
  ];
  const { setCardId } = useContext(cardIdContext);
  return (
    <div
      className={classNames(
        'flex flex-col justify-center items-start w-full border lg:border-0	rounded-5border-blue-250 lg:text-base',
        col && ' border-none',
      )}
      {...rest}
    >
      <div
        className={classNames(
          'flex flex-col lg:flex-col justify-start items-start w-full lg:text-base',
          col ? 'py-2 lg:pb-0' : 'py-4 px-2 lg:pl-0 lg:pt-1 ',
        )}
      >
        {col && (
          <div className="flex flex-col justify-center items-start w-full gap-1">
            {columnA.map((i) => (
              <div key={i.title} className="text-sm 2xl:text-xs 2xl:leading-6 3xl:leading-6	lg:text-base sm:text-xs	">
                <span className="font-bold text-blue-600 ">{i.title} </span>
                {i.uri !== '' ? (
                  <Link to={{ pathname: i?.pathname, search: encodeUri({ [i.uri]: i?.des?.id || '' }) }}>
                    <span
                      onClick={() => setCardId('')}
                      className={`font-sans text-blue-900 whitespace-normal ${
                        i.uri !== '' ? 'hover:underline' : 'cursor-auto'
                      }`}
                    >
                      {i.des?.name}
                    </span>
                  </Link>
                ) : (
                  <span>
                    {' '}
                    {i.des?.name?.split('||').map((e: any, index: number, array: any) => (
                      <Fragment key={index}>
                        <span className="text-blue-900">{e}</span>
                        {array.length > 1 && index !== array.length - 1 && (
                          <span className="text-yellow font-bold">|</span>
                        )}
                      </Fragment>
                    ))}
                  </span>
                )}

                <br />
                {true && <span className="font-sans text-10 text-blue-900 whitespace-normal"> {i.note} </span>}
              </div>
            ))}
          </div>
        )}
        <div className="flex flex-col justify-center items-start gap-1 sm:pt-1 ">
          {columnB
            .filter(({ des }) => des?.length)
            .map((i) => (
              <div key={i.title} className="text-sm 2xl:text-xs  leading-6	 2xl:leading-6	lg:text-base	sm:text-xs">
                <span className="font-bold text-blue-600">{i.title} </span>
                <span className="font-sans text-blue-900">
                  {i.des?.split('||').map((e, index: number, array) => (
                    <Fragment key={index}>
                      {e}
                      {array.length > 1 && index !== array.length - 1 && (
                        <span className="text-yellow font-bold">|</span>
                      )}
                    </Fragment>
                  ))}
                </span>
              </div>
            ))}
        </div>
        {opinion?.length ? (
          <>
            <div className="flex items-center text-center justify-center gap-2">
              <div className="text-sm font-bold  text-blue-600 2xl:text-xs flex items-center  lg:text-base sm:text-xs">
                Avis de la commission de transparence :
              </div>

              {!opinion?.includes('non disponible') ? (
                <>
                  {' '}
                  <div className="flex items-center justify-center">
                    {opinion?.split('||').map((opinionn, index) => (
                      <Fragment key={index}>
                        <>
                          <div
                            onClick={() => {
                              window.open(opinionn.trim(), '_blank');
                            }}
                            className="cursor-pointer"
                          >
                            <img src={pdfIcon} alt="" />
                          </div>{' '}
                          &nbsp;
                        </>
                        {opinion?.split('||').length > 1 && index === 0 ? <div>&nbsp; / &nbsp;</div> : ''}
                      </Fragment>
                    ))}
                  </div>
                </>
              ) : (
                <p className="text-sm 2xl:text-xs text-blue-900 lg:text-base sm:text-xs">{opinion}</p>
              )}
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
};
export default MedicationTable;

import React, { useContext } from 'react';
import classNames from 'utils/classNames';
import { Link, useLocation } from 'react-router-dom';
import PreviousMenuContext from 'contexts/PreviousMenuState';

import { MenuStatesValues } from 'contexts/MenuContext';
import TitleLink from './TitleLink';

interface RowProps {
  className?: string;
  data: any[];
  title: string;
}

const Row: React.FC<RowProps> = ({ className, data, title }) => {
  const location = useLocation();
  const { setPreviousStates } = useContext(PreviousMenuContext);

  return (
    <>
      <TitleLink title={title} className="pb-2" />
      {data.map((d) => (
        <Link
          onClick={() => {
            if (location.pathname) window.scroll({ top: 0 });

            setPreviousStates(MenuStatesValues.CLOSE);
          }}
          key={d.id}
          to={d.pathname}
          className={classNames(
            'pb-2 w-full text-white flex flex-row items-center',
            'cursor-pointer hover:text-yellow',

            className,
          )}
        >
          <div className="flex-1 text-current font-normal text-base  2xl:text-sm">{d.title}</div>
        </Link>
      ))}
    </>
  );
};

export default Row;

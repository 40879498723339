import React from 'react';

interface Props {
  display: string;
}

const DefinitionsGlossaryAbbreviations = ({ display }: Props) => {
  const DisplayCondition = 'DefinitionsGlossaryAbbreviations';
  const table = [
    {
      title: 'Aérosol :',
      subtitle: '',
      desc: 'suspension dans l’air (ou dans un autre gaz) de particules liquides ou solides dont la très petite taille (< 100 μm) leur assure une vitesse de chute très faible.',
    },
    {
      title: 'Anticholinergique :',
      subtitle: '',
      desc: 'classe pharmacothérapeutique dont la propriété utilisée dans le traitement de l’asthme et de la BPCO est l’effet bronchodilatateur. Synonyme : antagoniste des récepteurs muscariniques.',
    },
    {
      title: 'Bêta 2 (β2) mimétique :',
      subtitle: '',
      desc: 'classe pharmacothérapeutique dont la propriété utilisée dans le traitement de l’asthme et de la BPCO est l’effet bronchodilatateur. Synonymes : β2 stimulant, agoniste β2 adrénergique, agoniste sélectif β2 adrénergique, bêtamimétique β2 sélectif.',
    },
    {
      title: 'Contrôle de la prise :',
      subtitle: '',
      desc: 'système visuel, auditif ou gustatif grâce auquel le patient peut s’assurer après la prise, que le dispositif a bien généré une dose de médicament sous forme d’aérosol.',
    },
    {
      title: 'Corticoïde :',
      subtitle: '',
      desc: 'classe pharmacothérapeutique dont la propriété utilisée dans le traitement de l’asthme et de la BPCO est l’effet anti-inflammatoire. Synonyme : glucocorticoïde, anti-inflammatoire stéroïdien.',
    },
    { title: 'Dispositif : ', subtitle: '', desc: 'inhalateur permettant l’aérosolisation du médicament.' },
    {
      title: 'Dosage : ',
      subtitle: '',
      desc: 'quantité de médicament (généralement exprimée en mg ou mg pour les thérapeutiques inhalées) délivrée par dose sous forme d’aérosol.',
    },
    {
      title: 'Dose : ',
      subtitle: '',
      desc: 'unité de prise du médicament. Peut être constituée de plusieurs inhalations (ou bouffées) dans le cas des inhalateurs de poudre unidose ou multidose à doses préconditionnées.',
    },
    {
      title: 'DPI (Dry Powder Inhaler) : ',
      subtitle: '',
      desc: 'inhalateur de poudre. Dispositif dans lequel le médicament est présenté sous forme de poudre.',
    },
    {
      title: 'DPI Multidose ',
      subtitle: ' (multidose Dry Powder Inhaler) :',
      desc: (
        <div>
          <div>
            inhalateur de poudre comportant un réservoir de poudre avec plusieurs doses de médicaments. Il existe deux
            types de DPI multidose :
          </div>
          <div className="mt-4">
            <b>1. Multi dose reservoir device :</b>inhalateur de poudre avec réservoir multidose : toutes les doses du
            médicament sont stockées dans le même réservoir (ex : turbuhaler®, clickhaler®, easyhaler®, novolizer®).
          </div>
        </div>
      ),
    },
    {
      title: 'DPI Unidose',
      subtitle: ' (single dose Dry Powder Inhaler) : ',
      desc: ' inhalateur de poudre à doses prémesurées conditionnées dans des gélules.',
    },
    {
      title: 'Excipient :',
      subtitle: '',
      desc: ' substance (solubilisant, stabilisant, diluant, conservateur antimicrobien, gaz propulseur…) associée au principe actif facilitant la transformation du médicament en aérosol.',
    },
    { title: 'Inhalateur : ', subtitle: '', desc: 'dispositif permettant l’aérosolisation du médicament.' },
    {
      title: 'Médicament : ',
      subtitle: '',
      desc: 'selon la définition réglementaire : (Art. L. 5111-1 du Code de la santé publique), « on entend par médicament toute substance ou composition présentée comme possédant des propriétés curatives ou préventives à l’égard des maladies humaines ou animales, ainsi que tout produit pouvant être administré à l’homme ou à l’animal, en vue d’établir un diagnostic',
    },
    {
      title: 'MDI ou p-MDI ',
      subtitle: '(Metered Dose Inhaler ou pressurized Metered Dose Inhaler):',
      desc: ' aérosol doseur pressurisé. Dans le langage courant ce dispositif est de manière erronée souvent désigné par le terme « spray ».',
    },
    {
      title: 'MDI-BA ',
      subtitle: '(Metered Dose Inhaler Breath Actuated) :',
      desc: ' aérosol doseur pressurisé autodéclenché. Le terme déposé par l’industrie pharmaceutique est autohaler®.',
    },
    {
      title: 'MDI-spacer ',
      subtitle: '(Metered Dose Inhaler Spacer) : ',
      desc: 'aérosol doseur pressurisé avec chambre intégrée. Le seul actuellement commercialisé est le beclojet®.',
    },
    {
      title: 'MMAD',
      subtitle: ' (Mass Median Aerodynamic Diameter)= DAMM (Diamètre Aérodynamique Massique Médian) :',
      desc: 'Diamètre divisant la masse de l’aérosol en 2 parties égales. Il définit la taille des particules sous forme aérosol.',
    },
    {
      title: 'Posologie :',
      subtitle: '',
      desc: ' quantité de médicament (généralement exprimée en mg ou mg pour les thérapeutiques inhalées) indiquée par prise ou par jour.',
    },
    {
      title: 'Principe actif :',
      subtitle: '',
      desc: ' substance désignée par la DCI (Dénomination Commune Internationale), responsable de l’action pharmacologique, ex : salbutamol. Un médicament est constitué d’un ou plusieurs principes actifs associés à des excipients permettant l’aérosolisation. Un même principe actif peut être présent dans de multiples spécialités et présentations.',
    },
    {
      title: 'SMI ',
      subtitle: '(Soft Mist Inhaler) :',
      desc: ' inhalateur de brumisat. Représente la dernière génération des thérapeutiques inhalées. Le seul actuellement commercialisé est SPIRIVA respimat®.',
    },
    {
      title: 'Spécialité : ',
      subtitle: '',
      desc: 'on entend par spécialité pharmaceutique, tout médicament préparé à l’avance, présenté sous un conditionnement particulier et caractérisé par une dénomination spéciale. (Art. L. 5111-2 du Code de la santé publique). En pratique, les spécialités ont une autorisation de mise sur le marché. Par exemple « SEREVENT® » et « SEREVENT diskus® » sont des spécialités différentes contenant un même principe actif, le salmétérol.',
    },
  ];
  return (
    <div style={{ display: display === DisplayCondition ? 'block' : 'none' }}>
      {table.map((item) => (
        <div className="py-1">
          <span className="text-blue-900 font-bold text-sm py-3">{item?.title}</span>
          <span className="text-blue-900 text-sm py-3 ">{item?.subtitle}</span>{' '}
          <span className="text-sm py-3">{item?.desc}</span>
        </div>
      ))}
    </div>
  );
};

export default DefinitionsGlossaryAbbreviations;

import auteur1 from 'assets/png/aut1.jpeg';
import auteur2 from 'assets/png/auteur2.png';
import auteur3 from 'assets/png/auteur3.png';
import auteur4 from 'assets/png/auteur4.png';
import classNames from 'utils/classNames';

interface Props {
  display: string;
}
const Auteur = ({ display }: Props) => {
  const DisplayCondition = 'Auteurs';
  const array = [
    {
      img: auteur1,
      title: 'Valéry Trosini-Désert',
      desc: " Pneumologue, Praticien Hospitalier, Responsable de l’Unité Fonctionnelle d'endoscopie Bronchique du Service de Pneumologie , Groupe Hospitalier Pitié-Salpêtrière, Assistance Publique - Hôpitaux de Paris ",
    },
    {
      img: auteur2,
      title: ' Marie Antignac',
      desc: (
        <span>
          Pharmacien – Cheffe Service de pharmacie Pitié-Salpêtrière Charles Foix. <br /> Coordinatrice du diplôme
          universitaire d'éducation thérapeutique du patient, de l'université Paris 11
        </span>
      ),
    },
    {
      img: auteur3,
      title: 'Marie-Hélène Becquemin ',
      desc: "Pneumologue, ancien Maitre de Conférence des Universités (Paris 7) - ancien Praticien Hospitalier, Service d'Explorations Fonctionnelles de la Respiration, de l'Exercice et de la Dyspnée, Groupe Hospitalier Pitié-Salpétrière, Assistance Publique- Hôpitaux de Paris, Membre du GAT (Groupe Aérosol Thérapie de la Société de Pneumologie de Langue Française).  ",
    },
    {
      img: auteur4,
      title: '  Thomas SIMILOWSKI  ',
      desc: " Pneumologue, Professeur des Universités – Praticien Hospitalier, chef du Service de Pneumologie et Réanimation Médicale, Groupe Hospitalier Pitié-Salpêtrière, Assistance Publique – Hôpitaux de Paris, ER10 upmc de l'Université Raris 6 Pierre et Marie Curie. ",
    },
  ];

  return (
    <div className={classNames(display === DisplayCondition ? 'block' : 'hidden')}>
      <div className="flex flex-col lg:grid lg:grid-cols-2 sm:flex sm:flex-col">
        {array.map((m) => (
          <div className="flex flex-row lg:flex-col lg:items-center justify-center items-start  mb-5 lg:pr-4 lg:mb-8 sm:mb-5">
            <img src={m.img} alt="" className="rounded-5  lg:w-full lg:h-96" width="168px" height="300px" />{' '}
            {/* </div> */}
            <div className="flex flex-col flex-1 ml-4	lg:pt-4 lg:ml-0">
              <div className="2xl:text-base text-blue-600 text-26 font-semibold"> {m.title} </div>
              <div className="2xl:text-xs sm:text-sm text-lg text-blue-600 font-regular mt-5 text-justify	lg:text-xs">
                {' '}
                {m.desc}{' '}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Auteur;

import React, { Fragment } from 'react';
import pdfIcon from 'assets/svg/pdf.svg';
import pdfBarred from 'assets/svg/pdfBarred.svg';
import classNames from 'utils/classNames';

interface PdfComponentProps {
  url?: string;
  isChecked?: boolean;
  tooltip?: boolean;
  msg?: string;
  role?: string;
}
const PdfComponent = ({ url, isChecked, tooltip, msg, role }: PdfComponentProps) => {
  const [cursorType, setCursorType] = React.useState(false);
  const [hoverdTitle, setHoveredTitle] = React.useState(-1);

  const cursorChangeHandler = (cursor: boolean, index: number) => {
    setCursorType(cursor);
    setHoveredTitle(index);
  };
  return (
    <div className="flex items-center justify-center ml-4 ">
      {url?.split('||').map((u, index) => (
        <Fragment key={index}>
          <>
            <a target="_blank" href={u.trim()} className="cursor-pointer" rel="noreferrer">
              {!isChecked || role === 'admin' ? (
                <div key={index} className="  flex flex-col justify-center items-center relative ">
                  {cursorType && hoverdTitle === index && tooltip ? (
                    <div className="  sm:hidden md:hidden lg:hidden absolute  -top-16  font-bold text-xs px-4 py-2  rounded-2xl	 outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150  ">
                      <div className="  bg-blue  rounded-lg text-white  p-3 mb-0   truncate ">{msg}</div>
                    </div>
                  ) : null}

                  <img
                    src={pdfIcon}
                    alt=""
                    className={classNames('-mt-1 ', tooltip && 'cursor-pointer')}
                    width={32}
                    height={32}
                    onMouseOver={() => cursorChangeHandler(true, index)}
                    onMouseLeave={() => cursorChangeHandler(false, index)}
                  />
                </div>
              ) : (
                <div key={index} className="  flex flex-col justify-center items-center relative">
                  {cursorType && hoverdTitle === index && tooltip ? (
                    <div className="  sm:hidden md:hidden lg:hidden absolute  -top-16  font-bold text-xs px-4 py-2  rounded-2xl	 outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150  ">
                      <div className="  bg-blue  rounded-lg text-white  p-3 mb-0   truncate ">{msg}</div>
                    </div>
                  ) : null}

                  <img
                    src={pdfBarred}
                    alt=""
                    className={classNames('-mt-1 ', tooltip && 'cursor-pointer')}
                    width={32}
                    height={32}
                    onMouseOver={() => cursorChangeHandler(true, index)}
                    onMouseLeave={() => cursorChangeHandler(false, index)}
                  />
                </div>
              )}
            </a>
            &nbsp;
          </>
          {url?.split('||').length > 1 && index === 0 ? <div>&nbsp; / &nbsp;</div> : ''}
        </Fragment>
      ))}
    </div>
  );
};

export default PdfComponent;

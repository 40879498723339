import cardIdContext from 'contexts/CardIdContext';
import { Fragment, useContext, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { MedicamentsByIdData } from 'requests/medicaments';
import classNames from 'utils/classNames';
import { decodeUri } from 'utils/url';
import { MedicinesRequest, useAcceptMedicineRequest, useRefuseMedicineRequest } from 'requests/medicines';
import Button from 'components/Button';
import Comment from 'components/ux/Comment';
import ImagePreview from 'components/common/PreviewImage/ImagePreview';
import { InputGeneric as Input } from 'components/ux/InputGeneric';
import moment from 'moment';
import ConfirmationModal from 'components/ux/ConfirmationModal';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { useFormik } from 'formik';
import { isEmpty, isEqual } from 'lodash';
import PdfComponent from 'components/ux/PdfComponent';
import Delete from 'components/icons/Delete';
import Modal from 'components/common/Modal/Modal';
import MedicineInfosRow from './MedicineInfosRow';

// eslint-disable-next-line import/no-cycle
import MedicineInfoComponent from './MedicineInfosComponent';

export type MedicinesInfosProps = {
  item: MedicamentsByIdData['medicine'] | undefined;
  col?: boolean;
  request: MedicinesRequest | null;
  refetch: () => void;
  refetchMedicine?: () => void;
  commentAdmin?: string;
  requestedByFirstName?: string;
  requestedByLastName?: string;
  requestedByEmail?: string;
  requestedCreatedAt?: string;
  nameAdmin?: string;
  roleRequestedBy?: string;
};

function filterResponse(res: any): any {
  if (typeof res === 'boolean') return res;
  if (!res) return null;
  if (typeof res !== 'object' || Array.isArray(res)) return res;

  const result = {} as Record<string, any>;

  Object.keys(res).forEach((key) => {
    if (key !== '__typename') {
      const value = filterResponse(res[key]);
      if (!isEmpty(value)) {
        result[key] = value;
      }
    }
  });

  return result;
}
export const getNestedValue = (object: any, key: string): any => {
  if (object === null || typeof object !== 'object') return undefined;
  const newValue = key.split('.'); // [comp , name]
  if (newValue.length <= 1) {
    return object[key];
  }
  return getNestedValue(object[newValue[0]], newValue.slice(1).join('.'));
};

const getValue = (fields: any, value: any): boolean => {
  if (fields === null || typeof fields !== 'object') return true;
  return Object.keys(fields).every((element) => {
    if (typeof value[element] === 'object' && typeof fields[element] === 'object') {
      return getValue(fields[element], value[element]);
    }
    return value[element] !== undefined;
  });
};

const MedicineInfos = ({
  item,
  request,
  refetch,
  refetchMedicine,
  commentAdmin,
  requestedByFirstName,
  requestedByLastName,
  requestedCreatedAt,
  requestedByEmail,
  nameAdmin,
  roleRequestedBy,
}: MedicinesInfosProps) => {
  const {
    name,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    small_title,
    dose,
    components,
    lab,
    visualization,
    coMarketing,
    handToMouthCoordination,
    modality,
    existence,
    expires,
    repayment,
    refillCartridges,
    percentageFineParticles,
    dci,
    classe,
    device,
    action,
    deviceDiameter,
    inhalationChamber,
    refill,
    useControl,
    price,
    updatedDate,
    ready,
    readyFirstUse,
    deviceResistance,
    MobileSensorExistence,
    image,
    deviceType,
    opinion,
    generic,
  } = item || {};
  const { values, setFieldValue, setValues } = useFormik({
    initialValues: request?.fields || ({} as any),
    onSubmit: () => {},
  });
  const divRef = useRef<HTMLDivElement>(null);
  const columnA = [
    {
      title: 'Classe pharmacothérapeutique :',
      des: classe?.name,
      pathname: '/classes',
      uri: 'classe',
      type: 'objectId',
      key: 'classe',
    },
    {
      title: 'DCI :',
      des: dci?.name,
      pathname: '/dci',
      uri: 'dci',
      type: 'objectId',
      key: 'dci',
    },
    {
      title: 'Type de Dispositif :',
      des: deviceType?.name,
      pathname: '/types',
      uri: 'deviceType',
      type: 'objectId',
      key: 'deviceType',
    },
    {
      title: ' Nom de dispositif :',
      des: device?.name,
      pathname: '/devices',
      uri: 'device',
      type: 'objectId',
      key: 'device',
    },
    {
      title: 'Dosage : ',
      des: dose?.name,
      uri: '',
      type: 'text',
      key: 'dose.name',
    },
    {
      title: 'Note à propos du dosage : ',
      des: dose?.note,
      uri: '',
      type: 'text',
      key: 'dose.note',
    },
    {
      title: 'Mise à jour   : ',
      des: updatedDate,
      uri: '',
      type: 'text',
      key: 'updatedDate',
    },
  ];

  const columnB = [
    {
      title: 'Nom du médicament: ',
      des: name,
      type: 'text',
      key: 'name',
    },
    {
      title: 'Sous nom du médicament : ',
      des: small_title,
      type: 'text',
      key: 'small_title',
    },
    {
      title: 'Gaz propulseur :',
      des: components?.propellantGaz,
      type: 'text',
      key: 'components.propellantGaz',
    },
    {
      title: 'Excipient(s):',
      des: components?.excipient,
      type: 'text',
      key: 'components.excipient',
    },
    {
      title: ' Diamètre Aérodynamique Massique Médian :',
      des: deviceDiameter,
      type: 'text',
      key: 'deviceDiameter',
    },
    {
      title: ' Pourcentage de particules fines :',
      des: percentageFineParticles,
      type: 'text',
      key: 'percentageFineParticles',
    },
    {
      title: 'Nombre de doses : ',
      des: dose?.number,
      type: 'text',
      key: 'dose.number',
    },
    {
      title: 'Recharge(s) : ',
      des: refill,
      request: request?.fields?.refill,
      type: 'text',
      key: 'refill',
    },
    {
      title: 'Visualisation du nombre de doses restantes :  ',
      des: visualization?.doseRemaining,
      type: 'text',
      key: 'visualization.doseRemaining',
    },
    {
      title: 'Visualisation du nombre de doses prises :  ',
      des: visualization?.doseTaken,
      type: 'text',
      key: 'visualization.doseTaken',
    },
    {
      title: 'Date de péremption :  ',
      des: expires,
      type: 'text',
      key: 'expires',
    },
    {
      title: 'Prêt à l’emploi : ',
      des: ready,

      type: 'text',
      key: 'ready',
    },
    {
      title: 'Prêt à l’emploi dès la première utilisation :   ',
      des: readyFirstUse,

      type: 'text',
      key: 'readyFirstUse',
    },
    {
      title: '   Coordination main bouche : ',
      des: handToMouthCoordination,
      type: 'text',
      key: 'handToMouthCoordination',
    },
    {
      title: "Possibilité d'adaptation d'une chambre d'inhalation :  ",
      des: inhalationChamber,
      type: 'text',
      key: 'inhalationChamber',
    },
    {
      title: 'Résistance du dispositif :  ',
      des: deviceResistance,
      type: 'text',
      key: 'deviceResistance',
    },
    {
      title: " Modalité(s) d'inhalation :  ",
      des: modality?.inhalation,
      type: 'text',
      key: 'modality.inhalation',
    },
    {
      title: ' Modalités d’utilisation :  ',
      des: modality?.utilisation,
      type: 'text',
      key: 'modality.utilisation',
    },
    {
      title: '  Délai d’action :  ',
      des: action?.expire,
      type: 'text',
      key: 'action.expire',
    },
    {
      title: ' Délai d’action du bronchodilatateur :  ',
      des: action?.expireBronchodilator,
      type: 'text',
      key: 'action.expireBronchodilator',
    },

    {
      title: "  Durée d'action du bronchodilatateur :  ",
      des: action?.durationBronchodilator,
      type: 'text',
      key: 'action.durationBronchodilator',
    },
    {
      title: '  Durée d’action :   ',
      des: action?.duration,
      type: 'text',
      key: 'action.duration',
    },
    {
      title: '   Contrôle de la prise :  ',
      des: useControl,
      type: 'text',
      key: 'useControl',
    },
    {
      title: 'Reproductibilité de la dose :  ',
      des: dose?.reproducibility,
      type: 'text',
      key: 'dose.reproducibility',
    },
    {
      title: "Existence d'un capteur connecté à une application mobile :",
      des: MobileSensorExistence,
      type: 'text',
      key: 'MobileSensorExistence',
    },
    {
      title: 'Laboratoire : ',
      des: lab?.name,
      type: 'objectId',
      key: 'lab',
    },
    {
      title: '  Co-marketing : ',
      des: coMarketing,
      type: 'text',
      key: 'coMarketing',
    },
    {
      title: ' Existence d’un générique : ',
      des: existence,
      type: 'text',
      key: 'existence',
    },
    {
      title: ' Prix unitaire / Prix par Dose  :  ',
      des: price,
      type: 'text',
      key: 'price',
    },
    {
      title: ' Cartouches recharges :',
      des: refillCartridges,
      type: 'text',
      key: 'refillCartridges',
    },
    {
      title: ' Remboursement :',
      des: repayment,
      type: 'text',
      key: 'repayment',
    },
  ];
  const { setCardId } = useContext(cardIdContext);
  const location = useLocation();
  const uri = decodeUri(location.search);
  const [msgModal, setMsgModal] = useState<string>('');
  const [msgAcceptedModal, setMsgAcceptedModal] = useState<string>('');
  const [isAccepted, setAccepted] = useState(false);
  const [valueCommentAdmin, setValueCommentAdmin] = useState<string>('');
  const [msgRefuse, setMsgRefuse] = useState<string>('');
  const [msgAccepted, setMsgAccepted] = useState<string>('');
  const [openGeneric, setOpenGeneric] = useState<boolean>(false);

  const requestId = uri.request;
  const [refuseMedicineRequest] = useRefuseMedicineRequest({
    onCompleted: () => {
      setMsgModal('La demande a été refusée avec succès');
      setMsgRefuse('');
      refetch();
      if (refetchMedicine) refetchMedicine();
    },
  });
  const [accepteRequestMedicine] = useAcceptMedicineRequest({
    onCompleted: () => {
      setMsgAcceptedModal('La demande a été validée avec succès');
      setMsgAccepted('');
      refetch();
      if (refetchMedicine) refetchMedicine();
    },
  });
  const refuseMedicine = () => {
    const variables =
      isAccepted && request
        ? filterResponse({
            ...request.fields,
            dci: request.fields.dci?.id,
            classe: request.fields.classe?.id,
            device: request.fields.device?.id,
            deviceType: request.fields.deviceType?.id,
            lab: request.fields.lab?.id,
          })
        : filterResponse(values);
    refuseMedicineRequest({
      variables: {
        id: requestId,
        valuesEmail: {
          firstName: requestedByFirstName || '',
          lastName: requestedByLastName || '',
          nameLab: lab?.name || '',
          createdAt: requestedCreatedAt || '',
          nameMededine: name || '',
          smallTitle: small_title || '',
          respondedBy: nameAdmin || '',
          dateResponded: moment().format('DD/MM/YYYY-hh:mm:ss'),
          email: requestedByEmail || '',
        },
        commentAdmin: valueCommentAdmin,
        ...variables,
      },
    });
  };
  const accepteRequestMedicines = () => {
    const variables =
      isAccepted && request
        ? filterResponse({
            ...request.fields,
            dci: request.fields.dci?.id,
            classe: request.fields.classe?.id,
            device: request.fields.device?.id,
            deviceType: request.fields.deviceType?.id,
            lab: request.fields.lab?.id,
          })
        : filterResponse(values);
    accepteRequestMedicine({
      variables: {
        id: requestId,
        valuesEmail: {
          firstName: requestedByFirstName || '',
          lastName: requestedByLastName || '',
          nameLab: lab?.name || '',
          createdAt: requestedCreatedAt || '',
          nameMededine: name || '',
          smallTitle: small_title || '',
          respondedBy: nameAdmin || '',
          dateResponded: moment().format('DD/MM/YYYY-hh:mm:ss'),
          email: requestedByEmail || '',
        },
        commentAdmin: valueCommentAdmin,
        ...variables,
      },
    });
  };
  useEffect(() => {
    setValues(request?.response || {});
    setValueCommentAdmin('');
  }, [request]);
  useEffect(() => {
    if (!msgAccepted) setAccepted(false);
  }, [msgAccepted]);
  const fields = { ...request?.fields } || {};
  if (fields.comment) delete fields.comment;
  const getDescription = (oldValue: any, value: any, key: string) => {
    if (isEqual(oldValue?.trim().toLowerCase(), value?.trim().toLowerCase())) {
      return getNestedValue(request?.current, key) || 'Non renseigné';
    }
    return getNestedValue(item, key) || 'Non renseigné';
  };
  const getDescriptionB = (oldValue: any, value: any, key: string) => {
    if (isEqual(oldValue?.trim().toLowerCase(), value?.trim().toLowerCase())) {
      return getNestedValue(request?.current, key) || 'Non renseigné';
    }
    return oldValue || 'Non renseigné';
  };
  return (
    <div className="flex flex-col w-full  px-28 2xl:px-12 py-12">
      <div>
        {request?.status === 'pending' && (
          <div className="flex  justify-end w-full mb-4">
            <div className="flex items-end justify-end ">
              <Button
                onClick={() => {
                  setMsgRefuse('Êtes-vous sûr de vouloir refuser toutes ces modifications ?');
                }}
                className="flex items-center justify-center  ml-2 bg-red-300 hover:bg-red-250 text-white font-semibold "
                size="md"
              >
                <div className="text-sm ">Refuser toutes les modifications</div>
              </Button>
            </div>
            <div className="flex items-end justify-end">
              <Button
                onClick={() => {
                  setMsgAccepted('Êtes-vous sûr de vouloir accepter cette demande ?');
                  setAccepted(true);
                }}
                className="flex items-center justify-center gap-5 ml-2 bg-green-250 hover:bg-green-300 text-white font-semibold"
                size="md"
              >
                <div className="text-sm">Accepter toutes les modifications</div>
              </Button>
            </div>
          </div>
        )}
      </div>
      <div className="flex flex-row w-full sm:px-5 lg:flex-col  gap-16 2xl:gap-12 lg:m-auto lg:px-20 lg:gap-0">
        <Modal
          className="rounded-5 z-50 relative text-left py-24 px-20 bg-grey-300 2xl:w-934	h-661 2xl:h-539 lg:w-4/5 lg:p-8 lg:h-1/2"
          onClose={() => {
            setOpenGeneric(false);
          }}
          open={openGeneric}
        >
          <div className="text-blue-600 text-2xl  font-semibold lg:text-base md:text-sm">
            Cette spécialité est similaire à la spécialité FORADIL 12 μg, poudre pour inhalation en gélule (même
            composition qualitative et quantitative en principe actif et même forme pharmaceutique). Le mécanisme du
            dispositif d’inhalation fourni dans le conditionnement est identique à celui de FORADIL 12 μg et provient du
            même fabricant. Seules des modifications esthétiques, notamment de couleur, les différencient.
          </div>
        </Modal>

        <div className="w-2/5 2xl:w-1/3 lg:w-full  flex-0   ">
          <ImagePreview src={image} alt="" className="rounded-5 w-100" />
          <div className="flex flex-row pt-4 justify-start items-start  w-full text-blue-900 text-lg	font-semibold 2xl:text-base lg:text-lg">
            Description
          </div>
          <div className="flex flex-col justify-center items-start w-full gap-1">
            {columnA.map((i) => {
              const requestValue = getNestedValue(request?.fields, i.key);
              const value = i.type === 'text' ? requestValue : requestValue?.name;
              const description = getDescription(i?.des, value, i.key);
              const isCkecked =
                request?.status === 'pending' ? getNestedValue(values, i.key) : getNestedValue(values, i.key) || '';
              return (
                <div key={i.title} className="text-sm 2xl:text-xs 2xl:leading-6 3xl:leading-6	lg:text-base sm:text-xs	">
                  <span className="font-bold text-blue-600 ">{i.title} </span>
                  {i.uri !== '' ? (
                    <>
                      <span
                        onClick={() => setCardId('')}
                        className={classNames(
                          `font-sans text-blue-900 whitespace-normal `,
                          ((value && roleRequestedBy === 'admin') || isCkecked) && 'line-through',
                        )}
                      >
                        {i.type === 'text' ? description : description?.name}
                      </span>

                      {value && (
                        <MedicineInfosRow
                          value={value}
                          onClick={() =>
                            setFieldValue(i.key, i.type === 'text' ? requestValue : (requestValue as any).id)
                          }
                          onCancel={() => setFieldValue(i.key, '')}
                          medicineStatus={request?.status}
                          isChecked={roleRequestedBy !== 'admin' && isCkecked === ''}
                        />
                      )}
                    </>
                  ) : (
                    <>
                      <span
                        className={classNames(
                          'font-sans  ml-1',
                          ((value && roleRequestedBy === 'admin') || isCkecked) && 'line-through',
                          description === 'Non renseigné' ? 'text-yellow' : ' text-blue-900',
                        )}
                      >
                        {description?.split('||').map((e: any, indexData: number, array: any) => (
                          <Fragment key={indexData}>
                            {e}
                            {array.length > 1 && indexData !== array.length - 1 && (
                              <span className="text-yellow font-bold hidden">|</span>
                            )}
                          </Fragment>
                        ))}
                      </span>
                      {value && (
                        <MedicineInfosRow
                          value={value}
                          onClick={() =>
                            setFieldValue(i.key, i.type === 'text' ? requestValue : (requestValue as any).id)
                          }
                          onCancel={() => setFieldValue(i.key, '')}
                          medicineStatus={request?.status}
                          isChecked={roleRequestedBy !== 'admin' && isCkecked === ''}
                        />
                      )}
                    </>
                  )}
                </div>
              );
            })}
            {request?.fields?.comment && (
              <div className="max-w-full min-w-96 2xl:min-w-full">
                <Comment
                  firstName={request?.requestedBy?.firstName}
                  lastName={request?.requestedBy?.lastName}
                  text={request?.fields?.comment}
                />
              </div>
            )}
            {commentAdmin && (
              <div className="max-w-full min-w-96 2xl:min-w-full">
                <Comment
                  firstName={request?.respondedBy?.firstName}
                  lastName={request?.respondedBy?.lastName}
                  text={commentAdmin}
                />
              </div>
            )}
            <div className="mt-4 w-full">
              {request?.status === 'pending' && commentAdmin !== '' && (
                <Input
                  item=""
                  label=""
                  value={valueCommentAdmin}
                  onChange={(e) => {
                    if (e.target.value[0] !== ' ' && e.target.value.length < 451) {
                      setValueCommentAdmin(e.target.value);
                    }
                  }}
                  placeholder="Entrez votre commentaire"
                  name="commentAdmin"
                  error=""
                  borderColor="rgb(156 163 175)"
                />
              )}
            </div>
          </div>
        </div>

        <div className="flex flex-col flex-1">
          <div className="flex items-start">
            <div className="flex flex-col mb-2">
              <div className="font-bold text-3xl 2xl:text-2xl lg:text-xl md:text-2xl  text-blue-600 ">
                {name}
                <div className="font-regular font-normal	 text-3xl lg:text-base 2xl:text-lg text-grey-700 lg:text-blue-600	flex gap-3">
                  {small_title}{' '}
                  {generic === 'oui' && (
                    <div
                      className={classNames(
                        'px-4 flex cursor-pointer hover:bg-blue-600 py-2 bg-yellow text-white  text-xs font-medium rounded-3xl h-8',
                      )}
                      onClick={() => setOpenGeneric(true)}
                    >
                      <Delete height={18} />
                      <span className="px-1 normal-case"> Générique </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col border lg:border-0 rounded-5 border-blue-250 py-4 px-2 lg:pl-0 lg:pt-1">
            <div className="flex flex-col gap-2 sm:pt-1 ">
              {columnB.map((i) => {
                const requestValue = getNestedValue(request?.fields, i.key);
                const value = i.type === 'text' ? requestValue : requestValue?.name;
                const description = getDescriptionB(i?.des, value, i.key);
                return (
                  <MedicineInfoComponent
                    isChecked={
                      request?.status === 'pending'
                        ? getNestedValue(values, i.key)
                        : getNestedValue(values, i.key) || ''
                    }
                    value={value}
                    onClick={() => setFieldValue(i.key, i.type === 'text' ? requestValue : (requestValue as any).id)}
                    onCancel={() => setFieldValue(i.key, '')}
                    medicineStatus={request?.status}
                    keyInfo={i.key}
                    title={i.title}
                    description={description.split('||').map((e: string, indexData: number, array: [string]) => (
                      <Fragment key={indexData}>
                        {e}
                        {array.length > 1 && indexData !== array.length - 1 && (
                          <span className="text-yellow font-bold">|</span>
                        )}
                      </Fragment>
                    ))}
                    isNull={description === 'Non renseigné'}
                    isAdmin={roleRequestedBy === 'admin'}
                  />
                );
              })}

              <MedicineInfoComponent
                isPdf={!opinion?.includes('non disponible')}
                isChecked={getNestedValue(values, 'opinion')}
                value={
                  request?.fields?.opinion ? (
                    <PdfComponent
                      tooltip
                      isChecked={
                        request?.status === 'pending'
                          ? getNestedValue(values, 'opinion') === ''
                          : getNestedValue(values, 'opinion') === undefined ||
                            getNestedValue(values, 'opinion') === null
                      }
                      url={request?.fields?.opinion}
                      role={request?.requestedBy?.role || ''}
                      msg={
                        request?.requestedBy?.role === 'admin'
                          ? 'Fichier envoyé par admin'
                          : 'Fichier envoyé par le laboratoire'
                      }
                    />
                  ) : (
                    ''
                  )
                }
                onClick={() => setFieldValue('opinion', request?.fields?.opinion)}
                onCancel={() => setFieldValue('opinion', '')}
                medicineStatus={request?.status}
                isFlex
                title="Avis de la commission de transparence :"
                description={
                  (opinion && opinion?.includes('non disponible')) ||
                  (values === opinion && request?.fields?.opinion === 'non disponible') ? (
                    <span
                      className={classNames(
                        request?.requestedBy?.role === 'admin' && request?.fields?.opinion && 'line-through ',
                      )}
                    >
                      {opinion}
                    </span>
                  ) : (
                    <PdfComponent
                      tooltip={!!request?.fields?.opinion}
                      url={values === opinion ? request?.fields?.opinion : opinion}
                      isChecked={
                        request?.requestedBy?.role === 'admin' && request?.fields?.opinion
                          ? true
                          : getNestedValue(values, 'opinion')
                      }
                      msg="Fichier actuel"
                    />
                  )
                }
              />
            </div>
          </div>
        </div>
        <ConfirmationModal
          className="flex-col-reverse"
          onClose={() => {
            setMsgRefuse('');
          }}
          open={msgRefuse !== ''}
          msg={msgRefuse}
        >
          <div className="flex items-center">
            <Button
              onClick={refuseMedicine}
              className="flex items-center justify-center gap-5 ml-2 bg-green-250 hover:bg-green-300 text-white"
              size="sm"
            >
              <div className="text-sm"> Oui</div>
            </Button>

            <Button
              onClick={() => setMsgRefuse('')}
              className="flex items-center justify-center gap-5 ml-2 bg-red-300 hover:bg-red-250 text-white"
              size="sm"
            >
              <div className="text-sm"> Non</div>
            </Button>
          </div>
        </ConfirmationModal>

        <ConfirmationModal
          onClose={() => {
            setMsgModal('');
          }}
          open={msgModal !== ''}
          msg={msgModal}
        >
          <TaskAltIcon fill="#323232" fontSize="large" />
        </ConfirmationModal>
        <ConfirmationModal
          className="flex-col-reverse"
          onClose={() => {
            setMsgAccepted('');
          }}
          open={msgAccepted !== ''}
          msg={msgAccepted}
        >
          <div className="flex items-center">
            <Button
              onClick={accepteRequestMedicines}
              className="flex items-center justify-center gap-5 ml-2 bg-green-250 hover:bg-green-300 text-white"
              size="sm"
            >
              <div className="text-sm"> Oui</div>
            </Button>

            <Button
              onClick={() => setMsgAccepted('')}
              className="flex items-center justify-center gap-5 ml-2 bg-red-300 hover:bg-red-250 text-white"
              size="sm"
            >
              <div className="text-sm"> Non</div>
            </Button>
          </div>
        </ConfirmationModal>
        <ConfirmationModal
          onClose={() => {
            setMsgAcceptedModal('');
          }}
          open={msgAcceptedModal !== ''}
          msg={msgAcceptedModal}
        >
          <TaskAltIcon fill="#323232" fontSize="large" />
        </ConfirmationModal>
      </div>

      {request?.status === 'pending' && (
        <div ref={divRef} className="flex items-end justify-end mt-5">
          <Button
            disabled={!getValue(filterResponse(fields), values)}
            onClick={() => {
              setMsgAccepted('Êtes-vous sûr de vouloir valider cette modification ?');
            }}
            className={classNames(
              'flex items-center justify-center gap-5 ml-2 bg-blue-800 text-white hover:bg-purple-200 ',
              getValue(filterResponse(fields), values) ? 'cursor-pointer' : 'cursor-default',
            )}
            size="md"
          >
            <div className="text-sm font-semibold">Valider</div>
          </Button>
        </div>
      )}
    </div>
  );
};
export default MedicineInfos;

import Button from 'components/Button';
import Modal from 'components/common/Modal/Modal';
import Delete from 'components/icons/Delete';
import FavoriteFilled from 'components/icons/FavoriteFilled';
import QRCode from 'components/icons/QRCode';
import ModalComponent from 'components/ux/ModalDetailNote';
import ModalMedicine from 'components/ux/ModalMedicine';
import classNames from 'utils/classNames';
import { FunctionComponent, useContext, useEffect, useState } from 'react';
import { Favorite as FavoriteItem, useDeleteFavorite } from 'requests/Favoris';
import { useAddFavourite } from 'requests/medicaments';
import { MedicineLaboratory, Note as NoteType } from 'requests/types';
import { Spinner } from 'utils/loading';
import Edit from '@mui/icons-material/Edit';
import UserContext from 'contexts/UserContext';
import ArrowVideo from '../../../components/icons/ArrowVideo';
import Favorite from '../../../components/icons/Favorite';
import Note from '../../../components/icons/Note';
import NoteEmpty from '../../../components/icons/NoteEmpty';
import NoteFilled from '../../../components/icons/NoteFilled';
import NoteWhite from '../../../components/icons/NoteIconWhite';

export type HeaderMedicationProps = {
  name: string | undefined;
  deviceType: string | undefined;
  smallTitle?: string;
  onUpdate?: () => void;
  onVideoOpen?: (open: boolean) => void;
  id: string | undefined;
  pdf?: string | undefined;
  favorite?: FavoriteItem;
  note?: NoteType;
  refetchNote?: () => void;
  generic?: string;
  item: MedicineLaboratory | undefined;
  ref?: any;
};

const MedicationHeader: FunctionComponent<HeaderMedicationProps> = ({
  name,
  id,
  pdf,
  deviceType,
  smallTitle,
  onUpdate,
  onVideoOpen,
  favorite,
  note,
  refetchNote,
  generic,
  item,
  ref,
  ...rest
}) => {
  const { user } = useContext(UserContext);
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [addFavouriteFunction, { data, loading }] = useAddFavourite();
  const [deleteFavs, { loading: deleteLoading, data: deleteFavoriteData }] = useDeleteFavorite();
  const [width, setWidth] = useState(window.innerWidth);
  const [openGeneric, setOpenGeneric] = useState(false);
  const [cursorType, setCursorType] = useState(false);
  const [hoverdTitle, setHoveredTitle] = useState(-1);
  const isLabUser = user?.lab?.id === item?.lab.id;

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (data || deleteFavoriteData) {
      if (onUpdate) onUpdate();
    }
  }, [data, deleteFavoriteData]);

  const onOpen = () => {
    setOpen(true);
  };

  const addFavourite = (medicineID?: string) => {
    if (!loading && medicineID) {
      addFavouriteFunction({ variables: { medicine: medicineID } });
    }
  };
  const removeFavourite = () => {
    if (favorite) {
      deleteFavs({ variables: { id: favorite.id } });
    }
  };
  const cursorChangeHandler = (cursor: boolean, index: number) => {
    setCursorType(cursor);
    setHoveredTitle(index);
  };
  const ClasseA = [
    {
      icon: (
        <div onClick={() => onVideoOpen && onVideoOpen(true)}>
          {width > 1000 ? (
            <ArrowVideo fill="#014A8D" width="22px" height="22px" />
          ) : (
            <div className="bg-blue rounded-lg	  p-2 flex justify-center items-center ">
              <ArrowVideo fill="#FFFFFF" width="22px" height="22px" />{' '}
            </div>
          )}
        </div>
      ),

      title: <div onClick={() => onVideoOpen && onVideoOpen(true)}>Voir la vidéo</div>,
    },
    {
      icon: (
        <div onClick={onOpen}>
          {width > 1000 ? (
            [note ? <NoteFilled fill="#014A8D" width="18px" /> : <NoteEmpty fill="#014A8D" width="18px" />]
          ) : (
            <div className="bg-blue rounded-lg p-2 flex justify-center items-center ">
              {note ? <NoteWhite width="22px" height="22px" /> : <Note fill="white" width="22px" height="22px" />}
            </div>
          )}
        </div>
      ),
      title: <div onClick={onOpen}> Notes </div>,
    },
    {
      icon: (
        <div>
          {width > 1000 ? (
            <>
              {favorite ? (
                <>
                  {loading || deleteLoading ? (
                    <Spinner name="line-scale-pulse-out-rapid" width="22px" height="22px" color="#014A8D" />
                  ) : (
                    <FavoriteFilled
                      fill="#014A8D"
                      width="22px"
                      height="22px"
                      className="hover:fill-red	"
                      onClick={() => removeFavourite()}
                    />
                  )}
                </>
              ) : (
                <>
                  {loading ? (
                    <Spinner width="22px" height="22px" name="line-scale-pulse-out-rapid" color="#1466ff" />
                  ) : (
                    <>
                      <Favorite width="22px" height="22px" fill="#014A8D" onClick={() => addFavourite(id)} />
                    </>
                  )}
                </>
              )}
            </>
          ) : (
            <div className="bg-blue rounded-lg p-2 flex justify-center items-center ">
              {favorite ? (
                <FavoriteFilled fill="white" width="22px" height="22px" onClick={() => removeFavourite()} />
              ) : (
                <Favorite width="22px" height="22px" fill="#FFFFFF" onClick={() => addFavourite(id)} />
              )}
            </div>
          )}
        </div>
      ),
      title: (
        <>
          {deleteLoading ? (
            <div>suppression...</div>
          ) : (
            <div>{favorite ? <span>Supprimer de mes favoris</span> : <span>Ajouter à mes favoris</span>}</div>
          )}
        </>
      ),
    },
    {
      icon: (
        <div>
          {width > 1000 ? (
            <QRCode
              onClick={
                pdf === ''
                  ? () => {}
                  : () => {
                      window.open(`${process.env.REACT_APP_PHOTO_API}/uploads/pdf/unique/${pdf}`, '_blank');
                    }
              }
              fill="#014A8D"
              width="22px"
              height="22px"
            />
          ) : (
            <div
              className="bg-blue rounded-lg p-2 flex justify-center items-center "
              onClick={
                pdf === ''
                  ? () => {}
                  : () => {
                      window.open(`${process.env.REACT_APP_PHOTO_API}/uploads/pdf/unique/${pdf}`, '_blank');
                    }
              }
            >
              <QRCode fill="#FFFFFF" width="22px" height="22px" />{' '}
            </div>
          )}
        </div>
      ),
      title: (
        <div className="min-w-16 flex flex-col">
          <a href={`${process.env.REACT_APP_PHOTO_API}/uploads/pdf/unique/${pdf}`} target="blank" download>
            <span>QR Code </span>
          </a>
          {name === 'TRIMBOW' && <span className="text-center">( En attente ) </span>}
        </div>
      ),
    },
    {
      icon: (
        <div>
          {width > 1000 ? (
            <QRCode
              onClick={
                pdf === ''
                  ? () => {}
                  : () => {
                      window.open(`${process.env.REACT_APP_PHOTO_API}/uploads/pdf/planche/${pdf}`, '_blank');
                    }
              }
              fill="#014A8D"
              width="22px"
              height="22px"
            />
          ) : (
            <div
              className="bg-blue rounded-lg	p-2 flex justify-center items-center"
              onClick={
                pdf === ''
                  ? () => {}
                  : () => {
                      window.open(`${process.env.REACT_APP_PHOTO_API}/uploads/pdf/planche/${pdf}`, '_blank');
                    }
              }
            >
              <QRCode fill="#FFFFFF" width="22px" height="22px" />
            </div>
          )}
        </div>
      ),
      title: (
        <div
          onClick={
            pdf === ''
              ? () => {}
              : () => {
                  window.open(`${process.env.REACT_APP_PHOTO_API}/uploads/pdf/planche/${pdf}`, '_blank');
                }
          }
          className="cursor-pointer"
        >
          <div className="flex flex-col">
            <span>Planche de QR Code</span>
            {pdf === '' && <span className="text-center">( En attente ) </span>}
          </div>
        </div>
      ),
    },
  ];
  return (
    <div className="flex flex-col w-full" {...rest}>
      <div className="flex">
        <div className="flex items-start flex-1 justify-between">
          <div className="flex flex-col">
            <div className="font-bold text-3xl 2xl:text-2xl lg:text-xl md:text-2xl  text-blue-600">{name}</div>
            <div className="flex gap-2 font-regular text-3xl lg:text-base 2xl:text-lg text-grey-700 lg:text-blue-600">
              <span>{smallTitle}</span>
              {generic === 'oui' && (
                <div
                  className={classNames(
                    'mt-1 h-8 px-4 flex items-center cursor-pointer hover:bg-blue-600  bg-yellow text-white  text-xs font-medium rounded-3xl',
                    user?.role === 'admin' || (user?.role === 'lab' && isLabUser) ? '' : 'hidden',
                  )}
                  onClick={() => setOpenGeneric(true)}
                >
                  <Delete height={16} />
                  <span className="px-1 normal-case	"> Générique </span>
                </div>
              )}
            </div>
          </div>

          {generic === 'oui' && (
            <div
              className={classNames(
                'px-4 flex cursor-pointer hover:bg-blue-600 py-2 bg-yellow text-white  text-xs font-medium rounded-3xl',
                user?.role === 'admin' || (user?.role === 'lab' && isLabUser) ? 'hidden' : '',
              )}
              onClick={() => setOpenGeneric(true)}
            >
              <Delete height={18} />
              <span className="px-1"> Générique </span>
            </div>
          )}
          {((user?.role === 'lab' && isLabUser) || user?.role === 'admin') && (
            <div className="flex items-end justify-end">
              <Button
                onClick={() => {
                  setOpenModal(true);
                }}
                className="flex items-center justify-center ml-2 rounded-lg"
                variant="primary"
                size="xm"
              >
                <Edit />
                <div className="text-sm"> Demander une correction</div>
              </Button>
            </div>
          )}
        </div>

        <Modal
          className="rounded-5 z-50 relative text-left py-24 px-20 bg-grey-300 2xl:w-934	h-661 2xl:h-539 lg:w-4/5 lg:p-8 lg:h-1/2"
          onClose={() => {
            setOpenGeneric(false);
          }}
          open={openGeneric}
        >
          <div className="text-blue-600 text-2xl  font-semibold lg:text-base md:text-sm">
            Cette spécialité est similaire à la spécialité FORADIL 12 μg, poudre pour inhalation en gélule (même
            composition qualitative et quantitative en principe actif et même forme pharmaceutique). Le mécanisme du
            dispositif d’inhalation fourni dans le conditionnement est identique à celui de FORADIL 12 μg et provient du
            même fabricant. Seules des modifications esthétiques, notamment de couleur, les différencient.
          </div>
        </Modal>
      </div>
      {!!onVideoOpen && (
        <div className="flex flex-row gap-14 sm:gap-1 md:gap-2 lg:gap-2 xl:gap-4 relative">
          {ClasseA.map((i, index) => (
            <div key={index} className="  flex flex-col justify-center items-center m-4  2xl:ml-0 xl:m-4 lg:m-3 ">
              {cursorType && hoverdTitle === index ? (
                <div className="  sm:hidden md:hidden lg:hidden absolute  -top-10  font-bold text-xs px-4 py-2  rounded-2xl	 outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150  ">
                  {' '}
                  <div className="  bg-blue  rounded-lg text-white  p-3 mb-0   truncate ">{i.title} </div>
                </div>
              ) : null}
              <div
                className=" cursor-pointer relative lg:mr-0 rounded-lg h-6 w-6 lg:w-10 lg:h-10 "
                onMouseEnter={() => cursorChangeHandler(true, index)}
                onMouseLeave={() => cursorChangeHandler(false, -1)}
              >
                {' '}
                {i.icon}{' '}
              </div>{' '}
            </div>
          ))}
        </div>
      )}
      <ModalComponent
        note={note}
        medicine={id}
        onClose={() => {
          setOpen(false);
          if (refetchNote) refetchNote();
        }}
        open={open}
      />
      {item && (
        <ModalMedicine
          item={item}
          onClose={() => {
            setOpenModal(false);
          }}
          open={openModal}
          onSubmit={() => ''}
          userFirstName={user?.firstName}
          userLastName={user?.lastName}
          userEmail={user?.email}
        />
      )}
    </div>
  );
};

export default MedicationHeader;

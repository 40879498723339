import GuideSelect from 'components/filtres/Select/GuideSelect';
import { FunctionComponent, useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useMedicineLetters } from 'requests/medicaments';
import classNames from 'utils/classNames';
import { encodeUri } from 'utils/url';

export type FilterAlphaProps = {
  filterdDatas: (d: string) => void;
  filterAllDatas?: () => void | undefined;
  generic?: boolean;
  className?: string;
  uri: { [key: string]: string };
};
const FilterAlpha: FunctionComponent<FilterAlphaProps> = ({
  filterdDatas,
  filterAllDatas,
  className,
  uri,
  generic,
  ...rest
}) => {
  const { data } = useMedicineLetters({
    variables: {
      ...uri,
      indication: uri.indication ? uri.indication.split(',') : undefined,
      lab: uri.lab ? uri.lab.split(',') : undefined,
      generic: uri.generic ? 'oui' : undefined,
    },
  });

  const alphabet = [
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'I',
    'J',
    'K',
    'L',
    'M',
    'N',
    'O',
    'P',
    'Q',
    'R',
    'S',
    'T',
    'U',
    'V',
    'W',
    'X',
    'Y',
    'Z',
  ];

  const history = useHistory();
  const location = useLocation();
  const [optionSeleted, setoptionSeleted] = useState('Tous');

  const handleChange = (e: any) => {
    setoptionSeleted(e.value);
    if (e.value === 'Tous') {
      const newUri = { ...uri };
      delete newUri.start;
      return history.replace({ pathname: location.pathname, search: encodeUri(newUri) });
    }
    return filterdDatas(e.value);
  };
  useEffect(() => {
    if (location.search === '') setoptionSeleted('Tous');
  }, [location]);

  return (
    <div
      className={classNames(
        'items-center flex flex-row  flex-1 w-full lg:bg-transparent bg-white border-blue-150 lg:border-none rounded-5 px-3 h-14  border lg:pr-0',
        className,
      )}
      {...rest}
    >
      <>
        <div
          onClick={filterAllDatas}
          className="3xl:flex 2xl:flex xl:flex lg:hidden md:hidden sm:hidden ' pr-6 pl-16 2xl:pl-12 text-blue-600 text-base font-medium cursor-pointer"
        >
          Tous
        </div>
        {alphabet.map((i) => (
          <div
            key={i}
            className={classNames(
              '3xl:flex 2xl:flex xl:flex lg:hidden md:hidden sm:hidden ',
              'flex-1 flex items-center justify-center cursor-pointer',
              'text-base 2xl:text-sm font-medium',
              !data?.medicineLetters.some((letter) => letter === i)
                ? 'text-grey-250 pointer-events-none'
                : 'text-blue-600 hover:text-yellow',
            )}
            onClick={() => filterdDatas(i)}
          >
            {i}
          </div>
        ))}
      </>
      <p className=" 4xl:hidden 3xl:hidden 2xl:hidden xl:hidden lg:flex md:flex sm:flex text-xs w-full justify-end">
        <span className="md:mx-2 font-semibold lg:text-xs lg:mx-3">Filtrer par lettre :</span>
      </p>

      <GuideSelect
        root="4xl:hidden 3xl:hidden 2xl:hidden xl:hidden lg:flex md:flex sm:flex lg:w-24"
        placeholder="Tous"
        value={optionSeleted}
        options={['Tous', ...alphabet].map((e) => ({
          value: e,
          label: e,
          isDisabled: !data?.medicineLetters.some((letter) => letter === e) && e !== 'Tous',
        }))}
        onChange={handleChange}
      />
    </div>
  );
};
export default FilterAlpha;

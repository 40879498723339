import { useCountedDevices } from 'requests/devices';
import MedecineMenu from '../MedecineMenu/MedecineMenu';

const Devices = () => {
  const { data } = useCountedDevices();

  return <MedecineMenu pathname="/devices" type="device" data={data?.devicesCount} title="Noms de dispositifs" />;
};

export default Devices;

interface Props {
  display: string;
}
const Editorial20 = ({ display }: Props) => {
  const DisplayCondition = 'Éditorial 2020';
  return (
    <div id="editorial" style={{ display: display === DisplayCondition ? 'block' : 'none' }} className="font-regular">
      <p className="text-sm py-3">
        Une croissance annuelle de 10%, c'est le rêve des économistes et des politiques attachés au modèle du "toujours
        plus", et cela reste leur rêve même si chacun sait qu'il mène droit au cauchemar. Si l'on considère le nombre
        des thérapeutiques inhalées indiquées dans l'asthme et dans la BPCO de l'adulte, cette croissance est atteinte.
        L'édition 2019 du Guide ZÉPHIR 1 qui depuis 2011 actualise chaque année un annuaire exhaustif de ces
        traitements, décrit en effet 57 combinaisons molécules-dispositifs (sans compter les différents dosages), au
        lieu de 52 pour l'édition 2018. Cinq possibilités supplémentaires de prescription. Cinq possibilités
        supplémentaires qui ajoutent à la complexité d'un dossier déjà difficile. En effet, tous les jours, les médecins
        qui prescrivent ces traitements inhalés ont une conscience très claire du fait qu’ils devraient consacrer du
        temps à apprendre à leurs patients à se servir des dispositifs concernés ou à vérifier qu’ils s’en servent
        correctement. Un traitement inhalé mal pris ne soulage pas les symptômes comme il le devrait. Un traitement
        inhalé mal pris ne permet pas de stabiliser la maladie. La qualité de la prise est liées à la satisfaction
        ressentie vis-à-vis du traitement et donc à l'observance. La qualité de la prise est aussi un des déterminants
        de la survenue d'exacerbations, d'asthme comme de BPCO. Dans le cas de la BPCO, les patients qui n’utilisent pas
        correctement leurs inhalateurs ont un risque d’exacerbation deux fois plus élevé que ceux qui l’utilisent
        correctement, quelles que soient les raisons de cette association. Or, la proportion de patients qui commettent
        des erreurs susceptibles de rendre leur traitement inefficace ou moins efficace est très grande, pouvant
        allègrement dépasser 50 % dans certaines études.
      </p>
      <p className="text-sm py-3">
        Tous les jours, un grand nombre des prescripteurs de thérapeutiques inhalées constate ne pas avoir assez de
        temps à consacrer à la formation des patients à l’utilisation des inhalateurs ou à la vérification de la qualité
        de la prise. Qui ne s’est jamais contenté de dire « vous verrez, c’est bien expliqué sur la notice » ? Ou de
        dire « l’inhalateur, vous vous en servez bien, n’est-ce pas » ? Et comment faire autrement ? Les dispositifs
        sont nombreux et, en fait, de plus en plus nombreux. Cinq nouvelles spécialités en un an, avec de nouveaux
        dispositifs. Sans oublier qu’un patient peut avoir deux, parfois trois, dispositifs différents. Et le temps est
        compté, face à des salles d’attente pleines. Force est de reconnaître que la formation à l’utilisation des
        traitements inhalés s’avère souvent impossible en pratique : trop compliqué, trop chronophage.{' '}
      </p>
      <p className="text-sm py-3">
        C’est pour tenter de pallier au moins en partie cette difficulté que les fiches "Guide ZEPHIR" comportent toutes
        une vidéo réalisée dans des conditions standardisées et décrivant scrupuleusement, détail après détail, la
        technique de prise telle qu’elle est recommandée par le fabricant. Ces vidéos sont librement accessibles pour
        les patients sur le site de la SPLF, à l’adresse{' '}
        <a href="http://splf.fr/videos-zephir" target="blank" className="none-deco  text-blue-900 underline">
          http://splf.fr/videos-zephir
        </a>
        2. Chaque prescripteur peut donner à son patient un accès direct aux vidéos qui le concernent, grâce aux «
        étiquettes dynamiques ZÉPHIR ». Ces étiquettes autocollantes sont revêtues de « flashcodes » dont la lecture par
        un smartphone renvoie directement à la vidéo du produit concerné, que le patient peut alors visualiser à
        loisir3. Il y a autant d’étiquettes que de produits commercialisés. Ces étiquettes sont destinées à être collées
        sur les ordonnances, par le prescripteur (aujourd’hui) ou le pharmacien d’officine (demain), à côté du nom du
        produit prescrit. Elles sont réunies sous forme de planches4, dans un livret mis à la disposition des
        pneumologues (aujourd’hui à des médecins généralistes et demain à des pharmaciens).{' '}
      </p>
      <p className="text-sm py-3">
        Au terme d'un an d'existence, l'analyse de la fréquentation de la page de distribution des vidéos Zéphir sur le
        site internet de la SPLF montre une augmentation massive des connexions : c'est un indice fort du succès des
        étiquettes dynamiques. Il reste à évaluer l’impact de ce service sur les taux d’erreurs d’utilisation des
        dispositifs inhalés : rendez-vous dans quelques années.{' '}
      </p>
      <p className="text-sm py-3" />
      <h2 className="text-sm h2-1 text-blue-900 font-semibold">Le comité scientifique ZÉPHIR </h2>
      <p className="text-sm py-3">
        Valéry TROSINI-DÉSERT
        <br />
        Pneumologue, Praticien Hospitalier, Service de Pneumologie et Réanimation Médicale, Groupe Hospitalier
        Pitié-Salpêtrière Charles Foix, Paris{' '}
      </p>

      <p className="text-sm ">
        Marie ANTIGNAC <br />
        Pharmacien – Cheffe Service de pharmacie Pitié-Salpêtrière Charles Foix. <br /> Coordinatrice du diplôme
        universitaire d'éducation thérapeutique du patient, de l'université Paris 11
      </p>
      <p className="text-sm py-3">
        Marie-Hélène BECQUEMIN <br />
        Pneumologue, ancien Maître de Conférence des Universités (Paris 7) - ancien Praticien Hospitalier, Service
        d’Explorations Fonctionnelles de la Respiration, de l’Exercice et de la Dyspnée, Groupe Hospitalier
        Pitié-Salpêtrière Charles Foix, Paris. Membre du GAT (Groupe Aérosol Thérapie de la Société de Pneumologie de
        Langue Française){' '}
      </p>
      <p className="text-sm py-3">
        Thomas SIMILOWSKI <br />
        Pneumologue, Professeur des Universités-Praticien Hospitalier, Chef du Service de Pneumologie et Réanimation
        Médicale, Groupe Hospitalier Pitié-Salpêtrière Charles Foix, Paris{' '}
      </p>
      <p className="text-sm py-3" />
      <p className="text-sm py-3">
        <span className="text-blue-900"> 1. </span>« Guide Zéphir » est une marque déposée, qui protège les diverses
        déclinaisons du guide (livre, applications mobiles, site, disque, et désormais Étiquettes dynamiques).{' '}
      </p>
      <p className="text-sm py-3">
        <span className="text-blue-900"> 2.</span> les vidéos Zéphir sont relayées par le CESPHARM : Comité d’éducation
        sanitaire et sociale de la pharmacie française.
        <a
          href="http://www.cespharm.fr/fr/Prevention-sante/Actualites/2017/Asthme-et-BPCO-videos-de-demonstration-des-techniques-d-inhalation"
          target="_blank"
          className="text-blue-90 underline"
          rel="noreferrer"
        >
          http://www.cespharm.fr/fr/Prevention-sante/Actualites/2017/Asthme-et-BPCO-videos-de-demonstration-des-techniques-d-inhalation{' '}
        </a>{' '}
        . Elles vont être relayées par la CNAM, et peut-être par le dictionnaire Vidal.{' '}
      </p>
      <p className="text-sm py-3">
        <span className="text-blue-900"> 3. </span> Les patients qui n’ont pas de smartphone peuvent aussi saisir une
        adresse Internet simplifiée dans leur navigateur, adresse qui figure sur chaque étiquette.{' '}
      </p>
      <p className="text-sm py-3">
        <span className="text-blue-900"> 4. </span>On ne peut pas obtenir uniquement la planche d’un médicament donné ;
        en effet, les outils ZÉPHIR en général et les étiquettes dynamiques ZÉPHIR en particulier sont considérés comme
        « institutionnels » par l’ANSM, et non promotionnels ; cette labellisation implique que les 57 planches
        correspondant à 57 traitements soient indissociables, pour éviter tout usage publicitaire.{' '}
      </p>
      <h2 className="text-sm h2-1 text-blue-900">
        POUR ÊTRE EFFICACE, UN TRAITEMENT INHALÉ DOIT ÊTRE BIEN UTILISÉ : ACCÉDEZ À DES DÉMONSTRATIONS VIDÉOS !{' '}
      </h2>
      <p className="text-sm py-3">
        Votre médecin vous a prescrit un inhalateur pour votre maladie respiratoire. Il est très important de bien
        utiliser cet inhalateur pour que le traitement soit efficace. Pour cela, vous allez devoir suivre très
        précisément une série d’étapes qui correspond aux recommandations du fabriquant. Votre médecin ou votre
        pharmacien vous ont déjà expliqué le fonctionnement du dispositif. Vous pouvez retrouver cette information sur
        des vidéos explicatives et éducatives que nous vous proposons de visionner. Pour cela, une étiquette a été
        apposée sur votre ordonnance en regard de chaque traitement inhalé prescrit. Vous pouvez accéder à la vidéo :{' '}
      </p>
      <p className="text-sm py-3">
        • en « flashant » le code qui figure sur l’étiquette avec votre « smartphone » (on appelle cela un « QRcode ») ;{' '}
      </p>
      <p className="text-sm py-3">
        • ou bien en tapant l’adresse Internet, qui figure aussi sur l’étiquette, dans un navigateur Internet sur un
        ordinateur.{' '}
      </p>
      <p className="text-sm py-3">
        Il vous sera aisé de revoir autant de fois que nécessaire la ou les vidéos explicatives et éducatives concernant
        les inhalateurs qui vous ont été prescrits. Vous serez ainsi certains d’utiliser sans erreur votre inhalateur et
        de bénéficier de toute l’efficacité de votre traitement.{' '}
      </p>
      <h2 className="text-sm h2-1 text-blue-900">COMMENT LIRE UN QR CODE ? </h2>
      <p className="text-sm py-3">
        Il faut un téléphone portable « intelligent » (« smartphone ») et une application de lecture de code{' '}
      </p>
      <p className="text-sm py-3">1. Téléchargez une application de lecture de code </p>
      <p className="text-sm py-3">
        • Accéder à l’App Store, le Google Play Store, le Windows Store, le Blackberry App World ou toute autre
        application permettant le téléchargement en fonction de votre Smartphone.{' '}
      </p>
      <p className="text-sm py-3">• Faites une recherche avec « lecteur de QR Code » ou « QR Code ». </p>
      <p className="text-sm py-3">• Choisissez le lecteur en fonction de vos préférences et téléchargez-le. </p>
      <p className="text-sm py-3">2. Ouvrez l’application de lecture de code ; ceci active la caméra du téléphone </p>
      <p className="text-sm py-3">3. Lisez le code en le centrant sur l’écran </p>
      <p className="text-sm py-3">
        4. La vidéo de votre inhalateur s’affiche automatiquement ; il n’y a plus qu’à la lance{' '}
      </p>
      <p className="text-sm py-3" />
      <p className="text-sm py-3">1. TÉLÉCHARGEZ le lecteur de QR Code gratuitement sur votre mobile [+ image] </p>
      <p className="text-sm py-3">2. FLASHEZ le QR Code de l’étiquette avec votre lecteur [+ image] </p>
      <p className="text-sm py-3">3. ACCÉDEZ au contenu sur le site : splf.fr/ videos-zephir/ [+ image] </p>
      <h2 className="text-sm h2-1 text-blue-900">COMMENT UTILISER LES ÉTIQUETTES DYNAMIQUES </h2>
      <p className="text-sm py-3">Décollez la/les étiquettes du/des produits prescrits </p>
      <p className="text-sm py-3">
        Collez la/les étiquettes sur l’ordonnance de votre patient devant la/les prescriptions{' '}
      </p>
      <p className="text-sm py-3">POUR RECEVOIR LE RÉASSORT DES PLANCHES D’ÉTIQUETTES DYNAMIQUES </p>
      <p className="text-sm py-3">webmaster@splf.org </p>
      <p className="text-sm py-3" />
    </div>
  );
};

export default Editorial20;

import Card from 'components/common/Card/Card';
import { Medicine } from 'requests/medicaments';

interface SectionProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  description: Medicine[];
  title: string;
}

const Sections = ({ title, description }: SectionProps) => (
  <div className="grid grid-cols-111 2xl:grid-cols-3 lg:grid-cols-2 sm:grid-cols-1 gap-4 justify-between gap-y-5 pt-2.5 pb-10 relative ">
    {' '}
    {description
      .sort((a, b) => a.name.localeCompare(b.name, 'es', { sensitivity: 'base' }))
      .map((c) => {
        return (
          <Card
            from="chambreInhalation"
            medicinePhoto={c.image}
            deviceType={title}
            title={c.name}
            footer={c.lab.name}
            medicineId={c.id}
            generic={c.generic}
            smallTitle={c.small_title}
            videoData={c.video}
            medidineDescription={{
              classe: c.classe?.name,
              dci: c.dci?.name,
              device: c.device?.name,
              dosage: c.dose?.name,
            }}
          />
        );
      })}
  </div>
);
export default Sections;

import React from 'react';
import ReactTooltip from 'react-tooltip';
import ReactDOMServer from 'react-dom/server';

interface TooltipComponenetProps {
  item: string;
  heightToslice?: number;
}

const TooltipComponenet = ({ item, heightToslice = 19 }: TooltipComponenetProps) => {
  return (
    <>
      <div
        data-html
        data-tip={ReactDOMServer.renderToString(<div>{item}</div>)}
        className="text-sm font-normal text-blue cursor-pointer italic"
      >
        {item?.length > heightToslice ? (
          <div className="flex gap-1">
            {item?.slice(0, heightToslice)}...
            <div className="font-bold">Lire la suite</div>
          </div>
        ) : (
          item
        )}
      </div>
      <ReactTooltip backgroundColor="#014A8D" type="dark" place="top" effect="solid" multiline />
    </>
  );
};

export default TooltipComponenet;

import React, { useEffect, useMemo } from 'react';
import Input from 'components/ux/Input';
import Button from 'components/Button';
import { useFormik } from 'formik';
import * as yup from 'yup';

import { Checkbox } from '@mui/material';
import { useLaboratorys } from 'requests/laboratorys';
import GuideSelect, { LabelProps } from 'components/filtres/Select/GuideSelect';
import { User } from 'requests/types';

export const whiteSpaceRegex = /[A-Za-z]+/;
export interface CreateAdminVariables {
  email: string;
  password?: string;
  lastName: string;
  firstName: string;
  lab?: string;
  role?: string;
  useDefaultPassword?: boolean;
}
export const RoleOptions: LabelProps[] = [
  {
    value: 'user',
    label: 'Utilisateur',
  },
  {
    value: 'lab',
    label: 'Laboratoire',
  },
];

export interface RoleFormProps {
  onSubmit: (values: CreateAdminVariables) => void;
  item?: User;
  onClose: () => void;
}

const AdminForm = ({ onSubmit, item, onClose }: RoleFormProps) => {
  const { data: labData } = useLaboratorys({ fetchPolicy: 'no-cache' });
  const schema = yup.object({
    email: yup.string().email('Entrez une adresse e-mail valide').required('Ce champ est obligatoire'),
    lastName: yup.string().required('Ce champ est obligatoire').matches(whiteSpaceRegex, 'Ce champ est obligatoire'),
    firstName: yup.string().required('Ce champ est obligatoire').matches(whiteSpaceRegex, 'Ce champ est obligatoire'),
    password: yup.string().when('useDefaultPassword', {
      is: (useDefaultPassword: boolean) => !useDefaultPassword && !item,
      then: yup
        .string()
        .required('Ce champ est obligatoire')
        .min(6, 'Le mot de passe doit comporter au moins 6 caractères')
        .max(30, 'Le mot de passe ne peut pas dépasser 30 caractères')
        .matches(whiteSpaceRegex, 'Ce champ est obligatoire'),
    }),
    lab: yup.string().when('role', {
      is: 'lab',
      then: yup.string().required('Ce champ est obligatoire').matches(whiteSpaceRegex, 'Ce champ est obligatoire'),
    }),
  });
  const {
    values,
    handleChange,
    handleSubmit,
    setValues,
    resetForm,
    setFieldValue,
    touched,
    errors,
    handleBlur,
    setFieldTouched,
    setFieldError,
  } = useFormik({
    initialValues: {
      email: '',
      password: '',
      lastName: '',
      firstName: '',
      lab: '',
      role: 'user',
      useDefaultPassword: true,
    } as CreateAdminVariables,
    validationSchema: schema,

    onSubmit: async (v) => {
      const data = { ...v };
      if (v.role === 'user') {
        delete data.lab;
      }
      if (v.useDefaultPassword) {
        delete data.password;
      }
      onSubmit(data);
    },
  });
  const medicines = useMemo(() => {
    if (!labData) return [];
    return labData.laboratorys.data.map((lab) => ({
      label: lab.name,
      value: lab.id,
    }));
  }, [labData]);

  useEffect(() => {
    if (item) {
      setValues({
        email: item?.email,
        lastName: item?.lastName || '',
        firstName: item?.firstName || '',
        role: item?.role,
        useDefaultPassword: item?.useDefaultPassword,
        lab: item?.lab?.id || '',
      });
    } else {
      resetForm();
    }
  }, [item]);
  useEffect(() => {
    if (values.role === 'user' && !item) {
      setFieldValue('lab', '');
      setFieldValue('useDefaultPassword', true);
    }
    if (values.role === 'user' && item) {
      setFieldValue('lab', '');
      setFieldValue('useDefaultPassword', false);
    }
    if (values.role === 'lab' && !item) setFieldValue('useDefaultPassword', false);
  }, [values.role]);

  useEffect(() => {
    if (values.useDefaultPassword) {
      setFieldValue('password', '');
      setFieldTouched('password', false, false);
      setFieldError('password', '');
    }
  }, [values.useDefaultPassword]);
  return (
    <form className="flex flex-col gap-1 xl:gap-0 item-center pt-10 xl:pt-8 xl:my-3 m-8" onSubmit={handleSubmit}>
      <Input
        alwaysShowError
        onBlur={handleBlur}
        label="Identifiant"
        value={values.email}
        onChange={handleChange}
        id="email"
        name="email"
        placeholder="Adresse email"
        inputClassName="border-grey-400"
        labelClassName="!text-blue-600"
        error={touched.email && errors.email ? String(errors.email) : ''}
        addEtoile
      />

      <div className="flex gap-3">
        <Input
          alwaysShowError
          onBlur={handleBlur}
          label="Nom"
          value={values.lastName}
          onChange={handleChange}
          id="lastName"
          name="lastName"
          placeholder="Nom"
          inputClassName="border-grey-400"
          labelClassName="!text-blue-600"
          error={touched.lastName && errors.lastName ? String(errors.lastName) : ''}
          addEtoile
        />

        <Input
          alwaysShowError
          onBlur={handleBlur}
          label="Prénom"
          value={values.firstName}
          onChange={handleChange}
          id="firstName"
          name="firstName"
          placeholder="Prénom"
          inputClassName="  border-grey-400"
          labelClassName="!text-blue-600"
          error={touched.firstName && errors.firstName ? String(errors.firstName) : ''}
          addEtoile
        />
      </div>
      <div className="flex gap-3  mb-7 text-base items-start">
        <GuideSelect
          selectLabel="Rôle"
          root="border border-grey-400 flex-1 rounded-5 !text-sm"
          placeholder={item?.role === 'admin' ? 'Admin' : 'Rôle'}
          value={values.role || ''}
          options={RoleOptions}
          onChange={(e: LabelProps) => setFieldValue('role', e.value)}
          addEtoile
          isDisabled={item?.role === 'admin'}
        />

        {values.role === 'lab' ? (
          <GuideSelect
            selectLabel="Nom du laboratoire"
            onBlur={handleBlur}
            root="border border-grey-400 flex-1 rounded-5"
            value={values.lab || ''}
            onChange={(e: LabelProps) => setFieldValue('lab', e.value)}
            placeholder="Nom du laboratoire"
            options={medicines}
            error={touched.lab && errors.lab ? String(errors.lab) : ''}
            addEtoile
          />
        ) : null}
      </div>

      <>
        {values.role !== 'lab' && (
          <div className="flex items-center ">
            <div className="min-w-max mr-4 text-grey-400 text-base font-medium flex gap-2">
              Mot de passe <div className="text-yellow">{!item && '*'}</div>
            </div>
            <Checkbox
              checked={values.useDefaultPassword}
              onChange={(e) => setFieldValue('useDefaultPassword', e.target.checked)}
            />
            <div
              className="text-blue-950 cursor-pointer "
              onClick={() => setFieldValue('useDefaultPassword', !values.useDefaultPassword)}
            >
              (SPLF2024)
            </div>
          </div>
        )}
        <Input
          alwaysShowError
          labelClassName="!text-blue-600"
          label={values.role === 'lab' ? 'Mot de passe' : ''}
          onBlur={handleBlur}
          disabled={values.useDefaultPassword}
          value={values.password}
          onChange={handleChange}
          id="password"
          name="password"
          type="password"
          placeholder="Mot de passe"
          error={touched.password && errors.password ? errors.password : ''}
          inputClassName="border-grey-400"
          className="mt-2"
          addEtoile={!item && values.role === 'lab'}
        />
      </>

      <div className="">
        <Button
          type="submit"
          variant="primary"
          className="w-full  2xl:mb-2 lg:mb-5  rounded-5 text-26 font-semibold h-12"
        >
          {item ? 'Modifier' : 'Ajouter'}
        </Button>
        <div className="text-blue text-lg   w-full flex justify-center mt-4">
          <div onClick={onClose} className="cursor-pointer w-auto font-semibold">
            Annuler
          </div>
        </div>
      </div>
    </form>
  );
};

export default AdminForm;
